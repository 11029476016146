import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { APIMAIN } from '../../../redux/API';
import { StyledTitle } from '../../kit/Title';
import { allBanners } from '../../../components/constructor/Editors/Banner/BannerGifs';
import { useSelector } from 'react-redux';
import bgImg from '../../../assets/images/refer-3-and-its-free.png'

const StyledSection = styled.section`
  padding-top: 40px;
  padding-bottom: 12px;
`;

const StyledMainTitle = styled(StyledTitle)`
  font-size: 2rem;
  margin: 0.5rem 0.5rem;
`;

const StyledMainSubtitle = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
`;


const ProfilePage = ({ data, mode }) => {
  const background = data?.background?.url;
  const avatar = data?.avatar?.url;
  const isMobile = mode === 'mobile';

  return (
    <StyledSection style={{background: data?.background_primary_color}}>
        <StyledMainSubtitle style={{margin: mode === 'mobile' ? '0rem 0rem 6rem 0rem' : ''}}>
          <a target="_blank" href={`https://talkingvcard.com/${(data?.agent_link===null ? '':data?.agent_link)}`} style={{color:data?.body_color}}>
           <img src={bgImg} alt=""  className="fr-fic fr-dib" style={{width:"90%"}}/>
          </a>
        </StyledMainSubtitle>
    </StyledSection>
  );
};
export default ProfilePage;
