export const ALLOW_BOOK_TIME = 'ALLOW_BOOK_TIME'
export const SET_BUTTON_LABEL = 'SET_BUTTON_LABEL'
export const SET_TIME_FORMAT = 'SET_TIME_FORMAT'
export const SET_SESSION_DURATION = 'SET_SESSION_DURATION'
export const SET_AVAILABLE_TIME = 'SET_AVAILABLE_TIME'
export const SET_TIMEZONE = 'SET_TIMEZONE'
export const DELETE_AVAILABLE_TIME = 'DELETE_AVAILABLE_TIME'
export const PUT_AVAILABLES_TO_SERVER = 'PUT_AVAILABLES_TO_SERVER'
export const UPDATE_AVAILABLE_TIME = 'UPDATE_AVAILABLE_TIME'
export const CLEAR_DELETED_AVAILABLES = 'CLEAR_DELETED_AVAILABLES'
