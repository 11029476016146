import axios from 'axios'
import { getLoggedInUser } from '../../utils/helpers'
import * as types from './types'
import API from '../API'
import { fetchVcardDataToEditor } from '../constructor/actions'

export const updateServicesTitle = (string) => ({
  type: types.UPDATE_SERVICES_TITLE,
  string,
})

export const addServicesListItem = (data) => ({
  type: types.ADD_SERVICES_LIST_ITEM,
  data,
})

export const updateServicesListItem = (data) => ({
  type: types.UPDATE_SERVICES_LIST_ITEM,
  data,
})

export const updateServicesList = (data) => ({
  type: types.UPDATE_SERVICES_LIST,
  data,
})

export const deleteServicesListItem = (payload) => ({
  type: types.DELETE_SERVICES_LIST_ITEM,
  payload,
})

export const putServicesToServer = (vcardId, data) => {
  let user = getLoggedInUser()
  data = data.map((item) => {
    if (item.id < 0) {
      delete item.id
      return item
    } else return item
  })

  return (dispatch) => {
    axios
      .put(`${API}/vcards/${vcardId}/services`, data, {
        headers: {
          Authorization: `Bearer ${user.auth.token}`,
          'content-Type': 'application/json',
        },
      })
      .then((data) => {
        dispatch(fetchVcardDataToEditor(vcardId))
      })
      .catch((err) => console.warn('putServicesToServer:', err))
  }
}

export const deleteServicesFromServer = (vcardId, deletedServices) => {
  let user = getLoggedInUser()
  let promises = deletedServices.map((item) => {
    return axios.delete(`${API}/vcards/${vcardId}/services/${item}`, {
      headers: {
        Authorization: 'Bearer ' + user.auth.token,
        'content-Type': 'application/json',
      },
    })
  })
  return (dispatch) => {
    Promise.all(promises)
      .then((data) => {
        dispatch(clearDeletedServices())
        dispatch(fetchVcardDataToEditor(vcardId))
      })
      .catch((err) => console.warn('deleteServicesFromServer', err))
  }
}

export const clearDeletedServices = () => ({
  type: types.CLEAR_DELETED_SERVICES,
})
