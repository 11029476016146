import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { APIMAIN } from '../../../../redux/API';
import {
  fetchContent,
  fetchContentJson,
  contentInfo
} from '../../../../redux/request/actions';
import { STYLES } from '../../../../styles/index';
import DefaultTextArea from '../../../kit/DefaultTextArea';
import DefaultTextField from '../../../kit/DefaultTextField';
import SaveBtn from '../../../kit/SaveBtn';
import Header from '../Header';

import ReactDOM from 'react-dom';
import { getLoggedInUser } from '../../../../utils/helpers';


const StyledTitle = styled.h3`
  margin: 1rem 0;
  color: ${STYLES.COLORS.body};
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
`;

const MainContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector(
    (state) => state?.ConstructorReducer
  );

  const initState = {
    distributor_url: vcardDataToEdit?.distributor_url,
    distributor_title_id: vcardDataToEdit?.distributor_title_id,
    company_name: vcardDataToEdit?.company_name,
    about_title: vcardDataToEdit?.about_title,
    about_content: vcardDataToEdit?.about_content,
  };

  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });
  const [mainContent, setMainContent] = useState(initState);
  const [avatar, setAvatar] = useState(null);
  const [background, setBackground] = useState(null);

  const contentChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.id;
    const newStore = { ...mainContent };
    newStore[name] = value;
    setMainContent(newStore);
  };

  const isMessage = mainContent?.distributor_url !== '';

  const isSameContent =
    JSON.stringify(mainContent) === JSON.stringify(initState);

  const isSameData = isSameContent;

  const saveHandler = (e) => {
    e.preventDefault();
    if (!isSameContent)
      dispatch(contentInfo(localStorage.getItem('c_id'), mainContent)); 
  };

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isSameData || !isMessage} />
      <DefaultTextField
        value={mainContent?.distributor_title_id || ''}
        onChange={contentChangeHandler}
        label='Distributor Heading'
        id="distributor_title_id"
      />
      <DefaultTextField
        value={mainContent?.distributor_url || ''}
        onChange={contentChangeHandler}
        label='Distributor Url'
        required
        id="distributor_url"
      />
      <SaveBtn disabled={isSameData || !isMessage} />
    </form>
  );
};

export default MainContent;
