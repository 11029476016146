import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LinkM from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/MainLogo';
import Copyright from '../../../components/other/Copyright';
import {
  fetchRegisterUser,
  registerCompUser,
  fetchRegisterReferUser
} from '../../../redux/auth/actions';
import axios from 'axios';
import API from './../../../redux/API';
import { FalseFirstVcardFailed } from '../../../redux/request/actions';
import CreateFirstVcard from '../createFirstVcard';
import LanguageSelect from '../../../components/multiLanguage/LanguageSelect';

// function Copyright() {
//   const { t } = useTranslation();

//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {t('copyrightText')}
//       <LinkM color="inherit" href={window.location.origin}>
//         {t('dashboard')}
//       </LinkM>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  succes: {
    margin: theme.spacing(1),
    backgroundColor: 'green',
  },
  div: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const userInit = {
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    refer_name:''
  };
  const minPassword = 0;
  const [userData, setUserData] = useState(userInit);

  let { error, isRegistered } = useSelector((state) => state.Authorization);

  function handleChangeUserData(e) {
    console.log(e.target);
    if (e.target.id === 'password' || e.target.id === 'password_confirmation') {
      setUserData({
        ...userData,
        [e.target.id]: e.target.value.toLowerCase(),
      });
    } else {
      setUserData({
        ...userData,
        [e.target.id]: e.target.value,
      });
    }
  }

  function checkCurrentPassword(e) {
    let a = userData?.password.trim();
    let b = userData?.password_confirmation.trim();

    return a === b && a.length > minPassword && b.length > minPassword;
  }

  let checkPass = useMemo(checkCurrentPassword, [
    userData?.password_confirmation,
    userData?.password,
  ]);

  useEffect(() => {
    dispatch(FalseFirstVcardFailed());
  }, [dispatch]);

  // latest changes from here --------------------
  const [company_id, setCompanyId] = useState('');
  // const [company_name, setCompanyName] = useState('');
  const [getrefer_name, setRefer_name] = useState('');
    const [getrefer_id, setRefer_id] = useState('');
    const [getid, setid] = useState('');
  useEffect(() => {
    if (window.location.href.includes('companyid=')) {
      const company_id = window.location.href.split('companyid=')[1];
      const company_n = window.location.href.split('name=')[1];
      const company_n1 = company_n.replace("&companyid="+company_id, " ");
      const company_n2 = company_n1.replace("%20", " ");
      const company_name = company_n2.replace("%20", " ");
      if (company_id.length > 0) {
        setCompanyId(company_name.replace("%20", " "));
      }
    }
    if (window.location.href.includes('refer_name=')) {
      const refer_name1 = window.location.href.split('name=')[1];
      setid(refer_name1);
      axios
        .get(`${API}/users/${refer_name1}`)
        .then((res) => {
          if (res.data) {
              setRefer_name(res.data.username);
          }
        })
        .catch((err) => {
          console.log("refer error");
        });
      
    }

    if (window.location.href.includes('refer_id=')) {
      const refer_name1 = window.location.href.split('refer_id=')[1];
      setRefer_id(refer_name1);
    }

  }, []);
  const SingUp = (e) => {
    e.preventDefault();
    if (window.location.href.includes('companyid=')) {
      const company_id = window.location.href.split('companyid=')[1];
      if (company_id.length > 0) {
        dispatch(registerCompUser(userData, company_id));
        return;
      }
    }
    if (window.location.href.includes('refer_name=')) {
      const formDate = new FormData();
      formDate.append("username", userData.username);
      formDate.append("email", userData.email);
      formDate.append("password", userData.password);
      formDate.append("refer_name", getid);
      dispatch(fetchRegisterReferUser(userData, formDate));
    }else if (getrefer_id) {
      const formDate = new FormData();
      formDate.append("username", userData.username);
      formDate.append("email", userData.email);
      formDate.append("password", userData.password);
      formDate.append("referer_id", getrefer_id);
      dispatch(fetchRegisterReferUser(userData, formDate));
    }else{
      dispatch(fetchRegisterUser(userData));
    }

  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo />
        <div style={{ marginTop: '20px', marginBottom: '10px' }}>
          <LanguageSelect />
        </div>
        {!isRegistered && (localStorage.getItem("signUP")!=="1") ? (
          <>
            <Typography component="h1" variant="h5">
              {t('createYourAccount')}
            </Typography>
            <form
              autoComplete="off"
              className={classes.div}
              noValidate
              onSubmit={SingUp}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                error={error?.username ? true : false}
                helperText={error?.username ? t('hasAlreadyTaken') : ''}
                onChange={handleChangeUserData}
                id="username"
                label={t('name')}
                name={t('username')}
                autoFocus
                autoComplete="uname"
              />
              {company_id.length > 0 && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  // error={error?.username ? true : false}
                  // helperText={error?.username ? t('hasAlreadyTaken') : ''}
                  disabled
                  value={company_id}
                  id="company_id"
                  label={t('distributorId')}
                  name={t('distributorId')}
                />
              )}

              {getrefer_name && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  disabled
                  onChange={handleChangeUserData}
                  value={getrefer_name}
                  id="refer_name"
                  label="Authorized Seller"
                  name="refer_name"
                autoFocus
                autoComplete="refer_name"
                />
              )}

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                error={error?.email ? true : false}
                helperText={error?.email ? error?.email : ''}
                onChange={(e) => {
                  handleChangeUserData(e);
                }}
                id="email"
                type="email"
                label={t('emailAddress')}
                name={t('email')}
                autoComplete="email"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={handleChangeUserData}
                name={t('password')}
                label={t('password')}
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={handleChangeUserData}
                name={t('confirmPassword')}
                label={t('confirmPassword')}
                type="password"
                id="password_confirmation"
                autoComplete="confirm-password"
              />

              <p className="error-text">
                {!checkPass &&
                userData.password_confirmation.length > minPassword
                  ? t('passwordDontMatch')
                  : userData.password_confirmation.length > 5
                  ? ' '
                  : ' '}
              </p>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={t('rememberMe')}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={!checkPass}
                className={classes.submit}
              >
                {t('signUP')}
              </Button>
              <Grid container>
                <Grid item xs>
                  <LinkM href="/forgot-password" variant="body2">
                    {t('forgotPassword')}
                  </LinkM>
                </Grid>
                <Grid item>
                  <Link to="/sign-in">{t('alreadyHaveanAccount')}</Link>
                </Grid>
              </Grid>
            </form>
          </>
        ) : (
          <CreateFirstVcard />
        )}
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
