// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://www.stripe.com/docs/payments/integration-builder

import axios from 'axios'
import React, { useEffect } from 'react'
import 'react-credit-cards/lib/styles.scss'
import RecordVideo from '../components/constructor/Editors/RecordVideoOld'
import API from '../redux/API'
import { getLoggedInUser } from '../utils/helpers'

export default function MyCard() {
  // const dispatch = useDispatch();
  let { auth } = getLoggedInUser()

  useEffect(() => {
    axios
      .post(
        API + 'subscriptions/payment_with_discount',
        {
          //   coupon_id: coupon,
        },
        {
          headers: {
            Authorization: 'Bearer ' + auth.token,
            'content-Type': 'application/json',
          },
        }
      )
      .then(({ data }) => {
        console.log('data', data)
      })
      .catch((err) => {
        console.warn('err', err)
      })
  }, [auth.token])

  return (
    <div className='record-video'>
      {/* <GoogleLogin
        clientId="570934385380-ape0ol9m58h1ri9fi315plrsol2fmsq1.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      /> */}

      <RecordVideo />
    </div>
  )
}
