import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getLoggedInUser } from '../../utils/helpers'
import Phonenumber from '../../utils/PhoneNumber'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
}))

export default function ContactInformation() {
  const classes = useStyles()
  const { t } = useTranslation();

  const { formData } = useSelector((state) => state.Request)
  const user = getLoggedInUser()
  const [linksData, setLinksData] = useState({
    email: user?.user?.email,
    'display_call_me?': formData.get('display_call_me?'),
    'display_text_me?': formData.get('display_text_me?'),
    'display_email?': formData.get('display_email?'),
    'display_whatsapp?': formData.get('display_whatsapp?'),
    'display_add_to_contacts?': formData.get('display_add_to_contacts?'),
    phone_number: JSON.parse(formData.get('phone_number')),
  })

  function handleContentChange(event) {
    let newValue = event.target.value || ''
    let id = event.target.id
    if (id === 'phone_number') {
      if (newValue) {
        let number = newValue.match(/\d+$/gi)
        if (number == null) return false
      }
    }
    let newStore = { ...linksData }
    newStore[id] = newValue
    formData.set(id, newValue)

    setLinksData(newStore)
  }

  function handleCheckChange(e, check) {
    let newValue = check
    let id = e.target.id
    let newStore = { ...linksData }
    newStore[id] = newValue
    formData.delete(id)
    formData.set(id, newValue)
    setLinksData(newStore)
  }

  function handleChangePhone(data) {
    formData.set('phone_number', JSON.stringify(data))
    let newStore = { ...linksData }
    newStore.phone_number = data
    setLinksData(newStore)
  }

  return (
    <form className={classes.root} noValidate autoComplete='off'>
      <div className='mb-4'>
        <h6 className='form_title'>{t('enterPhone')}</h6>
        <Phonenumber
          onChange={handleChangePhone}
          value={linksData?.phone_number}
        />
        <h6 className='form_description'>{t('pleaseEnterYourEmail')}</h6>
        <div style={{ display: 'flex' }}>
          <TextField
            id='email'
            placeholder={t('emailFormat')}
            value={linksData?.email ?? formData.get('email')}
            onChange={handleContentChange}
            name={t('email')}
          />
        </div>
        <FormGroup column='true'>
          <FormControlLabel
            className='form_checkbox'
            control={
              <Checkbox
                checked={!!linksData['display_call_me?']}
                onChange={handleCheckChange}
                id='display_call_me?'
                name={t('callMe')}
              />
            }
            label={t('displayCallMe')}
          />
          <FormControlLabel
            className='form_checkbox'
            control={
              <Checkbox
                checked={!!linksData['display_text_me?']}
                onChange={handleCheckChange}
                id='display_text_me?'
                name={t('textMe')}
              />
            }
            label={t('displaTextMe')}
          />
          <FormControlLabel
            className='form_checkbox'
            control={
              <Checkbox
                checked={!!linksData['display_email?']}
                onChange={handleCheckChange}
                id='display_email?'
                name={t('email')}
              />
            }
            label={t('displayEmailButton')}
          />
          <FormControlLabel
            className='form_checkbox'
            control={
              <Checkbox
                checked={!!linksData['display_whatsapp?']}
                onChange={handleCheckChange}
                id='display_whatsapp?'
                name={t('whatsApp')}
              />
            }
            label={t('displayWhatsappButton')}
          />
          <FormControlLabel
            className='form_checkbox'
            control={
              <Checkbox
                checked={!!linksData['display_add_to_contacts?']}
                onChange={handleCheckChange}
                id='display_add_to_contacts?'
                name={t('addcontacts')}
              />
            }
            label={t('displayAddContactButton')}
          />
        </FormGroup>
      </div>
    </form>
  )
}
