import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setTimezone } from '../../../../redux/appointmentsSettings/action'
import { STYLES } from '../../../../styles'

const allUTCTimeZones = [
  {
    text: 'UTC -12:00 United States Minor Outlying Islands',
    timezone: -12,
  },
  {
    text: 'UTC -11:00 United States / New Zealand',
    timezone: -11,
  },
  {
    text: 'UTC -10:00 Hawaii',
    timezone: -10,
  },
  {
    text: ' UTC -09:30 Marquesas Islands',
    timezone: -9.5,
  },
  {
    text: 'UTC -09:00 Alaska',
    timezone: -9,
  },
  {
    text: 'UTC -08:00 Pacific Time Zone (USA/Canada)',
    timezone: -8,
  },
  {
    text: 'UTC -07:00 Mountain time (USA/Canada)',
    timezone: -7,
  },
  {
    text: 'UTC -06:00 Central time zone (USA/Canada)',
    timezone: -6,
  },
  {
    text: 'UTC -05:00 Eastern time zone (USA/Canada)',
    timezone: -5,
  },
  {
    text: 'UTC -04:00 Atlantic time zone (Canada)',
    timezone: -4.5,
  },
  {
    text: 'UTC -03:30 Newfoundland',
    timezone: -3.5,
  },
  {
    text: 'UTC -03:00 Brasilia',
    timezone: -3,
  },
  {
    text: ' UTC -02:00 South Georgia and the South Sandwich Islands',
    timezone: -2,
  },
  {
    text: 'UTC -01:00 Azores islands',
    timezone: -1,
  },
  {
    text: 'UTC 00:00 London',
    timezone: 0,
  },
  {
    text: 'UTC +01:00 Berlin, Rome, Paris, Madrid, Warsaw, Lagos, Kinshasa, Algiers, Casablanca',
    timezone: 1,
  },
  {
    text: 'UTC +02:00 Cairo, Johannesburg, Khartoum, Kyiv, Bucharest, Athens, Jerusalem, Sofia',
    timezone: 2,
  },
  {
    text: 'UTC +03:00 Moscow, Istanbul, Riyadh, Baghdad, Addis Ababa, Doha',
    timezone: 3,
  },
  {
    text: 'UTC +03:30 Tehran',
    timezone: 3.5,
  },
  {
    text: 'UTC +04:00 Dubai, Baku, Tbilisi, Yerevan, Samara',
    timezone: 4,
  },
  {
    text: 'UTC +04:30 Kabul',
    timezone: 4.5,
  },
  {
    text: 'UTC +05:00 Karachi, Tashkent, Yekaterinburg',
    timezone: 5,
  },
  {
    text: 'UTC +05:30 Mumbai, Colombo',
    timezone: 5.5,
  },
  {
    text: 'UTC +06:00 Bishkek, Dhaka, Almaty, Omsk',
    timezone: 6,
  },
  {
    text: 'UTC +06:30 Yangon',
    timezone: 6.5,
  },
  {
    text: 'UTC +07:00 Jakarta, Ho Chi Minh City, Bangkok, Krasnoyarsk',
    timezone: 7,
  },
  {
    text: 'UTC +08:00 Shanghai, Taipei, Kuala Lumpur, Singapore, Perth, Manila, Makassar, Irkutsk',
    timezone: 8,
  },
  {
    text: 'UTC +09:00 Tokyo, Seoul, Pyongyang, Ambon, Chita',
    timezone: 9,
  },
  {
    text: 'UTC +09:30 Adelaide',
    timezone: 9.5,
  },
  {
    text: 'UTC +10:00 Sydney, Port Moresby, Vladivostok',
    timezone: 10,
  },
  {
    text: 'UTC +11:00 Nouméa',
    timezone: 11,
  },
  {
    text: 'UTC +12:00 Auckland, Suva, Petropavlovsk-Kamchatsky',
    timezone: 12,
  },
  {
    text: 'UTC +13:00 Samoa',
    timezone: 13,
  },
  {
    text: 'UTC +14:00 Kiribati',
    timezone: 14,
  },
]

const StyledSelect = styled(Select)`
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  margin-top: 1rem;
  &.MuiOutlinedInput-root.Mui-focused > fieldset {
    border-color: ${STYLES.COLORS.lightBlue};
  }
  &.MuiOutlinedInput-root:hover > fieldset {
    border-color: rgba(0, 0, 0, 0.23);
  }
  &.MuiOutlinedInput-root > fieldset {
    top: 0;
    border: none;
  }
`

const SelectTimeZone = ({ timezone }) => {
  const dispatch = useDispatch()
  const timezoneHandler = (e) => {
    dispatch(setTimezone(e.target.value.toString()))
  }

  return (
    <div>
      <StyledSelect value={timezone || ''} onChange={timezoneHandler}>
        {allUTCTimeZones.map((item) => (
          <MenuItem value={item.timezone} key={item.timezone + 'timezone'}>
            {item.text}
          </MenuItem>
        ))}
      </StyledSelect>
    </div>
  )
}

export default SelectTimeZone
