import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addVideoLink,
  deleteVideosFromServer,
  putVideosToServer,
  updateVideos,
} from '../../../../redux/StoryvideoLibrary/action';
import {
  fetchContentJson,
} from '../../../../redux/request/actions';
import AddBtn from '../../../kit/AddBtn'
import SaveBtn from '../../../kit/SaveBtn'
import Wrapper from '../../../kit/Wrapper'
import Header from '../Header'
import VideoItem from './VideoItem';
import DefaultTextField from '../../../kit/DefaultTextField';

const VideoLibrary = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { vcardDataToEdit } = useSelector(
    (state) => state?.ConstructorReducer
  );

  const initState = {
    story_video_title: vcardDataToEdit?.story_video_title,
  };

  const [mainContent, setMainContent] = useState(initState);

  const contentChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.id;
    const newStore = { ...mainContent };
    newStore[name] = value;
    setMainContent(newStore);
  };

  const isSameContent =
    JSON.stringify(mainContent) === JSON.stringify(initState);

  const { videoLibrary, deletedVideos } = useSelector(
    (s) => s?.videoLibraryReducer
  )
  const { id, story_videos } = useSelector(
    (s) => s?.ConstructorReducer?.vcardDataToEdit
  )

  const addVideoLinkHandler = () => {
    const id = Math.round(Math.random() * -1000)
    const link = {
      id,
      url: '',
    }
    dispatch(addVideoLink(link))
  }

  const isVideosSame = useMemo(() => {
    return JSON.stringify(story_videos) === JSON.stringify(videoLibrary)
  }, [story_videos, videoLibrary]) && isSameContent;

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isSameContent)
      dispatch(fetchContentJson(vcardDataToEdit?.id, mainContent));
    if (!isVideosSame) {
      if (videoLibrary.length) dispatch(putVideosToServer(id, videoLibrary))
      if (deletedVideos.length)
        dispatch(deleteVideosFromServer(id, deletedVideos))
    }
  }

  useEffect(() => {
    dispatch(updateVideos(story_videos))
  }, [dispatch, story_videos])

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isVideosSame} />
      <DefaultTextField
        value={mainContent?.story_video_title || ''}
        onChange={contentChangeHandler}
        label={t('title')}
        id="story_video_title"
      />
      <Wrapper>
        {videoLibrary?.map((item, idx) => (
          <VideoItem item={item} key={idx} />
        ))}
        <AddBtn onClick={addVideoLinkHandler} textContent={t('link')} />
      </Wrapper>
      <SaveBtn array={videoLibrary} disabled={isVideosSame} />
    </form>
  )
}

export default VideoLibrary
