import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Welcome from '../../../components/session/Welcome';
import AboutCompany from '../../../components/session/AboutCompany';
import UploadPictures from '../../../components/session/UploadPictures';
import ContactInformation from '../../../components/session/ContactInformation';
import Domain from '../../../components/session/Domain';
import HappyStart from '../../../components/session/HappyStart';
import styled from 'styled-components';
import { createFirstVcard } from '../../../redux/request/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  skipButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    'Welcome',
    'About Me',
    'Upload Pictures',
    'Contact Pnformation',
    'Domain',
  ];
}

function getStepContent(stepIndex, distributorId) {
  switch (stepIndex) {
    case 0:
      return <Welcome distributorId={distributorId} />;
    case 1:
      return <AboutCompany distributorId={distributorId} />;
    case 2:
      return <UploadPictures distributorId={distributorId} />;
    case 3:
      return <ContactInformation distributorId={distributorId} />;
    case 4:
      return <Domain distributorId={distributorId} />;
    default:
      return 'Unknown stepIndex';
  }
}

export default function CreateFirstVcard() {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, formData, domainIsValid, domainError, firstVcardCreated } =
    useSelector((state) => state.Request);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (domainIsValid) {
        dispatch(createFirstVcard(formData));
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const [distributorId, setDistributorId] = useState('');
  useEffect(() => {
    if (window.location.href.includes('companyid=')) {
      const distributorId = window.location.href.split('companyid=')[1];
      console.log(distributorId);
      if (distributorId.length > 0) {
        setDistributorId(distributorId);
      }
    }
  }, []);

  useEffect(() => {
    formData.set('display_call_me?', true);
    formData.set('display_text_me?', true);
    formData.set('display_email?', true);
    formData.set('display_whatsapp?', true);
    formData.set('display_add_to_contacts?', true);
    if (firstVcardCreated) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [formData, firstVcardCreated]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getColorMessage(domainIsValid) {
    return domainIsValid
      ? { color: 'green', fontSize: '14px' }
      : { color: 'red', fontSize: '14px' };
  }

  const StyledVideoFrame = styled.div`
  overflow: hidden;
  margin: 0 auto;
  max-width: 700px;
  aspect-ratio: 16/9;
  background-color: ${(props) => props.color};

  & iframe {
    width: 100%;
    height: 100%;
  }
`;

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep===0 ? 
      <StyledVideoFrame style={{'margin-top': '20px'}}>
        <iframe
          src="https://player.vimeo.com/video/820300297?h=cc77490679"
          title="Sign Up"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </StyledVideoFrame>
      : ''}
      <div className={classes.content}>
        {activeStep === steps.length ? (
          <div>
            <HappyStart distributorId={distributorId} />
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, distributorId)}
            </Typography>
            {domainError && activeStep === steps.length - 1 ? (
              <h6
                style={{
                  ...getColorMessage(domainIsValid),
                  lineHeight: '1.2',
                  marginBottom: '0.5rem',
                  fontWeight: '500',
                }}
              >
                {t(domainError)}
              </h6>
            ) : (
              ''
            )}
            <div style={{ display: 'flex' }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                {t('back')}
              </Button>
              {activeStep !== steps.length - 1 ? (
                <Button
                  className={classes.skipButton}
                  variant="outlined"
                  color="primary"
                  onClick={handleNext}
                >
                  {t('skip')}
                </Button>
              ) : (
                false
              )}
              <Button
                disabled={
                  activeStep === steps.length - 1 ? !domainIsValid : false
                }
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? t('finish') : t('next')}
              </Button>
              {loading ? (
                <div
                  style={{
                    paddingLeft: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress style={{ width: '30px', height: '30px' }} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
