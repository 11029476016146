import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import DefaultTextField from '../../components/kit/DefaultTextField';
import API from '../../redux/API';
import { updateAccount, updateUserData } from '../../redux/request/actions';
import { getLoggedInUser } from '../../utils/helpers';
import Title from './Title';
import {
  referralRequest,
} from '../../redux/request/actions';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: 'min-content',
    whiteSpace: 'nowrap',
  },
}));

export default function ReferRequest() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector(
    (state) => state?.ConstructorReducer
  );

  const initState = {
    title: vcardDataToEdit?.title,
  };

  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });
  const [mainContent, setMainContent] = useState(initState);
  const [getRefer, setrefer]=useState("1");
  const classes = useStyles();
  const contentChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.id;
    const newStore = { ...mainContent };
    newStore[name] = value;
    setMainContent(newStore);
  };

  const isMessage = mainContent?.title !== '';

  const isSameContent =
    JSON.stringify(mainContent) === JSON.stringify(initState);

  const isSameData = isSameContent;

  
  const onSumbit = (e) => {
    e.preventDefault();
    setrefer("2");
    localStorage.setItem('dkrefer','2')
    dispatch(referralRequest(mainContent));
  };
  var refer= localStorage.getItem('dkrefer');
  
  return (
    <div style={{ maxWidth: 370 }}>
      {
        (user?.is_referral_request === true) ?
          "You have already submitted the form,  vCard Administrator will contact you back."         
        :
        (getRefer === "2" || refer === '2') ?
          "You have already submitted the form,  vCard Administrator will contact you back."         
        :
        <>
      <Title>{t('Refer 3 and it`s FREE')}</Title>
      <DefaultTextField
        label={t('name')}
        subtitle={t('Enter full name')}
        onChange={contentChangeHandler}
        id="name"
      />
      <DefaultTextField
        label={t('email')}
        subtitle={t('Enter email address')}
        onChange={contentChangeHandler}
        id="email"
      />
      <DefaultTextField
        label={t('User Name')}
        subtitle={t('This is the referral name for your page')}
        onChange={contentChangeHandler}
        id="username"
      />
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onSumbit}
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        {t('save')}
      </Button>
      </>
        }
    </div>
  );
}
