import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Modal } from 'react-bootstrap'
import { bannerUploade, selectBanner, getAllBanners,setBannerUser, deleteBannerUser } from '../../../redux/banner/actions';
import SaveBtn from '../../kit/SaveBtn';

const ScreenRecording = () => {
  const dispatch = useDispatch();
  const { vcardDataToEdit, formData } = useSelector(
    (state) => state?.ConstructorReducer
  );
const [getCountDownTime, setCountDownTime] = useState(vcardDataToEdit.time_limit === 0 ? 3 : vcardDataToEdit.time_limit === null ? 3 : vcardDataToEdit.time_limit);
var styles = {
  video: {
    width: "100%"
  },
}
// This array stores the recorded media data
let chunks = []; 
let selectedMedia = "vid";
const [getVideo, setVideo] = useState("");
const [getFile, setFile] = useState("");
function blob2file(blobData) {
  const fd = new FormData();
  fd.set('a', blobData, 'filename');
  return fd.get('a');
}
  const starttimer = async () => {  
      const constraints = {
        video: {
          width: 1280, height: 720
        }
      };
      try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      window.stream = stream;

      const gumVideo = document.querySelector('video#web-cam-container');
      gumVideo.srcObject = stream;
      } catch (e) {
        console.error('navigator.getUserMedia error:', e);
      }
      var timeleft = 5;
      var recordingTime = getCountDownTime*60000;

      var downloadTimer = setInterval(function(){
        if(timeleft <= 0){
          clearInterval(downloadTimer);
          var x = document.getElementById("countdown");
           x.style.display = "none";

          var y = document.getElementById("web-cam-container");
          y.style.display = "block";
          const videoMediaConstraints = {
            audio: true,
            video: true,
          };
          navigator.mediaDevices.getUserMedia(
              selectedMedia === "vid" ?
              videoMediaConstraints :
              '')
              .then((mediaStream) => {

              // Create a new MediaRecorder instance
              const mediaRecorder =
                new MediaRecorder(mediaStream);

              //Make the mediaStream global
              window.mediaStream = mediaStream;
              //Make the mediaRecorder global
              window.mediaRecorder = mediaRecorder;

              mediaRecorder.start();
              countdown();
               setTimeout(
            () => stopRecording(), 
            recordingTime
          );
    mediaRecorder.ondataavailable = (e) => {

      // Push the recorded media data to
      // the chunks array
      chunks.push(e.data);
    };

    // When the MediaRecorder stops
    // recording, it emits "stop"
    // event
    mediaRecorder.onstop = () => {

      /* A Blob is a File like object.
      In fact, the File interface is
      based on Blob. File inherits the
      Blob interface and expands it to
      support the files on the user's
      systemThe Blob constructor takes
      the chunk of media data as the
      first parameter and constructs
      a Blob of the type given as the
      second parameter*/
      const blob = new Blob(
        chunks, {
          type: selectedMedia === "vid" ?
            "video/mp4" : "audio/mpeg"
        });
      setFile(blob);
      // Create a video or audio element
      // that stores the recorded media
      const recordedMedia = document.createElement(
        selectedMedia === "vid" ? "video" : "audio");
      recordedMedia.controls = true;
      // You can not directly set the blob as
      // the source of the video or audio element
      // Instead, you need to create a URL for blob
      // using URL.createObjectURL() method.
      const recordedMediaURL = URL.createObjectURL(blob);

      // Now you can use the created URL as the
      // source of the video or audio element
      setVideo(recordedMediaURL);
      recordedMedia.src = recordedMediaURL;
    };
          console.log(mediaStream);
          const webCamContainer =
            document.getElementById("web-cam-container");
              if (selectedMedia === "vid") {

                // Remember to use the srcObject
                // attribute since the src attribute
                // doesn't support media stream as a value
                webCamContainer.srcObject = mediaStream;
              }

              document.getElementById(
                  `vid-record-status`)
                  .innerText = "Recording";

            });
            var start = document.getElementById("start-vid-recording");
            var stop = document.getElementById("stop-vid-recording");
            start.disabled = true;
            stop.disabled = false;
        } else {
          document.getElementById("countdown").innerHTML = timeleft;

          var y = document.getElementById("countdown");
           y.style.display = "block";
        }
        timeleft -= 1;
      }, 1000);
    }
    const mediaSelector = document.getElementById("media");


const pauseRecording = () => {
  window.mediaRecorder.pause();
}

const resumeRecording = () => {
  window.mediaRecorder.resume();
}


const deleteVideo = () => {

  setVideo("");
}

function stopRecording(){
  setCountDownTime("");
  if(window.mediaRecorder.state==="recording"){
    // Stop the recording
    window.mediaRecorder.stop();

    // Stop all the tracks in the
    // received media stream
    window.mediaStream.getTracks()
    .forEach((track) => {
      track.stop();
    });


    window.stream.getTracks()
    .forEach((track) => {
      track.stop();
    });

      document.getElementById(`vid-record-status`).innerText = "Recording done!";
        var start = document.getElementById("start-vid-recording");
        var stop = document.getElementById("stop-vid-recording");
        start.disabled = false;
        stop.disabled = true;
  }
}

const cstopRecording = () => {
  setCountDownTime("");
  // Stop the recording
  window.mediaRecorder.stop();

  // Stop all the tracks in the
  // received media stream
  window.mediaStream.getTracks()
  .forEach((track) => {
    track.stop();
  });

  window.stream.getTracks()
    .forEach((track) => {
      track.stop();
    });

  document.getElementById(`vid-record-status`).innerText = "Recording done!";
  var start = document.getElementById("start-vid-recording");
  var stop = document.getElementById("stop-vid-recording");
  start.disabled = false;
  stop.disabled = true;
}

const saveHandler = (e) => {
  e.preventDefault();
  const formDate = new FormData();
  formDate.append("icon", getFile);
  dispatch(bannerUploade(39, formDate));
};

function startTimer(duration, display) {
    var timer = duration, minutes, seconds;
    setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
            timer = duration;
        }
    }, 1000);
}

function countdown() {
    var fiveMinutes = 60 * getCountDownTime,
        display = document.querySelector('#time');
    startTimer(fiveMinutes, display);
};


return (
    <div className="Scren-Record-Wrapper" style={{ padding: "5px 20px" }}>
  <div className="display-none recorder_menu mb-3" id="vid-recorder">
    <h3>Record Video </h3>
    <div id="countdown" style={{"text-align": "center",width: "100%",height: "100px","font-size": "42px",fontWeight: 900}}></div>
    <video autoplay='autoplay' muted id="web-cam-container" style={{ width: "100%",height: "250px",background:"black" }}
      >
      Your browser doesn't support
      the video tag
    </video>
    <div className="recording" id="vid-record-status">
      Click the "Start video Recording"
      button to start recording
    </div>
    <div className='recorder_menu mb-3'>
                <Button
                  className='mx-1'
                  onClick={starttimer}
                   id="start-vid-recording"
                  style={{ background: 'green', color: 'white' }}
                >
                  Start
                </Button>
                <Button
                id="start"
                  className='mx-1'
                  style={{ background: '#3f51b5', color: 'white' }}
                  onClick={pauseRecording}
                >
                  Pause
                </Button>
                <Button
                  className='mx-1'
                  style={{ background: '#3f51b5', color: 'white' }}
                  onClick={resumeRecording}
                >
                  Resume
                </Button>
                <Button
                  className='mx-1'
                  id="stop-vid-recording"
                  onClick={cstopRecording}
                  style={{ background: 'red', color: 'white' }}
                >
                  Stop
                </Button>
              {getVideo !== "" && (
                <>
                <Button
                  className='mx-1'
                  id="stop-vid-recording"
                  onClick={deleteVideo}
                  style={{ background: 'red', color: 'white' }}
                >
                  Delete
                </Button></>
              )}
              </div>
              {getVideo === "" && (
                              <>
                  <div><span id="time">0{getCountDownTime}:00</span> minutes!</div>
                  </>
              )}
              {getVideo !== "" && (
                <>
                  <video controls src={getVideo} style={{ width: "100%",height: "250px",background:"black" }}>
                    Your browser doesn't support the video tag
                  </video>
                </>
              )}
              <SaveBtn style={{ marginBottom:10 }} onClick={saveHandler}/>
  </div>
    </div>
  );
};
export default ScreenRecording;
