import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import DefaultTextField from '../components/kit/DefaultTextField'
import { checkDomainOnValid, createFirstVcard } from '../redux/request/actions'
import { useTranslation } from 'react-i18next'

const StyledDomainTextField = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 2px;
  label {
    margin: 0;
    width: 100%;
  }
  span {
    font-size: 1.125rem;
  }
`

export const EnterDomainPrefixForNewVcard = (props) => {
  const { t } = useTranslation()

  const [domain, setDomain] = useState('')
  const [domain_prefix, setDomainToFetch] = useState('')

  const dispatch = useDispatch()
  const state = useSelector((state) => {
    return state.Request
  })

  function handleContentChange(e) {
    let newValue = e.target.value
      ?.toLowerCase()
      ?.replace(/\s+/g, '-')
      ?.replace(/^[-]$/gi, '')
      ?.replace(/[^a-z0-9-]/g, '')
    let trimmedValue = newValue.replace(/^[^a-z\d]*|[^a-z\d]*$/gi, '')
    setDomain(newValue)
    setDomainToFetch(trimmedValue)
    dispatch(checkDomainOnValid(newValue))
  }

  const handleSumbit = () => {
    dispatch(createFirstVcard({ domain_prefix }, 'newVcard'))
    props.setModalShow(false)
    setDomain('')
  }

  function getStyle() {
    if (state?.domainIsValid)
      return { padding: '3px 0px 0px 10px', color: 'green', height: 20 }
    else return { padding: '3px 0px 0px 10px', color: 'red', height: 20 }
  }

  return (
    <>
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ width: '100%' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              {t('addyourCustomSubdomain')} {t('note')}: <br></br>
              <span style={{ color: 'rgba(240,10,10,.8)' }}>
                {t('editDomainDesc')}
              </span>{' '}
              <br></br>
              {t('yourCardPublishURL')} <br></br>{t('ex')}:
              {t('yournameDomain')}
            </div>
            <StyledDomainTextField>
              <DefaultTextField
                required
                label={t('domain')}
                placeholder={t('myvcard')}
                value={domain}
                onChange={(e) => handleContentChange(e, 'domain_prefix')}
              />
              <span>.talkingvcard.net</span>
            </StyledDomainTextField>
            <div style={getStyle()}>{state?.domainError}</div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              style={{ marginRight: '10px' }}
              disabled={!state?.domainIsValid}
              onClick={handleSumbit}
            >
              {t('create')}
            </Button>
            <Button onClick={props.onHide}>{t('close')}</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
