import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import StarBorder from '@material-ui/icons/StarBorder';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '../../redux/auth/actions';
import { getLoggedInUser } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export const MainListItems = ({ setPage }) => {
  var refer= localStorage.getItem('dkrefer');
  const { t } = useTranslation();

  const classes = useStyles();
  // const [open, setOpen] = useState(false)
  const open = false;

  let { user } = getLoggedInUser();
  const [userData, setUserData] = useState({ ...user });

  return (
    <div>
      <ListItem
        onClick={() => {
          setPage('accountInfo');
        }}
        button
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={t('accountInformation')} />
      </ListItem>
      <ListItem
        onClick={() => {
          setPage('myvcards');
        }}
        button
      >
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary={t('myVcards')} />
      </ListItem>
      <ListItem
        onClick={() => {
          setPage('settings');
        }}
        button
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={t('settings')} />
      </ListItem>
      {userData?.user_type === 'admin' && (
        <ListItem
          onClick={() => {
            setPage('corporateAccount');
          }}
          button
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t('corporateAccount')} />
        </ListItem>
      )}
      {userData?.user_type === 'content_admin' && (
      <>
        <ListItem
          onClick={() => {
            setPage('companies');
          }}
          button
        >
          <ListItemIcon>
            <FormatListBulletedIcon />
          </ListItemIcon>
          <ListItemText primary={'Company List'} />
        </ListItem>        
        <ListItem
          onClick={() => {
            setPage('MenuVideo');
          }}
          button
        >
          <ListItemIcon>
            <MissedVideoCallIcon />
          </ListItemIcon>
          <ListItemText primary={'Menu Video'} />
        </ListItem>
        </>
      )}
      {userData?.user_type === 'super_admin' && (
        <>
        <ListItem
          onClick={() => {
            setPage('companies');
          }}
          button
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={'SaaS'} />
        </ListItem>
        <ListItem
          onClick={() => {
            setPage('customer');
          }}
          button
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={'Legacy List'} />
        </ListItem>
        </>
      )}
      {(userData?.user_type === 'sales' || userData?.is_referral_request === true)  && (
        <>
        <ListItem
          onClick={() => {
            setPage('referUserList');
          }}
          button
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary={'Sales'} />
        </ListItem>
        <ListItem
          onClick={() => {
            setPage('link');
          }}
          button
        >
          <ListItemIcon>
            <InsertLinkIcon />
          </ListItemIcon>
          <ListItemText primary={t('Link')} />
        </ListItem>
        </>
      )}
      {(userData?.is_referral_request === false) ? (
        <>
        <ListItem
          onClick={() => {
            setPage('referRequest');
          }}
          button
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary={'Refer 3 and it`s FREE'} />
        </ListItem>
        </>
      )
      :
        ""
      }
      {/* <ListItem
        onClick={() => {
          setPage("reseller");
          handleClick();
        }}
        button
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Reseller" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem> */}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t('information')} />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t('sellvCard')} />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t('settings')} />
          </ListItem>
        </List>
      </Collapse>
    </div>
  );
};

export const SecondaryListItems = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div>
      {/* <ListSubheader inset>Saved reports</ListSubheader> */}
      <Divider />
      <ListItem onClick={() => dispatch(logOutUser())} button>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary={t('logout')} />
      </ListItem>
    </div>
  );
};
