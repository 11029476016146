import axios from 'axios';
import { setSession, getLoggedInUser } from '../../utils/helpers';
import { updateVcardData } from '../constructor/actions';
import * as types from './constants';

import API from './../API';

export const fetchLoginUser = (data) => {
  // console.log('from login actions', data);
  return (dispatch) => {
    dispatch(loginUser());
    axios
      .post(
        API + '/auth',
        {},
        {
          auth: {
            username: (data.username).toLowerCase(),
            password: data.password,
          },
        }
      )
      .then((res) => {
        setSession(res.data);
        // dsa
        dispatch(loginUserSuccess(res.data));
        // if(res.data.auth=='')
      })
      .catch((err) => {
        let messsage = '';
        if (err.response.status === 401) {
          messsage = 'Invalid login or password.';
        } else if (err.response.status) {
          messsage = 'Server error, code: ' + err.response.status;
        }
        dispatch(loginUserFailed(messsage));
      });
  };
};

export const fetchForgotPassword = (email) => {
  return async (dispatch) => {
    dispatch(loginUser());
    let data = await axios
      .post(API + '/users/forgot_password', {
        email,
      })
      .catch(() => 'error');
    dispatch(forgotUserFailed(data));
  };
};

export const fetchChangePassword = (dataBlock) => {
  return async (dispatch) => {
    dispatch(loginUser());
    let data = await axios
      .post(API + '/users/reset_password', dataBlock)
      .catch(() => 'error');
    dispatch(forgotUserFailed(data));
  };
};

export const loginUser = () => {
  return {
    type: types.LOGIN_USER,
    payload: null,
  };
};

export const loginUserSuccess = (data) => {
  localStorage.removeItem('vcard_id');
  return (dispatch) => {
    dispatch(updateVcardData(null));
    dispatch({
      type: types.LOGIN_USER_SUCCESS,
      payload: data,
    });
  };
};

export const loginUserFailed = (data) => {
  return {
    type: types.LOGIN_USER_FAILED,
    payload: data,
  };
};

export const forgotUser = () => {
  return {
    type: types.FORGET_PASSWORD,
    payload: null,
  };
};

export const forgotUserSuccess = (data) => {
  return {
    type: types.FORGET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const forgotUserFailed = (data) => {
  return {
    type: types.FORGET_PASSWORD_FAILED,
    payload: data,
  };
};

export const logOutUser = (data) => {
  setSession(null);
  return {
    type: types.LOGOUT_USER,
    payload: data,
  };
};

export const registerUserSuccess = (data) => {
  return {
    type: types.REGISTER_USER_SUCCESS,
    payload: data,
  };
};

export const registerUserFailed = (data) => {
  return {
    type: types.REGISTER_USER_FAILED,
    payload: data,
  };
};

export const fetchRegisterUser = (data) => {
  // КОСТЫЛЬ НАДО УДАЛИТЬ
  // КОСТЫЛЬ НАДО УДАЛИТЬ
  // КОСТЫЛЬ НАДО УДАЛИТЬ
  // КОСТЫЛЬ НАДО УДАЛИТЬ
  // console.log('from action:', data);
  let dataforLOgin = { username: data.email, password: data.password };
  return (dispatch) => {
    dispatch(loginUser());
    // setTimeout(() => {
    //     dispatch(registerUserSuccess(getLoggedInUser()))
    // }, 500)

    axios
      .post(API + '/users', data, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(({ data }) => {
        if (!data.success) throw data.errors;
        dispatch(registerUserSuccess(getLoggedInUser()));
        axios
          .post(
            API + '/auth',
            {},
            {
              auth: {
                username: dataforLOgin.username,
                password: dataforLOgin.password,
              },
            }
          )
          .then((res) => {
            setSession(res.data);
            setTimeout(() => {
              dispatch(loginUserSuccess(res.data));
              window.location.href = window.location.href = '/#/card';
            }, 10);
          })
          .catch((err) => {
            dispatch(loginUserFailed('Invalid login or password.'));
          });
      })
      .catch((err) => {
        dispatch(registerUserFailed(err));
      });
  };
};

export const registerCompUser = (data, company_id) => {
  // console.log('from action:', data);
  let dataforLOgin = { username: data.email, password: data.password };
  return (dispatch) => {
    dispatch(loginUser());
    // setTimeout(() => {
    //     dispatch(registerUserSuccess(getLoggedInUser()))
    // }, 500)

    axios
      .post(API + `/users?company_id=${company_id}`, data, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(({ data }) => {
        if (!data.success) throw data.errors;
        dispatch(registerUserSuccess(getLoggedInUser()));
        axios
          .post(
            API + '/auth',
            {},
            {
              auth: {
                username: dataforLOgin.username,
                password: dataforLOgin.password,
              },
            }
          )
          .then((res) => {
            setSession(res.data);
            setTimeout(() => {
              dispatch(loginUserSuccess(res.data));
              window.location.href = window.location.href = '/#/card';
            }, 10);
          })
          .catch((err) => {
            dispatch(loginUserFailed('Invalid login or password.'));
          });
      })
      .catch((err) => {
        dispatch(registerUserFailed(err));
      });
  };
};

export const fetchRegisterReferUser = (data,formData) => {
  // КОСТЫЛЬ НАДО УДАЛИТЬ
  // КОСТЫЛЬ НАДО УДАЛИТЬ
  // КОСТЫЛЬ НАДО УДАЛИТЬ
  // КОСТЫЛЬ НАДО УДАЛИТЬ
  // console.log('from action:', data);
  let dataforLOgin = { username: data.email, password: data.password };
  return (dispatch) => {
    dispatch(loginUser());
    // setTimeout(() => {
    //     dispatch(registerUserSuccess(getLoggedInUser()))
    // }, 500)

    axios
      .post(API + '/users', formData, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(({ data }) => {
        if (!data.success) throw data.errors;
        dispatch(registerUserSuccess(getLoggedInUser()));
        axios
          .post(
            API + '/auth',
            {},
            {
              auth: {
                username: dataforLOgin.username,
                password: dataforLOgin.password,
              },
            }
          )
          .then((res) => {
            setSession(res.data);
            setTimeout(() => {
              dispatch(loginUserSuccess(res.data));
              window.location.href = window.location.href = '/#/card';
            }, 10);
          })
          .catch((err) => {
            dispatch(loginUserFailed('Invalid login or password.'));
          });
      })
      .catch((err) => {
        dispatch(registerUserFailed(err));
      });
  };
};


export const isRegisteredFalse = (data) => {
  return {
    type: types.IS_REGISTERED_FALSE,
    payload: data,
  };
};
