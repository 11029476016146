import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Copyright from '../../../components/other/Copyright';
import MainLogo from '../../../assets/images/MainLogo';
import { fetchLoginUser } from '../../../redux/auth/actions';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../../components/multiLanguage/LanguageSelect';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  succes: {
    margin: theme.spacing(1),
    backgroundColor: 'green',
  },
  div: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  let { loading, login, error, user } = useSelector(
    (state) => state.Authorization
  );
  const [userData, setUserData] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    if (user) {
      let { from } = location.state || { from: { pathname: '/' } };
      history.replace(from);
    }
  }, [user, history, location.state]);

  function handleChangeUserData(e) {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  }

  const SingUp = (e) => {
    e.preventDefault();
    dispatch(fetchLoginUser(userData));
  };
  //use memo
  let checkPass = {};

  const checkInpurts = (e) => {
    checkPass.username = userData.username.length > 0 ? true : false;
    checkPass.password = userData.password.length > 0 ? true : false;
    // let checkPass = {
    //   all:
    // }
    return checkPass.username === checkPass.password && checkPass.password;
  };

  let checkPassBtn = useMemo(checkInpurts, [
    userData.username,
    checkPass,
    userData.password,
  ]);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <MainLogo />
        <div style={{ marginTop: '20px', marginBottom: '10px' }}>
          <LanguageSelect />
        </div>
        <Avatar className={login ? classes.succes : classes.avatar}>
          {loading ? (
            <CircularProgress />
          ) : login ? (
            <LockOpenIcon />
          ) : (
            <LockOutlinedIcon />
          )}
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('signIn')}
        </Typography>
        <form className={classes.div} noValidate onSubmit={SingUp}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={handleChangeUserData}
            id="username"
            label={t('emailAddress')}
            name={t('email')}
            autoComplete="email"
            autoFocus
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={handleChangeUserData}
            name={t('password')}
            label={t('password')}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <p style={{ color: 'red' }}>
            {error != null ? (error.status ? '' : error.toString()) : false}
          </p>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t('rememberMe')}
          />
          <Button
            // onClick={() => dispatch(fetchLoginUser(userData))}
            fullWidth
            type="submit"
            disabled={!checkPassBtn}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t('login')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/forgot-password" variant="body2">
                {t('forgotPassword')}
              </Link>
            </Grid>
            <Grid item>
              <Link to="/sign-up">{t('dontHaveAccount')}</Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
