import axios from 'axios';
import { getLoggedInUser, setSession } from '../../utils/helpers';
import API from './../API';
import { updateVcardData } from './../constructor/actions';
import { updateAccount, updateUserData } from '../../redux/request/actions';
import {
  UPDATE_VCARDS,
  UPDATE_VCARDS_FAILED,
  UPDATE_VCARDS_SUCCESS,
} from './constants';

export const fetchAccountVcardsAll = () => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(fetchVcards());

    axios
      .get(API + '/vcards', {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'access-token': auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchVcardsSuccess(data));
        dispatch(updateVcardData(data[0])).then((res) => {});
      })
      .catch((err) => {
        dispatch(fetchVcardsFailed(err));
      });
  };
};

export const fetchCompantAll = () => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(fetchVcards());

    axios
      .get(API + '/companies', {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'access-token': auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchVcardsSuccess(data));
        dispatch(updateVcardData(data[0])).then((res) => {});
      })
      .catch((err) => {
        dispatch(fetchVcardsFailed(err));
      });
  };
};

export const fetchDistributorAll = () => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(fetchVcards());

    axios
      .get(API + '/companies/' + localStorage.getItem('c_id') + '/all_users', {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'access-token': auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchVcardsSuccess(data));
        dispatch(updateVcardData(data[0])).then((res) => {});
      })
      .catch((err) => {
        dispatch(fetchVcardsFailed(err));
      });
  };
};
export const fetchUserCardAll = () => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(fetchVcards());

    axios
      .get(API + '/users/' + localStorage.getItem('user_id') + '/vcards', {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'access-token': auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchVcardsSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchVcardsFailed(err));
      });
  };
};

export const fetchCustomerAll = () => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(fetchVcards());

    axios
      .get(API + '/users/all_users', {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'access-token': auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchVcardsSuccess(data));
        dispatch(updateVcardData(data[0])).then((res) => {});
      })
      .catch((err) => {
        dispatch(fetchVcardsFailed(err));
      });
  };
};


export const fetchChangePassword = (id, password, cPassword) => {
  let { auth } = getLoggedInUser()
  const formDate = new FormData();
  formDate.append("new_password", password);
  formDate.append("new_password_confirmation", cPassword);
  return (dispatch) => {
    axios
      .patch(API + `/users/`+id+'/change_user_password', formDate, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        alert("Your password was successfully updated")
      })
      .catch((err) => {})
  }
}

export const setWebLink = (agentID,id,data) => {
  let { auth } = getLoggedInUser()
  let user = getLoggedInUser();
  const formDate = new FormData();
  formDate.append("agent_link", agentID);
  return (dispatch) => {
    axios
      .patch(API + `/users/`+id, formDate, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ res }) => {
          alert("Your link updated successfully")
      })
      .catch((err) => {})
  }
}

export const fetchVcards = () => {
  return {
    type: UPDATE_VCARDS,
    payload: null,
  };
};

export const fetchVcardsSuccess = (data) => {
  return {
    type: UPDATE_VCARDS_SUCCESS,
    payload: data,
  };
};

export const fetchVcardsFailed = (data) => {
  return {
    type: UPDATE_VCARDS_FAILED,
    payload: data,
  };
};
