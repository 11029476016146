import {
  SAVE_CONTENT_TO_SEND,
  SAVE_GALLERY_TO_SEND,
  DELETE_GALLERY_ITEM_FROM_SEND,
  SEND_CONTENT,
  SEND_CONTENT_SUCCESS,
  SEND_CONTENT_FAILED,
  ERROR_BY_DOMAIN,
  SUCCESS_BY_DOMAIN,
  STEP_DOMAIN_IS_VALID,
  FETCH_DOMAIN_IS_VALID,
  CREATE_FIRST_VCARD_FAILED,
  CREATE_FIRST_VCARD_SUCCESS,
  FALSE_FIRST_VCARD_FAILED,
  SUCCESS_GALLERY_ITEM_FROM_SEND,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
} from './constans'

const INIT_STATE = {
  loading: false,
  error: null,
  contentToSend: {},
  galleryToSend: [],
  domainError: false,
  formData: new FormData(),
  domainIsValid: false,
  firstVcardCreated: false,
  accountUpdate: null,
  passwordChange: null,
}

const Request = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_CONTENT_TO_SEND:
      return { ...state, contentToSend: action.payload }
    case SUCCESS_GALLERY_ITEM_FROM_SEND:
      return { ...state, galleryToSend: [], formData: new FormData() }
    case SAVE_GALLERY_TO_SEND:
      const newState = () => {
        let id = action.payload.id
        let find = false
        let newState = state.galleryToSend.map((item) => {
          if (item.id === id) {
            item = action.payload
            find = true
          }
          return item
        })
        if (!find) newState.push(action.payload)
        return newState
      }
      return { ...state, galleryToSend: newState() }
    case DELETE_GALLERY_ITEM_FROM_SEND:
      let id = action.payload
      let newGallery = state.galleryToSend.filter((item) => item.id !== id)
      return { ...state, galleryToSend: newGallery }
    case SEND_CONTENT:
      return { ...state, loading: true, error: false, domainError: false }
    case SEND_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        formData: new FormData(),
      }
    case SEND_CONTENT_FAILED:
      return { ...state, loading: false, error: 'Ошибка' }

    case ERROR_BY_DOMAIN:
      return {
        ...state,
        domainIsValid: false,
        loading: false,
        error: false,
        domainError: action.payload.message,
      }
    case SUCCESS_BY_DOMAIN:
      return {
        ...state,
        domainIsValid: true,
        loading: false,
        error: false,
        domainError: action.payload.message,
      }

    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountUpdate: action.payload,
      }
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChange: action.payload,
      }
    case STEP_DOMAIN_IS_VALID:
      return {
        ...state,
        loading: false,
        domainIsValid: action.payload.is_available,
        domainError: action.payload.message,
      }
    case FETCH_DOMAIN_IS_VALID:
      return {
        ...state,
        loading: action.payload,
        domainIsValid: action.payload,
        domainError: '',
      }

    case CREATE_FIRST_VCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        firstVcardCreated: true,
        domainError: null,
        formData: new FormData(),
      }
    case CREATE_FIRST_VCARD_FAILED:
      return {
        ...state,
        loading: false,
        firstVcardCreated: false,
        domainError: action.payload.message,
      }
    case FALSE_FIRST_VCARD_FAILED:
      return { ...state, firstVcardCreated: false }
    default:
      return { ...state }
  }
}

export default Request
