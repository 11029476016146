import FileUploadIcon from '@mui/icons-material/FileUpload'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { saveContentToSend } from '../../../../redux/request/actions'
import { STYLES } from '../../../../styles'
import { getBase64 } from '../../../../utils/helpers'

const StyledUploadButton = styled.div`
  margin-bottom: 1rem;
  h4 {
    margin-bottom: 4px;
    font-size: 1.125rem;
    color: inherit;
    font-weight: 400;
  }
  div {
    padding: 1rem;
    border: 1px dashed #63b3ed;
    position: relative;
  }
  input {
    display: none;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 0;
  }
  span {
    padding: 1rem;
    background-color: ${STYLES.COLORS.lightBlue};
    cursor: pointer;
    color: #fff;
    border-radius: 4px;
    margin: 0.5rem;
    display: flex;
    align-items: center;
  }
  p {
    font-size: 1rem;
    margin: 0;
    color: ${STYLES.COLORS.body};
  }
`

const UploadButton = ({ id, setState, formData, textContent }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const label = id === 'filename' ?  t("logoPImage") :  t("coverTBanner")
  return (
    <StyledUploadButton>
      {/*<h4>Banner</h4>*/}
      <div>
        <input
          onChange={(e) => {
            let file = e.target.files[0]
            if (!file) return true
            formData.set(id, file, file.name)
            getBase64(file, (result) => {
              setState(result)
            })
            dispatch(saveContentToSend(formData))
          }}
          id={id}
          accept='image/*'
          multiple
          type='file'
          name={t('file')}
        />
        <label htmlFor={id}>
          <span>
            <FileUploadIcon /> {t('clickToUpload')}
          </span>
          <p>{textContent}</p>
        </label>
      </div>
    </StyledUploadButton>
  )
}

export default UploadButton
