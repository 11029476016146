import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import Start from '../components/session/HappyStart'
import Card from '../components/templates/components/Card-Minimal'
import Account from '../pages/account'
import Constructor from '../pages/constructor'
import ContentAdmin from '../pages/contentAdmin'
import Home from '../pages/home'
import Preview from '../pages/preview'
import PublicPreview from '../pages/preview/publicPreview'
import ChangePassword from '../pages/session/change-password/change-password'
import Vcard from '../pages/session/createFirstVcard'
import ForgotPassword from '../pages/session/forget-password'
import SignIn from '../pages/session/sign-in'
import SignUp from '../pages/session/sign-up'
import PrivateRoute from '../utils/PrivateRoute'
import PublicRoute from '../utils/PublicRoute'
import TestBlock from '../pages/test'
import CancelAppointment from '../pages/cancelAppointment'
import Distributor from '../pages/account/distributor/Distributor';
import UserCardList from '../pages/account/referUserList/vcard';
import CardList from '../pages/account/card/Vcard';
import MenuVideo from '../pages/account/MenuVideo/index';

export default function Routes() {
  const PrivateRoutes = [
    { path: '/account', route: PrivateRoute, component: Account },
    { path: '/constructor', route: PrivateRoute, component: Constructor },
    { path: '/ContentAdmin', route: PrivateRoute, component: ContentAdmin },
    { path: '/Distributor', route: PrivateRoute, component: Distributor },
    { path: '/CardList', route: PrivateRoute, component: CardList },
    { path: '/user-card-list', route: PrivateRoute, component: UserCardList },
    { path: '/preview/', route: PrivateRoute, component: Preview },
    { path: '/MenuVideo/', route: PrivateRoute, component: MenuVideo },
  ]
  const PublicRoutes = [
    { path: '/preview/:name/', route: PublicRoute, component: PublicPreview },
    { path: '/card', route: PublicRoute, component: Card },
    { path: '/test', route: PublicRoute, component: TestBlock },
    { path: '/', route: PublicRoute, component: Home },
    { path: '/sign-in', route: PublicRoute, component: SignIn },
    { path: '/sign-up', route: PublicRoute, component: SignUp },
    { path: '/forgot-password', route: PublicRoute, component: ForgotPassword },
    {
      path: '/change-password/:code/',
      route: PublicRoute,
      component: ChangePassword,
    },
    { path: '/vcard', route: PublicRoute, component: Vcard },
    { path: '/start', route: PublicRoute, component: Start },
    {
      path: '/cancel-appointment',
      route: PublicRoute,
      component: CancelAppointment,
    },
  ]
  // Redirection for Signup URL
  let url = window.location.href;
  // console.log("url", url)
  if (url.indexOf('sign-up#/') >0){
    window.location.href = window.location.href.replace('sign-up#/', '#/sign-up')
  }
  return (
    <Switch>
      {PrivateRoutes.map((item) => {
        return (
          <item.route
            key={item.path}
            exact
            path={item.path}
            component={item.component}
          />
        )
      })}
      {PublicRoutes.map((item) => {
        return (
          <item.route
            key={item.path}
            exact
            path={item.path}
            component={item.component}
          />
        )
      })}
    </Switch>
  )
}
