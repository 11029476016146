import React from 'react'
import { ArrowLeft } from 'react-bootstrap-icons'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setComponentEditor } from '../../redux/constructor/actions'
import { STYLES } from '../../styles'
import SaveButton from '../constructor/SaveButton'
import { useTranslation } from 'react-i18next'

const StyledGoBack = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  max-width: 25rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  height: 56px;
  border-bottom: 1px solid #bbb;
  color: ${STYLES.COLORS.lightBlue};
  cursor: pointer;
  align-items: center;
  background-color: #d7e9ed;
`

const GoBack = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();


  return (
    <StyledGoBack>
      <div onClick={() => dispatch(setComponentEditor(''))}>
        <ArrowLeft /> {t('backToMainMenu')}
      </div>
      <SaveButton />
    </StyledGoBack>
  )
}
export default GoBack
