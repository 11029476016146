import React from 'react'
import { useTranslation } from 'react-i18next';
import { schemeList } from '../../../lib/colorSchemes'
import ColorPicker from './ColorPicker'
import ColorScheme from './ColorScheme'

export default function ColorEditor() {
  const { t } = useTranslation();

  return (
    <div className='color_scheme'>
      <h2 className='form_title'>{t('pickColorScheme')}</h2>
      <div className='colors'>
        {schemeList.map((item) => {
          return (
            <ColorScheme
              key={item.title + 'colorscheme'}
              data={item}
              title={item.title}
            />
          )
        })}
      </div>
      <h2 className='form_title'>{t('changeIndividualColors')}</h2>
      <div className='color_pickers'>
        <ColorPicker
          key={1 + 'colorpicker'}
          title={t('primary')}
          label='primary_color'
        />
        <ColorPicker
          key={2 + 'colorpicker'}
          title={t('headings')}
          label='heading_color'
        />
        <ColorPicker
          key={3 + 'colorpicker'}
          title={t('body')}
          label='body_color'
        />
        <ColorPicker
          key={4 + 'colorpicker'}
          title={t('buttonText')}
          label='button_text_color'
        />
        <ColorPicker
          key={5 + 'colorpicker'}
          title={t('backgroundPrimary')}
          label='background_primary_color'
        />
        <ColorPicker
          key={6 + 'colorpicker'}
          title={t('backgroundSecondary')}
          label='background_secondary_color'
        />
        <ColorPicker
          key={7 + 'colorpicker'}
          title={t('backgroundPage')}
          label='background_page_color'
        />
      </div>
    </div>
  )
}
