import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { APIMAIN } from '../../../redux/API'
import {
  deleteItemGalleryToSend,
  fetchGalleryDataFromServer,
  saveContentToSend,
  saveGalleryDataToSend,
} from '../../../redux/request/actions'
import { getBase64 } from '../../../utils/helpers'
import DefaultTextField from '../../kit/DefaultTextField'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    background: '#408BEF',
  },
  buttonRemove: {
    margin: theme.spacing(1),
    color: 'white',
    background: '#dd3333aa',
    width: 'min-content',
    fontSize: '14px',
    padding: '0px 0px',
  },
  uploadBtn: {
    position: 'relative',
    background: '#408BEF',
    fontSize: '14px',
    margin: '8px',
    padding: '10px 20px',
    color: 'white',
    fontWeight: 500,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    lineHeight: '1.75',
    borderRadius: '4px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    transition: 'all 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '5px',
    '&:hover': {
      cursor: 'pointer',
      background: '#e0e0e0',
    },
  },
}))

function CreateNewImages({ data, itemId, del, vcardid }) {
  const { t } = useTranslation();

  const dispatch = useDispatch()
  if (itemId === undefined) itemId = data.id
  const classes = useStyles()

  useEffect(() => {
    setImg(data?.filename?.url ? APIMAIN + data?.filename?.url : '')
    setLink(data?.link)
  }, [data])

  const [image, setImg] = useState(
    data?.filename?.url ? APIMAIN + data?.filename?.url : ''
  )
  const [link, setLink] = useState(data?.link)

  let formData = useRef(new FormData())

  return (
    <div className='upload_images_form'>
      {/* {data.id}{itemId} */}
      {image ? (
        <div className='gallery_image'>
          <img src={image} alt='' />
          <button
            onClick={() => {
              setImg(null)
              formData.current.current.set('filename', null)
            }}
            className='delete_image_editor'
          >
            <DeleteForeverIcon />
          </button>
        </div>
      ) : (
        <>
          <input
            accept='image/*'
            style={{ visibility: 'hidden' }}
            id='file-name'
            multiple
            type='file'
            onChange={(e) => {
              let file = e.target.files[0]
              if (!file) return true
              formData.current.set('filename', e.target.files[0], file.name)
              getBase64(file, (result) => {
                setImg(result)
              })
              dispatch(
                saveGalleryDataToSend({ id: itemId, data: formData.current })
              )
            }}
          />
          <label
            htmlFor='file-name'
            style={{ padding: '30px 30px', border: 'dotted 3px #3f51b5' }}
          >
            <div className={classes.uploadBtn}>
              <CloudUploadIcon className='mx-2' />
              {t('upload')}
            </div>
          </label>
        </>
      )}
      <DefaultTextField
        label={t('addUrlToImage')}
        onChange={(e) => {
          setLink(e.target.value)
          formData.current.set('link', e.target.value)
          dispatch(
            saveGalleryDataToSend({ id: itemId, data: formData.current })
          )
        }}
        as='input'
        value={link ?? ''}
        placeholder={t('url')}
      />

      <Button
        variant='contained'
        color='default'
        className={classes.buttonRemove}
        onClick={() => {
          dispatch(deleteItemGalleryToSend(vcardid, itemId))
          del(itemId)
        }}
      >
        {t('remove')}
      </Button>
    </div>
  )
}

export default function Gallery() {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const classes = useStyles()

  const { vcardDataToEdit, formData } = useSelector(
    (state) => state.ConstructorReducer
  )
  const [images, setImages] = useState(vcardDataToEdit?.gallery_images)
  const [title, setTitle] = useState(vcardDataToEdit?.gallery_title)

  useEffect(() => {
    setImages(vcardDataToEdit?.gallery_images)
    dispatch(fetchGalleryDataFromServer(vcardDataToEdit.id))
  }, [vcardDataToEdit?.gallery_images, dispatch, vcardDataToEdit.id])

  const deleteImage = (id) => {
    let newImages = images.filter((item, index) => item.itemId !== id)
    setImages(newImages)
  }

  return (
    <div style={{ marginBottom: '10px', paddingBottom: '10px' }}>
      <DefaultTextField
        label={t('galleryTitle')}
        value={title || ''}
        onChange={(e) => {
          formData.set('gallery_title', e.target.value)
          setTitle(e.target.value)
          dispatch(saveContentToSend(formData))
        }}
      />

      <Form.Group className='mb-4'>
        <Form.Label className='form_title'>{t('galleryImages')}</Form.Label>
      </Form.Group>
      {images.map((item, index) => {
        return (
          <CreateNewImages
            vcardid={vcardDataToEdit?.id}
            key={index + 'images'}
            del={deleteImage}
            itemId={item.itemId}
            data={item}
          />
        )
      })}
      <Button
        variant='contained'
        color='default'
        className={classes.button}
        startIcon={<CloudUploadIcon />}
        onClick={() => {
          setImages([
            ...images,
            { filename: { url: '' }, link: '', itemId: -Math.random() - 1 },
          ])
        }}
      >
        {t('addNewImage')}
      </Button>
    </div>
  )
}
