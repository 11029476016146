import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBase64 } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    background: '#408BEF',
  },
  buttonRemove: {
    margin: theme.spacing(1),
    color: 'white',
    background: '#dd3333aa',
    width: 'min-content',
    fontSize: '14px',
    padding: '0px 0px',
  },
  input: {
    display: 'none',
  },
}));

export default function UploadPictures({ distributorId }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { formData } = useSelector((state) => state.Request);
  const [images, setImages] = useState({});

  useEffect(() => {
    let imgs = {};
    getBase64(formData.get('avatar'), (result) => {
      imgs.avatar = result;
    });
    getBase64(formData.get('background'), (result) => {
      imgs.background = result;
    });
    setTimeout(() => {
      setImages(imgs);
    }, 25);
  }, [formData]);

  return (
    <div
      style={{
        marginBottom: '40px',
        paddingBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className="upload_pictures">
          {images.avatar ? (
            <div className="signup_avatar">
              <img src={images.avatar} alt="avatar" />
              <button
                onClick={() => {
                  setImages({ ...images, avatar: null });
                  formData.delete('avatar');
                }}
                className="delete_image"
              >
                <DeleteForeverIcon />
              </button>
            </div>
          ) : (
            <Form.Group style={{ marginBottom: '1.5rem' }}>
              <input
                // accept="image/*"
                className={classes.input}
                id="avatar"
                multiple
                type="file"
                accept="image/gif,image/jpeg, image/jpg, image/png"
                onChange={(e) => {
                  let file = e.target.files[0];
                  if (!file) return true;
                  formData.set('avatar', e.target.files[0], file.name);
                  getBase64(file, (result) => {
                    setImages({ ...images, avatar: result });
                  });
                }}
              />
              <label
                htmlFor="avatar"
                style={{ padding: '30px 50px', border: 'dotted 3px #3f51b5' }}
              >
                <Button variant="contained" color="primary" component="span">
                  {t('upload')}
                </Button>
              </label>
            </Form.Group>
          )}
          <Form.Label className="form_title">
            {distributorId.length > 0 ? t('pimage') : t('logoPImage')}
          </Form.Label>
          <div className="form_description" style={{ marginTop: '10px' }}>
            {t('uploadDesc')}
          </div>
        </div>
        {!distributorId && (
          <div className="upload_pictures">
            {images.background ? (
              <div className="signup_background">
                <img src={images.background} alt="background" />
                <button
                  onClick={() => {
                    setImages({ ...images, background: null });
                    formData.delete('background');
                  }}
                  className="delete_image"
                >
                  <DeleteForeverIcon />
                </button>
              </div>
            ) : (
              <Form.Group style={{ marginBottom: '1.5rem' }}>
                <input
                  accept="image/gif,image/jpeg, image/jpg, image/png"
                  className={classes.input}
                  id="background"
                  multiple
                  type="file"
                  onChange={(e) => {
                    let file = e.target.files[0];
                    if (!file) return true;
                    formData.set('background', e.target.files[0], file.name);
                    getBase64(file, (result) => {
                      setImages({ ...images, background: result });
                    });
                  }}
                />
                <label
                  htmlFor="background"
                  style={{ padding: '30px 50px', border: 'dotted 3px #3f51b5' }}
                >
                  <Button variant="contained" color="primary" component="span">
                    {t('upload')}
                  </Button>
                </label>
              </Form.Group>
            )}
            <Form.Label className="form_title">{t('coverTBanner')}</Form.Label>
            <div className="form_description" style={{ marginTop: '10px' }}>
              {t('uploadCoverNote')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
