import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContentJson } from '../../../../redux/request/actions'
import SaveBtn from '../../../kit/SaveBtn'
import Header from '../Header'
import styled from 'styled-components'
import { STYLES } from '../../../../styles'
import DefaultTextField, {
  StyledInputLabel,
  StyledTextField,
} from '../../../kit/DefaultTextField'
import DefaultSelectNormal from '../../../kit/DefaultSelectNormal'
import { languageMap } from '../../../../utils/helpers'



const StyledDomainTextField = styled.div`
  margin-bottom: 4px;

  label {
    margin: 0;
    width: 100%;
    & > div {
      display: flex;
      column-gap: 4px;
      align-items: center;
    }
  }
  h5 {
    font-size: 0.875rem;
    color: ${STYLES.COLORS.warning};
  }
  input {
    box-shadow: 0 0 0 0.125rem
      ${(p) => (p.isValid ? 'transparent' : STYLES.COLORS.warning)};

    &:focus {
      box-shadow: 0 0 0 0.125rem
        ${(p) => (p.isValid ? STYLES.COLORS.lightBlue : STYLES.COLORS.warning)};
    }
  }
  h6 {
    margin-top: 4px;
    font-weight: 400;
    color: ${(p) => (p.isValid ? 'green' : STYLES.COLORS.warning)};
  }
`

const CardLanguage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector((state) => state.ConstructorReducer)
  const { id } = vcardDataToEdit
  const Vcard_languageFromServer = vcardDataToEdit?.vcard_language

  const [vcard_language, setVcard_language] = useState(Vcard_languageFromServer)

  const inputHandler = (e) => {
    const value = e.target.value
    const id = e.target.name
    formData.set(id, value)
    setVcard_language(value)
  }

  const isVcardLanguageSame = vcard_language === Vcard_languageFromServer

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isVcardLanguageSame) {
      dispatch(fetchContentJson(id, { vcard_language }))
    }
  }


  return (
    <>
      <form onSubmit={saveHandler}>
        <StyledDomainTextField >
          <StyledInputLabel>
            <h4>
              {t('select_language')}<span>*</span>
            </h4>
            <div>
              <DefaultSelectNormal
                onChange={inputHandler}
                array={languageMap}
                name='vcard_language'
                value={vcard_language || 'en'}
              />
            </div>
          </StyledInputLabel>
        </StyledDomainTextField>
        <SaveBtn type='submit' disabled={isVcardLanguageSame} />
      </form>
    </>
  )
}

export default CardLanguage
