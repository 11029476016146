import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { STYLES } from '../../styles'

const StyledAddBtn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  color: ${STYLES.COLORS.lightBlue};
  text-transform: capitalize;
  cursor: pointer;
`

const AddBtn = ({ onClick, textContent }) => {
  const { t } = useTranslation();

  return (
    <StyledAddBtn type='button' onClick={onClick}>
      + {t('addAnother')} {textContent}
    </StyledAddBtn>
  )
}

export default AddBtn
