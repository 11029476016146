import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    const currentTime = Date.now() / 1000;
    if (user.auth.expiry > currentTime) {
        return true;
    } else {
        console.warn('access token expired');
        return false;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const setSession = user => {
    let cookies = new Cookies();
    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};

const getBase64 = (file, cb) => {
    if (!file) return true
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.warn('getBase64: ', error);
    };
}

const languageMap = {
    den: { label: "Select Dashboard language", dir: "ltr", active: true },
    en: { label: "English", dir: "ltr", active: false },
    fr: { label: "French", dir: "ltr", active: false },
    de: { label: "German", dir: "ltr", active: false },
    es: { label: "Spanish ", dir: "ltr", active: false }
};

export { getBase64, isUserAuthenticated, setSession, getLoggedInUser, languageMap };


