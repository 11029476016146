import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { STYLES } from '../../styles'

const StyledSelect = styled(Select)`
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  margin-top: 1rem;
  &.MuiOutlinedInput-root.Mui-focused > fieldset {
    border-color: ${STYLES.COLORS.lightBlue};
  }
  &.MuiOutlinedInput-root:hover > fieldset {
    border-color: rgba(0, 0, 0, 0.23);
  }
  &.MuiOutlinedInput-root > fieldset {
    top: 0;
    border: none;
  }
`
const DefaultSelectNormal = ({ value, onChange, array, id, name }) => {

  return (
    <StyledSelect name={name} value={value || 'English'} onChange={onChange}>


      {Object.keys(array)?.map((item, index) => (
        <MenuItem key={index} value={item}>
          {array[item].label}
        </MenuItem>
      ))}


    </StyledSelect>


  )
}

export default DefaultSelectNormal
