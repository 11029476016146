import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'
export default function QRCode({ cardData, setQrCode }) {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  return (
    <div className='qr-code-block'>
      <div
        style={{ color: '#fff' }}
        className='x-btn'
        onClick={() => dispatch(setQrCode(false))}
      >
        <svg
          stroke='currentColor'
          fill='currentColor'
          strokeWidth='0'
          viewBox='0 0 1024 1024'
          height='30px'
          width='30px'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z'></path>
        </svg>
      </div>

      <div
        className='section'
        // eslint-disable-next-line react/jsx-no-duplicate-props
        className='qr-code'
        style={{ background: cardData?.background_primary_color }}
      >
        {/* <div></div> */}
        <h2>{cardData.title}</h2>
        <p className='qr-link'>
          <a
            href={`https://${cardData?.domain_prefix}.talkingvcard.net`}
            target='_blank'
            rel='noreferrer noopener'
          >
            {cardData?.domain_prefix}.talkingvcard.net
          </a>
        </p>
        <div>
          <img src={cardData.qr_code} alt='qr code' />
        </div>
      </div>
      <div className='dw-block'>
        <a
          href={cardData.qr_code}
          download={`${cardData.domain_prefix}_qrCode`}
          className='dw-btn'
        >
          <svg
            stroke='currentColor'
            fill='currentColor'
            strokeWidth='0'
            viewBox='0 0 1024 1024'
            className='mr-3 font-bold text-2xl'
            height='1em'
            width='1em'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M624 706.3h-74.1V464c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v242.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.7a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9z'></path>
            <path d='M811.4 366.7C765.6 245.9 648.9 160 512.2 160S258.8 245.8 213 366.6C127.3 389.1 64 467.2 64 560c0 110.5 89.5 200 199.9 200H304c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8h-40.1c-33.7 0-65.4-13.4-89-37.7-23.5-24.2-36-56.8-34.9-90.6.9-26.4 9.9-51.2 26.2-72.1 16.7-21.3 40.1-36.8 66.1-43.7l37.9-9.9 13.9-36.6c8.6-22.8 20.6-44.1 35.7-63.4a245.6 245.6 0 0 1 52.4-49.9c41.1-28.9 89.5-44.2 140-44.2s98.9 15.3 140 44.2c19.9 14 37.5 30.8 52.4 49.9 15.1 19.3 27.1 40.7 35.7 63.4l13.8 36.5 37.8 10C846.1 454.5 884 503.8 884 560c0 33.1-12.9 64.3-36.3 87.7a123.07 123.07 0 0 1-87.6 36.3H720c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h40.1C870.5 760 960 670.5 960 560c0-92.7-63.1-170.7-148.6-193.3z'></path>
          </svg>
          {t('saveQRCodeImage')}
        </a>
        <div onClick={() => dispatch(setQrCode(false))} className='btn-tag'>
          {t('backToCard')}
        </div>
      </div>
    </div>
  )
}
