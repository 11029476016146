import axios from 'axios';
import { getLoggedInUser } from '../../utils/helpers';
import API, { APIMAIN } from '../API';
import { fetchVcardDataToEditor } from '../constructor/actions';
import {
  FETCH_BANNER,
  FETCH_BANNER_FAILURE,
  FETCH_BANNER_SUCCESS,
  SELECT_BANNER,
  UPLOADE_SUCCESS,
  DELETE_MASSAGE,
} from './constants';

export const selectBanner = (data) => ({
  type: SELECT_BANNER,
  payload: data,
});
export const successMassage = (id) => ({
  type: UPLOADE_SUCCESS,
  payload: id,
});

export const deleteMassage = (id) => ({
  type: DELETE_MASSAGE,
  payload: id,
});

export const getAllBanners = (company_id) => (dispatch) => {
  dispatch({ type: FETCH_BANNER });
  let { auth } = getLoggedInUser();

  return axios
    .get(`${API}/companies/${company_id}/banner_images`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
    .then((res) => {
      if (res.data) {
        const b = res.data?.filter((item) => item.filename.url !== null);
        if (b.length > 0) {
          const c = b.map((item) => {
            return {
              ...item,
              filename: {
                url: APIMAIN + item.filename.url,
              },
            };
          });
          dispatch({
            type: FETCH_BANNER_SUCCESS,
            payload: c,
          });
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: FETCH_BANNER_FAILURE,
        payload: err,
      });
    });
};
export const setBannerUser = (id, data) => {
  let { auth } = getLoggedInUser()
  const formDate = new FormData();
  formDate.append("banner_image_id", data);
  return (dispatch) => {
    axios
      .patch(API + `/vcards/${id}`, formDate, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(fetchVcardDataToEditor(id))
      })
      .catch((err) => {})
  }
}

export const setBannerColor = (vid, id, data) => {
  let { auth } = getLoggedInUser()
  return (dispatch) => {
    axios
      .post(API + `/companies/${id}/company_group_colors`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(fetchVcardDataToEditor(vid))
      })
      .catch((err) => {})
  }
}

export const setBannerSecondaryColor = (colorId,vid, id, data) => {
  let { auth } = getLoggedInUser()
  return (dispatch) => {
    axios
      .patch(API + `/companies/${id}/company_group_colors/${colorId}`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(fetchVcardDataToEditor(vid))
      })
      .catch((err) => {})
  }
}

export const deleteBannerUser = (cId, id) => {
  let user = getLoggedInUser();

  return (dispatch) => {
    if (id >= 0) {
      axios
        .delete(API + '/companies/' + cId + '/banner_images/' + id, {
          headers: {
            Authorization: 'Bearer ' + user.auth.token,
          },
        })
        .then((data) => {
          setTimeout(() => {
            dispatch(deleteMassage(id));
          }, 2);
          dispatch(getAllBanners(cId));
        });
    } 
  };
};

export const bannerUploade = (id, data) => {
  let { auth } = getLoggedInUser()
  console.log(data);
  return (dispatch) => {
    axios
      .post(API + `/companies/${id}/banner_images`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(getAllBanners(id));
        setTimeout(() => {
          dispatch(successMassage(id));
        }, 2);
      })
      .catch((err) => {})
  }
}
//------------------end-banner--------------------