import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContentJson } from '../../../../redux/request/actions'
import DefaultTextField from '../../../kit/DefaultTextField'
import SaveBtn from '../../../kit/SaveBtn'
import Header from '../Header'

const VmailBlaze = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector((state) => state.ConstructorReducer)
  const { id } = vcardDataToEdit
  const VmailBlazeCodeFromServer = vcardDataToEdit?.vmail_blaze_code

  const [vmail_blaze_code, setvmail_blaze_code] = useState(VmailBlazeCodeFromServer)

  const inputHandler = (e) => {
    const value = e.target.value
    const id = e.target.id
    formData.set(id, value)
    setvmail_blaze_code(value)
  }

  const isVmailBlazeCodeSame = vmail_blaze_code === VmailBlazeCodeFromServer

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isVmailBlazeCodeSame) {
      dispatch(fetchContentJson(id, { vmail_blaze_code }))
    }
  }
  return (
    <>
      <form onSubmit={saveHandler}>
        <Header disabled={isVmailBlazeCodeSame} />
        <DefaultTextField
          value={vmail_blaze_code || ''}
          onChange={inputHandler}
          label={t('vmailBLAZE')}
          subtitle={t('vmailSubTitle')}
        />
        <SaveBtn type='submit' disabled={isVmailBlazeCodeSame} />
      </form>
    </>
  )
}

export default VmailBlaze
