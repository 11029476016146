import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setAvailabilities } from '../../../../redux/availabilities/action'
import { STYLES } from '../../../../styles'
import { StyledLabel } from './Appointments'
import Availabilities from './Availabilities'

const StyledAddButton = styled.button`
  color: #fff;
  background-color: ${STYLES.COLORS.lightBlue};
  border-radius: 99px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
`

const WeekDay = ({ availabilities, day, weekday }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const exactWeekday = availabilities.filter((el) => el.weekday === weekday)

  function createNewTimeLine() {
    const id = Math.round(Math.random() * -1000)
    const timeline = {
      id,
      weekday,
      start_time_hh: '12',
      start_time_mm: '00',
      end_time_hh: '13',
      end_time_mm: '00',
    }
    dispatch(setAvailabilities(timeline))
  }

  return (
    <div>
      <StyledLabel>{day}</StyledLabel>
      {!exactWeekday.length ? (
        <StyledAddButton onClick={createNewTimeLine}>
          + {t("addTime")} 
        </StyledAddButton>
      ) : (
        <Availabilities
          exactWeekday={exactWeekday}
          availabilities={availabilities}
          createNewTimeLine={createNewTimeLine}
        />
      )}
    </div>
  )
}

export default WeekDay
