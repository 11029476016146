import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Template from '../templates';
import BookingForm from '../templates/components/BookingForm';
import GalleryImgPreview from '../templates/components/GalleryImgPreview';

const StyledTemplatePreview = styled.div`
  flex: 1 1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 1.25rem;
  height: 100%;
  position: relative;
  & > div {
    border-radius: 5px;
    border: none;
    width: ${(props) => (props.isMobile ? '413px' : 'calc(100% - 2rem)')};
  }
  & > div:first-child {
    overflow-y: scroll;
    scroll-behavior: smooth;
    width: ${(props) => (props.isMobile ? '413px' : '100%')};
    height: 100%;
    border: solid grey;
  }
`;

const TemplatePreview = () => {
  const { previewDisplay, vcardDataToEdit } = useSelector(
    (state) => state.ConstructorReducer
  );
  const { bookingForm } = useSelector((s) => s.appointmentsReducer);
  const { imgPreview, popup } = useSelector((s) => s.galleryReducer);

  const isMobile = previewDisplay === 'mobile';
  return (
    <StyledTemplatePreview isMobile={isMobile}>
      {vcardDataToEdit?.title && (
            <>
      <Template
        previewDisplay={previewDisplay}
        vcardDataToEdit={vcardDataToEdit}
      />
      </>
      )}
      {bookingForm && <BookingForm vcardDataToEdit={vcardDataToEdit} />}
      {popup && <GalleryImgPreview imgPreview={imgPreview} />}
    </StyledTemplatePreview>
  );
};
export default TemplatePreview;
