import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import API from '../../redux/API'
import App from '../../components/templates/App'
import NoActive from './noActive'
import { useSelector } from 'react-redux'
import BookingForm from '../../components/templates/components/BookingForm'
import GalleryImgPreview from '../../components/templates/components/GalleryImgPreview'
import styled from 'styled-components'

const StyledAppContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;

  & > div {
    border-radius: 5px;
    border: none;
    width: 100%;
    height: 100%;
    position: fixed;
  }
  & > div:first-child {
    position: static;
  }
`

export default function Preview({ domainName }) {
  const [cardData, setCardData] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    axios
      .post(
        API + '/vcards/get_vcard_by_domain_prefix',
        {
          domain_prefix: domainName,
        },
        {
          headers: {
            'content-Type': 'application/json',
          },
        }
      )
      .then((data) => {
        setCardData(data.data)
        setLoading(true)
      })
      .catch(() => setLoading(true))
  }, [domainName])

  const { imgPreview, popup } = useSelector((s) => s.galleryReducer)
  const { bookingForm } = useSelector((s) => s.appointmentsReducer)

  const windowMedia = window.matchMedia('(max-width: 576px)')
  const mode = windowMedia.matches ? 'mobile' : 'display'
  const isMobile = mode === 'mobile'

  return (
    <div>
      {loading ? (
        <>
          {cardData?.activity_info?.is_active ? (
            <StyledAppContainer mode={isMobile}>
              <App mode={mode} data={cardData} />
              {bookingForm && <BookingForm vcardDataToEdit={cardData} />}
              {popup && <GalleryImgPreview imgPreview={imgPreview} />}
            </StyledAppContainer>
          ) : (
            <NoActive domainName={domainName} have={cardData?.activity_info} />
          )}
        </>
      ) : (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            padding: '20px',
            marginTop: '200px',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  )
}
