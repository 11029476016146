import React from 'react'

const WhatsappIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <path
        fill='#36CB34'
        d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16c8.836 0 16-7.164 16-16S24.837 0 16 0zm.008 24.004a9.196 9.196 0 0 1-4.257-1.047l-4.988 2.27 1.396-5.586a9.298 9.298 0 0 1-1.396-4.887c0-5.096 4.134-9.233 9.246-9.233 5.093 0 9.228 4.137 9.228 9.233-.001 5.113-4.135 9.25-9.229 9.25z'
      />
      <path
        fill='#36CB34'
        d='M16.008 7.371c-4.083 0-7.397 3.299-7.397 7.383a7.38 7.38 0 0 0 1.797 4.835l-1.012 2.566 2.251-1.431a7.257 7.257 0 0 0 4.361 1.431c4.082 0 7.379-3.316 7.379-7.4a7.373 7.373 0 0 0-7.379-7.384zm4.676 10.471c-.07.366-.942 1.152-1.623 1.309-1.465.331-3.541-.838-4.885-2.076-1.344-1.221-2.686-3.194-2.494-4.678.087-.681.802-1.623 1.151-1.745.157-.035.383-.035.593-.035.244.017.454.035.524.174l.785 2.042c.052.157-.07.331-.227.524-.226.262-.261.279-.471.559-.104.139-.035.314 0 .383.227.541.75 1.152 1.221 1.588.489.437 1.133.908 1.692 1.099.035 0 .244.087.401.052.139-.017.226-.122.348-.279.226-.279.314-.384.506-.646.157-.192.313-.262.472-.175l1.779.821c.14.07.192.244.209.489.036.211.054.438.019.594z'
      />
    </svg>
  )
}

export default WhatsappIcon
