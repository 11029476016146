import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styled from 'styled-components'
import { NavArrow, StyledCarouselSection } from './Testimonials'
import { getLink } from './VideoFrame';
import Title from '../../kit/Title';

const CarouselWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
`

const StyledVideoFrame = styled.div`
  overflow: hidden;
  margin: 0 auto;
  max-width: 700px;
  aspect-ratio: 16/9;
  background-color: ${(props) => props.color};

  & iframe.iframe {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`

const VideoGallery = ({ data, mode }) => {
  const isMobile = mode === 'mobile'

  let titleIframe = ''
  const { videos } = data
  const carouselEnabled = videos?.length > 1

  return (
    videos?.length > 0 && (
      <StyledCarouselSection
        isMobile={isMobile}
        carouselEnabled={carouselEnabled}
      >
        {
          data?.video_title ?
          <Title textContent={data?.video_title} />
          : ''
        }
        <CarouselWrapper>
          {videos?.length > 1 ? (
            <Carousel
              infiniteLoop
              showThumbs={false}
              showIndicators={false}
              emulateTouch
              showStatus={false}
              // autoPlay
              // interval='5000'
              renderArrowPrev={(clickHandler) => {
                return <NavArrow clickHandler={clickHandler} dir='back' />
              }}
              renderArrowNext={(clickHandler) => {
                return <NavArrow clickHandler={clickHandler} dir='forward' />
              }}
            >
              {videos?.map((item, idx) => (
                <StyledVideoFrame key={idx}>
                  <iframe
                    className='iframe'
                    src={getLink(item.url, titleIframe)}
                    title={titleIframe}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  ></iframe>
                </StyledVideoFrame>
              ))}
            </Carousel>
          ) : (
            videos?.map((item, idx) => (
              <StyledVideoFrame key={idx}>
                <iframe
                  className='iframe'
                  src={getLink(item.url, titleIframe)}
                  title={titleIframe}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </StyledVideoFrame>
            ))
          )}
        </CarouselWrapper>
      </StyledCarouselSection>
    )
  )
}

export default VideoGallery
