import TMGreen1 from '../../../../assets/gifs/Group 1/Team Mojo Corp Green 1.gif';

import TMPurple1 from '../../../../assets/gifs/Group 2/Team Mojo Pink Purple 1.gif';

const group1 = [
  {
    name: 'TMGreen1',
    url: TMGreen1,
  },
];
const group2 = [
  {
    name: 'TMPurple1',
    url: TMPurple1,
  },
];
const allBanners = [...group1, ...group2];

export { group1, group2, allBanners };
