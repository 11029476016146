import React from 'react'
import styled from 'styled-components'
import CreateTimeLine from './CreateTimeLine'

const StyledAvailabilities = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  row-gap: 0.25rem;
`

const Availabilities = ({
  exactWeekday,
  availabilities,
  createNewTimeLine,
}) => {
  return (
    <StyledAvailabilities>
      {exactWeekday.map((item, index) => (
        <CreateTimeLine
          key={index}
          index={index}
          item={item}
          id={item.id}
          exactWeekday={exactWeekday}
          createNewTimeLine={createNewTimeLine}
          availabilities={availabilities}
        />
      ))}
    </StyledAvailabilities>
  )
}

export default Availabilities
