import React from 'react'
import styled from 'styled-components'
import { STYLES } from '../../styles'
import { StyledInputLabel } from './DefaultTextField'

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 116px;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1.125rem;
  background-color: white;
  border: none;
  outline: none;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%),
    0 0 0 0.025rem #bdbdbd;
  &:focus {
    box-shadow: 0 0 0 0.125rem ${STYLES.COLORS.lightBlue};
  }
`

const DefaultTextArea = ({
  label,
  id,
  onChange,
  value,
  required,
  placeholder,
}) => {
  return (
    <StyledInputLabel>
      {label ? (
        <h4>
          {label}
          <span>{required ? '*' : ''}</span>
        </h4>
      ) : null}
      <StyledTextArea
        value={value}
        id={id}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
      />
    </StyledInputLabel>
  )
}

export default DefaultTextArea
