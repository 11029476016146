import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  deleteVideoLink,
  updateVideoLink
} from '../../../../redux/videoLibrary/action'
import DefaultTextField from '../../../kit/DefaultTextField'
import ItemWrapper from '../../../kit/ItemWrapper'
import RemoveBtn from '../../../kit/RemoveBtn'

const VideoItem = ({ item, id }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const updateTestimonialHandler = (e) => {
    const val = e.target.value
    const id = e.target.id
    dispatch(updateVideoLink({ ...item, [id]: val }))
  }

  const deleteItemHandler = () => {
    dispatch(deleteVideoLink(item.id))
  }
  const [url, setUrl] = React.useState(item.url);
  const [getId, setid] = React.useState("D"+id);

  return (
    <ItemWrapper>
      <DefaultTextField
        id={item.id}
        label={item.title}
        value={url}
        name={getId}
        onChange={(e) => setUrl(e.target.value)}
      />
    </ItemWrapper>
  )
}

export default VideoItem
