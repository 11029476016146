import { getLoggedInUser } from '../../utils/helpers'
//Authorization
import {
  LIST_REFER_USER,
  LIST_REFER_USER_FAILED,
  LIST_REFER_USER_SUCCESS,
  LIST_REFER_USER_SUCCESS_ID
} from './constants'

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
  error: null,
  rUsers: [],
}

const Account = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_REFER_USER:
      return { ...state, loading: true, error: false }
    case LIST_REFER_USER_SUCCESS_ID:
      return { ...state, loading_id: false, error: false, rUsersId: action.payload.ancestors }
    case LIST_REFER_USER_SUCCESS:
      return { ...state, loading: false, error: false, rUsers: action.payload }    
    case LIST_REFER_USER_FAILED:
      return { ...state, loading: false, error: 'Какая то ошибка.' }
    default:
      return { ...state }
  }
}

export default Account
