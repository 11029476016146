import * as types from './types'

export const togglePopup = (bool) => ({
  type: types.TOGGLE_POPUP,
  bool,
})
export const setImgPreview = (str) => ({
  type: types.SET_IMG_PREVIEW,
  str,
})
