import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { goVcardDataToEditor } from '../../redux/constructor/actions';

export default function HappyStart({ distributorId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const { formData } = useSelector((state) => state.Request)
  const { vCards } = useSelector((state) => state.Account);
  // const [mainData, setMainData] = useState({})
  const history = useHistory();
  // const user = getLoggedInUser()
  function handleClickToEditor() {
    history.replace('/account');
  }
  const formatDomainPrefix = () => {
    const newValue = vCards?.[0]?.domain_prefix
      .toLowerCase()
      .split(' ')
      .filter((el) => el)
      .join('-');
    return newValue;
  };
  const formattedDomainPrefix = formatDomainPrefix();

  useEffect(() => {
    if (vCards) dispatch(goVcardDataToEditor(vCards[0]));
  }, [vCards, dispatch]);

  return (
    <Form style={{ width: '768px' }}>
      <div style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
        <p
          style={{
            textTransform: `${!distributorId} ? uppercase : 'none'`,
            fontWeight: 500,
          }}
        >
          {!distributorId ? t('hapystartDesc') : t('distFinalDesc1')}
        </p>
      </div>
      <div
        className="mt-1"
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        {!distributorId ? (
          <>
            <a
              href={`https://${formattedDomainPrefix}.talkingvcard.net`}
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: '#0d6efd', textDecoration: 'underline' }}
            >
              <Button
                style={{ textTransform: 'lowercase', fontSize: '1.2rem' }}
                color="primary"
              >
                {formattedDomainPrefix}.talkingvcard.net
              </Button>
            </a>

            <div className="mt-3">
              <p style={{ textTransform: 'uppercase', fontWeight: 500 }}>
                {t('happyHourHeadOver')}
              </p>
              <br />
              <div>
                <span style={{ margin: 0, padding: 0 }}>
                  {t('addAppointments')}
                </span>
                <br />

                <span style={{ margin: 0, padding: 0 }}>
                  {t('addServices')}
                </span>
                <br />

                <span style={{ margin: 0, padding: 0 }}>
                  {t('customiseColorScheme')}
                </span>
                <br />
              </div>
            </div>
            <Button color="secondary" onClick={handleClickToEditor}>
              {t('goToEditor')}
            </Button>
          </>
        ) : (
          <div className="mt-3">
            
            <p style={{ fontWeight: 500 }}></p>
            <p style={{ fontWeight: 500 }}>
              <Link to="/account">Click Here</Link> to continue
            </p>
          </div>
        )}
      </div>
    </Form>
  );
}
