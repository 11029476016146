import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContentJson } from '../../../../redux/request/actions'
import DefaultTextField from '../../../kit/DefaultTextField'
import SaveBtn from '../../../kit/SaveBtn'
import Header from '../Header'

const Calendly = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector((state) => state.ConstructorReducer)
  const { id } = vcardDataToEdit
  const vcardCalendlyIdFromServer = vcardDataToEdit?.vcard_calendly_id

  const [vcard_calendly_id, setVcard_calendly_id] = useState(vcardCalendlyIdFromServer)

  const inputHandler = (e) => {
    const value = e.target.value
    const id = e.target.id
    formData.set(id, value)
    setVcard_calendly_id(value)
  }

  const isvcardCalendlyIdSame = vcard_calendly_id === vcardCalendlyIdFromServer

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isvcardCalendlyIdSame) {
      dispatch(fetchContentJson(id, { vcard_calendly_id }))
    }
  }
  return (
    <>
      <form onSubmit={saveHandler}>
        <Header disabled={isvcardCalendlyIdSame} />
        <DefaultTextField
          value={vcard_calendly_id || ''}
          onChange={inputHandler}
          label={t('calendly')}
          subtitle={t('doNotEnterTheCalendlyURL')}
        />
        <SaveBtn type='submit' disabled={isvcardCalendlyIdSame} />
      </form>
    </>
  )
}

export default Calendly
