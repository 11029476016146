import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@mui/material/TextField'
import LinkM from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import { fetchForgotPassword } from '../../../redux/auth/actions'
import { useTranslation } from 'react-i18next'

function Copyright() {
  const { t } = useTranslation()

  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {t('copyrightText')}
      <LinkM color='inherit' href={window.location.href}>
        {t('copywrite')}
      </LinkM>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  succes: {
    margin: theme.spacing(1),
    backgroundColor: 'green',
  },
  div: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ForgetPassword() {
  const { t } = useTranslation()

  const classes = useStyles()
  const dispatch = useDispatch()

  let { loading, login, error } = useSelector((state) => state.Authorization)

  const [userData, setUserData] = useState('')
  const [status, setStatus] = useState(true)
  function handleChangeUserData(e) {
    setUserData((e.target.value))
  }

  function sendForgotPassword(e) {
    e.preventDefault()
    if (!userData) return
    dispatch(fetchForgotPassword(userData.toLowerCase()))
  }

  useEffect(() => {
    if (error?.status === 200) {
      setStatus(false)
    }
  }, [error?.status])

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={login ? classes.succes : classes.avatar}>
          {loading ? (
            <CircularProgress />
          ) : login ? (
            <LockOpenIcon />
          ) : (
            <LockOutlinedIcon />
          )}
        </Avatar>
        <Typography component='h1' variant='h5'>
          {t('forgotPassword')}
        </Typography>
        {status ? (
          <form
            className={classes.div}
            noValidate
            onSubmit={sendForgotPassword}
          >
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              onChange={handleChangeUserData}
              id='email'
              label={t('emailAddress')}
              name={t('email')}
              autoComplete='email'
              autoFocus
            />
            <p style={{ color: 'red' }}>
              {error === 'error'
                ? t('emailAddressDesc')
                : ''}
            </p>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              type='submit'
              disabled={userData.length > 0 ? false : true}
              className={classes.submit}
            >
              {t('resetPassword')}
            </Button>
            <Grid container>
              <Grid item xs>
                <LinkM href='/sign-in' variant='body2'>
                  {t('login')}
                </LinkM>
              </Grid>
              <Grid item>
                <Link to='/sign-up'>{t('dontHaveAccount')}</Link>
              </Grid>
            </Grid>
          </form>
        ) : (
          <div className={classes.div} noValidate>
            <div className={classes.center} style={{ textAlign: 'center' }}>
              {/* {error?.data.message} */}
              {t('weHavesentYou')}
            </div>

            <div
              className={classes.center}
              style={{
                textAlign: 'center',
                background: '#0070f3',
                color: '#fff',
                marginTop: '15px',
                padding: '8px 10px',
              }}
            >
              <Link
                to='/sign-in'
                style={{
                  color: '#fff',
                  marginTop: '15px',
                  padding: '3px 10px',
                }}
              >
                {t('login')}
              </Link>
            </div>
          </div>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
