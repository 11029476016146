import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en.json";
import translationDE from "./locales/de.json";
import translationFR from "./locales/fr.json";
import translationES from "./locales/es.json";

const fallbackLng = ["en"];
const availableLanguages = ["den", "en", "fr", "de", "es"];
const defLang = localStorage.getItem("i18nextLng");
if (defLang === null) {
  localStorage.setItem("i18nextLng", "den")
}
const resources = {
  den: {
    translation: translationEN
  },
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  de: {
    translation: translationDE
  },
  es: {
    translation: translationES
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
