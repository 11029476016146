import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { APIMAIN } from '../../../../redux/API';
import {
  fetchContent,
  contentInfo,
} from '../../../../redux/request/actions';
import { STYLES } from '../../../../styles/index';
import SaveBtn from '../../../kit/SaveBtn';
import Header from '../Header';
import UploadButton from './UploadButton';
import UploadedImage from './UploadedImage';

import ReactDOM from 'react-dom';
import { getLoggedInUser } from '../../../../utils/helpers';


const StyledTitle = styled.h3`
  margin: 1rem 0;
  color: ${STYLES.COLORS.body};
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
`;

const MainContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector(
    (state) => state?.ConstructorReducer
  );

  const initState = {
    title: vcardDataToEdit?.title,
    subtitle: vcardDataToEdit?.subtitle,
    company_name: vcardDataToEdit?.company_name,
    about_title: vcardDataToEdit?.about_title,
    about_content: vcardDataToEdit?.about_content,
  };

  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });
  const [mainContent, setMainContent] = useState(initState);
  const [calender_image, setCalender_image] = useState(null);
  const [join_team_img, setJoin_team_img] = useState(null);

  const contentChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.id;
    const newStore = { ...mainContent };
    newStore[name] = value;
    setMainContent(newStore);
  };
  const [about_content, setAbout_content] = useState('');

  useEffect(() => {
    const newStore = { ...mainContent };
    newStore['about_content'] = about_content;
    setMainContent(newStore);
  }, [about_content]);

  useEffect(() => {
    if (vcardDataToEdit?.calender_image?.url)
      setCalender_image(APIMAIN + vcardDataToEdit?.calender_image?.url);
    if (vcardDataToEdit?.join_team_img?.url)
      setJoin_team_img(APIMAIN + vcardDataToEdit?.join_team_img?.url);
  }, [vcardDataToEdit?.calender_image?.url, vcardDataToEdit?.join_team_img?.url]);

  const isMessage = mainContent?.title !== '';

  const isAvatarSame = vcardDataToEdit?.calender_image?.url
    ? calender_image === APIMAIN + vcardDataToEdit?.calender_image?.url
    : calender_image === vcardDataToEdit?.calender_image?.url;

  const isBackgroundSame = vcardDataToEdit?.join_team_img?.url
    ? join_team_img === APIMAIN + vcardDataToEdit?.join_team_img?.url
    : join_team_img === vcardDataToEdit?.join_team_img?.url;

  const isSameContent =
    JSON.stringify(mainContent) === JSON.stringify(initState);

  const isSameData = isSameContent && isAvatarSame && isBackgroundSame;

  const saveHandler = (e) => {
    e.preventDefault();
    if (!isAvatarSame) dispatch(contentInfo(localStorage.getItem('c_id'), formData));
    if (!isBackgroundSame)
      dispatch(contentInfo(localStorage.getItem('c_id'), formData));
  };

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isSameData || !isMessage} />
      {calender_image ? (
        <UploadedImage
          image={calender_image}
          setState={setCalender_image}
          formData={formData}
          id="calender_image"
        />
      ) : (
        <UploadButton
          id="calender_image"
          setState={setCalender_image}
          formData={formData}
          textContent="Calendar"
        />
      )}
      <br />
      {join_team_img ? (
        <UploadedImage
          image={join_team_img}
          setState={setJoin_team_img}
          formData={formData}
          id="join_team_img"
        />
      ) : (
        <UploadButton
          id="join_team_img"
          setState={setJoin_team_img}
          formData={formData}
          textContent="Join Our Team"
        />
      )}
      <br />
      <SaveBtn disabled={isSameData || !isMessage} />
    </form>
  );
};

export default MainContent;
