import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ component: Component, ...rest }) => {
    let { user } = useSelector(state => state.Authorization)
    return (
        <div>
            {user ? <Route {...rest} component={Component} /> :
                <Redirect to={{
                    pathname: "/sign-in",
                    state: { from: rest.path }
                }} />}
        </div>
    );
};

export default PrivateRoute;