import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { APIMAIN } from '../../../redux/API';
import { StyledTitle } from '../../kit/Title';
import { allBanners } from '../../../components/constructor/Editors/Banner/BannerGifs';
import { useSelector } from 'react-redux';

const StyledSection = styled.section`
  padding-top: ${(props) => (props.background ? '0' : '0px')};
`;

const StyledMainTitle = styled(StyledTitle)`
  font-size: 2rem;
  margin: 0rem 0;
`;

const StyledMainSubtitle = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
`;

const ProfilePage = ({ data, mode }) => {
  const background = data?.background?.url;
  const avatar = data?.avatar?.url;
  const isMobile = mode === 'mobile';

  const { selectedBanner } = useSelector((state) => state.Banner);
  const [banner, setBanner] = useState(null);
  // useEffect(() => {
  //   if (selectedBanner) {
  //     allBanners.forEach((item) => {
  //       if (item.name === selectedBanner) {
  //         setBanner(item);
  //       }
  //     });
  //   }
  // }, [selectedBanner]);
  useEffect(() => {
    if (selectedBanner) {
      setBanner(selectedBanner);
    }
  }, [selectedBanner]);
  console.log(mode);
 const StyledAvatar = styled.div`
    width: max(33%, 105px);
    box-shadow: 5px 5px 20px rgb(0 0 0 / 20%);
    background-color: #ffffff80;
    border-radius: 50%;
    border: 2px solid #ffffff80;

    position: absolute;
    top: ${mode === 'mobile' ? '21%' : '21%'};
    left: ${mode === 'mobile' ? '11%' : '11%'};

    overflow: hidden;

    img {
      aspect-ratio: 1;
      vertical-align: middle;
      width: 100%;
    }
  `;
  const StyledAvatarN = styled.div`
    width: max(25%, 170px);
    margin-top: 40px !important;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 20%);
  background-color: #ffffff80;
  border-radius: 50%;
  border: 2px solid #ffffff80;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  img {
    aspect-ratio: 1;
    vertical-align: middle;
    width: 100%;
  }
  `;


  const StyledBackground = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${(props) => props.color};
    height: ${mode === 'mobile' ? '100%' : 'auto'};
    position: relative;
    img {
      width: 100%;
    }
  `;
  const StyledBackgroundN = styled.div`
    display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  margin-bottom: min(-12.5%, -85px);

  img {
    width: 100%;
  }
`;
console.log("test",data?.user_type)
  return (
    <StyledSection background={banner}>
        {data?.user_type === ("distributor") && (
          <>
      <div style={{ position: 'relative' }}>
        {data?.banner_image && (
        <StyledBackground
          isMobile={isMobile}
          color={data?.background_primary_color}
        >
          <img src={APIMAIN + data?.banner_image} alt='background' />
        </StyledBackground>
      )}
        {avatar && (
          <StyledAvatar background={background}>
            <img src={APIMAIN + avatar} alt="avatar" />
          </StyledAvatar>
        )}
      </div>
        </>
        )}
        {data?.user_type === ("admin") && (
          <>
      <div style={{ position: 'relative' }}>
        {data?.banner_image && (
        <StyledBackground
          isMobile={isMobile}
          color={data?.background_primary_color}
        >
          <img src={APIMAIN + data?.banner_image} alt='background' />
        </StyledBackground>
      )}
        {avatar && (
          <StyledAvatar background={background}>
            <img src={APIMAIN + avatar} alt="avatar" />
          </StyledAvatar>
        )}
      </div>
        </>
        )}
      {(data?.user_type === null || data?.user_type === 'sales') && (
        <>
      {background && (
        <StyledBackgroundN
          isMobile={isMobile}
          color={data?.background_primary_color}
        >
          <img src={APIMAIN + background} alt='background' />
        </StyledBackgroundN>
      )}
      {avatar && (
        <StyledAvatarN background={background}>
          <img src={APIMAIN + avatar} alt='avatar' />
        </StyledAvatarN>
      )}

        </>
        )}
      <StyledMainTitle style={{'margin-top': '16px'}}>{data?.title}</StyledMainTitle>

      {data?.subtitle && (
        <StyledMainSubtitle>{data?.subtitle}</StyledMainSubtitle>
      )}
    </StyledSection>
  );
};
export default ProfilePage;
