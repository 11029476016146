import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { APIMAIN } from '../../../../redux/API';
import {
  fetchContent,
  fetchContentJson,
} from '../../../../redux/request/actions';
import { STYLES } from '../../../../styles/index';
import DefaultCKEditor from '../../../kit/DefaultCKEditor';
import DefaultTextArea from '../../../kit/DefaultTextArea';
import DefaultTextField from '../../../kit/DefaultTextField';
import SaveBtn from '../../../kit/SaveBtn';
import Header from '../Header';
import UploadButton from './UploadButton';
import UploadedImage from './UploadedImage';

import ReactDOM from 'react-dom';
import { getLoggedInUser } from '../../../../utils/helpers';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { FroalaEditor } from './FroalaEditor';

const StyledTitle = styled.h3`
  margin: 1rem 0;
  color: ${STYLES.COLORS.body};
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
`;

const MainContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector(
    (state) => state?.ConstructorReducer
  );

  const initState = {
    title: vcardDataToEdit?.title,
    subtitle: vcardDataToEdit?.subtitle,
    company_name: vcardDataToEdit?.company_name,
    about_title: vcardDataToEdit?.about_title,
    about_content: vcardDataToEdit?.about_content,
  };

  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });
  const [mainContent, setMainContent] = useState(initState);
  const [avatar, setAvatar] = useState(null);
  const [background, setBackground] = useState(null);

  const contentChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.id;
    const newStore = { ...mainContent };
    newStore[name] = value;
    setMainContent(newStore);
  };
  const [about_content, setAbout_content] = useState('');
  // const ckEditorChangeHandler = (value, name) => {
  //   console.log(value)
  // if (name === "about_content") {
  // setAbout_content(value)
  // }

  /*    const value = e.target.value
       const name = e.target.id */
  // const newStore = { ...mainContent }
  // newStore[name] = value
  // setMainContent(newStore)
  // }

  const froalaEditor = (value, name) => {
    // alert(value)
    // if (name === "about_content") {
    setAbout_content(value);
    // }

    /*    const value = e.target.value
       const name = e.target.id */
    // const newStore = { ...mainContent }
    // newStore[name] = value
    // setMainContent(newStore)
  };
  useEffect(() => {
    const newStore = { ...mainContent };
    newStore['about_content'] = about_content;
    setMainContent(newStore);
  }, [about_content]);

  useEffect(() => {
    if (vcardDataToEdit?.avatar?.url)
      setAvatar(APIMAIN + vcardDataToEdit?.avatar?.url);
    if (vcardDataToEdit?.background?.url)
      setBackground(APIMAIN + vcardDataToEdit?.background?.url);
  }, [vcardDataToEdit?.avatar?.url, vcardDataToEdit?.background?.url]);

  const isMessage = mainContent?.title !== '';

  const isAvatarSame = vcardDataToEdit?.avatar?.url
    ? avatar === APIMAIN + vcardDataToEdit?.avatar?.url
    : avatar === vcardDataToEdit?.avatar?.url;

  const isBackgroundSame = vcardDataToEdit?.background?.url
    ? background === APIMAIN + vcardDataToEdit?.background?.url
    : background === vcardDataToEdit?.background?.url;

  const isSameContent =
    JSON.stringify(mainContent) === JSON.stringify(initState);

  const isSameData = isSameContent && isAvatarSame && isBackgroundSame;

  const saveHandler = (e) => {
    e.preventDefault();
    if (!isSameContent)
      dispatch(fetchContentJson(vcardDataToEdit?.id, mainContent));
    if (!isAvatarSame) dispatch(fetchContent(vcardDataToEdit?.id, formData));
    if (!isBackgroundSame)
      dispatch(fetchContent(vcardDataToEdit?.id, formData));
  };

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isSameData || !isMessage} />
      <DefaultTextField
        value={mainContent?.title || ''}
        onChange={contentChangeHandler}
        label={t('name')}
        required
        id="title"
      />
      <DefaultTextArea
        value={mainContent?.subtitle || ''}
        onChange={contentChangeHandler}
        label={t('subTitle')}
        id="subtitle"
      />
      <DefaultTextField
        value={mainContent?.company_name || ''}
        onChange={contentChangeHandler}
        label={t('companyName')}
        subtitle={t('companySubTitle')}
        id="company_name"
      />
      <StyledTitle>{t('aboutSection')}</StyledTitle>
      <DefaultTextField
        value={mainContent?.about_title || ''}
        onChange={contentChangeHandler}
        label={t('aboutSectionTitle')}
        id="about_title"
      />

      {/* <DefaultTextArea
        value={mainContent?.about_content || ''}
        onChange={contentChangeHandler}
        label={t('aboutSectionContent')}
        id='about_content'
      /> */}
      <FroalaEditor
        value={mainContent?.about_content || ''}
        onChange={froalaEditor}
        id="about_content"
        label={t('aboutSectionContent')}
      />
      {/*<DefaultCKEditor
        value={mainContent?.about_content || ''}
        onChange={ckEditorChangeHandler}
        label={t('aboutSectionContent')}
        id='about_content'
      />*/}
      {avatar ? (
        <UploadedImage
          image={avatar}
          setState={setAvatar}
          formData={formData}
          id="avatar"
        />
      ) : (
        <UploadButton
          id="avatar"
          setState={setAvatar}
          formData={formData}
          textContent={t('uploadTitle')}
        />
      )}
      {/*<p>{t('mainContentNote')}</p>*/}
      {(loggedUser?.user_type === null || loggedUser?.user_type === 'sales' || loggedUser?.user_type === 'super_admin') && (
          <>
          
      <br />
      {background ? (
        <UploadedImage
          image={background}
          setState={setBackground}
          formData={formData}
          id="background"
        />
      ) : (
        <UploadButton
          id="background"
          setState={setBackground}
          formData={formData}
          textContent={t('uploadCoverTitle')}
        />
      )}
      {/*<p>{t('uploadCoverNote')}</p>*/}
      <br />
      </>
        )}
      <SaveBtn disabled={isSameData || !isMessage} />
    </form>
  );
};

export default MainContent;
