import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { changeQrCode } from '../../redux/constructor/actions';
import About from './components/About';
import HTMLediter from './components/HTMLediter';
import HTMLediterTwo from './components/HTMLediterTwo';
import ContactInfo from './components/ContactInfo';
import AddMeToContacts from './components/AddMeToContacts';
import Calendar from './components/Calendar';
import JoinTeam from './components/JoinTeam';
import Appointments from './components/Appointments';
import ContactForm from './components/ContactForm';
import Gallery from './components/Gallery';
import ModalWindowIOS from './components/ModalWindowIOS';
import PhoneLinks from './components/PhoneLinks.jsx';
import ProfilePage from './components/ProfilePage';
import QRCode from './components/qrCode';
import Services from './components/Services';
import ShareMyCard from './components/ShareMyCard';
import Testimonials from './components/Testimonials';
import VideoFrame from './components/VideoFrame';
import VideoGallery from './components/VideoGallery';
import { APIMAIN } from '../../redux/API';
import VmailBLAZE from './components/VmailBLAZE';
import StoriesVideo from './components/StoriesVideo';
import { Helmet } from 'react-helmet';
import LanguageSelect from '../multiLanguage/LanguageSelect';
import i18next from 'i18next';
import CalendlyAppointments from './components/CalendlyAppointments';
import { getLoggedInUser } from '../../utils/helpers';
import { getAllBanners, selectBanner } from '../../redux/banner/actions';
import CompanyInfo from './components/CompanyInfo';
import Footer from './components/Footer';

const StyledApp = styled.div`
  h3 {
    color: ${(p) => p.heading};
  }
  p {
    color: ${(p) => p.body};
    margin: 0;
  }
`;

const StyledBackground = styled.div`
  background-color: ${(props) => props.color};
`;

const StyledContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  background-color: ${(props) => props.color};
`;

const App = ({ mode, data }) => {
  const { qrCode } = useSelector((s) => s.ConstructorReducer);
  window.document.title = data?.page_title;
  const heading = data?.heading_color;
  const body = data?.body_color;
  const avatar = data?.avatar?.url;

  const getMobileOperatingSystem = () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPhone/i)) {
      return 'iOS';
    } else if (userAgent.match(/Android/i)) {
      return 'Android';
    } else {
      return 'unknown';
    }
  };

  const getBgColor = () => {
    const userColor = data?.group_type;
    if (userColor===1) {
      return '#239A05';
    } else if (userColor===2) {
      return '#FD65C3';
    } else if (userColor===3) {
      return '#FD1616';
    } else if (userColor===4) {
      return '#CBA489';
    } else {
      return data?.background_primary_color;
    }
  };

  const [modal, setModal] = useState(true);
  const mobileOS = getMobileOperatingSystem();
  console.log(mobileOS);
  const UserNcolor = data?.background_primary_color;
  const bgcolor = data?.banner_group.background_primary_color;
  const bgheading_color = data?.banner_group.heading_color;
  const bgbody_color = data?.banner_group.body_color;

  useEffect(() => {
    if (window.location.hash !== '#/constructor') {
      if (data?.vcard_language) {
        i18next.changeLanguage(data?.vcard_language);
      }
    }
  }, [data]);
  useEffect(() => {
    if (data?.google_tracking) {
      ReactGA.initialize(data?.google_tracking);
    }
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, [data]);

  useEffect(() => {
    const options = {
      autoConfig: true,
      debug: false,
    };
    if (data?.facebook_pixel) {
      ReactPixel.init(data?.facebook_pixel, options);
    }
    ReactPixel.pageView();
  }, [data]);

  /*   useEffect(() => {
      const avatar = data?.avatar?.url
      console.log("sfdasdasdsa", avatar);
  
      if (avatar) {
        console.log("sfd");
        var ogImage = document.createElement('meta');
        ogImage.Property = "og:image";
        ogImage.content = APIMAIN + avatar;
        document.head.appendChild(ogImage);
  
        var ogImagetype = document.createElement('meta');
        ogImagetype.property = "og:image:type";
        ogImagetype.content = "image/png";
        document.head.appendChild(ogImagetype);
  
        var ogImagewidth = document.createElement('meta');
        ogImagewidth.property = "og:image:width";
        ogImagewidth.content = "1024";
        document.head.appendChild(ogImagewidth);
  
        var ogImageheight = document.createElement('meta');
        ogImageheight.property = "og:image:height";
        ogImageheight.content = "1024";
        document.head.appendChild(ogImageheight);
      }
    }, [data]) */

  useEffect(() => {
    if (data?.tvc_tracking_code) {
      const datastr = data?.tvc_tracking_code.split('hmt_script.src');
      if (datastr.length > 1) {
        const keyurl = datastr[1]
          .split('hmt_purl')[0]
          .split('//')[1]
          .split('="+')[0];
        console.log('dataStr', keyurl);
        if (typeof hmtracker == 'undefined') {
          window.hmtParentUrl = '//talkingvcard.com/tracking/';
          var hmt_script = document.createElement('script'),
            hmt_purl = encodeURIComponent(window.location.href).replace(
              '.',
              '~'
            );
          hmt_script.type = 'text/javascript';
          hmt_script.src = '//' + keyurl + '=' + hmt_purl;
          document.getElementsByTagName('head')[0].appendChild(hmt_script);
        }
        var hmtParallaxScript = document.createElement('script');
        hmtParallaxScript.src =
          '//talkingvcard.com/tracking/js/parallax-script.js';
        document.head.appendChild(hmtParallaxScript);
      }
    }
  }, [data]);

  useEffect(() => {
    var hmtParallaxScript = document.createElement('script');
    hmtParallaxScript.src =
      'https://assets.calendly.com/assets/external/widget.js';
    document.head.appendChild(hmtParallaxScript);
  }, [data]);

  const dispatch = useDispatch();
  // let { user } = getLoggedInUser();
  // const [loggedUser, setLoggedUser] = useState({ ...user });

  // useEffect(() => {
  //   dispatch(getAllBanners(loggedUser?.company_id));
  // }, []);

  const { banners } = useSelector((state) => state.Banner);

  // TODO: make selected banner here

  useEffect(() => {
    if (banners.length > 0) {
      dispatch(selectBanner(banners[0].filename.url));
    }
  }, [banners]);
  localStorage.setItem('chatPal', data?.kwiki_chat);
  
      console.log(bgcolor);
  return (
    <StyledApp heading={(data?.user_type === null) || (data?.user_type === 'sales') ? heading : bgheading_color} body={(data?.user_type === null) || (data?.user_type === 'sales') ? body : bgbody_color}>
      {data?.avatar?.url && (
        <Helmet>
          <meta
            property="og:image"
            content={APIMAIN + data?.avatar?.url}
          ></meta>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={APIMAIN + data?.avatar?.url}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={APIMAIN + data?.avatar?.url}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={APIMAIN + data?.avatar?.url}
          />
        </Helmet>
      )}

      <link rel="apple-touch-icon" sizes="180x180" href={APIMAIN + avatar} />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={APIMAIN + avatar + '?v=2'}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={APIMAIN + avatar + '?v=2'}
      />
      {qrCode ? (
        <QRCode cardData={data} setQrCode={changeQrCode} />
      ) : (
        <StyledBackground color={(data?.user_type === null || data?.user_type === 'sales') ? data?.background_page_color : data?.banner_group?.background_page_color}>        
          <StyledContainer color={(data?.user_type === null || data?.user_type === 'sales') ? data?.background_primary_color :bgcolor}>
            {/* <LanguageSelect /> */}
            <ProfilePage mode={mode} data={data} />

            <VideoFrame mode={mode} data={data} />

            <PhoneLinks mode={mode} data={data} />            

            <Appointments mode={mode} data={data} />

            <CalendlyAppointments mode={mode} data={data} />


            <CompanyInfo mode={mode} data={data} />
            
            <AddMeToContacts mode={mode} data={data} />

            <ContactInfo mode={mode} data={data} />

            <About mode={mode} data={data} />

            {(data?.user_type === null) && (
            <>
              <HTMLediterTwo mode={mode} data={data} />
            </>
            )}

            {(data?.user_type === 'sales') && (
            <>
              <HTMLediterTwo mode={mode} data={data} />
            </>
            )}



            <Calendar mode={mode} data={data} />
            
            {data?.user_type === 'admin' && (
            <>
              <HTMLediter mode={mode} data={data} />
            </>
            )}
            {data?.user_type === 'distributor' && (
            <>
              <HTMLediter mode={mode} data={data} />
            </>
            )}
            <Services mode={mode} data={data} />

            <Gallery mode={mode} data={data} />

            <Testimonials mode={mode} data={data} />

            <StoriesVideo mode={mode} data={data} />

            <VideoGallery mode={mode} data={data} />

            <ContactForm mode={mode} data={data} />

            <VmailBLAZE mode={mode} data={data} />

            <JoinTeam mode={mode} data={data} />

            <AddMeToContacts mode={mode} data={data} />

            {(data?.user_type === null) && (
            <>
              <HTMLediter mode={mode} data={data} />
            </>
            )}

            {(data?.user_type === 'sales') && (
            <>
              <HTMLediter mode={mode} data={data} />
            </>
            )}


            <ShareMyCard setQrCode={changeQrCode} mode={mode} data={data} />
            
            {/*{modal && mobileOS === 'iOS' && (
              <ModalWindowIOS setModal={setModal} />
            )}*/}
            {data?.user_type === 'salesnnsss' && (
              <>
            <Footer mode={mode} data={data} />
            </>
            )}
            {/*{modal && mobileOS === 'Android' && (
              <ModalWindowIOS setModal={setModal} />
            )}*/}
          </StyledContainer>
        </StyledBackground>
      )}
    </StyledApp>
  );
};
export default App;
