import axios from 'axios'
import { getLoggedInUser } from '../../utils/helpers'
import API from '../API'
import { fetchVcardDataToEditor } from '../constructor/actions'
import * as types from './types'

export const addTestimonialsListItem = (testimonial) => ({
  type: types.ADD_TESTIMONIALS_LIST_ITEM,
  testimonial,
})

export const updateTestimonialsListItem = (data) => ({
  type: types.UPDATE_TESTIMONIALS_LIST_ITEM,
  data,
})

export const updateTestimonialsList = (data) => ({
  type: types.UPDATE_TESTIMONIALS_LIST,
  data,
})

export const deleteTestimonialsListItem = (id) => ({
  type: types.DELETE_TESTIMONIALS_LIST_ITEM,
  id,
})

export const putTestimonialsToServer = (vcardId, data) => {
  let user = getLoggedInUser()
  data = data.map((item) => {
    if (item.id < 0) {
      delete item.id
      return item
    } else return item
  })

  return (dispatch) => {
    axios
      .put(`${API}/vcards/${vcardId}/testimonials`, data, {
        headers: {
          Authorization: `Bearer ${user.auth.token}`,
          'content-Type': 'application/json',
        },
      })
      .then((data) => {
        dispatch(fetchVcardDataToEditor(vcardId))
      })
      .catch((err) => console.warn('putTestimonialsToServer:', err))
  }
}

export const deleteTestimonialsFromServer = (vcardId, deletedTestimonials) => {
  let user = getLoggedInUser()
  let promises = deletedTestimonials.map((item) => {
    return axios.delete(`${API}/vcards/${vcardId}/testimonials/${item}`, {
      headers: {
        Authorization: 'Bearer ' + user.auth.token,
        'content-Type': 'application/json',
      },
    })
  })
  return (dispatch) => {
    Promise.all(promises)
      .then((data) => {
        dispatch(clearDeletedTestimonials())
        dispatch(fetchVcardDataToEditor(vcardId))
      })
      .catch((err) => console.warn('deleteTestimonialsFromServer', err))
  }
}

export const clearDeletedTestimonials = () => ({
  type: types.CLEAR_DELETED_TESTIMONIALS,
})
