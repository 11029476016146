// This example shows you how to set up React Stripe.js and use Elements.
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import PaymentForm from "./PaymentForm"
import axios from 'axios';
import './stripe-card.css';
import React, { useEffect, useState, createRef } from 'react';
import Card from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation  } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../utils/alert';
import { getLoggedInUser } from '../../../utils/helpers';
import API from './../../../redux/API';
import { logOutUser } from './../../../redux/auth/actions';
import './styles.scss';
import { Button } from '@material-ui/core';

function PlanBlock({ data, active, setActive }) {
  return (
    <div
      type="button"
      onClick={() => setActive(data)}
      className={`rounded btn-plan flex flex-col self-start w-40 text-left focus:outline-none border-4 border-green-500 ${
        active.id === data.id ? 'active-plan' : ''
      }`}
    >
      <span className="py-2 px-3">{data.title}</span>
      <span className="inline-block w-full py-2 px-3 pb-4 font-bold text-black">
        ${data.price} / {data.text}
      </span>
    </div>
  );
}

const StyledLogoutBtn = styled.div`
  text-align: center;
  a {
    padding: 0.5rem;
  }
`;

export default function MyCard() {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  let { auth, user } = getLoggedInUser();
  if (window.location.href.includes('payment_intent=')) {

    const redirect_status = window.location.href.split('redirect_status=')[1];
    const redirect_status_r = redirect_status.replace('#/', "");
    const client_secret = window.location.href.split('payment_intent_client_secret=')[1];
    const client_secret_r = client_secret.replace('&redirect_status='+redirect_status, "");
    const payment_intent = window.location.href.split('payment_intent=')[1];
    const payment_intent_r = payment_intent.replace('&payment_intent_client_secret='+client_secret, "");
    let { auth, user } = getLoggedInUser();
      fetch(API +"/charges/payment_status", {
        method: "POST",
        headers: {
            Authorization: 'Bearer ' + auth.token,
            'content-Type': 'application/json',
          },
        body: JSON.stringify({
          payment_id : payment_intent_r,
          client_secret : client_secret_r,
          status : redirect_status_r,
        }),
      }).then(async (result) => {
        localStorage.setItem('payment_status',"1")
        localStorage.setItem('signUP',"1")
      });    
    }

  // Initializing all the state variables 
  const [amount, setamount] = useState(99);
  const [info, setInfo] = useState([]);
  const [input, setInput] = useState(0);
  const [from, setFrom] = useState("usd");
  const [to, setTo] = useState("usd");
  const [options, setOptions] = useState([]);
  const [output, setOutput] = useState(0);
  
  // Calling the api whenever the dependency changes
  useEffect(() => {
    axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${from}.json`)
   .then((res) => {
      setInfo(res.data[from]);
    })
  }, [from]);
  
  // Calling the convert function whenever
  // a user switches the currency
  useEffect(() => {
    setOptions(Object.keys(info));
    convert();
  }, [info])
    
  // Function to convert the currency
  function convert() {
    var rate = info[to];
  }
  
  // Function to switch between two currency
  function flip() {
    var temp = from;
    setFrom(to);
    setTo(temp);
  }
  const [price, setPrice] = useState('');
  const [data, setData] = useState({
    number: '',
    name: user.username,
    expiry: '',
    cvc: '',
    issuer: '',
    focused: '',
    formData: null,
  });
  const [issuer, setIssuer] = useState({});
  const [focused, setFocused] = useState({});
  const [error, setError] = useState(false);
  // const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer)
  const allPlans = [
    {
      id: 1,
      title: '',
      price: 99,
      text: t('lifetime'),
      type: 'lifetime',
    },
    {
      id: 2,
      title: t('yearly'),
      price: price.year,
      // price: 1,
      text: t('yearly'),
      type: 'year',
    },
    {
      id: 3,
      title: t('monthly'),
      price: price.month,
      // price: 1,
      text: t('monthly'),
      type: 'month',
    },
  ];

  const allPlans2 = [
    {
      id: 1,
      title: t('monthly'),
      price: price.monthly===null ? 13 : price.monthly,
      // price: 1,
      text: t('monthly'),
      type: 'sass_month_subscription_1',
    },
    {
      id: 2,
      title: t('yearly'),
      price: price.yearly===null ? 121 : price.yearly,
      // price: 1,
      text: t('yearly'),
      type: 'sass_month_subscription_2',
    },
  ];

   const [plans, setPlans] = useState(allPlans);
   const [plans2, setPlans2] = useState(allPlans2);
  const [active, setActive] = useState(allPlans2[1]);
  // if user_type="admin" then show only two plans
  useEffect(() => {
    if (user?.user_type === 'admin') {
      setActive(plans2[1]);
    }else if (user?.user_type === 'distributor') {
      setActive(plans2[1]);
    } else {
      setActive(plans[0]);
    }
  }, []);

  const [coupon, setCoupon] = useState('');
  const [cardPay, setCardPay] = useState(true);
  const [couponData, setCouponData] = useState('');
  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setIssuer(issuer);
    }
  };

  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  };
  const [loggedUser, setLoggedUser] = React.useState({ ...user });

  useEffect(() => {
    if (user?.user_type === 'distributor') {
      axios
      .get(API + `/companies/${loggedUser?.company_id}`, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        setPrice(data);

      console.log("monthly d",data.monthly);
      })
      .catch((err) => {
        console.log('err', err);
      });
      console.log("monthly d",price);
      if(price.monthly===null){
        axios
        .get(API + '/subscriptions/prices', {
          headers: {
            Authorization: `Bearer${auth.token}`,
            'content-Type': 'application/json',
          },
        })
        .then(({ data }) => {
          setPrice(data);
        })
        .catch((err) => {
          console.log('err', err);
        });
      }
    }else if (user?.user_type === 'admin') {
      axios
      .get(API + `/companies/${loggedUser?.company_id}`, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        setPrice(data);
      })
      .catch((err) => {
        console.log('err', err);
      });
      console.log("monthly a",price.monthly);
      if(price.monthly===null){
        axios
        .get(API + '/subscriptions/prices', {
          headers: {
            Authorization: `Bearer${auth.token}`,
            'content-Type': 'application/json',
          },
        })
        .then(({ data }) => {
          setPrice(data);
        })
        .catch((err) => {
          console.log('err', err);
        });
      }
    }else {
      axios
      .get(API + '/subscriptions/prices', {
        headers: {
          Authorization: `Bearer${auth.token}`,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        setPrice(data);
      })
      .catch((err) => {
        console.log('err', err);
      });
    }
  }, [auth.token]);

  useEffect(() => {
    axios
      .post(
        API + '/subscriptions/payment_with_discount',
        {
          coupon_id: coupon,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'content-Type': 'application/json',
          },
        }
      )
      .then(({ data }) => {
        setCouponData(data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [coupon, auth.token]);

  const [captcha, setCaptcha] = useState(false);
  function onChange(value) {
    setCaptcha(true);
  }

  function handleInputChange(event){
    setTo(event.target.value);
    if(event.target.value==="usd"){
      setamount(((info[event.target.value])*99).toFixed(2));
    }else{
      setamount(((info[event.target.value])*101).toFixed(2));
    }
    
    setError(false);
    // if (target.name === 'number') {
    //   target.value = formatCreditCardNumber(target.value);
    // } else if (target.name === 'expiry') {
    //   target.value = formatExpirationDate(target.value);
    // } else if (target.name === 'cvc') {
    //   target.value = formatCVC(target.value);
    // }

    setData({ ...data, [event.target.name]: event.target.value });
  };
  // setamount(toamount);
  const recaptchaRef = React.createRef();
  const [success, setSuccess ] = useState(false);

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [stripePromise, setStripePromise] = useState(loadStripe("pk_live_51N0PjhFZy7FCqNjEMBCDkiiOk9Z6cYVcgqU502LJHvAUjPf0c3jZTUFIARJZIjx97Vxi08OIxK0n6OLOqxr7pCbA00YKU1Va1J"));
  const [clientSecret, setClientSecret] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});
      console.log(formData);
    fetch(API +"/charges", {
      method: "POST",
      headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      body: JSON.stringify({
        currency :formData.currency,
        line1 : formData.address1,
        line2 : formData.address2,
        city : formData.city,
        state : formData.state,
        postal_code : formData.zip,
        subscription_plan : "lifetime"
      }),
    }).then(async (result) => {
      console.log(result);
      var { clientSecret } = await result.json();
      console.log(clientSecret);
      setClientSecret(clientSecret);
    });
  };

  // useEffect(() => {
  //   fetch(API +"/charges", {
  //     method: "POST",
  //     headers: {
  //         Authorization: 'Bearer ' + auth.token,
  //         'content-Type': 'application/json',
  //       },
  //     body: JSON.stringify({
  //       currency :"usd",
  //       line1 : "A-87 Sarvodaya Nagar , Lucknow",
  //       line2 : "",
  //       city : "Lucknow",
  //       state : "UP",
  //       postal_code : 226016,
  //       subscription_plan : "lifetime"
  //     }),
  //   }).then(async (result) => {
  //     console.log(result);
  //     var { clientSecret } = await result.json();
  //     console.log(clientSecret);
  //     setClientSecret(clientSecret);
  //   });
  // }, []);
  function handleClickToSignup() {
    history.replace('/sign-up');
  }
  const logoutHandler = () => {
    dispatch(logOutUser());
  };
  return (
    <div className={`card-block ${cardPay ? '' : 'success-block'}`}>
      {window.location.href.includes('payment_intent=')===false ? (
        <>
          {user.activity_info.is_active ? (
            ''
          ) : (
            <div className="card-mini p-8 bg-red-200 my-4 mt-0">
              <h1 class="mb-4 text-lg">
                {t('hello')} {user.username},
              </h1>
              <p className="continue-text">{t('toContinue')}</p>
            </div>
          )}
          <div key="Payment" className="card-mini">
            <div>
              <h3>{t('pleaseEnterBillingDetails')}</h3>
            </div>
            <div>
              <label className="text-lg mb-1 text-gray-700 block undefined">
                {t('choosePlan')}
                <span className="text-red-500 font-bold">*</span>
              </label>
            </div>
            <div className="flex justify-around mb-1 mt-2">
              {
                user?.user_type === 'distributor' ?
                <>
                {allPlans2 && allPlans2.map((item) => (
                  <PlanBlock
                    data={item}
                    key={item.id}
                    active={active}
                    setActive={setActive}
                  />
                ))}
                </>
                :''
              }
              {
                user?.user_type === 'admin' ?
                <>
                {allPlans2 && allPlans2.map((item) => (
                  <PlanBlock
                    data={item}
                    key={item.id}
                    active={active}
                    setActive={setActive}
                  />
                ))}
                </>
                :''
              } 
              {
                (user?.user_type === null || user?.user_type === 'sales') ?
                <>
                {allPlans && allPlans.map((item) => (

                (item.id === 1) ?
                <>
                  <PlanBlock
                    data={item}
                    key={item.id}
                    active={active}
                    setActive={setActive}
                  />
                  </>
                :''
              
                ))}
                </>
                :''
              } 
              
            </div>

            {clientSecret && stripePromise ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentForm />
              </Elements>
            ) :
            (<div className="App-payment">
              {/* ref={(c) => (this.form = c)}  */}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <div>
                    {t('name')}
                    <span className="text-red-500 font-bold">*</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder={t('name')}
                      maxLength="30"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    Currency
                    <span className="text-red-500 font-bold">*</span>
                  </div>
                  <div className="form-group">
                    <select class="form-control" required onChange={handleInputChange}  name="currency">
                      <option>select currency</option>    
                      <option value="gbp">British Pound Sterling</option>
                      <option value="cad">Canadian Dollar</option>
                      <option value="usd" selected>US Dollar</option>
                      <option value="eur">Euro</option>
                      </select>
                    
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    {t('Address Line 1')}
                    <span className="text-red-500 font-bold">*</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="address1"
                      className="form-control"
                      placeholder={t('Address Line 1')}
                      maxLength="30"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    {t('Address Line 2')}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="address2"
                      className="form-control"
                      placeholder={t('Address Line 2')}
                      maxLength="30"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    {t('City')}
                    <span className="text-red-500 font-bold">*</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder={t('City')}
                      maxLength="30"
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div>
                    {t('state')}
                    <span className="text-red-500 font-bold">*</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="state"
                      className="form-control"
                      placeholder={t('state')}
                      maxLength="30"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    {t('Postal Code')}
                    <span className="text-red-500 font-bold">*</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="zip"
                      className="form-control"
                      placeholder={t('Postal Code')}
                      maxLength="30"
                      required
                    />
                  </div>
                </div>
                <input type="hidden" name="issuer" value={issuer} />
                <div className="form-group">
                  <div>{t('coupon')}</div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="coupon"
                      className="form-control"
                      placeholder="Enter coupon"
                      maxLength="30"
                      onChange={(e) => setCoupon(e.target.value)}
                      onFocus={handleInputFocus}
                    />
                    {coupon ? (
                      couponData.message ? (
                        <div class="text-red-700" style={{ color: 'red' }}>
                          {t('couponNotValid')}
                        </div>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="total-block">
                    <span className="discount-span">
                      {t('discount')} :{' '}
                      {couponData[active.text]
                        ? ((amount===0 ? amount.toFixed(2) : amount) - couponData[active.text]).toFixed(2)
                        : '0'}
                      
                    </span>
                    <span className="total-span">
                      {t('total')} :{' '}
                      {couponData[active.text]
                  ? couponData[active.text]
                  : (amount===99 ? amount.toFixed(2) : amount)}{' '}
                      
                    </span>
                  </div>
                </div>

                <div className="form-actions">
                    <button className="btn btn-primary btn-block">
                      {t('pay 1 -> 2')}{' '}
                    </button>
                </div>
              </form>
            </div>)
            }
          </div>
          {user.activity_info.is_active ? (
            ''
          ) : (
            <StyledLogoutBtn
              className="card-mini p-8  my-4 mt-0"
              onClick={logoutHandler}
            >
              <Link to="/sign-in">{t('logout')}</Link>
            </StyledLogoutBtn>
          )}
        </>
      ) : (
        <div className="card-mini success-subscribing">
          <h2>{t('thankuSubscribing')}</h2>
          <div className="total-block">
            <Button color="secondary" onClick={handleClickToSignup}>
              Continue
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
