import React from 'react'
import styled from 'styled-components'

const StyledSection = styled.section`
  padding: 0.5rem 2rem;
  margin-top: 0rem;
`

const Section = ({ children }) => {
  return <StyledSection>{children}</StyledSection>
}

export default Section
