import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import API from '../../../redux/API'
import { StyledDefaultButton } from '../../kit/DefaultButton'
import Section from '../../kit/Section'

const StyledAddMeToContacts = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.color};
  a {
    text-decoration: none;
    font-size: 0.875rem;
    &:hover,
    &:focus {
      color: ${(props) => props.color};
    }
  }
`

const AddMeToContacts = ({ mode, data }) => {
  const { t } = useTranslation();

  const vcardId = data?.id
  // const [vcf, setVcf] = useState('')

  // useEffect(() => {
  //   axios({
  //     method: 'get',
  //     url: `${API}/vcards/${vcardId}/get_vcf_file`,
  //   })
  //     .then(({ data }) => {
  //       console.log(data);
  //       const url = window.URL.createObjectURL(
  //         new Blob([data], { type: 'text/vcard;charset=utf-8' })
  //       )
  //       setVcf(url)
  //     })
  //     .catch((err) => console.warn('AddMeToContacts', err))
  // }, [vcardId])

  return (
    data['display_add_to_contacts?'] && (
      <Section>
        <StyledAddMeToContacts color={(data?.user_type === null) || (data?.user_type === 'sales') ? data?.button_text_color : data?.banner_group?.button_text_color}>
          <StyledDefaultButton
            bgcolor={(data?.user_type === null) || (data?.user_type === 'sales') ? data?.primary_color : data?.banner_group?.primary_color}
            color={(data?.user_type === null) || (data?.user_type === 'sales') ? data?.button_text_color : data?.banner_group?.button_text_color}
            as='a'
            href={`${API}/vcards/${vcardId}/get_vcf_file`}
          >
            {t('addMeToContacts')}
          </StyledDefaultButton>
        </StyledAddMeToContacts>
      </Section>
    )
  )
}

export default AddMeToContacts
