import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import WarningIcon from '../../../assets/icons/WarningIcon'
import { changeQrCode } from '../../../redux/constructor/actions'
import { STYLES } from '../../../styles'

const StyledSidebarFooter = styled.div`
  background-color: #e9effa;
`
const StyledDomainPreview = styled.div`
  text-align: center;
  padding: 1rem;
  p {
    font-size: 1.125rem;
    color: #1a202c;
    margin: 0;
  }
  a {
    text-decoration: none;
  }
`
const StyledMakePayment = styled.div`
  display: flex;
  flex-direction: column;
  color: #7b341e;
  background-color: #fbd38d;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  & > div {
    display: flex;
    align-items: center;
  }
  p {
    color: inherit;
    margin-bottom: 0.75rem;
  }
  a {
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    line-height: 1;
    background-color: #0070f3;
    color: #fff;
    text-transform: uppercase;
    border-radius: 25px;
  }
`
const StyledQrCode = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${STYLES.COLORS.sidebarBorder};
  button {
    padding: 1rem;
    background-color: #b5cbf2;
    display: flex;
    outline: none;
    border: none;
    column-gap: 1rem;
    align-items: center;
    img {
      width: 56px;
    }
    h3 {
      font-size: 1.125rem;
      color: #000;
      font-weight: 700;
      margin: 0;
    }
    p {
      opacity: 0.5;
      color: #000;
      margin: 0;
      font-size: 1rem;
    }
  }
`
const SupportLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  column-gap: 5px;
  text-transform: capitalize;
  padding: 1rem;
  p {
    margin: 0;
    font-size: inherit;
    line-height: 1;
    color: #1a202c;
  }
  a {
    line-height: 1;
    font-size: inherit;
    text-decoration: underline;
  }
`

const SidebarFooter = ({ vcardDataToEdit }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const qrcodeHandler = () => {
    dispatch(changeQrCode(true))
  }

  return (
    <StyledSidebarFooter>
      <StyledDomainPreview>
        <p>{t('yourVcardLink')}</p>
        <a
          href={`https://${vcardDataToEdit?.domain_prefix}.talkingvcard.net`}
          target='_blank'
          rel='noreferrer noopener'
        >
          {vcardDataToEdit?.domain_prefix}.talkingvcard.net
        </a>
      </StyledDomainPreview>
      <StyledQrCode>
        <button
          type='button'
          className='flex bg-ocean-blue-200'
          onClick={qrcodeHandler}
        >
          {/*<div>
            <img src={vcardDataToEdit.qr_code} alt='qr code' />
          </div>*/}
          <div>
            <h3>{t('seeyourQR')}</h3>
            <p>({t('clickToenlarge')})</p>
          </div>
        </button>
      </StyledQrCode>
      {/* <SupportLink>
        <p>{t('needSupport')}</p>
        <a
          href='https://talkingvcard.support/'
          target='_blank'
          rel='noreferrer noopener'
        >
          {t('clickhere')}.
        </a>
      </SupportLink> */}
    </StyledSidebarFooter>
  )
}

export default SidebarFooter
