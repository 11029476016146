import * as types from './types'

const initState = {
  preloader: false,
  successMessage: '',
}

const preloaderReducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_PRELOADER:
      return { ...state, preloader: action.bool }
    case types.SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.string }
    case types.RESET_MESSAGE:
      return { ...state, successMessage: '' }
    default:
      return { ...state }
  }
}
export default preloaderReducer
