import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { APIMAIN } from '../../../../redux/API';
import {
  fetchContent,
  fetchContentJson,
  contentInfo,
} from '../../../../redux/request/actions';
import { STYLES } from '../../../../styles/index';
import SaveBtn from '../../../kit/SaveBtn';
import Header from '../Header';
import UploadButton from './UploadButton';
import UploadedImage from './UploadedImage';

import ReactDOM from 'react-dom';
import { getLoggedInUser } from '../../../../utils/helpers';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { FroalaEditor } from '../../../kit/FroalaEditor';

const StyledTitle = styled.h3`
  margin: 1rem 0;
  color: ${STYLES.COLORS.body};
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
`;

const MainContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector(
    (state) => state?.ConstructorReducer
  );

  const initState = {
    title: vcardDataToEdit?.title,
    subtitle: vcardDataToEdit?.subtitle,
    company_name: vcardDataToEdit?.company_name,
    about_title: vcardDataToEdit?.about_title,
    content_info: vcardDataToEdit?.content_info,
  };

  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });
  const [mainContent, setMainContent] = useState(initState);
  const [avatar, setAvatar] = useState(null);
  const [background, setBackground] = useState(null);

  const contentChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.id;
    const newStore = { ...mainContent };
    newStore[name] = value;
    setMainContent(newStore);
  };
  const [content_info, setAbout_content] = useState('');

  const froalaEditor = (value, name) => {
    setAbout_content(value);
  };
  useEffect(() => {
    const newStore = { ...mainContent };
    newStore['content_info'] = content_info;
    setMainContent(newStore);
  }, [content_info]);

  useEffect(() => {
    if (vcardDataToEdit?.avatar?.url)
      setAvatar(APIMAIN + vcardDataToEdit?.avatar?.url);
    if (vcardDataToEdit?.background?.url)
      setBackground(APIMAIN + vcardDataToEdit?.background?.url);
  }, [vcardDataToEdit?.avatar?.url, vcardDataToEdit?.background?.url]);

  const isMessage = mainContent?.title !== '';

  const isAvatarSame = vcardDataToEdit?.avatar?.url
    ? avatar === APIMAIN + vcardDataToEdit?.avatar?.url
    : avatar === vcardDataToEdit?.avatar?.url;

  const isBackgroundSame = vcardDataToEdit?.background?.url
    ? background === APIMAIN + vcardDataToEdit?.background?.url
    : background === vcardDataToEdit?.background?.url;

  const isSameContent =
    JSON.stringify(mainContent) === JSON.stringify(initState);

  const isSameData = isSameContent && isAvatarSame && isBackgroundSame;

  const saveHandler = (e) => {
    e.preventDefault();
    if (!isSameContent)
      dispatch(contentInfo(localStorage.getItem('c_id'), mainContent)); 
  };

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isSameData || !isMessage} />      
      <FroalaEditor
        value={mainContent?.content_info || ''}
        onChange={froalaEditor}
        id="content_info"
        label={t('aboutSectionContent')}
      />
      <br />
      <SaveBtn disabled={isSameData || !isMessage} />
    </form>
  );
};

export default MainContent;
