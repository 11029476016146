export const STYLES = {
  COLORS: {
    primary: '#3f51b5',
    lightBlue: '#0070f3',
    lightGreen: '#d7e9ed',
    sidebarBorder: '#e0e0e0',
    body: '#4a5568',
    warning: '#e53e3e'
  },
}
