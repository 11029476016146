import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Section from '../../kit/Section';
import Title from '../../kit/Title';
import { getLoggedInUser } from '../../../utils/helpers';

const StyledAboutContent = styled.div`
  color: ${(props) => props.color};
  display: flex;
  flex-direction: column;

  #content_info {
    width: 100%;
    display: flex;
    flex-direction: column;

    margin: 10px 10px !important;
  }
`;

const ContactInfo = ({ data }) => {
  const vcardDataToEdit = data;
  const title = data?.about_title?.length > 0;
  const content = vcardDataToEdit?.content_info?.length > 0;

  // wrap all iframes in vcardDataToEdit?.about_content with a div
  useEffect(() => {
    const p = document.querySelector('#content_info p');
    if (p) {
      Object.assign(p.style, { width: '100%' });

      const iframes = document.querySelectorAll('#content_info iframe');
      if (iframes) {
        iframes.forEach((iframe) => {
          Object.assign(iframe, {
            width: '100%',
            height: '100%',
          });
          const wrapper = document.createElement('div');
          wrapper.classList.add('iframe-wrapper');
          Object.assign(wrapper.style, {
            overflow: 'hidden',
            aspectRatio: '16/9',
            width: '100%',
            marginTop: '15px',
            marginBottom: '15px',
          });
          iframe.parentNode.insertBefore(wrapper, iframe);
          wrapper.appendChild(iframe);
        });
      }
    }
  }, [vcardDataToEdit]);

  return (
    (title || content) && (      
      <>
      {vcardDataToEdit?.content_info && (
      <Section>
          <StyledAboutContent color={vcardDataToEdit?.body_color}>
            <div
              id="content_info"
              dangerouslySetInnerHTML={{
                __html: vcardDataToEdit?.content_info,
              }}
            ></div>
          </StyledAboutContent>
      </Section>
      )}
      </>
    )
  );
};

export default ContactInfo;
