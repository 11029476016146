import axios from 'axios'
import { getLoggedInUser } from '../../utils/helpers'
import * as types from './types'
import API from '../API'
import { fetchVcardDataToEditor } from '../constructor/actions'
//availabilities

export const setAvailabilities = (obj) => ({
  type: types.SET_AVAILABILITIES,
  obj,
})
export const updateAvailabilities = (data) => ({
  type: types.UPDATE_AVAILABILITIES,
  data,
})
export const deleteAvailabilities = (id) => ({
  type: types.DELETE_AVAILABILITIES,
  id,
})

export const clearDeletedAvailabilities = () => ({
  type: types.CLEAR_DELETED_AVAILABILITIES,
})

export const postAvailabilitiesToServer = (vcardId, data) => {
  let user = getLoggedInUser()
  data = data.map((item) => {
    delete item.id
    return item
  })
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${API}/vcards/${vcardId}/availabilities`,
      data,
      headers: {
        Authorization: `Bearer ${user.auth.token}`,
        'content-Type': 'application/json',
      },
    })
      .then((data) => {
        dispatch(fetchVcardDataToEditor(vcardId))
      })
      .catch((err) => console.warn('postAvailabilitiesToServer', err))
  }
}

//available time

export const getAvailableTime = (vcardId, timezone) => {
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${API}/vcards/${vcardId}/availabilities/get_available_time`,
      headers: {
        'content-Type': 'application/json',
      },
      params: {
        timezone,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: types.GET_AVAILABLE_TIME,
          data,
        })
      })
      .catch((err) => console.warn('getAvailableTime', err))
  }
}
