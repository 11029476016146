import axios from 'axios';
import { getLoggedInUser } from '../../utils/helpers';
import API from '../API';
// import { fetchVcardDataToEditor } from '../constructor/actions';
import {
  LIST_REFER_USER,
  LIST_REFER_USER_FAILED,
  LIST_REFER_USER_SUCCESS,
  LIST_REFER_USER_SUCCESS_ID
} from './constants';

export const fetchAllUser = (id) => (dispatch) => {
  let { auth } = getLoggedInUser();
  return axios
    .get(`${API}/users/sales_data?id=${(typeof id !== "undefined") ? id : auth.bearer_id}`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
    .then((res) => {
      if (res.data) {
          dispatch({
            check: 1,
            type: LIST_REFER_USER_SUCCESS,
            payload: res.data,
          });
      }
    })
    .catch((err) => {
      dispatch({
        check: 1,
        type: LIST_REFER_USER_FAILED,
        payload: err,
      });
    });
};


export const fetchVcards = () => {
  return {
    type: LIST_REFER_USER,
    payload: null,
  };
};

export const fetchVcardsSuccess = (data) => {
  return {
    type: LIST_REFER_USER_SUCCESS,
    payload: data,
  };
};

export const fetchVcardsFailed = (data) => {
  return {
    type: LIST_REFER_USER_FAILED,
    payload: data,
  };
};
export const fetchUserCardAll = () => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(fetchVcards());

    axios
      .get(API + '/vcard/' + localStorage.getItem('u_id'), {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'access-token': auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchVcardsSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchVcardsFailed(err));
      });
  };
};

export const fetchAllUserId = (id) => (dispatch) => {
  let { auth } = getLoggedInUser();
  return axios
    .get(`${API}/users/sales_ancestors?id=${(typeof id !== "undefined") ? id : auth.bearer_id}`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
    .then((res) => {
      if (res.data) {
          dispatch({
            check: 2,
            type: LIST_REFER_USER_SUCCESS_ID,
            payload: res.data,
          });
      }
    })
    .catch((err) => {
      dispatch({
        check: 2,
        type: LIST_REFER_USER_FAILED,
        payload: err,
      });
    });
};