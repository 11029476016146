import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import DefaultSelect from "../kit/DefaultSelect";
import { Button, List, ListItem, ListSubheader, Popover, Select } from "@mui/material";
import { Link } from "react-router-dom";
import { languageMap } from "../../utils/helpers";



const LanguageSelect = () => {
    const { t } = useTranslation();
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const selected = localStorage.getItem("i18nextLng") || "en";
    useEffect(() => {
        document.body.dir = languageMap[selected].dir;
    }, [menuAnchor, selected])
    return (
        <>
            <div className="d-flex justify-content-end align-items-center language-select-root">

                <Button variant="outlined" style={{backgroundColor: '#fff'}} onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
                    {languageMap[selected].label}
                    {/* <ArrowDropDown fontSize="small" /> */}
                </Button>
                <Popover
                    open={!!menuAnchor}
                    anchorEl={menuAnchor}
                    onClose={() => setMenuAnchor(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}>
                    <div>
                        <List>
                            {/* <ListSubheader>{t("select_language")}</ListSubheader> */}
                            {Object.keys(languageMap)?.map(item => (
                                <ListItem
                                    button
                                    key={item}
                                    onClick={() => {
                                        i18next.changeLanguage(item);
                                        setMenuAnchor(null);
                                    }}
                                >
                                    {languageMap[item].label}
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Popover>
            </div>
        </>
    );
};

export default LanguageSelect;