import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import API, { APIMAIN } from '../../../../redux/API';
import { STYLES } from '../../../../styles/index';
import SaveBtn from '../../../kit/SaveBtn';
import Header from '../Header';
import UploadButton from './UploadButton';
import UploadedImage from './UploadedImage';
import { Radio } from '@mui/material';
import { Delete } from '@mui/icons-material';
import ReactDOM from 'react-dom';
import { bannerUploade, selectBanner, getAllBanners,setBannerUser, deleteBannerUser } from '../../../../redux/banner/actions';
import { getLoggedInUser } from '../../../../utils/helpers';
// Require Editor JS files.
import axios from "axios";

const StyledTitle = styled.h3`
  margin: 1rem 0;
  color: ${STYLES.COLORS.body};
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
`;

const MainContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let { loading, error } = useSelector(
    (state) => state.Banner
  );
  const { vcardDataToEdit, formData } = useSelector(
    (state) => state?.ConstructorReducer
  );
  const GridDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    background-color: #fff;
  `;

  const [filename, setFilename] = useState(null);
  const [type, setType] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const saveHandler = (e) => {
    e.preventDefault();
    if(type===null){
      setErrorMessage("Select Group");
    }else{
      setErrorMessage(error);
      formData.append("group_type", type);
      dispatch(bannerUploade(localStorage.getItem('c_id'), formData));
    }
  };

  let { user } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });

  useEffect(() => {
    dispatch(getAllBanners(loggedUser?.company_id));
  }, []);
  useEffect(() => {
    dispatch(getAllBanners(localStorage.getItem('c_id')));
  }, []);
  const [banner, setBanner] = useState();

  useEffect(() => {
    dispatch(selectBanner(banner));
  }, []);


  const handleBannerChange = (bannerGif) => {
    setBanner(bannerGif.filename.url);

    dispatch(setBannerUser(localStorage.getItem('c_id'), bannerGif.id));
    dispatch(selectBanner(bannerGif.filename.url));
  };

  const { banners } = useSelector((state) => state.Banner);
  return (
    <form onSubmit={saveHandler}>
      <Header />
      {errorMessage && (
      <p style={{background: "red",padding: "12px",color: "white", fontweight: "800"}}>{errorMessage}</p>
      )}
      {error !== null && (
      <p style={{background: "green",padding: "12px",color: "white", fontweight: "800"}}>{error}</p>
      )}
      <select
      onChange={(e) => setType(e.target.value)}
          
                  style={{  width: "100%", padding: "12px 20px", margin: "8px 0", border: "3px solid #ccc",transition: "0.5s",  outline: "none"}}>
            <option value='1'>
              {t('selectGroup')}
            </option>
            <option value='1'>
              {t('group')} 1
            </option>
            <option value='2'>
              {t('group')} 2
            </option>
            <option value='3'>
              {t('group')} 3
            </option>
            <option value='4'>
              {t('group')} 4
            </option>
          </select>
      {filename ? (
        <UploadedImage
          image={filename}
          setState={setFilename}
          formData={formData}
          id="filename"
        />
      ) : (
        <UploadButton
          id="filename"
          setState={setFilename}
          formData={formData}
          textContent={t('uploadeBanner')}
        />
      )}
      <br />
      <br />
      <SaveBtn style={{ marginBottom:10 }}/>
      <br />
      <br />
      <p style={{ padding: 10, marginBottom: '-10px' ,width: '100%' , background:"white", fontWeight : "700"}}>Group 1</p>
          <GridDiv> 
            {banners.map((el) => (
            <>
              {
                el.group_type === 1?
                <div style={{ display: 'flex', flexDirection: 'column' }}>   
                  <SingleBanner
                    bannerGif={el}
                    banner={banner}
                    handleBannerChange={handleBannerChange}
                  /> 

      <Delete
        onClick={() => {
          dispatch(deleteBannerUser(localStorage.getItem('c_id'), el.id))
        }}
        value="a"
        name="radio-buttons"
        size="small"
      />  
                </div>
                :''
              }
            </>
            ))}   
          </GridDiv>
          <p style={{ padding: 10, marginBottom: '-10px' ,width: '100%' , background:"white", fontWeight : "700"}}>Group 2</p>
          <GridDiv> 
            {banners.map((el) => (
            <>
              {
                el.group_type === 2?
                <div style={{ display: 'flex', flexDirection: 'column' }}>   
                  <SingleBanner
                    bannerGif={el}
                    banner={banner}
                    handleBannerChange={handleBannerChange}
                  />   
                  <Delete
        onClick={() => {
          dispatch(deleteBannerUser(localStorage.getItem('c_id'), el.id))
        }}
        value="a"
        name="radio-buttons"
        size="small"
      /> 
                </div>
                :''
              }
            </>
            ))}   
          </GridDiv>
          <p style={{ padding: 10, marginBottom: '-10px' ,width: '100%' , background:"white", fontWeight : "700"}}>Group 3</p>
          <GridDiv> 
            {banners.map((el) => (
            <>
              {
                el.group_type === 3?
                <div style={{ display: 'flex', flexDirection: 'column' }}>   
                  <SingleBanner
                    bannerGif={el}
                    banner={banner}
                    handleBannerChange={handleBannerChange}
                  />   
                  <Delete
        onClick={() => {
          dispatch(deleteBannerUser(localStorage.getItem('c_id'), el.id))
        }}
        value="a"
        name="radio-buttons"
        size="small"
      /> 
                </div>
                :''
              }
            </>
            ))}   
          </GridDiv>
          <p style={{ padding: 10, marginBottom: '-10px' ,width: '100%' , background:"white", fontWeight : "700"}}>Group 4</p>
          <GridDiv> 
            {banners.map((el) => (
            <>
              {
                el.group_type === 4?
                <div style={{ display: 'flex', flexDirection: 'column' }}>   
                  <SingleBanner
                    bannerGif={el}
                    banner={banner}
                    handleBannerChange={handleBannerChange}
                  />   
                  <Delete
        onClick={() => {
          dispatch(deleteBannerUser(localStorage.getItem('c_id'), el.id))
        }}
        value="a"
        name="radio-buttons"
        size="small"
      /> 
                </div>
                :''
              }
            </>
            ))}   
          </GridDiv>
    </form>
  );
};
const SingleBanner = ({ bannerGif, banner, handleBannerChange }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        style={{ width: '80%', height: '70%', objectFit: 'cover' }}
        src={bannerGif.filename.url}
        alt="TMGreen1"
      />
    </div>
  );
};
export default MainContent;
