import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import mainLogo from './../../assets/images/main-logo.png'

const StyledLogo = styled.img`
  height: 40px;
  max-width: 201px;
`

const MainLogo = () => {
  return (
    <Link to="/">
      <StyledLogo src={mainLogo} alt="main-logo" />
    </Link>
  )
}
export default MainLogo