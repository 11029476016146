import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DefaultTextField from '../kit/DefaultTextField';
import styled from 'styled-components';

export default function Welcome() {
  const { t } = useTranslation();

  const { formData } = useSelector((state) => state.Request);
  const [mainData, setMainData] = useState({});

  function handleContentChange(event, label) {
    let newValue = event.target.value;
    let newStore = { ...mainData };
    newStore[label] = newValue;
    formData.set(label, newValue);
    setMainData(newStore);
  }

  // latest changes from here --------------------
  const [distributorId, setDistributorId] = useState('');
  useEffect(() => {
    if (window.location.href.includes('companyid=')) {
      const distributorId = window.location.href.split('companyid=')[1];

      if (distributorId.length > 0) {
        setDistributorId(distributorId);
      }
    }
  }, []);



  return (
    <Form style={{ width: '100%' }}>
      <div className="mb-4" style={{ width: '100%', textAlign: 'center' }}>
        {t('welcome')} {mainData?.title}!<br></br>
        {t('welcomeDesc')}
      </div>
      <DefaultTextField
        label={t('name')}
        subtitle={t('thisAppearsOnVcard')}
        value={mainData?.title ?? formData.get('title')}
        onChange={(e) => handleContentChange(e, 'title')}
      />

      <DefaultTextField
        label={t('subTitle')}
        subtitle={t('subTitleDesc')}
        value={mainData?.subtitle ?? formData.get('subtitle')}
        onChange={(e) => handleContentChange(e, 'subtitle')}
      />
      {!distributorId && (
        <DefaultTextField
          label={t('companyName')}
          subtitle={t('companySubTitleDesc')}
          value={mainData?.company_name ?? formData.get('company_name')}
          onChange={(e) => handleContentChange(e, 'company_name')}
        />
      )}
    </Form>
  );
}
