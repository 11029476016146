import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  deleteTestimonialsListItem,
  updateTestimonialsListItem,
} from '../../../../redux/testimonials/action';
import DefaultCKEditor from '../../../kit/DefaultCKEditor';
import DefaultTextArea from '../../../kit/DefaultTextArea';
import DefaultTextField from '../../../kit/DefaultTextField';
import ItemWrapper from '../../../kit/ItemWrapper';
import RemoveBtn from '../../../kit/RemoveBtn';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { FroalaEditor } from '../mainContent/FroalaEditor';

const TestimonialItem = ({ item }) => {
  const { message, given_by, title_of_the_person } = item;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateTestimonialHandler = (e) => {
    const val = e.target.value;
    const id = e.target.id;
    dispatch(updateTestimonialsListItem({ ...item, [id]: val }));
  };
  //message

  const [bodyValue, setBodyValue] = useState('');
  const ckEditorChangeHandler = (value, name) => {
    if (name === 'message') {
      setBodyValue(value);
    }
  };
  useEffect(() => {
    dispatch(updateTestimonialsListItem({ ...item, ['message']: bodyValue }));
  }, [bodyValue]);

  const deleteItemHandler = () => dispatch(deleteTestimonialsListItem(item.id));

  return (
    <ItemWrapper>
      {/* <DefaultTextArea
        label={t('messageText')}
        id='message'
        value={message}
        onChange={updateTestimonialHandler}
        required
      /> */}

      {/* <DefaultCKEditor
        value={message || ''}
        onChange={ckEditorChangeHandler}
        label={t('messageText')}
        id='message'
      /> */}
      <FroalaEditor
        value={message || ''}
        onChange={(value) => setBodyValue(value)}
        id="message"
        label={t('messageText')}
      />

      {/* <DefaultTextField
        label={t('givenByName')}
        id="given_by"
        value={given_by}
        onChange={updateTestimonialHandler}
      />

      <DefaultTextField
        label={t('titleOfThePerson')}
        id="title_of_the_person"
        value={title_of_the_person}
        onChange={updateTestimonialHandler}
      /> */}

      <RemoveBtn onClick={deleteItemHandler} textContent={t('testimonial')} />
    </ItemWrapper>
  );
};

export default TestimonialItem;
