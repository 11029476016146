import axios from 'axios';
import { getLoggedInUser, setSession } from '../../utils/helpers';
import { fetchAccountVcardsAll } from '../account/actions';
import { fetchVcardDataToEditor } from '../constructor/actions';
// import { fetctCompanyDataToEditor } from '../contentAdmin/actions'
import API from './../API';
import {
  CREATE_FIRST_VCARD_FAILED,
  CREATE_FIRST_VCARD_SUCCESS,
  DELETE_GALLERY_ITEM_FROM_SEND,
  ERROR_BY_DOMAIN,
  FALSE_FIRST_VCARD_FAILED,
  FETCH_DOMAIN_IS_VALID,
  SAVE_CONTENT_TO_SEND,
  SAVE_GALLERY_TO_SEND,
  SEND_CONTENT,
  SEND_CONTENT_FAILED,
  SEND_CONTENT_SUCCESS,
  STEP_DOMAIN_IS_VALID,
  SUCCESS_BY_DOMAIN,
  SUCCESS_GALLERY_ITEM_FROM_SEND,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
} from './constans';

export const saveContentToSend = (data) => ({
  type: SAVE_CONTENT_TO_SEND,
  payload: data,
});

export const sendContent = (id) => ({
  type: SEND_CONTENT,
  payload: id,
});
export const sendContentSuccess = (id) => ({
  type: SEND_CONTENT_SUCCESS,
  payload: id,
});

export const updateAccount = (id) => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  payload: id,
});

export const updatePassword = (id) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: id,
});
export const sendContentFailed = (id) => ({
  type: SEND_CONTENT_FAILED,
  payload: id,
});

export const errorByDomain = (text) => ({
  type: ERROR_BY_DOMAIN,
  payload: text,
});

export const checkDomainInSteppik = (bool) => ({
  type: STEP_DOMAIN_IS_VALID,
  payload: bool,
});
export const fetchDomainInSteppik = (bool) => ({
  type: FETCH_DOMAIN_IS_VALID,
  payload: bool,
});
export const succesByDomain = (data) => ({
  type: SUCCESS_BY_DOMAIN,
  payload: data,
});

export const checkDomainOnValidMainData = (domain) => {
  return (dispatch) => {
    if (domain?.trim()?.length === 0) {
      dispatch(errorByDomain('Domain must not be empty'));
      return false;
    }
    axios
      .post(
        API + '/vcards/is_domain_prefix_available',
        {
          domain_prefix: domain,
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      )
      .then(({ data }) => {
        if (data?.is_available) dispatch(succesByDomain(data));
        else dispatch(errorByDomain(data));
      })
      .catch((err) => {});
  };
};

export const checkDomainOnValid = (domain) => {
  return (dispatch) => {
    if (domain.trim().length === 0) {
      dispatch(errorByDomain('Domain must not be empty'));
      return false;
    }
    dispatch(fetchDomainInSteppik(true));
    axios
      .post(
        API + '/vcards/is_domain_prefix_available',
        {
          domain_prefix: domain,
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      )
      .then(({ data }) => {
        dispatch(checkDomainInSteppik(data));
      })
      .catch((err) => {});
  };
};

export const fetchContent = (id, data) => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(sendContent());

    axios
      .patch(API + `/vcards/${id}`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/octet-stream',
          'mime-Type': 'application/octet-stream',
          type: 'formData',
        },
      })
      .then(({ data }) => {
        dispatch(sendContentSuccess());
        dispatch(fetchVcardDataToEditor(id));
      })
      .catch((err) => {});
  };
};
export const fetchContentJson = (id, data) => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(sendContent());

    axios
      .patch(API + `/vcards/${id}`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        // if (data.message.match(/domain is not/)) {
        //     dispatch(errorByDomain(true))
        // } else dispatch(errorByDomain(false))
        dispatch(sendContentSuccess());
        dispatch(fetchVcardDataToEditor(id));
      })
      .catch((err) => {});
  };
};

export const storeCalendarJson = (id, data) => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(sendContent());

    axios
      .patch(API + `/users/${id}`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        // if (data.message.match(/domain is not/)) {
        //     dispatch(errorByDomain(true))
        // } else dispatch(errorByDomain(false))
        dispatch(sendContentSuccess());
        dispatch(fetchVcardDataToEditor(id));
      })
      .catch((err) => {});
  };
};

export const createFirstVcard = (data, type = '') => {
  let user = getLoggedInUser();
  return (dispatch) => {
    dispatch(sendContent());
    axios
      .post(API + `/vcards`, data, {
        headers: {
          Authorization: 'Bearer ' + user.auth.token,
          'content-Type':
            type === 'newVcard'
              ? 'application/json'
              : 'application/octet-stream',
          'mime-Type': 'application/octet-stream',
          type: 'formData',
        },
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch(fetchAccountVcardsAll());
          dispatch(createFirstVcardSuccess());
        } else {
          dispatch(createFirstVcardFailed(data));
        }
      })
      .catch((err) => {
        dispatch(createFirstVcardFailed());
      });
  };
};

function createFirstVcardSuccess(data) {
  return {
    type: CREATE_FIRST_VCARD_SUCCESS,
    payload: data,
  };
}
function createFirstVcardFailed(data) {
  return {
    type: CREATE_FIRST_VCARD_FAILED,
    data,
  };
}
export const FalseFirstVcardFailed = (data) => {
  return {
    type: FALSE_FIRST_VCARD_FAILED,
    payload: data,
  };
};

export const sendVideoData = (data) => {
  return (dispatch) => {};
};

export const saveGalleryDataToSend = (data) => {
  for (const val of data.data.entries()) {
    console.log(val);
  }

  return {
    type: SAVE_GALLERY_TO_SEND,
    payload: data,
  };
};

export const updateUserData = (data) => {
  let user = getLoggedInUser();
  return (dispatch) => {
    axios
      .patch(
        API + '/users/' + data.id,
        {
          email: data.email,
          username: data.username,
        },
        {
          headers: {
            Authorization: 'Bearer ' + user.auth.token,
            'content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          user.user = { ...user.user, data };
          let arr = {
            ...user,
            user: data,
          };
          setSession(arr);
          dispatch(updateAccount(res.data));
        } else {
          dispatch(updateAccount(res.data));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log('Invalid login or password.');
        } else if (err.response.status) {
          console.log('Server error, code: ' + err.response.status);
        }
      });
  };
};

export const updatePasswordData = (data) => {
  let user = getLoggedInUser();

  return (dispatch) => {
    axios
      .post(
        API + '/users/change_password/',
        {
          // id: user.user.id,
          current_password: data.currentPassword,
          new_password: data.password,
          new_password_confirmation: data.password_confirmation,
        },
        {
          headers: {
            Authorization: 'Bearer ' + user.auth.token,
            'content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        if (res) {
          dispatch(updatePassword(res.data));
        } else {
          dispatch(updatePassword(res.data));
        }
        // dsa

        // dispatch(loginUserSuccess(res.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log('Invalid login or password.');
        } else if (err.response.status) {
          console.log('Server error, code: ' + err.response.status);
        }
        // dispatch(loginUserFailed(messsage));
      });
  };
};

export const deleteItemGalleryToSend = (vcardId, id) => {
  let user = getLoggedInUser();

  return (dispatch) => {
    if (id >= 0) {
      axios
        .delete(API + '/vcards/' + vcardId + '/gallery_images/' + id, {
          headers: {
            Authorization: 'Bearer ' + user.auth.token,
          },
        })
        .then((data) => {
          dispatch(sendContentSuccess());
          dispatch(fetchVcardDataToEditor(vcardId));
        });
    } else {
      return {
        type: DELETE_GALLERY_ITEM_FROM_SEND,
        payload: id,
      };
    }
  };
};
export const fetchGalleryDataToServer = (vcardId, data) => {
  let user = getLoggedInUser();
  console.log(data);
  return (dispatch) => {
    data.forEach((item) => {
      if (item.id < 0) {
        axios
          .post(API + '/vcards/' + vcardId + '/gallery_images', item.data, {
            headers: {
              Authorization: 'Bearer ' + user.auth.token,
              'content-Type': 'application/octet-stream',
              'mime-Type': 'application/octet-stream',
              type: 'formData',
            },
          })
          .then((data) => {
            dispatch(successGalleryToSend());
            dispatch(fetchVcardDataToEditor(vcardId));
          });
      } else {
        axios
          .patch(
            API + '/vcards/' + vcardId + '/gallery_images/' + item.id,
            item.data,
            {
              headers: {
                Authorization: 'Bearer ' + user.auth.token,
                'content-Type': 'application/octet-stream',
                'mime-Type': 'application/octet-stream',
                type: 'formData',
              },
            }
          )
          .then((data) => {
            dispatch(successGalleryToSend());
            dispatch(fetchVcardDataToEditor(vcardId));
          });

        axios
          .patch(API + '/vcards/' + vcardId, data, {
            headers: {
              Authorization: 'Bearer ' + user.auth.token,
              'content-Type': 'application/json',
            },
          })
          .then((data) => {
            dispatch(successGalleryToSend());
            dispatch(fetchVcardDataToEditor(vcardId));
          });
      }
    });
  };
};
export const successGalleryToSend = (data) => {
  return {
    type: SUCCESS_GALLERY_ITEM_FROM_SEND,
    payload: data,
  };
};
export const fetchGalleryDataFromServer = (vcardId) => {
  let user = getLoggedInUser();
  return (dispatch) => {
    axios.get(API + '/vcards/' + vcardId + '/gallery_images', {
      headers: {
        Authorization: 'Bearer ' + user.auth.token,
        'content-Type': 'application/json',
      },
    });
    // .then((data) => console.log('fetchGalleryDataFromServer', data))
  };
};

//------------------Start-banner--------------------
export const contentInfo = (id, data) => {
  let { auth } = getLoggedInUser()
  return (dispatch) => {
    dispatch(sendContent())

    axios
      .patch(API + `/companies/${id}`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(sendContentSuccess())
        // dispatch(fetctCompanyDataToEditor(id))
        dispatch(fetchVcardDataToEditor(localStorage.getItem('vcard_id')));
      })
      .catch((err) => {})
  }
}

//------------------Refer Request--------------
export const referralRequest = (data) => {
  let { auth } = getLoggedInUser();
  return (dispatch) => {
    dispatch(sendContent());

    axios
      .post(API + `/referral_requests`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(sendContentSuccess());
      })
      .catch((err) => {});
  };
};



