import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addVideoLink,
  deleteVideosFromServer,
  putVideosToServer,
  updateVideos
} from '../../../../redux/videoLibrary/action';
import {
  fetchContentJson,
} from '../../../../redux/request/actions';
import AddBtn from '../../../kit/AddBtn'
import SaveBtn from '../../../kit/SaveBtn'
import Wrapper from '../../../kit/Wrapper'
import Header from '../Header'
import VideoItem from './form';
import DefaultTextField from '../../../kit/DefaultTextField';
import {
  getVideoLinkByComppany,
  UpdateVideoLinkByComppany
} from '../../../../redux/contentAdmin/actions';

const VideoLibrary = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { videoLibrary, deletedVideos } = useSelector(
    (s) => s?.videoLibraryReducer
  )
  const { id, videos } = useSelector(
    (s) => s?.ConstructorReducer?.vcardDataToEdit
  )

  const isVideosSame = useMemo(() => {
    return JSON.stringify(videos) === JSON.stringify(videoLibrary)
  }, [videos, videoLibrary]);

  const saveHandler = (e) => {
    e.preventDefault();
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D0.id,e.currentTarget.D0.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D1.id,e.currentTarget.D1.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D2.id,e.currentTarget.D2.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D3.id,e.currentTarget.D3.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D4.id,e.currentTarget.D4.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D5.id,e.currentTarget.D5.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D6.id,e.currentTarget.D6.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D7.id,e.currentTarget.D7.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D8.id,e.currentTarget.D8.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D9.id,e.currentTarget.D9.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D10.id,e.currentTarget.D10.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D11.id,e.currentTarget.D11.value))
    dispatch(UpdateVideoLinkByComppany(e.currentTarget.D12.id,e.currentTarget.D12.value))
  }
  const { videoLink, loading } = useSelector((state) => state.ContentAdminReducer);
  useEffect(() => {
    dispatch(getVideoLinkByComppany())
  }, [dispatch]);

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isVideosSame} />
      <Wrapper>
        {videoLink?.map((item, idx) => (
          <VideoItem item={item} key={idx} id={idx}/>
        ))}
      </Wrapper>
      <SaveBtn/>
    </form>
  )
}

export default VideoLibrary
