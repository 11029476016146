import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';

// DialogTitle
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  UpdateVideoLink,
  getVideoLinkList
} from '../../../redux/contentAdmin/actions';
import TextField from '@mui/material/TextField';

export default function UserTable({ allUsers }) {

  const { videoLink, loading } = useSelector((state) => state.ContentAdminReducer);
  useEffect(() => {
    dispatch(getVideoLinkList())
  }, [dispatch]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [id, setID] = React.useState('');
  const handlePasswordClick = () => {
      dispatch(UpdateVideoLink(id, title, url));
      setOpen(false);
  };

  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="right">Video Link</TableCell>
            <TableCell align="right">Update Date</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {videoLink?.length > 0 ? (
            videoLink.map((row,index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="right">{row.url}</TableCell>
                <TableCell align="right">{row.updated_at}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={(e) => {
                      setOpen(true);
                      setID(row.id);
                      setTitle(row.title);
                      setUrl(row.url);
                    }}
                    variant="contained"
                  >
                    Link
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Typography varaint="h5">No Users Found</Typography>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <Dialog open={open}>
        <DialogTitle style={{ paddingBottom: 0 }}>
          {t('Update Video Link')}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            id="title"
            label={t('title')}
            name={t('title')}
            variant="standard"
            value={title}
            disabled
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            value={url}
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            id="url"
            label={t('url')}
            name={t('url')}
            variant="standard"
            onChange={(e) => setUrl(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => {
            setOpen(false);
          }}>{t('cancel')}</Button>
          <Button onClick={handlePasswordClick}>{t('update')}</Button>
        </DialogActions>
      </Dialog>
      </>
  );
}
