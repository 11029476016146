import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storeCalendarJson } from '../../../../redux/request/actions';
import { getLoggedInUser } from '../../../../utils/helpers';
import DefaultTextField from '../../../kit/DefaultTextField';
import SaveBtn from '../../../kit/SaveBtn';
import Header from '../Header';

const DistributorID = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector(
    (state) => state.ConstructorReducer
  );
  const distributorHeadig = vcardDataToEdit?.distributor_title_id === null ? "Distributor ID" : vcardDataToEdit?.distributor_title_id === "" ? "Distributor ID" : vcardDataToEdit?.distributor_title_id;
  const { id } = vcardDataToEdit;
  const vcardCalendlyIdFromServer = vcardDataToEdit?.distributor_id;

  const [distributor_id, setVcard_calendly_id] = useState(
    vcardCalendlyIdFromServer
  );

  const inputHandler = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    formData.set(id, value);
    setVcard_calendly_id(value);
  };

  const isvcardCalendlyIdSame = distributor_id === vcardCalendlyIdFromServer;

  const saveHandler = (e) => {
    e.preventDefault();
    if (!isvcardCalendlyIdSame) {
      dispatch(storeCalendarJson(id, { distributor_id }));
    }
  };
  let { user } = getLoggedInUser();
  return (
    <>
      <form onSubmit={saveHandler}>
        <Header disabled={isvcardCalendlyIdSame} />
        <DefaultTextField
          value={distributor_id || ''}
          onChange={inputHandler}
          label={distributorHeadig}
          subtitle={t('calendlySubTitle')}
        />
        <SaveBtn
          type="submit"
          disabled={
            isvcardCalendlyIdSame ||
            user?.user_type === 'distributor' ||
            user?.user_type === null
          }
        />
      </form>
    </>
  );
};

export default DistributorID;
