// const API = 'http://45.79.96.107:8015/api/v1';
// const APIMAIN = 'http://45.79.96.107:8015';
const API = 'https://api.talkingvcard.net/api/v1';
const APIMAIN = 'https://api.talkingvcard.net/';

// const API = 'http://143.42.166.173:8015/api/v1';
// const APIMAIN = 'http://143.42.166.173:8015';

// of no use
// const API = 'http://api.talkingvcard.co.in/api/v1'
// const APIMAIN = 'http://api.talkingvcard.co.in'

// https://api.talkingvcard.net/api/v1 --- production

// http://talkingvcard.net/
export { APIMAIN };
export default API;
