import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setColorScheme } from '../../../redux/constructor/actions'

const ColorScheme = ({ data, title }) => {
  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer)
  let vcardId = vcardDataToEdit.id
  const {
    primary_color,
    heading_color,
    body_color,
    button_text_color,
    background_primary_color,
    background_secondary_color,
    background_page_color,
  } = data
  const dispatch = useDispatch()

  function handleCLick() {
    dispatch(
      setColorScheme(vcardId, {
        primary_color,
        heading_color,
        body_color,
        button_text_color,
        background_primary_color,
        background_secondary_color,
        background_page_color,
      })
    )
  }

  return (
    <div className='color_scheme_item' onClick={handleCLick}>
      <div className='list_box'>
        <div className='color_box' style={{ background: primary_color }}></div>
        <div className='color_box' style={{ background: body_color }}></div>
        <div
          className='color_box'
          style={{ background: button_text_color }}
        ></div>
      </div>
      <div className='color_title'>{title}</div>
    </div>
  )
}

export default ColorScheme
