import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { createCustomLink } from '../../../../redux/socialLinks/action'
import AddBtn from '../../../kit/AddBtn'
import Wrapper from '../../../kit/Wrapper'
import CustomLinkItem from './CustomLinkItem'

const CustomLinks = ({ socialNetworks }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const addCustomLinkHandler = () => {
    const id = Math.random() * -1
    dispatch(createCustomLink(id))
  }
  return (
    <Wrapper>
      {socialNetworks.map((item, idx) => (
        <CustomLinkItem item={item} key={idx} />
      ))}
      <AddBtn onClick={addCustomLinkHandler} textContent={t('link')} />
    </Wrapper>
  )
}

export default CustomLinks
