import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUser } from '../../../redux/agent/actions';
import Title from '../Title';
import CompanyCard from './List';

export default function CompanyList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer);
  const { rUsers, loading } = useSelector((state) => state.Agent);

  useEffect(() => {
    dispatch(fetchAllUser());
  }, [dispatch]);

  useEffect(() => {}, [vcardDataToEdit]);  
  console.log(rUsers);
  return (
    <React.Fragment>
      <Title>{t('subscriberList')}</Title>
      <Grid container spacing={3}>
        {loading ? (
          <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : (
          <> 
            <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
              <CompanyCard /> 
            </div>                         
          </>
        )}   
      </Grid>
    </React.Fragment>
  );
}
