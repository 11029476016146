export const SET_COMPONENT_EDITOR = 'SET_COMPONENT_EDITOR'

export const UPDATE_DATA = 'UPDATE_DATA'
export const UPDATE_DATA_SUCCES = 'UPDATE_DATA_SUCCES'
export const UPDATE_DATA_FAILED = 'UPDATE_DATA_FAILED'

export const SET_PREVIEWDISPLAY = 'SET_PREVIEWDISPLAY'

export const SET_MAINCONTENT = 'SET_MAINCONTENT'
export const SET_QR_CODE = 'SET_QR_CODE'

export const SET_COLOR_SCHEME = 'SET_COLOR_SCHEME'
// AnotherLinks ->
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
// <- AnotherLinks

//mainContent
export const SAVE_MAIN_CONTENT_TO_SEND = 'SAVE_MAIN_CONTENT_TO_SEND'
// <-MainCOntent
// Services
export const UPDATE_SERVICES_TITLE = 'UPDATE_SERVICES_TITLE'
export const UPDATE_SERVICES_LIST = 'UPDATE_SERVICES_LIST'
export const ADD_SERVICES_LIST_ITEM = 'ADD_SERVICES_LIST_ITEM'
export const DELETE_SERVICES_LIST_ITEM = 'DELETE_SERVICES_LIST_ITEM'
export const UPDATE_SERVICES_LIST_ITEM = 'UPDATE_SERVICES_LIST_ITEM'
export const DELETE_SERVICES_EMPTY = 'DELETE_SERVICES_EMPTY'
export const SET_ERROR_ANOTHER_SERVICES = 'SET_ERROR_ANOTHER_SERVICES'
// <-Services
// Record Video
export const SET_WEBVIDEOURL = 'SET_WEBVIDEOURL'
// << Record Video
export const UPDATE_GALLERY_TITLE = 'UPDATE_GALLERY_TITLE'

export const UPDATE_PREVIEW = 'UPDATE_PREVIEW'
export const UPDATE_STORE = 'UPDATE_STORE'
export const UPDATE_PREVIEW_TEMPLATE = 'UPDATE_PREVIEW_TEMPLATE'

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'

export const GO_TO_EDIT_VCARD_DATA = 'GO_TO_EDIT_VCARD_DATA'
export const SAVE_CONTENT_TO_SEND = 'SAVE_CONTENT_TO_SEND'
export const UPDATE_VCARD_DATA = 'UPDATE_VCARD_DATA'

export const UPDATE_VCARD_TRANSFER_DATA = 'UPDATE_VCARD_TRANSFER_DATA'
