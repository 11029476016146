import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getLoggedInUser } from '../../utils/helpers';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Title from './Title';
import UserTable from './UserTable';
import { useDispatch, useSelector } from 'react-redux';
import API, { APIMAIN } from '../../redux/API';
import UploadButton from '../../components/constructor/Editors/mainContent/UploadButton';
import UploadedImage from '../../components/constructor/Editors/mainContent/UploadedImage';
import SaveBtn from '../../components/kit/SaveBtn';
import { fetchContent } from '../../redux/request/actions';
import { Link } from 'react-router-dom';
import LogoUploadBtn from './logoUpload/LogoUploadBtn';
import ImageLogoUploaded from './logoUpload/ImageLogoUploaded';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MenuBar() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const menu = ['Change Logo', 'Users Registered', 'Share Link'];

  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = React.useState({ ...user });
  const [allUsers, setAllUsers] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${API}/companies/${loggedUser?.company_id}/all_users`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        setAllUsers(res.data);
      });
  }, []);
  // console.log(allUsers);
  // for share link panel
  const [showInput, setShowInput] = React.useState(false);
  const [inputVal, setInputVal] = React.useState('');
  const baseUrl = 'https://talkingvcard.net/#/sign-up/';
  const handleShareLink = () => {
    setShowInput(!showInput);
    let company_id = loggedUser?.company_id;
    const userName = (loggedUser?.username).replace(" ", "%20");
    const userName1 = (userName).replace(" ", "%20");
    const userLink = `${baseUrl}?name=${userName1}&companyid=${company_id}`;
    setInputVal(userLink);
  };
  const inputRef = React.useRef(null);

  // ------------- code for logo -------------
  const logoUrl = API + `/companies/${loggedUser?.company_id}`;
  const [logo, setLogo] = React.useState(null);
  const [logoData, setLogoData] = React.useState(null);

  const getLogo = async () => {
    const res = await axios.get(logoUrl, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });

    return res.data;
  };

  React.useEffect(() => {
    getLogo().then((res) => {
      setLogo(APIMAIN + res.logo.url);
    });
  }, []);

  const uploadLogo = async () => {
    const res = await axios.patch(
      logoUrl,
      {
        logo: logoData,
      },
      {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
          'mime-Type': 'application/octet-stream',
          type: 'json',
        },
      }
    );

    return res;
  };
  const uploadLogoHandler = () => {
    uploadLogo().then((res) => {
      if (res.status === 200) {
        alert('Logo uploaded successfully');
        window.location.reload();
      }
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {menu.map((item, index) => (
            <Tab
              style={{ marginRight: 10 }}
              label={item}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div style={{ padding: '12px' }}>
          {logo ? (
            <ImageLogoUploaded
              image={logo}
              setState={setLogo}
              // setLogoData={setLogoData}
              id="avatar"
              type="logo"
            />
          ) : (
            <LogoUploadBtn
              id="avatar"
              setState={setLogo}
              setLogoData={setLogoData}
              textContent={'Upload a new logo'}
              type="logo"
            />
          )}

          <Button
            style={{ backgroundColor: '#3f51b5', color: 'white' }}
            color="primary"
            variant="contained"
            disabled={logoData === null}
            onClick={uploadLogoHandler}
          >
            Save the new logo
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UserTable allUsers={allUsers} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '12px',
          }}
        >
          <Button
            onClick={handleShareLink}
            style={{ backgroundColor: '#3F51B5', color: 'white' }}
            variant="contained"
          >
            Join Us
          </Button>
          {showInput && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <input
                ref={inputRef}
                value={inputVal}
                type="text"
                style={{ width: '300px', padding: 6, marginLeft: 20 }}
                disabled
              />
              <Button
                onClick={() =>
                  navigator.clipboard.writeText(inputRef.current?.value)
                }
                variant="contained"
                style={{
                  marginLeft: 10,
                  backgroundColor: '#90A4AE',
                  color: 'white',
                }}
              >
                Copy Link
              </Button>
            </div>
          )}
        </div>
      </TabPanel>
    </Box>
  );
}
