import React from 'react'

const SmsIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
    >
      <defs>
        <linearGradient id='a' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#86FC6F' />
          <stop offset='100%' stopColor='#0CD419' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <circle cx='20' cy='20' r='20' fill='url(#a)' />
        <path
          fill='#FFF'
          d='M18.2668118,29.8443217 L18.2814837,29.8495979 C18.2814837,29.8495979 18.2610147,29.8453482 18.2225776,29.838829 C18.13452,29.8277541 18.0467591,29.8158772 17.9593048,29.8032099 C17.3326588,29.736367 15.906312,29.6986819 14.7114519,30.5065113 C13.3873071,31.4076176 11.4096883,31.9716688 10.7837289,31.7274759 C10.7115029,31.6965219 12.4036567,30.1006695 12.7166364,29.1376551 C13.1018421,27.9579625 12.3039159,27.7000122 12.3039159,27.7000122 L12.4188101,27.7413292 C9.44843909,25.8234836 7.53333333,22.898022 7.53333333,19.95 C7.53333333,14.4267123 13.115,9.95 20,9.95 C26.885,9.95 32.4666667,14.4267123 32.4666667,19.95 C32.4666667,25.4732877 26.885,29.95 20,29.95 C19.4118714,29.95 18.8332531,29.9137555 18.2668117,29.8443217 Z'
        />
      </g>
    </svg>
  )
}

export default SmsIcon
