import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import DefaultTextField from '../../components/kit/DefaultTextField';
import API from '../../redux/API';
import { updateAccount, updateUserData } from '../../redux/request/actions';
import { getLoggedInUser } from '../../utils/helpers';
import Title from './Title';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: 'min-content',
    whiteSpace: 'nowrap',
  },
}));

export default function AccountInfo() {
  const { t } = useTranslation();

  let { accountUpdate } = useSelector((state) => {
    return state.Request;
  });
  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer);
  const [error, setError] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  let { user, auth } = getLoggedInUser();

  const [userData, setUserData] = useState({ ...user });

  function handleChangeUserData(e) {
    let value = e.target.value;
    let id = e.target.id;
    setUserData({ ...userData, [id]: value });
  }

  useEffect(() => {
    dispatch(updateAccount(''));
  }, [dispatch]);

  function computeUserData(a, b) {
    a = JSON.stringify(a);
    b = JSON.stringify(b);
    return a === b;
  }

  const checkUserChange = useMemo(
    () => computeUserData(userData, user),
    [userData, user]
  );

  const onSumbit = (e) => {
    e.preventDefault();
    dispatch(updateUserData(userData));
  };

  function confirmAlert() {
    Swal.fire({
      width: 500,
      height: 500,
      showConfirmButton: true,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#32b482',
      cancelButtonColor: 'red',
      confirmButtonText: 'Cancel',
      cancelButtonText: 'Yes',
      title: t('yourDefinitely'),
    }).then((result) => {
      if (!result.value) {
        axios
          .delete(API + '/subscriptions/cancel', {
            headers: {
              Authorization: 'Bearer ' + auth.token,
              'content-Type': 'application/json',
            },
          })
          .then((data) => {
            setError(data.data.success);
            Swal.fire({
              showConfirmButton: true,
              icon: 'success',
              width: 500,
              title: t('sccessfulUnSubscibed'),
              timer: 2000,
              confirmButtonColor: '#32b482',
              // confirmButtonText: "ок",
            });
          });
      }
    });
  }

  return (
    <div style={{ maxWidth: 370 }}>
      <Title>{t('accountInformation')}</Title>
      <DefaultTextField
        label={t('name')}
        subtitle={t('yourAccountName')}
        onChange={handleChangeUserData}
        value={userData?.username}
        id="username"
      />
      <DefaultTextField
        label={t('email')}
        subtitle={t('thisIsLoginEmail')}
        onChange={handleChangeUserData}
        value={userData?.email}
        id="email"
      />
      {accountUpdate && (
        <p style={{ color: accountUpdate.success ? 'green' : 'red' }}>
          {accountUpdate.message || accountUpdate.error}
        </p>
      )}
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onSumbit}
        disabled={checkUserChange}
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        {t('saveAccountInfo')}
      </Button>
      {error ? (
        ''
      ) : (
        <>
          {vcardDataToEdit?.activity_info.next_payment_date ? (
            <>
              <Title>{t('subscriptionInfo')}</Title>
              <div>
                <div>
                  {t('youHavea')}{' '}
                  {vcardDataToEdit?.activity_info.subscription_plan ===
                  'lifetime'
                    ? 'lifetime'
                    : `${vcardDataToEdit?.activity_info.subscription_plan}ly`}{' '}
                  {t('subscription')}
                </div>
                {vcardDataToEdit?.activity_info.subscription_plan ===
                'lifetime' ? (
                  ''
                ) : (
                  <>
                    <div>
                      {t('nextPaymentDate')}:{' '}
                      {moment(
                        vcardDataToEdit.activity_info.next_payment_date
                      ).format('MMMM Do YYYY')}
                    </div>
                    <div className="cancel-btn" onClick={confirmAlert}>
                      {t('cancelSubscription')}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
}
