import * as types from './constants'

const INIT_STATE = {
  videoLink: []
}

const ContentAdmin = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_VIDEO_SUCCESS:
      return { ...state, videoLink: action.payload, loading: true }
    default:
      return { ...state }
  }
}

export default ContentAdmin
