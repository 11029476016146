import SaveIcon from '@mui/icons-material/Save'
import Button from '@mui/material/Button'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchContent,
  fetchGalleryDataToServer,
} from '../../redux/request/actions'

export default function SaveButton() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { vcardDataToEdit, formData, component, webVideoUrl } = useSelector(
    (state) => state.ConstructorReducer
  )

  const { contentToSend, galleryToSend } = useSelector((state) => state.Request)

  function handleClick() {
    if (checkMain) {
      dispatch(fetchContent(vcardDataToEdit?.id, formData))
    }
    if (checkGallery) {
      dispatch(fetchGalleryDataToServer(vcardDataToEdit?.id, galleryToSend))
    }
    if (checkWebVideo) {
      dispatch(fetchContent(vcardDataToEdit?.id, formData))
    }
  }

  let checkWebVideo = useMemo(() => {
    return (
      JSON.stringify(webVideoUrl) !==
      JSON.stringify(vcardDataToEdit?.video_link)
    )
  }, [webVideoUrl, vcardDataToEdit?.video_link])

  let checkGallery = useMemo(() => {
    if (galleryToSend && galleryToSend.length > 0) return true
    return JSON.stringify(contentToSend) !== JSON.stringify(vcardDataToEdit)
  }, [contentToSend, vcardDataToEdit, galleryToSend])

  let checkMain = useMemo(() => {
    return JSON.stringify(contentToSend) !== JSON.stringify(vcardDataToEdit)
  }, [contentToSend, vcardDataToEdit])

  function checkStatus() {
    if (component === t('recordAVideo')) return !checkWebVideo
    return false
  }

  return (
    <>
      {component === 'Color Scheme' ? (
        false
      ) : (
        <Button
          variant='contained'
          color='primary'
          size='small'
          startIcon={<SaveIcon />}
          onClick={handleClick}
          disabled={checkStatus()}
        >
          {t('save')}
        </Button>
      )}
    </>
  )
}
