import React from 'react'
import MainLogo from '../../assets/images/MainLogo.jsx'
import LogIn from '../../components/account/LogIn.jsx'
import PublicPreview from '../preview/publicPreview'
import styled from 'styled-components'
import bgImg from '../../assets/images/banner-bg.jpg'
import { useTranslation } from 'react-i18next'
import ScriptTag from 'react-script-tag';
import { Link, useHistory  } from 'react-router-dom';
import { getLoggedInUser } from '../../utils/helpers';
import API from '../../redux/API';

const StyledHome = styled.div`
  & > header {
    position: fixed;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
  & > main {
    max-height: 100vh;
    height: 100vh;
    background: url(${bgImg}) center / cover no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > h2 {
      text-transform: uppercase;
      color: #ffffff;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 50px;
      margin-top: 0;
      max-width: 700px;
      text-align: center;
    }
    & > a > button {
      outline: none;
      cursor: pointer;
      display: inline-block;
      height: 50px;
      padding: 0px 20px;
      border: none;
      background-color: #00bcd4;
      color: #fff;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
`

export default function Home() {
  const { t } = useTranslation()

  if (window.location.href.includes('payment_intent=')) {

  const redirect_status = window.location.href.split('redirect_status=')[1];
  const redirect_status_r = redirect_status.replace('#/', "");
  const client_secret = window.location.href.split('payment_intent_client_secret=')[1];
  const client_secret_r = client_secret.replace('&redirect_status='+redirect_status, "");
  const payment_intent = window.location.href.split('payment_intent=')[1];
  const payment_intent_r = payment_intent.replace('&payment_intent_client_secret='+client_secret, "");
  let { auth, user } = getLoggedInUser();
    fetch(API +"/charges/payment_status", {
      method: "POST",
      headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      body: JSON.stringify({
        payment_id : payment_intent_r,
        client_secret : client_secret_r,
        status : redirect_status_r,
      }),
    }).then(async (result) => {
      localStorage.setItem('payment_status',"1")
      localStorage.setItem('signUP',"1")
    });    
  }
  const domain = window.location.hostname.split('.')

  const count = domain[1] === 'localhost' ? 1 : 2
  return (
    <>
      {domain[0] === 'www' && domain[2] === 'talking' ? (
        <PublicPreview domainName={domain[1]} />
      ) : domain.length > count && domain[0] !== 'www' ? (
        <PublicPreview domainName={domain[0]} />
      ) : (
        <StyledHome>

            <ScriptTag type="text/javascript"  src="https://talkingvcard.support/supportboard/js/min/jquery.min.js" />
            <ScriptTag  type="text/javascript" src="https://talkingvcard.support/supportboard/js/main.js" />
          <header>
            <MainLogo />
            <LogIn />
          </header>
          <main>
            <h2>{t("home.heading")}</h2>
            <Link to='/constructor'>
              <button>{t("home.button")}</button>
            </Link>
          </main>
        </StyledHome>
      )}
    </>
  )
}
