import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  fetchContentJson,
} from '../../../../redux/request/actions';
import { STYLES } from '../../../../styles/index';
import { FroalaEditor } from '../../../kit/FroalaEditor';
import SaveBtn from '../../../kit/SaveBtn';
import Header from '../Header';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

const StyledTitle = styled.h3`
  margin: 1rem 0;
  color: ${STYLES.COLORS.body};
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
`;

const MainContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector(
    (state) => state?.ConstructorReducer
  );

  const initState = {
    html_editor2: vcardDataToEdit?.html_editor2,
  };
  const [mainContent, setMainContent] = useState(initState);

  const contentChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.id;
    const newStore = { ...mainContent };
    newStore[name] = value;
    setMainContent(newStore);
  };
  const [html_editor2, setAbout_content] = useState('');

  const froalaEditor = (value, name) => {
    setAbout_content(value);
  };
  useEffect(() => {
    const newStore = { ...mainContent };
    newStore['html_editor2'] = html_editor2;
    setMainContent(newStore);
  }, [html_editor2]);


  const isMessage = mainContent?.title !== '';

  const isSameContent =
    JSON.stringify(mainContent) === JSON.stringify(initState);

  const isSameData = isSameContent;

  const saveHandler = (e) => {
    e.preventDefault();
    if (!isSameContent)
      dispatch(fetchContentJson(vcardDataToEdit?.id, mainContent));
  };

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isSameData || !isMessage} />
      {/*<StyledTitle>Testimonials</StyledTitle>*/}
      <FroalaEditor
        value={mainContent?.html_editor2 || ''}
        onChange={froalaEditor}
        id="html_editor2"
        label={t('aboutSectionContent')}
      />
      <br />
      <SaveBtn disabled={isSameData || !isMessage} />
    </form>
  );
};

export default MainContent;
