import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  allowBookTime,
  setButtonLabel,
  setSessionDuration,
  setTimeFormat,
  setTimezone,
} from '../../../../redux/appointmentsSettings/action'
import {
  postAvailabilitiesToServer,
  updateAvailabilities,
} from '../../../../redux/availabilities/action'
import { fetchContentJson } from '../../../../redux/request/actions'
import { STYLES } from '../../../../styles'
import DefaultCheckbox from '../../../kit/DefaultCheckbox'
import DefaultSelect from '../../../kit/DefaultSelect'
import DefaultTextField from '../../../kit/DefaultTextField'
import SaveBtn from '../../../kit/SaveBtn'
import Header from '../Header'
import SelectTimeZone from './SelectTimeZone'
import WeekDay from './WeekDay'

const StyledButtonGroup = styled.div`
  display: flex;
  height: 35px;
`
const StyledSelectBtn = styled.button`
  flex: 1;
  border: 1px solid ${STYLES.COLORS.lightBlue};
  outline: none;
  background-color: ${(props) => (props.active ? STYLES.COLORS.lightBlue : '')};
  font-size: 1.125rem;
  color: ${(props) => (props.active ? '#fff' : '#000')};
`
export const StyledLabel = styled.h5`
  margin-bottom: 4px;
  margin-top: 1.25rem;
  font-size: 1.125rem;
  color: ${STYLES.COLORS.body};
  font-weight: 400;
`
export const StyledText = styled.p`
  color: ${STYLES.COLORS.body};
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 200;
`
const StyledSaveButton = styled.div`
  margin-top: 0rem;
`

const Appointments = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const checked = useSelector(
    (s) => s.appointmentsSettingsReducer['allow_book_time?']
  )
  const {
    appointment_button_label,
    appointment_time_format,
    session_duration,
    timezone,
  } = useSelector((s) => s.appointmentsSettingsReducer)

  const { availabilities } = useSelector((s) => s.availabilitiesReducer)

  const { vcardDataToEdit } = useSelector((s) => s.ConstructorReducer)
  const vcardId = vcardDataToEdit?.id

  const availabilitiesFromServer = vcardDataToEdit?.availabilities
  const checkedFromServer = vcardDataToEdit?.['allow_book_time?']
  const labelFromServer = vcardDataToEdit?.appointment_button_label
  const timeFormatFromServer = vcardDataToEdit?.appointment_time_format
  const sessionDurationFromServer = vcardDataToEdit?.session_duration
  const timezoneFromServer = vcardDataToEdit?.timezone

  const checkboxHandler = () => dispatch(allowBookTime(!checked))
  const inputHandler = (e) => dispatch(setButtonLabel(e.target.value))
  const formatHandler = (e) => dispatch(setTimeFormat(e.target.value))
  const sessionHandler = (e) => dispatch(setSessionDuration(e.target.value))

  const isAvailabilitiesSame = useMemo(
    () =>
      JSON.stringify(availabilities) ===
      JSON.stringify(availabilitiesFromServer),

    [availabilities, availabilitiesFromServer]
  )

  const isCheckSame = useMemo(
    () => checked === checkedFromServer,
    [checked, checkedFromServer]
  )
  const isLabelSame = useMemo(
    () => appointment_button_label === labelFromServer,
    [appointment_button_label, labelFromServer]
  )
  const isTimeFormatSame = useMemo(
    () => appointment_time_format === timeFormatFromServer,
    [appointment_time_format, timeFormatFromServer]
  )
  const isSessionDurationSame = useMemo(
    () => session_duration === sessionDurationFromServer,
    [session_duration, sessionDurationFromServer]
  )
  const isTimezoneSame = useMemo(
    () => timezone === timezoneFromServer,
    [timezoneFromServer, timezone]
  )

  const isSaveBtnDisabled =
    isAvailabilitiesSame &&
    isCheckSame &&
    isLabelSame &&
    isTimeFormatSame &&
    isSessionDurationSame &&
    isTimezoneSame

  const sessionDuration = new Array(12).fill(null).map((item, index) => {
    index = index + 1
    return index * 5
  })

  const days = [
    t("sunday"),
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
  ]

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isLabelSame)
      dispatch(fetchContentJson(vcardId, { appointment_button_label }))
    if (!appointment_button_label)
      dispatch(
        fetchContentJson(vcardId, {
          appointment_button_label: t("bookanappointment"),
        })
      )
    if (!isCheckSame)
      dispatch(fetchContentJson(vcardId, { 'allow_book_time?': checked }))
    if (!isTimeFormatSame)
      dispatch(fetchContentJson(vcardId, { appointment_time_format }))
    if (!isSessionDurationSame)
      dispatch(fetchContentJson(vcardId, { session_duration }))
    if (!isTimezoneSame) dispatch(fetchContentJson(vcardId, { timezone }))
    if (!isAvailabilitiesSame) {
      dispatch(postAvailabilitiesToServer(vcardId, availabilities))
    }
  }

  useEffect(() => {
    checkedFromServer && dispatch(allowBookTime(checkedFromServer))
    labelFromServer &&
      dispatch(setButtonLabel(labelFromServer))
    timeFormatFromServer && dispatch(setTimeFormat(timeFormatFromServer))
    dispatch(updateAvailabilities(availabilitiesFromServer))
    dispatch(setSessionDuration(sessionDurationFromServer))
    timezoneFromServer && dispatch(setTimezone(timezoneFromServer))
  }, [
    dispatch,
    checkedFromServer,
    labelFromServer,
    timeFormatFromServer,
    sessionDurationFromServer,
    availabilitiesFromServer,
    timezoneFromServer,
  ])

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isSaveBtnDisabled} />

      <DefaultCheckbox
        onChange={checkboxHandler}
        checked={checked}
        label={t("allowPeopletobooktimewithme")}
      />
      <DefaultTextField
        placeholder={t("enterALable")}
        value={appointment_button_label}
        onChange={inputHandler}
        label={t("lablefortheappointmentbutton")}
      />

      <StyledLabel>{t("selectTimeFormat")}</StyledLabel>
      <StyledButtonGroup>
        <StyledSelectBtn
          value='12'
          onClick={formatHandler}
          active={12 === appointment_time_format}
          type='button'
        >
          {t("12hrformat")}
         
        </StyledSelectBtn>
        <StyledSelectBtn
          value='24'
          onClick={formatHandler}
          active={24 === appointment_time_format}
          type='button'
        >
          {t("24hrformat")}
         
        </StyledSelectBtn>
      </StyledButtonGroup>

      <StyledLabel>{t("selectsessionduration")}</StyledLabel>
      <DefaultSelect
        value={session_duration}
        onChange={sessionHandler}
        array={sessionDuration}
      />

      <StyledLabel>{t("addAvailability")}</StyledLabel>
      <StyledText>
      {t("addAvailabilitymessage")}
        
      </StyledText>

      {days.map((day, index) => (
        <WeekDay
          key={day}
          availabilities={availabilities}
          day={day}
          weekday={index}
        />
      ))}
      <SelectTimeZone timezone={timezone} />
      <StyledSaveButton>
        <SaveBtn array={availabilities} disabled={isSaveBtnDisabled} />
      </StyledSaveButton>
    </form>
  )
}

export default Appointments
