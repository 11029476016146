import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styled from 'styled-components'
import Title from '../../kit/Title'

export const StyledCarouselSection = styled.section`
  width: ${(props) =>
    props.carouselEnabled
      ? props.isMobile
        ? 'calc(100% - 40px)'
        : 'calc(100% - 80px)'
      : '100%'};
  padding: 0.5rem 2rem;
  margin: 0 auto;
  margin-top: 0rem;

  & .carousel.carousel-slider {
    overflow: visible;
  }
`

const CarouselWrapper = styled.div`
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 0.25rem;
  background-color: #fff;
  & li {
    display: flex;
    justify-content: center;
  }
`

const StyledItem = styled.div`
  padding: 1.25rem;
  text-align: center;
  cursor: ${(props) => props.length && 'grab'};
  p {
    white-space: pre-wrap;
  }
  p:nth-child(1) {
    font-style: italic;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
  p:nth-child(3) {
    font-weight: 200;
    opacity: 0.75;
  }
`

const StyledNavArrow = styled.button`
  position: absolute;
  top: 50%;
  left: ${(props) => (props.dir === 'back' ? '-40px' : '')};
  right: ${(props) => (props.dir === 'back' ? '' : '-40px')};
  transform: translateY(-50%);
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 999;
  cursor: pointer;
`

export const NavArrow = ({ clickHandler, dir }) => {
  const arrowHandler = () => {
    clickHandler()
  }

  return (
    <StyledNavArrow onClick={arrowHandler} dir={dir}>
      {dir === 'back' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
    </StyledNavArrow>
  )
}

const Testimonials = ({ mode, data }) => {
  const { t } = useTranslation();

  const isMobile = mode === 'mobile'

  const testimonials = data?.testimonials
  const carouselEnabled = testimonials?.length > 1

  return (
    testimonials?.length > 0 && (
      <StyledCarouselSection
        isMobile={isMobile}
        carouselEnabled={carouselEnabled}
      >
        {data?.user_type === 'admin' && (
        <>
        <Title textContent={t('testimonials')} />
        </>
        )}
        {data?.user_type === 'distributor' && (
        <>
        <Title textContent={t('testimonials')} />
        </>
        )}
        {(data?.user_type === null || data?.user_type ==='sales') && (
        <>
        <Title textContent={t('testimonialsStore')} />
        </>
        )}
        <CarouselWrapper>
          {testimonials.length > 1 ? (
            <Carousel
              infiniteLoop
              showThumbs={false}
              showIndicators={false}
              emulateTouch
              showStatus={false}
              autoPlay
              interval='5000'
              renderArrowPrev={(clickHandler) => {
                return <NavArrow clickHandler={clickHandler} dir='back' />
              }}
              renderArrowNext={(clickHandler) => {
                return <NavArrow clickHandler={clickHandler} dir='forward' />
              }}
            >
              {testimonials?.map(
                ({ message, given_by, title_of_the_person }, idx) => (
                  <StyledItem length={true} key={idx} color={data.body_color}>
                    <p> <div dangerouslySetInnerHTML={{ __html: message }} /></p>
                    <p>{given_by}</p>
                    <p>{title_of_the_person}</p>
                  </StyledItem>
                )
              )}
            </Carousel>
          ) : (
            testimonials.map(
              ({ message, given_by, title_of_the_person }, idx) => (
                <StyledItem key={idx} data={data}>
                  <p><div dangerouslySetInnerHTML={{ __html: message }} /></p>
                  <p>{given_by}</p>
                  <p>{title_of_the_person}</p>
                </StyledItem>
              )
            )
          )}
        </CarouselWrapper>
      </StyledCarouselSection>
    )
  )
}

export default Testimonials
