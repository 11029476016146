import React from 'react'
import { ArrowRight } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setComponentEditor } from '../../../redux/constructor/actions'
import { STYLES } from '../../../styles'

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid ${STYLES.COLORS.sidebarBorder};
  &:hover {
    background-color: #e9effa;
  }
`
const StyledText = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  h3 {
    color: #1a202c;
    font-size: 1.125rem;
    margin: 0;
    font-weight: 400;
  }
  p {
    font-size: 0.875rem;
    color: #4a5568;
    margin: 0;
    font-weight: 300;
  }
`
export default function ListItem({ item }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const setComponentHandler = () => {
    dispatch(setComponentEditor(item.title))
  }
  return (
    <StyledListItem onClick={setComponentHandler}>
      <StyledText>
        <h3>{t('' + item.title)}</h3>
        <p>{item.description}</p>
      </StyledText>
      <ArrowRight />
    </StyledListItem>
  )
}
