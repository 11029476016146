import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import LanguageSelect from '../multiLanguage/LanguageSelect.js'
import LogOut from './LogOut.jsx'

const LogIn = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.Authorization)
  return !user ? <div style={{"display": "flex", "align-items": "center", "justify-content": "flex-end"}}><LanguageSelect /><Link style={{marginLeft:10}} to='/account'>{t("login")}</Link></div> : <LogOut />
}
export default LogIn
