import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  deleteVideoLink,
  updateVideoLink
} from '../../../../redux/videoLibrary/action'
import DefaultTextField from '../../../kit/DefaultTextField'
import ItemWrapper from '../../../kit/ItemWrapper'
import RemoveBtn from '../../../kit/RemoveBtn'

const VideoItem = ({ item }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const updateTestimonialHandler = (e) => {
    const val = e.target.value
    const id = e.target.id
    dispatch(updateVideoLink({ ...item, [id]: val }))
  }

  const deleteItemHandler = () => {
    dispatch(deleteVideoLink(item.id))
  }
  return (
    <ItemWrapper>
      <DefaultTextField
        id='url'
        label={t('addLinkToVideo')}
        value={item.url}
        required
        onChange={updateTestimonialHandler}
      />
      <RemoveBtn onClick={deleteItemHandler} textContent={t('link')}/>
    </ItemWrapper>
  )
}

export default VideoItem
