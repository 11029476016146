import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLoggedInUser } from '../../../utils/helpers';
// datatabel
import { 
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton, 
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
  },
  buttonBox: {
    display: 'contents ',
  },
});

export default function CompanyCard({ data }) {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.ConstructorReducer);
  const classes = useStyles();

  const history = useHistory();
  const { user } = getLoggedInUser();
  const [userData] = useState({ ...user });
  const [setTransferMessage] = React.useState('');
  
  localStorage.removeItem('user_id');
  function handleClick(data) {
    localStorage.setItem('user_id', data.id);
    if(userData?.user_type === 'sales'){
      history.replace('/distributor');
    }else{
      history.replace('/ContentAdmin');
    }
  }

  useEffect(() => {
    if (error !== undefined && error !== '') {
      setTransferMessage(t('notfoundMessage'));
    }
  }, [error]);

  //datatable
  const columns = [
    { field: 'username', headerName: 'Name' , width: 200},
    { field: 'email', headerName: 'Email' , width: 200},
    { field: 'number_of_vcards', headerName: 'Number Of Vcards' , width: 200},
    { field: 'vcard_url', headerName: 'Vcard Url' , width: 300},
    { field: 'actions', headerName: 'Actions', renderCell: (params) => {
      return (
        <Button
          onClick={(e) => handleClick(params.row)}
          variant="contained"
        >
          View
        </Button>
      );
    } }
  ]
  
  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarQuickFilter
  quickFilterParser={(searchInput) =>
    searchInput.split(',').map((value) => value.trim())
  }
  quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
  debounceMs={200} // time before applying the new quick filter value
/>
      </div>
    </div>
  );
}

  interface QuickSearchToolbarProps {
      clearSearch: () => void;
      onChange: () => void;
      value: string;
    }

  return (
    <>
      <Card className={classes.root}>
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            rows={data}
            columns={columns}
            componentsProps={{
             toolbar: {
                 showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
              },
           }}
          />
        </div>
      </Card>
    </>
  );
}
