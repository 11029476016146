/* AUTH */
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED'

export const IS_REGISTERED_FALSE = 'IS_REGISTERED_FALSE'
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED'
export const LOGOUT_USER = 'LOGOUT_USER'

export const FORGET_PASSWORD = 'FORGET_PASSWORD'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const REFRESH_AUTH = 'REFRESH_AUTH'
export const REFRESH_AUTH_SUCCESS = 'REFRESH_AUTH_SUCCESS'
export const REFRESH_AUTH_FAILED = 'REFRESH_AUTH_FAILED'
export const DROP_AUTH_ERROR = 'DROP_AUTH_ERROR'
