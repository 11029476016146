import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { STYLES } from '../../styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    '& span': {
      marginBottom: 4,
      fontSize: '1.125rem',
      color: STYLES.COLORS.body,
      fontWeight: 400,
    },
  },
})

const DefaultCheckbox = ({ onChange, checked, label }) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Checkbox
          onChange={onChange}
          checked={checked}
          sx={{
            color: STYLES.COLORS.lightBlue,
            '&.Mui-checked': {
              color: STYLES.COLORS.lightBlue,
            },
          }}
        />
      }
      label={label}
    />
  )
}

export default DefaultCheckbox
