import Button from '@material-ui/core/Button'
import React from 'react'
import {
  ArrowClockwise,
  BoxArrowUpRight,
  Display,
  Phone,
} from 'react-bootstrap-icons'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  setPreviewDisplay,
  updatePreviewDisplay,
} from '../../redux/constructor/actions'
import { STYLES } from '../../styles/index'
import LogOut from '../account/LogOut.jsx'
import { useTranslation } from 'react-i18next'


const StyledHeader = styled.div`
  width: 100%;
  height: min-content;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`
const StyledNavbar = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  & > div {
    display: flex;
    align-items: center;
    height: 100%;
    color: blue;

    &:first-child {
      column-gap: 1rem;
    }
    &:last-child {
      button {
        color: ${STYLES.COLORS.primary};
        font-size: 1.25rem;
      }
    }
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 22px;
  font-size: 1.25rem;
  height: 100%;
  color: ${STYLES.COLORS.primary};

  &:hover {
    color: ${STYLES.COLORS.primary};
  }
`

const TemplateHeader = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { previewDisplay, vcardDataToEdit } = useSelector(
    (state) => state.ConstructorReducer
  )

  return (
    <StyledHeader>
      <StyledNavbar>
        <div>
          <Button
            variant='contained'
            color='primary'
            size='small'
            disabled={previewDisplay === 'mobile' ? true : false}
            onClick={() => dispatch(setPreviewDisplay('mobile'))}
            endIcon={<Phone />}
          >
            {t('phone')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='small'
            disabled={previewDisplay === 'display' ? true : false}
            onClick={() => dispatch(setPreviewDisplay('display'))}
            startIcon={<Display />}
          >
            {t('display')}
          </Button>
        </div>
        <div>
          <StyledLink to='/preview' target='_blank' rel='noreferrer noopener'>
            <BoxArrowUpRight />
          </StyledLink>
          <Button
            onClick={() => dispatch(updatePreviewDisplay(vcardDataToEdit?.id))}
          >
            <ArrowClockwise />
          </Button>
        </div>
      </StyledNavbar>
      <LogOut />
    </StyledHeader>
  )
}
export default TemplateHeader
