import React from 'react'
import styled from 'styled-components'
import { STYLES } from '../../styles'
import GoBack from '../kit/GoBack';

const StyledSidebarComponent = styled.div`
  background-color: ${STYLES.COLORS.lightGreen};
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 56px;
  & > * {
    padding: 1rem;
  }
`
const SidebarComponent = ({ Component }) => {
  return (
    <StyledSidebarComponent>
      <GoBack />
      <Component />
    </StyledSidebarComponent>
  )
}

export default SidebarComponent
