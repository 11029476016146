import React, { useState } from 'react';
import { isCompositeComponentWithType } from 'react-dom/test-utils';
import styled from 'styled-components';
import { STYLES } from '../../../styles';
import { getLoggedInUser } from '../../../utils/helpers';
import ListItem from './SidebarListItem';
import ListItemNew from './SidebarListItemNew';
import { useDispatch, useSelector } from 'react-redux';
import { getVideoLink } from '../../../redux/contentAdmin/actions';

const StyledSidebarListGroup = styled.ul`
  padding: 0;
  border-top: 1px solid ${STYLES.COLORS.sidebarBorder};
  margin: 0;
`;

const SidebarListGroup = ({ components }) => {
  // let { user } = getLoggedInUser();
  // const [vcardDataToEdit, setLoggedUser] = useState({ ...user });
  const { vcardDataToEdit } = useSelector(
    (state) => state?.ConstructorReducer
  );

  
  return (
    <StyledSidebarListGroup>
      {components.map((item) => {
        if (
          item.title === 'Servicesss' &&
          (vcardDataToEdit?.user_type === 'distributor' ||
            vcardDataToEdit?.user_type === null)
        ) {
          return null;
         } else if (
          item.title === 'Image Uploade' &&
          (vcardDataToEdit?.user_type === 'admin' ||
            vcardDataToEdit?.user_type === 'distributor')
        ) {
          return null;
        } else if (
          item.title === 'VmailBLAZE' &&
          (vcardDataToEdit?.user_type === 'admin' ||
            vcardDataToEdit?.user_type === 'distributor')
        ) {
          return null;
        } else if (
          item.title === 'Color Scheme' &&
          (vcardDataToEdit?.user_type === 'admin' ||
            vcardDataToEdit?.user_type === 'distributor')
        ) {
          return null;
        } else if (
          item.title === 'Testimonials' &&
          (vcardDataToEdit?.user_type === 'admin' ||
            vcardDataToEdit?.user_type === 'distributor')
        ) {
          return null;
        } else if (
          item.title === 'Appointments' &&
          (vcardDataToEdit?.user_type === 'admin' ||
            vcardDataToEdit?.user_type === 'distributor')
        ) {
          return null;
        } else if (
          item.title === 'Calendly' &&
          (vcardDataToEdit?.user_type === 'admin' ||
            vcardDataToEdit?.user_type === 'distributor')
        ) {
          return null;
        } else if (
          item.title === 'Services' &&
          (vcardDataToEdit?.user_type === 'admin' ||
            vcardDataToEdit?.user_type === 'distributor')
        ) {
          return null;
        } else if (
          item.title === 'Refer 3 and it`s FREE' &&
          (vcardDataToEdit?.user_type === 'admin' ||
            vcardDataToEdit?.user_type === 'distributor')
        ) {
          return null;
        } else if (
          item.title === 'Testimonials Video Library' &&
          (vcardDataToEdit?.user_type === 'admin' ||
            vcardDataToEdit?.user_type === 'distributor')
        ) {
          return null;
        } else if (item.title === 'Our Stories' && (vcardDataToEdit?.user_type === null || vcardDataToEdit?.user_type === 'sales')) {
          return null;
        } else if (item.title === 'Our Stories Video Library' && (vcardDataToEdit?.user_type === null || vcardDataToEdit?.user_type === 'sales')) {
          return null;
        } else if (item.title === 'HTML Editor' && (vcardDataToEdit?.user_type === 'admin' || vcardDataToEdit?.user_type === 'distributor')) {
          return null;
        } else if (item.title === 'HTML' && (vcardDataToEdit?.user_type === 'admin' || vcardDataToEdit?.user_type === 'distributor')) {
          return null;
        } else if (item.title === vcardDataToEdit?.distributor_title_id && (vcardDataToEdit?.user_type === null || vcardDataToEdit?.user_type === 'sales')) {
          return null;
        } else if (item.title === "Distributor ID" && (vcardDataToEdit?.user_type === null || vcardDataToEdit?.user_type === 'sales')) {
          return null;
        } else if (item.title === 'Video Testimonials Library' && (vcardDataToEdit?.user_type === null || vcardDataToEdit?.user_type === 'sales')) {
          return null;
        } else if (item.title === 'Company Info' && (vcardDataToEdit?.user_type === null || vcardDataToEdit?.user_type === 'sales')) {
          return null;
        } else if (item.title === 'Calendar' && (vcardDataToEdit?.user_type === null || vcardDataToEdit?.user_type === 'sales')) {
          return null;
        } else if (item.title === 'Video Testimonials Library' && (vcardDataToEdit?.user_type === null || vcardDataToEdit?.user_type === 'sales')) {
          return null;
        } else if (item.title === 'Banner' && (vcardDataToEdit?.user_type === null || vcardDataToEdit?.user_type === 'sales')) {
          return null;
        } else {
            return <ListItemNew key={item.id} item={item} />;
        }
      })}
    </StyledSidebarListGroup>
  );
};

export default SidebarListGroup;
