import { Button } from '@material-ui/core';
import WebLinkPage from './WebLinkPage';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from '../../utils/helpers';
import Title from './Title';

const WebLink = () => {
  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });

  // 24-10 changes in get all users of that company

  return (
    <div>
      <Title>Enter your agent Id in the box</Title>
      <WebLinkPage />
    </div>
  );
};

export default WebLink;
