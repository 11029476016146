import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { STYLES } from '../../styles'

const StyledSelect = styled(Select)`
  background-color: #fff;
  & > div {
    padding: 0.5rem;
    padding-right: 24px !important;
  }
  & > svg {
    right: 0;
  }
  &.MuiOutlinedInput-root.Mui-focused > fieldset {
    border-color: ${STYLES.COLORS.lightBlue};
  }
  &.MuiOutlinedInput-root:hover > fieldset {
    border-color: rgba(0, 0, 0, 0.23);
  }
  &.MuiOutlinedInput-root > fieldset {
    top: 0;
    border: none;
  }
  .MuiMenuItem-root {
    display: flex;
  }
`
const DefaultSelect = ({ value, onChange, array, id, name }) => {
  const { appointment_time_format } = useSelector(
    (s) => s.appointmentsSettingsReducer
  )
  return (
    <StyledSelect name={name} value={value} defaultValue='' onChange={onChange}>
      {array.map((el, idx) => (
        <MenuItem key={idx} value={el?.[24] || el}>
          {el?.[appointment_time_format] || el}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

export default DefaultSelect
