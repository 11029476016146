import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import MainLogo from '../../../assets/images/MainLogo';
import API, { APIMAIN } from '../../../redux/API';
import { getLoggedInUser } from '../../../utils/helpers';
import SidebarFooter from './SidebarFooter';
import SidebarListGroup from './SidebarListGroup';

const StyledSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
`;
const StyledLogo = styled.div`
  padding: 10px 1rem;
`;
const StyledTitle = styled.h3`
  padding: 0 1rem;
  margin: 0.5rem 0;
  font-size: 1.125rem;
`;

export default function SidebarMainMenu({ components }) {
  const { t } = useTranslation();
  const { vcardDataToEdit } = useSelector((state) => {
    return state.ConstructorReducer;
  });

  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });
  const logoUrl = API + `/companies/${localStorage.getItem('c_id')}`;
  const [logo, setLogo] = useState(null);

  const getLogo = async () => {
    const res = await axios.get(logoUrl, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
    return res.data;
  };

  useEffect(() => {
    getLogo().then((res) => {
      localStorage.setItem('c_info', res?.content_info)
      localStorage.setItem('html_editer', res?.html_editor)
      setLogo(APIMAIN + res.logo.url);
    });
  }, []);

  return (
    <StyledSidebarContent>
      <StyledLogo>
        <img
            src={logo}
            alt="logo"
            style={{ height: '50px', objectFit: 'contain' }}
          />
      </StyledLogo>
      <StyledTitle>{t('editYourCard')}: </StyledTitle>
      <SidebarListGroup components={components} />
      
    </StyledSidebarContent>
  );
}
