import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Appointments from './Editors/appointment/Appointments';
import Calendly from './Editors/calendly/Calendly';
import Calendar from './Editors/calendar/Calendar';
import DistributorID from './Editors/distributorID/DistributorID';
import CompanyInfo from './Editors/companyInfo/CompanyInfo';
import CardLanguage from './Editors/cardLanguage/CardLanguage';
import ColoreScheme from './Editors/ColoreEditor';
import Gallery from './Editors/Gallery';
import GreetingVideo from './Editors/greetingVideo/GreetingVideo';
import MainContent from './Editors/mainContent/MainContent';
import RecordVideo from './Editors/greetingVideo/RecordVideo'
import Services from './Editors/services/Services';
import Settings from './Editors/settings/Settings';
import SocialLinks from './Editors/socialLinks/SocialLinks';
import Testimonials from './Editors/testimonials/Testimonials';
import VideoLibrary from './Editors/videoLibrary/VideoLibrary';
import VideoTestimonialsLibrary from './Editors/VideoTestimonialsLibrary/VideoTestimonialsLibrary';
import HTMLEditorOne from './Editors/HTMLEditorOne/HTMLEditor';
import HTMLEditorTwo from './Editors/HTMLEditorTwo/HTMLEditor';
import VmailBlaze from './Editors/vmailBlaze/VmailBlaze';
import OurStoriesVideoLibrary from './Editors/OurStoriesVideoLibrary/OurStoriesVideoLibrary';
import './index.scss';
import SidebarMainMenu from './mainMenu/SidebarMainMenu';
import SidebarComponent from './SidebarComponent';
import Banner from './Editors/Banner';
import { getVideoLinkByComppany, getVideoLinkList } from '../../redux/contentAdmin/actions';
import { getLoggedInUser } from '../../utils/helpers';
import API from '../../redux/API';
import axios from 'axios';

const StyledSidebar = styled.aside`
  position: relative;
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  min-width: 35rem;
  max-width: 35rem;
`;

export default function Sidebar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { user } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });

  const { component } = useSelector((s) => s.ConstructorReducer);
  const { vcardDataToEdit } = useSelector(
    (state) => state?.ConstructorReducer
  );
  const { videoLink, loading } = useSelector((state) => state.ContentAdminReducer);
  
  const [getBanner, setBanner] = useState('');
  const [getMainContent, setMainContent] = useState('');
  const [getCardLanguage, setCardLanguage] = useState('');
  const [getRecordVideo, setRecordVideo] = useState('');
  const [getGreetingVideo, setGreetingVideo] = useState('');
  const [getSocialLinks, setSocialLinks] = useState('');
  const [getCalendar, setCalendar] = useState('');  
  const [getDistributorID, setDistributorID] = useState('');
  const [getGallery, setGallery] = useState('');
  const [getTestimonials, setTestimonials] = useState('');
  const [getVideoTestimonialsLibrary, setVideoTestimonialsLibrary] = useState('');
  const [getVideoLibrary, setVideoLibrary] = React.useState('');
  const [getSettings, setSettings] = React.useState('');
  const [getHTML, setHTML] = React.useState('');
  const [getReferRequest, setReferRequest] = useState('');

  const [getColoreScheme, setColoreScheme] = useState('');
  const [getServices, setServices] = useState('');
  const [getAppointments, setAppointments] = useState('');
  const [getCalendly, setCalendly] = React.useState('');
  const [getVmailBlaze, setVmailBlaze] = React.useState('');
  const [getHTMLEditor, setHTMLEditor] = React.useState('');
  const [getOurStoriesVideoLibrary, setOurStoriesVideoLibrary] = React.useState('');

  // const [videoLink, setvideoLink] = React.useState([]);

  // if(loggedUser?.user_type === ('admin' || 'distributor')){
  //     dispatch(getVideoLinkByComppany(loggedUser?.company_id))
  // }else{
  //     dispatch(getVideoLinkList())
  // }
  let { auth } = getLoggedInUser();
  useEffect(() => {
    if(loggedUser?.user_type === 'admin'){
    axios
      .get(API + `/menu_videos?company_id=${loggedUser?.company_id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        data.map((row, index) => {
          if(row.title === "Banner"){
            setBanner(row.url);
          }
          if(row.title === "Main Content"){
            setMainContent(row.url);
          }
          if(row.title === "Set Vcard Default Language"){
            setCardLanguage(row.url);
          }
          if(row.title === "Record a Greeting video"){
            setRecordVideo(row.url);
          }
          if(row.title === "Greeting Video"){
            setGreetingVideo(row.url);
          }
          if(row.title === "Phone/Social Links"){
            setSocialLinks(row.url);
          }
          if(row.title === "Calendar"){
            setCalendar(row.url);
          }
          if(row.title === "DistributorID"){
            setDistributorID(row.url);
          }
          if(row.title === "Gallery"){
            setGallery(row.url);
          }
          if(row.title === "Our Stories"){
            setTestimonials(row.url);
          }
          if(row.title === "Testimonials Video Library"){
            setVideoTestimonialsLibrary(row.url);
          }
          if(row.title === "Video Library"){
            setVideoLibrary(row.url);
          }
          if(row.title === "Settings"){
            setSettings(row.url);
          }
        })   
      })
      .catch((err) => {
        console.log('err', err);
      });
    }else if(loggedUser?.user_type === "distributor"){
    axios
      .get(API + `/menu_videos?company_id=${loggedUser?.company_id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        data.map((row, index) => {
          if(row.title === "Banner"){
            setBanner(row.url);
          }
          if(row.title === "Main Content"){
            setMainContent(row.url);
          }
          if(row.title === "Set Vcard Default Language"){
            setCardLanguage(row.url);
          }
          if(row.title === "Record a Greeting video"){
            setRecordVideo(row.url);
          }
          if(row.title === "Greeting Video"){
            setGreetingVideo(row.url);
          }
          if(row.title === "Phone/Social Links"){
            setSocialLinks(row.url);
          }
          if(row.title === "Calendar"){
            setCalendar(row.url);
          }
          if(row.title === "DistributorID"){
            setDistributorID(row.url);
          }
          if(row.title === "Gallery"){
            setGallery(row.url);
          }
          if(row.title === "Our Stories"){
            setTestimonials(row.url);
          }
          if(row.title === "Testimonials Video Library"){
            setVideoTestimonialsLibrary(row.url);
          }
          if(row.title === "Video Library"){
            setVideoLibrary(row.url);
          }
          if(row.title === "Settings"){
            setSettings(row.url);
          }
        })   
      })
      .catch((err) => {
        console.log('err', err);
      });
    }else{
      axios
      .get(API + `/menu_videos`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        data.map((row, index) => {
          if(row.title === "Banner"){
            setBanner(row.url);
          }
          if(row.title === "Main Content"){
            setMainContent(row.url);
          }
          if(row.title === "Set Vcard Default Language"){
            setCardLanguage(row.url);
          }
          if(row.title === "Record a Greeting video"){
            setRecordVideo(row.url);
          }
          if(row.title === "Greeting Video"){
            setGreetingVideo(row.url);
          }
          if(row.title === "Phone/Social Links"){
            setSocialLinks(row.url);
          }
          if(row.title === "Calendar"){
            setCalendar(row.url);
          }
          if(row.title === "DistributorID"){
            setDistributorID(row.url);
          }
          if(row.title === "Gallery"){
            setGallery(row.url);
          }
          if(row.title === "Our Stories"){
            setTestimonials(row.url);
          }
          if(row.title === "Testimonials"){
            setTestimonials(row.url);
          }
          if(row.title === "Testimonials Video Library"){
            setOurStoriesVideoLibrary(row.url);
          }
          if(row.title === "Video Library"){
            setVideoLibrary(row.url);
          }
          if(row.title === "Settings"){
            setSettings(row.url);
          }
          if(row.title === "HTML"){
            setHTML(row.url);
          }

          if(row.title === "Color Scheme"){
            setColoreScheme(row.url);
          }
          if(row.title === "Services"){
            setServices(row.url);
          }
          if(row.title === "Appointments"){
            setAppointments(row.url);
          }
          if(row.title === "Calendly"){
            setCalendly(row.url);
          }
          if(row.title === "VmailBLAZE"){
            setVmailBlaze(row.url);
          }
          if(row.title === "HTML Editor"){
            setHTMLEditor(row.url);
          }
          if(row.title === "Refer Request"){
            setReferRequest(row.url);
          }
        })   
      })
      .catch((err) => {
        console.log('err', err);
      });
    }
  }, [auth.token]);

  const distributorHeadig = vcardDataToEdit?.distributor_title_id === null ? "Distributor ID" : vcardDataToEdit?.distributor_title_id === "" ? "Distributor ID" : vcardDataToEdit?.distributor_title_id;

  const list = {
    Banner,
    Services,
    Appointments,
    Calendly: Calendly,
    Gallery,
    Calendar,
    VmailBLAZE: VmailBlaze,
    Settings,
    'HTML' : HTMLEditorTwo,
    'Main Content': MainContent,
    'Set Vcard Default Language': CardLanguage,
    'Record a Greeting video': RecordVideo,
    'Greeting Video': GreetingVideo,
    'Color Scheme': ColoreScheme,
    'Phone/Social Links': SocialLinks,
    [distributorHeadig] : DistributorID,
    'HTML Editor': HTMLEditorOne,
    'Company Info': CompanyInfo,
    'Our Stories':Testimonials,
    'Testimonials Video Library':OurStoriesVideoLibrary,
    'Video Library': VideoLibrary,
    'Testimonials': Testimonials,
    'Our Stories Video Library': OurStoriesVideoLibrary
  };


  

  const components = [
    {
      title: 'Banner',
      description: 'Add a banner to your VCard',
      component: Banner,
      link: "https://vimeo.com/656977714",
      saas: getBanner
    },
    // {
    //   title: 'Company Info',
    //   description: 'Not editable by Distributor',
    //   component: CompanyInfo,
    // },
    {
      title: 'Main Content',
      description: t('mainContentDesc'),
      component: MainContent,
      link: "1",
      saas: getMainContent
    },
    {
      title: 'Set Vcard Default Language',
      description: t('languageDesc'),
      component: CardLanguage,
      link: "2",
      saas: getCardLanguage
    },
    {
      title: 'Record a Greeting video',
      description: '',
      component: RecordVideo,
      link: "3",
      saas: getRecordVideo
    },
    {
      title: 'Greeting Video',
      description: t('greetingVideoDesc'),
      component: GreetingVideo,
      link: "4",
      saas: getGreetingVideo
    },
    {
      title: 'Color Scheme',
      description: t('colorSchemeDesc'),
      component: ColoreScheme,
      link: "5",
      saas: getColoreScheme
    },
    {
      title: 'Phone/Social Links',
      description: t('phoneSocialLinkDesc'),
      component: SocialLinks,
      link: "6",
      saas: getSocialLinks
    },    
    {
      title: 'HTML',
      description: '',
      component: HTMLEditorTwo,
      link: "15",
      saas: getHTML
    },
    {
      title: 'Services',
      description: t('addEditServices'),
      component: Services,
      link: "7",
      saas: getServices
    },
    {
      title: 'Appointments',
      description: t('appointmentsDesc'),
      component: Appointments,
      link: "8",
      saas: getAppointments
    },
    {
      title: 'Calendly',
      description: t('calendlyDesc'),
      component: Calendly,
      link: "9",
      saas: getCalendly
    },
    {
      title: 'Calendar',
      description: '',
      component: Calendar,
      link: "100",
      saas: getCalendar
    },
    {
      title: distributorHeadig,
      description: '',
      component: DistributorID,
      link: "https://vimeo.com/656977714",
      saas: getDistributorID
    },
    {
      title: 'Gallery',
      description: t('galleryDesc'),
      component: Gallery,
      link: "10",
      saas: getGallery
    },    
    {
      title: 'Testimonials',
      description: t('testimonialsDesc'),
      component: Testimonials,
      link: "11",
      saas: getTestimonials
    },
    {
      title: 'Testimonials Video Library',
      description: t('testimonialsDesc'),
      component: OurStoriesVideoLibrary,
      link: "12",
      saas: getOurStoriesVideoLibrary
    },
    {
      title: 'Our Stories',
      description: t('testimonialsDesc'),
      component: Testimonials,
      link: "https://vimeo.com/656977714",
      saas: getTestimonials
    },
    {
      title: 'Our Stories Video Library',
      description: t('videoLibraryDesc'),
      component: VideoTestimonialsLibrary,
      link: "https://vimeo.com/656977714",
      saas: getVideoTestimonialsLibrary
    },
    {
      title: "Video Library",
      description: t('videoLibraryDesc'),
      component: VideoLibrary,
      link: "13",
      saas: getVideoLibrary
    },
    {
      title: 'VmailBLAZE',
      description: t('vmailBLAZEDesc'),
      component: VmailBlaze,
      link: "14",
      saas: getVmailBlaze
    },
    {
      title: 'HTML Editor',
      description: '',
      component: HTMLEditorOne,
      link: "15",
      saas: getHTMLEditor
    },
    {
      title: 'Settings',
      description: t('settingsDesc'),
      component: Settings,
      link: "16",
      saas: getSettings
    },
  ];

  return (
    <StyledSidebar>
      {!component ? (
        <SidebarMainMenu components={components} />
      ) : (
        <SidebarComponent Component={list[component]} />
      )}
    </StyledSidebar>
  );
}
