import {
  FETCH_BANNER,
  FETCH_BANNER_FAILURE,
  FETCH_BANNER_SUCCESS,
  SELECT_BANNER,
  UPLOADE_SUCCESS,
  DELETE_MASSAGE,
} from './constants';

const INIT_STATE = {
  selectedBanner: null,
  banners: [],
  loading: false,
  error: null,
};

const Banner = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPLOADE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "Banner Uploade Successfully",
        login: true,
        user: action.payload,
      }
      case DELETE_MASSAGE:
        return {
          ...state,
          loading: false,
          error: "Delete Successfully",
          login: true,
          user: action.payload,
        }
    
    case SELECT_BANNER:
      return { ...state, selectedBanner: action.payload };
    case FETCH_BANNER:
      return { ...state, loading: true };
    case FETCH_BANNER_SUCCESS:
      return { ...state, banners: action.payload, loading: false };
    case FETCH_BANNER_FAILURE:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};

export default Banner;
