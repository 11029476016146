import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import icon from '../../../assets/icons/share.png'

const StyledModalWindowIOS = styled.div`
  display: flex;
  position: fixed;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #00000050;
  backdrop-filter: blur(2px);
  color: #fff;
  border-radius: 5px;
  max-width: 100%;
  width: fit-content;
  font-size: 1rem;
  transition: bottom 0.5s ease;
  div {
    padding: 0.5rem;
    align-self: center;
    display: flex;
    align-items: center;
  }

  img {
    filter: invert(1);
    width: 20px;
  }

  button {
    outline: none;
    border: none;
    background-color: #000;
    border-radius: 0 5px 5px 0;
    color: #fff;
    padding: 0 0.5rem;
    svg {
      font-size: 1rem;
      vertical-align: middle;
    }
  }
`

const ModalWindowIOS = ({ setModal }) => {
  const { t } = useTranslation();

  let prevScrollpos = window.pageYOffset
  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset
    if (prevScrollpos > currentScrollPos) {
      document.getElementById('modal-window').style.bottom = '2%'
    } else {
      document.getElementById('modal-window').style.bottom = '-10%'
    }
    prevScrollpos = currentScrollPos
  }

  const closeHandler = () => {
    setModal(false)
  }

  return (
    <StyledModalWindowIOS id='modal-window'>
      <div>
        {t('tap')} <img src={icon} alt='' /> {t('tapDesc')}
      </div>
      <button onClick={closeHandler}>
        <CloseRoundedIcon />
      </button>
    </StyledModalWindowIOS>
  )
}

export default ModalWindowIOS
