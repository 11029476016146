import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { getLoggedInUser } from '../../../../utils/helpers';
import './styles.css';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';

import 'froala-editor/js/plugins/font_family.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/third_party/embedly.min.css';

// import "froala-editor/css/plugins/fullscreen.min.css";

import Froala from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import Tribute from 'tributejs';
import 'tributejs/dist/tribute.css';

export const FroalaEditor: React.FC = ({ onChange, value }) => {
  const ref = useRef({ editor: null });
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);
  const [editor, setEditor] = useState(undefined);
  const [model, setModel] = useState(value);

  const handleModelChange = (model) => {
    console.log('aaa', model);
    setModel(model);
    onChange(model);
  };
  let { auth } = getLoggedInUser();

  // Editor initialization
  useEffect(() => {
    setEditor(ref.current.editor);
    editor && setIsFroalaInitialized(true);
    console.log('tes', <Froala />);
  }, [ref.current]);

  // Do after initialization

  return (
    <div className="App">
      <Froala
        ref={ref}
        model={model}
        onModelChange={handleModelChange}
        tag="textarea"
        config={{
          key: 'oc1F2vB1H1D1B4C2A6A5nDe1YRTYKd1Dd1f1JVVD1VJKKYLMPvC2B1F1E1B4E4I4B1C8E7==',
          attribution: false,
          placeholder: 'Start typing...',
          fontFamily: {
            'Arial,Helvetica,sans-serif': 'Arial',
            'Roboto,sans-serif': 'Roboto',
            'Oswald,sans-serif': 'Oswald',
            'Montserrat,sans-serif': 'Montserrat',
            "'Open Sans Condensed',sans-serif": 'Open Sans Condensed',
            'Bruno, serif': 'Bruno',
          },
          imageUploadParam: 'image',
          requestHeaders: {Authorization: 'Bearer ' + auth.token},
          imageUploadURL: 'https://api.talkingvcard.net/api/v1/image_uploads',

          events: {
              'image.uploaded': function (response) {
                  console.log("https://api.talkingvcard.net/"+JSON.parse(response).image_url);
                  var editor = this;
                  // Parse response to get image url.
                  var img_url = String("https://api.talkingvcard.net"+JSON.parse(response).image_url);
                  // console.log(response.split(" "));
                  editor.image.insert(img_url, false, null, editor.image.get(), response);
               editor.popups.hideAll();
               return false;
            },
          },
          htmlRemoveTags: [''],
         htmlExecuteScripts: true, 
         htmlAllowedTags: ['.*'],
         htmlAllowedAttrs: ['.*'],
         toolbarButtons: {
            moreText: {
              buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting',
                'color',
              ],
            },
            moreParagraph: {
              buttons: [
                'alignLeft',
                'alignCenter',
                'formatOLSimple',
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote',
              ],
            },
            moreRich: {
              buttons: [
                'insertLink',
                'insertImage',
                'insertFiles',
                'insertVideo',
                'insertTable',
                'emoticons',
                'fontAwesome',
                'specialCharacters',
                'embedly',
                'insertFile',
                'insertHR',
              ],
            },
            moreMisc: {
              buttons: [
                'undo',
                'redo',
                'fullscreen',
                'print',
                'getPDF',
                'spellChecker',
                'selectAll',
                'html',
                'help',
              ],
              align: 'right',
              buttonsVisible: 2,
            },
          },
          pluginsEnabled: [
            'fontFamily',
            'fontSize',
            'table',
            'spell',
            'quote',
            'save',
            'quickInsert',
            'paragraphFormat',
            'paragraphStyle',
            'help',
            'draggable',
            'align',
            'link',
            'lists',
            'file',
            'image',
            'emoticons',
            'url',
            'video',
            'embedly',
            'colors',
            'entities',
            'inlineClass',
            'inlineStyle',
            'codeBeautif ',
            'spellChecker',
            'imageTUI',
            'codeView',
            'print',
            'fullscreen',
            'specialCharacters',
            'color',
            'clearFormatting',
            'keepFormatOnDelete',
            'filesManager',
          ],
        }}
      />
      <FroalaEditorView value={value} />
    </div>
  );
};

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

// Render Froala Editor component.
