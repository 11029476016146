
import { fetchAllUser, fetchAllUserId } from '../../../redux/agent/actions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useRef, useState } from 'react';
import context from 'react-bootstrap/esm/AccordionContext';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import { getLoggedInUser } from '../../../utils/helpers';
import { APIMAIN } from '../../../redux/API';
// datatabel
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import SearchBar from 'material-ui-search-bar';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    marginTop: '15px'
  },
  media: {
    height: 40,
    backgroundSize: 'contain',
  },
  buttonBox: {
    display: 'contents ',
  },
  paidStyle: {
    color: 'white',
    backgroundColor: 'green',
    borderRadius: '8px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '2px',
    paddingTop: '2px',
    fontSize: 13
  },
  unPaidStyle: {
    color: 'white',
    backgroundColor: 'red',
    borderRadius: '8px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '2px',
    paddingTop: '2px',
    fontSize: 13
  },
  buttonStyle: {
    backgroundColor: '#3f51b5'
  },
  bradcomeStyle: {
    fontSize: '14px',
    backgroundColor: '#3f51b5',
    color: '#FFFFFF',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '3px',
    paddingBottom: '3px',
    borderRadius: '10px'
  },
  searchBar: {
    margin: 10,
    width: '40%',
    float: 'right',
  }
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

const CustomToolbar = (props) => (
  <div className={useStyles().searchBar}>

    <SearchBar {...props} />
  </div>
);


export default function CompanyCard() {
  // console.log(data);
  const { rUsers, loading } = useSelector((state) => state.Agent);

  const { rUsersId, loading_id } = useSelector((state) => state.Agent);
  const { user } = getLoggedInUser();
  const [userData, setUserData] = useState({ ...user });
  useEffect(() => {
    dispatch(fetchAllUser(userData?.id));
    dispatch(fetchAllUserId(userData?.id));
  }, [dispatch]);
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.ConstructorReducer);

  const dispatch = useDispatch();
  const classes = useStyles();

  const history = useHistory();
  const refs = useRef();
  const [open, setOpen] = React.useState(false);
  const [transferEmail, setTransferEmail] = React.useState('');
  const [transferMessage, setTransferMessage] = React.useState('');


  function handleClick(data) {
    dispatch(fetchAllUser(data));
    dispatch(fetchAllUserId(data));
  }

  function handleClickName(data) {
    dispatch(fetchAllUser(data));
    dispatch(fetchAllUserId(data));
  }
  //datatable
  const defaultTheme = createTheme();
  const columns = [
    { field: 'username', headerName: t('customerName'), width: 250, headerAlign: 'center' },
    {
      field: 'vcard_url', headerName: t('vcardURL'), renderCell: (params) => {
        return (
          <a href={params.row.vcard_url} target='_blank'>
            {params.row.vcard_url}
          </a>
        );
      }, width: 350, headerAlign: 'center'
    },
    { field: 'number_of_vcards', headerName: t('vcardCount'), width: 200, headerAlign: 'center' },
    {
      field: 'created_at', headerName: t('signupDate'), renderCell: (params) => {
        return (
          <p>
            {Moment(params.row.created_at).local().format("DD-MM-YYYY")}
          </p>
        );
      }, width: 200, headerAlign: 'center', type: 'dateTime',
    },
    {
      field: 'payment_made', headerName: t('subscriptionStatus'), renderCell: (params) => {
        return (
          <>
            {(params.row.activity_info.payment_made === true) ? <p className={classes.paidStyle}>  Paid </p> : <p className={classes.unPaidStyle}> unpaid </p>}
          </>
        );
      }, width: 200, headerAlign: 'center'
    },
    {
      field: 'is_commission', headerName: t('commissionStatus'), renderCell: (params) => {
        return (
          <>
            {(params.row.is_commission === true) ? <p className={classes.paidStyle}>  Paid  </p> : <p className={classes.unPaidStyle}> Unpaid  </p>}
          </>
        );
      }, width: 200, headerAlign: 'center'
    },
    {
      field: 'Refer user', headerName: t('subscriptionActions'), renderCell: (params) => {
        return (
          <Button
            onClick={(e) => handleClick(params.row.id)}
            variant="contained"
            color="primary"
          >
            View
          </Button>
        );
      }, headerAlign: 'center'
    }
  ]

  interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
  }

  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState(rUsers);
  useEffect(() => {
    setTableData(rUsers)
  }, [rUsers])
  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
    const filteredRows = rUsers.filter((o) => {
      return Object.keys(o).some((k) => {
        return searchRegex.test(o[k]);
      });
    });
    setTableData(filteredRows);
  };


  const cancelSearch = () => {
    setSearchText("");
    requestSearch(searchText);
  };
  return (
    <>
      <div style={{ 'text-align': 'start', 'font-size': '17px', 'cursor': 'pointer' }}>
        {Array.isArray(rUsersId) && rUsersId.length > 0 && rUsersId.reverse().map((answer, i) => (
          <span
            className={classes.bradcomeStyle}
            onClick={() => {
              handleClickName(answer.id);
            }}
          >{answer.name} >> </span>
        ))}
      </div>
      <Card className={classes.root}>

        <br />
        <br /><br /><br />
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={tableData}
            columns={columns}
            pageSize={10}
            rrowsPerPageOptions={[5, 10, 15]}
            components={{ Toolbar: CustomToolbar }}

            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                /* quickFilterProps: { debounceMs: 500 }, */
                value: searchText,
                onChange: (searchVal) => requestSearch(searchVal),
                onCancelSearch: () => cancelSearch()
              },
            }}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'created_at',
                    sort: 'desc',
                  },
                ],
              },

            }}
          />
        </div>
      </Card>
    </>
  );
}
