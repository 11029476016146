import * as types from './types'

const initState = {
  availabilities: [],
  availableTime: [],
}

const availabilitiesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_AVAILABILITIES:
      return {
        ...state,
        availabilities: [...state.availabilities, action.obj],
      }
    case types.UPDATE_AVAILABILITIES:
      return {
        ...state,
        availabilities: [...action.data],
      }
    case types.DELETE_AVAILABILITIES:
      return {
        ...state,
        availabilities: (() => {
          const id = action.id
          const newState = state.availabilities.filter((item) => item.id !== id)
          return newState
        })(),
      }
    case types.CLEAR_DELETED_AVAILABILITIES:
      return {
        ...state,
        deletedAvailabilities: [],
      }
    case types.GET_AVAILABLE_TIME:
      return {
        ...state,
        availableTime: action.data,
      }
    default:
      return { ...state }
  }
}
export default availabilitiesReducer
