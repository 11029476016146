import React from 'react'
import styled from 'styled-components'

export const StyledDefaultButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  padding: 8px 25px;
  text-transform: uppercase;
  font-size: 0.85rem;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  font-weight: 500;

  &:hover {
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
  }
`

const DefaultButton = ({ data, textContent, type, onClick }) => {
  return (
    <StyledDefaultButton
      bgcolor={(data?.user_type === null) || (data?.user_type === 'sales') ? data?.primary_color : data?.banner_group.primary_color}
      color={(data?.user_type === null) || (data?.user_type === 'sales') ? data?.button_text_color : data?.banner_group?.button_text_color}
      type={type}
      onClick={onClick}
    >
      {textContent}
    </StyledDefaultButton>
  )
}

export default DefaultButton
