import Swal from "sweetalert2";

function Alert(
  title,
  icon = "success",
  confirmBtnColor = "#32b482",
//   time = 2000
) {
  return Swal.fire({
    showConfirmButton: true,
    icon: icon,
    width: 500,
    title: title,
    // timer: time,
    confirmButtonColor: confirmBtnColor,
    // confirmButtonText: "ок",
  });
}

function confirmAlert(title) {
  Swal.fire({
    width: 500,
    height: 500,
    showConfirmButton: true,
    icon: "error",
    showCancelButton: true,
    confirmButtonColor: "#32b482",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Написать в поддержку",
    cancelButtonText: "ОК",
    title: title,
  }).then((result) => {
    if (result.value) {
      setTimeout(() => {
        window.location.href = "https://neobis.kg/";
      }, 1000);
    }
  });
}

export { confirmAlert };
export default Alert;
