import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from '@material-ui/core';
import { ArrowRight } from 'react-bootstrap-icons'
import { ArrowClose } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setComponentEditor } from '../../../redux/constructor/actions'
import { STYLES } from '../../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle   } from '@fortawesome/free-solid-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CloseButton from 'react-bootstrap/CloseButton';
import Section from '../../kit/Section';
import { getVideoLink } from '../../../redux/contentAdmin/actions';
import React, { useEffect, useState } from 'react';
import { getLoggedInUser } from '../../../utils/helpers';

const StyledVideoFrame = styled.div`
  overflow: hidden;
  margin: 0 auto;
  max-width: 1000px;
  aspect-ratio: 16/9;
  background-color: ${(props) => props.color};

  & iframe {
    width: 100%;
    height: 100%;
  }
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid ${STYLES.COLORS.sidebarBorder};
  &:hover {
    background-color: #e9effa;
  }
`
const StyledText = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  h3 {
    color: #1a202c;
    font-size: 1.125rem;
    margin: 0;
    font-weight: 400;
  }
  p {
    font-size: 0.875rem;
    color: #4a5568;
    margin: 0;
    font-weight: 300;
  }
`
export default function ListItem({ item }) {
  let { user } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [link, setLink] = React.useState('');
  const setComponentHandler = () => {
    dispatch(setComponentEditor(item.title))
  }
  const { videoLink, loading } = useSelector((state) => state.ContentAdminReducer);
  
  function handleClick(id) {
    setLink(id);
    setOpen(true);
  }
  console.log(link);
  let titleIframe = '';
  const src=getLink(link, titleIframe)
  const [open, setOpen] = React.useState(false);
  // const link = "https://vimeo.com/656977714";
  // console.log("bbb",item.link);
  // const link = item.link;
  // console.log(videoLink);
  

  return (
    <>
    <StyledListItem>
        <FontAwesomeIcon icon={faQuestionCircle} style={{
        'font-size': '30px',
        color: 'white',
        background: '#100f0f',
        'border-radius': '48%',
        'margin-right': '14px'
        }}  onClick={(e) => handleClick(item.saas)}/>
      <StyledText onClick={setComponentHandler}>
        <h3>{t('' + item.title)}</h3>
        <p>{item.description}</p>
      </StyledText>
      <ArrowRight />
    </StyledListItem>
    <Dialog open={open}>
        {/*<DialogTitle style={{ paddingBottom: 0 }}>
        </DialogTitle>  */}      
        <DialogActions>
          <CloseButton onClick={(e) => {
            setOpen(false);
          }}/>
        </DialogActions>
        <DialogContent style={{width:"600px"}}>
            <StyledVideoFrame>
              <iframe
                src={src}
                title=""
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </StyledVideoFrame>
        </DialogContent>
      </Dialog>
      </>
  )
}
export const getLink = (link, titleIframe) => {
  let videoID = '';
  let webLinkRgx = new RegExp(/.youtube.+\/?v=(.+)/);
  let copyLinkRgx = new RegExp(/.youtu.be\/(.+)/);
  let vimeoLinkRgx = new RegExp(/vimeo.+\/(.+)/);
  if (link) {
    if (link.match(/youtu/)) {
      videoID = link.match(webLinkRgx) || link.match(copyLinkRgx);
      videoID = 'https://www.youtube.com/embed/' + videoID?.[1];
      titleIframe = 'YouTube Video Player';
    } else if (link.match(/vimeo/)) {
      videoID = link.match(vimeoLinkRgx);
      videoID = 'https://player.vimeo.com/video/' + videoID?.[1];
      titleIframe = 'Vimeo Video Player';
    } else if (link.match(/player/)) {
      videoID = link.match(vimeoLinkRgx);
      videoID = 'https://player.vimeo.com/video/' + videoID?.[1]+"?loop=1";
      titleIframe = 'Vimeo Video Player';
    }
  }

  return videoID;
};