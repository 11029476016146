import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Section from '../../kit/Section';
import calendar from '../../../assets/images/mycalendar.png';
import { APIMAIN } from '../../../redux/API';
const Calendar = ({ data }) => {

  return (data?.calender_url && (
      <Section>
        {data?.user_type === ('admin') && (
          <>
            <div className="">
              <a href={data?.calender_url} target="_blank"  style={{width:"100%"}}>
                <img src={data?.calender_image ? APIMAIN + data?.calender_image?.url : calendar} alt=""  className="fr-fic fr-dib" style={{width:"100%"}}/>
              </a>
            </div>
          </>
        )}
        {data?.user_type === ('distributor') && (
          <>
            <div className="">
              <a href={data?.calender_url} target="_blank"  style={{width:"100%"}}>
                <img src={data?.calender_image ? APIMAIN + data?.calender_image?.url : calendar} alt=""  className="fr-fic fr-dib" style={{width:"100%"}}/>
              </a>
            </div>
          </>
        )}
      </Section>
     )
    )
}

export default Calendar;
