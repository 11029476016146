import React from 'react';
import styled from 'styled-components';
import DeleteLogoBtn from './DeleteLogoBtn';

const StyledUploadedImage = styled.div`
  width: ${(props) => (props.isAvatar ? '186px' : '100%')};
  height: ${(props) => (props.isAvatar ? '186px' : 'auto')};
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  position: relative;
  margin-bottom: 1rem;

  img {
    width: ${(props) => (props.isAvatar ? '170px' : '100%')};
    aspect-ratio: ${(props) => (props.isAvatar ? 'auto' : '16 / 9')};
    height: ${(props) => (props.isAvatar ? '170px' : 'auto')};
    object-fit: cover;
    border-radius: ${(props) => (props.isAvatar ? '50%' : '0.25rem')};
  }
`;

const ImageLogoUploaded = ({ image, setState, id, type }) => {
  const isAvatar = id === 'avatar' ? true : false;
  return (
    <StyledUploadedImage isAvatar={isAvatar}>
      <img src={image} alt={id} />

      <DeleteLogoBtn setState={setState} id={id} type={type} />
    </StyledUploadedImage>
  );
};

export default ImageLogoUploaded;
