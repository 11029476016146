import React, { useRef, useState } from 'react'
import { StyledInputLabel } from './DefaultTextField'
import './Style.css'
import { Editor } from '@tinymce/tinymce-react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link
} from "suneditor/src/plugins";


// import 'tinymce/plugins/image'
// import 'tinymce/plugins/imagetools'
//  import 'tinymce/plugins/table'
// import 'tinymce/plugins/link'

const StyledAboutContent = styled.p`
  text-align: start;
  white-space: pre-wrap;
  font-size: 1.125rem;
  margin-top: 10px;
`

const DefaultCKEditor = ({
  label,
  id,
  onChange,
  value,
  required,
  placeholder,
}) => {

  const onChangeHandler = (content) => {
    console.log(content)
    onChange(content, id)
  }
  return (
    <StyledInputLabel>
      {label ? (
        <h4>
          {label}
          <span>{required ? '*' : ''}</span>
        </h4>
      ) : null}
      <SunEditor height='400' defaultValue={value}
        onChange={onChangeHandler}
        id={id}
        setOptions={{
          showPathLabel: false,
          minHeight: "50vh",
          maxHeight: "50vh",
          placeholder: "Enter your text here!!!",
          plugins: [
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            table,
            template,
            textStyle,
            image,
            link
          ],
          resizingBar: true,
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript"
            ],
            ["fontColor", "hiliteColor"],
            ["removeFormat"],
            "/", // Line break
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image", "fullScreen"]
          ],
          formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
          font: [
            "Arial",
            "Calibri",
            "Comic Sans",
            "Courier",
            "Garamond",
            "Georgia",
            "Impact",
            "Lucida Console",
            "Palatino Linotype",
            "Segoe UI",
            "Tahoma",
            "Times New Roman",
            "Trebuchet MS"
          ]
        }}
      />
    </StyledInputLabel>
  )
}


export default DefaultCKEditor
