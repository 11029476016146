import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from '../../components/contentAdmin/Sidebar';
import Template from '../../components/contentAdmin/Template';
import { fetchVcardDataToEditor } from '../../redux/constructor/actions';
import { fetchAccountVcardsAll } from '../../redux/account/actions';

const StyledConstructor = styled.div`
  display: flex;
  background: #2d3748;
  height: 100vh;
`;

const Constructor = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer);
  const { vCards, loading } = useSelector((state) => state.Account)

  let id = localStorage.getItem('vcard_id');

  useEffect(() => {
    dispatch(fetchAccountVcardsAll())
  }, [dispatch])

  useEffect(() => {
    if (!vcardDataToEdit) {
      if (id) {
        dispatch(fetchVcardDataToEditor(id));
        return;
      }
      history.replace('/account');
    }
  }, [dispatch, vcardDataToEdit, history, id]);

  if (vCards && vCards[0]?.activity_info?.is_active === false) {
    window.location.href = '/#/card'
  }
  
  return vcardDataToEdit ? (
    <StyledConstructor>
      <Sidebar />
      <Template />
    </StyledConstructor>
  ) : (
    <div></div>
  );
};
export default Constructor;
