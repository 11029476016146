import { getLoggedInUser } from '../../utils/helpers'
//Authorization
import {
  UPDATE_VCARDS,
  UPDATE_VCARDS_FAILED,
  UPDATE_VCARDS_SUCCESS,
} from './constants'

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
  error: null,
  vCards: [],
}

const Account = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_VCARDS:
      return { ...state, loading: true, error: false }
    case UPDATE_VCARDS_SUCCESS:
      return { ...state, loading: false, error: false, vCards: action.payload }
    case UPDATE_VCARDS_FAILED:
      return { ...state, loading: false, error: 'Какая то ошибка.' }
    default:
      return { ...state }
  }
}

export default Account
