import { isUserAuthenticated } from '../../utils/helpers'

//Authorization
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  IS_REGISTERED_FALSE,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
} from './constants'

const INIT_STATE = {
  user: isUserAuthenticated(),
  loading: false,
  login: false,
  passwordResetStatus: false,
  isRegistered: false,
  forgotSucces: false,
  error: null,
}

const Authorization = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: null, forgotSucces: false }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        login: true,
        user: action.payload,
      }
    case LOGIN_USER_FAILED:
      return { ...state, loading: false, login: false, error: action.payload }
    case FORGET_PASSWORD:
      return { ...state, loading: true, error: null, forgotSucces: false }
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        login: true,
        forgotSucces: true,
      }
    case FORGET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        login: false,
        forgotSucces: true,
        error: action.payload,
      }
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        login: true,
        user: action.payload,
        isRegistered: true,
      }
    case REGISTER_USER_FAILED:
      return { ...state, loading: false, login: false, error: action.payload }
    case LOGOUT_USER:
      return { ...state, user: null, login: false }
    case IS_REGISTERED_FALSE:
      return { ...state, isRegistered: false }
    default:
      return { ...state }
  }
}

export default Authorization
