import axios from 'axios'
import API from '../API'
import { getAvailableTime } from '../availabilities/action'
import { setPreloader, setSuccessMessage } from '../preloader/action'
import * as types from './types'

export const setStartDate = (date) => ({
  type: types.SET_START_DATE,
  date,
})

export const setStartTime = (time) => ({
  type: types.SET_START_TIME,
  time,
})

export const openBookingForm = (bool) => ({
  type: types.OPEN_BOOKING_FORM,
  bool,
})

export const updateState = (key, val) => ({
  type: types.UPDATE_STATE,
  [key]: val,
})

export const resetState = () => ({
  type: types.RESET_STATE,
})

export const postAppointmentToServer = (vcardId, data) => {
  const { timezone } = data
  return (dispatch) => {
    axios
      .post(`${API}/vcards/${vcardId}/appointments`, data, {
        headers: {
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        // dispatch(fetchVcardDataToEditor(vcardId))
        dispatch(getAvailableTime(vcardId, timezone))
        dispatch(resetState())
        dispatch(setPreloader(false))
        // dispatch(openBookingForm(false))
        dispatch(setSuccessMessage(data?.message))
      })
      .catch((err) => console.warn('postAppointmentToServer:', err))
  }
}
export const updateBookingTimezone = (data) => ({
  type: types.UPDATE_BOOKING_TIMEZONE,
  data,
})
