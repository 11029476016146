import axios from 'axios';
import { getLoggedInUser } from '../../utils/helpers';
import API from '../API';
// import { fetchVcardDataToEditor } from '../constructor/actions';
import {
  // FETCH_BANNER,
  // FETCH_BANNER_FAILURE,
  // FETCH_BANNER_SUCCESS,
  SELECT_BANNER,
  UPLOADE_SUCCESS,
  DELETE_MASSAGE,
} from './constants';

export const selectBanner = (data) => ({
  type: SELECT_BANNER,
  payload: data,
});
export const successMassage = (id) => ({
  type: UPLOADE_SUCCESS,
  payload: id,
});

export const deleteMassage = (id) => ({
  type: DELETE_MASSAGE,
  payload: id,
});

export const recordVideoUploade = (id, data) => {
  let { auth } = getLoggedInUser()
  console.log(data);
  return (dispatch) => {
    axios
      .post(API + `/vcards/${id}/upload_vimeo_video`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        // dispatch(getAllBanners(id));
        setTimeout(() => {
          dispatch(successMassage(id));
        }, 3000);
      })
      .catch((err) => {})
  }
}
//------------------end-banner--------------------