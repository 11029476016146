import React from 'react'
import styled from 'styled-components'

const StyledItemWrapper = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #a0aec0;
`

const ItemWrapper = ({ children }) => {
  return <StyledItemWrapper>{children}</StyledItemWrapper>
}

export default ItemWrapper
