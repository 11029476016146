import React from 'react';
import styled from 'styled-components';
import TemplateHeader from './TemplateHeader';
import TemplatePreview from './TemplatePreview';
import ScriptTag from 'react-script-tag';

const StyledTemplate = styled.main`
  position: relative;
  flex-grow: 1;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Template = () => {
  return (
    <StyledTemplate>
      <ScriptTag type="text/javascript"  src="https://talkingvcard.support/supportboard/js/min/jquery.min.js" />
      <ScriptTag  type="text/javascript" src="https://talkingvcard.support/supportboard/js/main.js" />
      <TemplateHeader />
      <TemplatePreview />
    </StyledTemplate>
  );
};

export default Template;
