import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import App from '../../components/templates/App'
import { fetchVcardDataToEditor } from '../../redux/constructor/actions'
import LoadingComponent from '../../components/kit/loadingComponent'

export default function Preview() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { vcardDataToEdit } = useSelector((s) => s.ConstructorReducer)

  const windowMedia = window.matchMedia('(max-width: 576px)')
  const mode = windowMedia.matches ? 'mobile' : 'display'

  useEffect(() => {
    let id = localStorage.getItem('vcard_id');
    console.log("test id",id);
    if (!vcardDataToEdit) {
      id ? dispatch(fetchVcardDataToEditor(id)) : history.replace('/account')
    }
  }, [history, dispatch, vcardDataToEdit])
  // this.hostname = document.location.hostname;
    // console.log("url".this.hostname);
  return vcardDataToEdit ? (
    <App data={vcardDataToEdit} mode={mode} />
  ) : (
    <LoadingComponent />
  )
}
