import React from 'react'
import './style.scss'
import src from './../../assets/brb.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export default function NoActivePreview({ domainName, have }) {
  const { t } = useTranslation()

  //   const [cardData, setCardData] = useState("");

  return (
    <div>
      <div className='pt-10 mx-auto max-w-lg mb-8'>
        <h1 className='text-3xl lg:text-5xl text-center'>
          {t('willbe')} <span className='font-bold'>{t('back')}</span> {t('soon')}
        </h1>
        <div className='flex justify-center my-10'>
          <img src={src} alt='' className='h-32 lg:h-48' />
        </div>
        <div className='text-xl flex mb-1  error-block'>
          <div className='px-4 py-2'>
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth='0'
              viewBox='0 0 16 16'
              size='40'
              className='text-yellow-700'
              height='40'
              width='40'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                d='M7.938 2.016a.146.146 0 00-.054.057L1.027 13.74a.176.176 0 00-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 00.066-.017.163.163 0 00.055-.06.176.176 0 00-.003-.183L8.12 2.073a.146.146 0 00-.054-.057A.13.13 0 008.002 2a.13.13 0 00-.064.016zm1.044-.45a1.13 1.13 0 00-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z'
                clip-rule='evenodd'
              ></path>
              <path d='M7.002 12a1 1 0 112 0 1 1 0 01-2 0zM7.1 5.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0L7.1 5.995z'></path>
            </svg>
          </div>
          <div>
            <p className='pr-4'>
              <span className='underline text-ocean-blue-500 break-all'>
                https://{domainName}.talkingvcard.net
              </span>{' '}
              {have
                ? t('isCurrentlyUnavailable')
                : t('suchDomainHasbeenChanged')}
            </p>
          </div>
        </div>
        <div className='text-xl flex mb-4'>
          <div className='px-4 py-2'>
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth='0'
              viewBox='0 0 16 16'
              size='40'
              className='text-gray-500'
              height='40'
              width='40'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                d='M8 15A7 7 0 108 1a7 7 0 000 14zm8-7A8 8 0 110 8a8 8 0 0116 0z'
                clip-rule='evenodd'
              ></path>
              <path
                fill-rule='evenodd'
                d='M7.5 3a.5.5 0 01.5.5v5.21l3.248 1.856a.5.5 0 01-.496.868l-3.5-2A.5.5 0 017 9V3.5a.5.5 0 01.5-.5z'
                clip-rule='evenodd'
              ></path>
            </svg>
          </div>
          <div className='pr-4'>
            <div className='font-medium'>{t('ifYourAVisitor')}</div>
            <div className='font-light'>{t('pleaseTryAgain')}</div>
          </div>
        </div>
        <div className='text-xl flex'>
          <div className='px-4'>
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth='0'
              viewBox='0 0 1024 1024'
              size='40'
              className='text-gray-500'
              height='40'
              width='40'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 632H136V232h752v560zM610.3 476h123.4c1.3 0 2.3-3.6 2.3-8v-48c0-4.4-1-8-2.3-8H610.3c-1.3 0-2.3 3.6-2.3 8v48c0 4.4 1 8 2.3 8zm4.8 144h185.7c3.9 0 7.1-3.6 7.1-8v-48c0-4.4-3.2-8-7.1-8H615.1c-3.9 0-7.1 3.6-7.1 8v48c0 4.4 3.2 8 7.1 8zM224 673h43.9c4.2 0 7.6-3.3 7.9-7.5 3.8-50.5 46-90.5 97.2-90.5s93.4 40 97.2 90.5c.3 4.2 3.7 7.5 7.9 7.5H522a8 8 0 0 0 8-8.4c-2.8-53.3-32-99.7-74.6-126.1a111.8 111.8 0 0 0 29.1-75.5c0-61.9-49.9-112-111.4-112s-111.4 50.1-111.4 112c0 29.1 11 55.5 29.1 75.5a158.09 158.09 0 0 0-74.6 126.1c-.4 4.6 3.2 8.4 7.8 8.4zm149-262c28.5 0 51.7 23.3 51.7 52s-23.2 52-51.7 52-51.7-23.3-51.7-52 23.2-52 51.7-52z'></path>
            </svg>
          </div>
          <div className='pr-4'>
            <div className='font-medium'>{t('ifYourAOwner')}</div>{' '}
            <div className='font-light'>
              {t('please')}{' '}
              <Link to='/sign-in' className='underline text-ocean-blue-500 '>
                {t('signIn')}
              </Link>{' '}
              {t('toResolveIssue')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
