import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addVideoLink,
  deleteVideosFromServer,
  putVideosToServer,
  updateVideos
} from '../../../../redux/videoLibrary/action'
import AddBtn from '../../../kit/AddBtn'
import SaveBtn from '../../../kit/SaveBtn'
import Wrapper from '../../../kit/Wrapper'
import Header from '../Header'
import VideoItem from './VideoItem'

const VideoLibrary = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { videoLibrary, deletedVideos } = useSelector(
    (s) => s?.videoLibraryReducer
  )
  const { id, videos } = useSelector(
    (s) => s?.ConstructorReducer?.vcardDataToEdit
  )

  const addVideoLinkHandler = () => {
    const id = Math.round(Math.random() * -1000)
    const link = {
      id,
      url: '',
    }
    dispatch(addVideoLink(link))
  }

  const isVideosSame = useMemo(() => {
    return JSON.stringify(videos) === JSON.stringify(videoLibrary)
  }, [videos, videoLibrary])

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isVideosSame) {
      if (videoLibrary.length) dispatch(putVideosToServer(id, videoLibrary))
      if (deletedVideos.length)
        dispatch(deleteVideosFromServer(id, deletedVideos))
    }
  }

  useEffect(() => {
    dispatch(updateVideos(videos))
  }, [dispatch, videos])

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isVideosSame} />
      <Wrapper>
        {videoLibrary?.map((item, idx) => (
          <VideoItem item={item} key={idx} />
        ))}
        <AddBtn onClick={addVideoLinkHandler} textContent={t('link')} />
      </Wrapper>
      <SaveBtn array={videoLibrary} disabled={isVideosSame} />
    </form>
  )
}

export default VideoLibrary
