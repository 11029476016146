import React from 'react'
import Button from '@mui/material/Button'
import SaveIcon from '@mui/icons-material/Save'
import { styled } from '@mui/material/styles'
import { STYLES } from '../../styles'
import { useTranslation } from 'react-i18next'

const StyledButton = styled(Button)({
  backgroundColor: `${STYLES.COLORS.lightBlue}`,
})

const SaveBtn = ({ onClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <StyledButton
      type='submit'
      variant='contained'
      startIcon={<SaveIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      {t('save')}
    </StyledButton>
  )
}

export default SaveBtn
