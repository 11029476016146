import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserCardAll } from '../../../redux/agent/actions';
import { getLoggedInUser } from '../../../utils/helpers';
import { EnterDomainPrefixForNewVcard } from '../../../utils/ModalWindows.jsx';
import Title from '../Title';
import VcardList from './VcardList';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import LogOut from '../../../components/account/LogOut.jsx';
import Logo from '../../../assets/images/MainLogo';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { SecondaryListItems } from '../ListItems';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
// import MyVcards from '../MyVcards';
// import AccountInfo from '../AccountInfo.jsx';
// import Settings from '../Settings.jsx';
// import CompanyList from '../company/Companies';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useHistory } from "react-router-dom";
import { fetchAccountVcardsAll } from '../../../redux/account/actions';

export default function DistributorList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const drawerWidth = 240;
  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer);
  const { vCards, loading } = useSelector((state) => state.Account);
  const [modalShow, setModalShow] = useState(false);
  const { user } = getLoggedInUser();

  useEffect(() => {
    dispatch(fetchUserCardAll());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchAccountVcardsAll())
  }, [dispatch])

  useEffect(() => {}, [vcardDataToEdit]);
  function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t('copyrightText')}
      <Link color="inherit" href={window.location.href}>
        {t('copywrite')}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
  const open = true;
  // vCards.

  if (vCards && vCards[0]?.activity_info?.is_active === false) {
    window.location.href = '/#/card'
  }
  const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '100%',
  },
}));
  const history = useHistory();
  const routeChange = () =>{ 
    let path = `account`; 
    history.push(path);
  }
  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [userData] = useState({ ...user });

  return (
    <React.Fragment>
      <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {t('dashboard')}
          </Typography>
          <IconButton color="inherit">
            {/* <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge> */}
          </IconButton>
          <LogOut />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Logo />
        </div>

        <Divider />
        <List>
          <div>
          {userData?.user_type === 'sales' && (

        <ListItem
          onClick={routeChange}
          button
        >
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary={'B2B'} />
        </ListItem>
      )}
    </div>
        </List>
        <Divider />
        <List>
          <SecondaryListItems />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>                
                <Title>{t('Distributor List')}</Title>
                <Grid container spacing={3}>
                  {loading ? (
                    <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <> 
                      <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
                        <VcardList data={vCards} /> 
                      </div>                         
                    </>
                  )}  
                  <EnterDomainPrefixForNewVcard
                    show={modalShow}
                    setModalShow={setModalShow}
                    onHide={() => setModalShow(false)}
                  />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
    </React.Fragment>
  );
}
