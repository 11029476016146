export const schemeList = [
  {
    title: 'Default',
    primary_color: '#0066D6',
    heading_color: '#2D3748',
    body_color: '#4A5568',
    button_text_color: '#ffffff',
    background_primary_color: '#E2E8F0',
    background_secondary_color: '#CBD5E0',
    background_page_color: '#2D77D4',
  },
  {
    title: 'Sakura',
    primary_color: '#EB1E63',
    heading_color: '#14031B',
    body_color: '#1F052A',
    button_text_color: '#ffffff',
    background_primary_color: '#F3F3F4',
    background_secondary_color: '#D0D0D4',
    background_page_color: '#090104',
  },
  {
    title: 'Nutmeg',
    primary_color: '#884534',
    heading_color: '#362214',
    body_color: '#482E1A',
    button_text_color: '#ffffff',
    background_primary_color: '#F3ECEB',
    background_secondary_color: '#DDD2CA',
    background_page_color: '#000000',
  },
  {
    title: 'Green',
    primary_color: '#165602',
    heading_color: '#071A01',
    body_color: '#583F6B',
    button_text_color: '#ffffff',
    background_primary_color: '#F7F8F8',
    background_secondary_color: '#D3E1D0',
    background_page_color: '#171818',
  },
]
