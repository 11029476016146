import * as types from './types'

const localTimezone = (new Date().getTimezoneOffset() / 60) * -1

const initState = {
  'allow_book_time?': true,
  appointment_button_label: 'Book an appointment',
  appointment_time_format: 24,
  session_duration: 5,
  timezone: localTimezone,
}

const appointmentsSettingsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.ALLOW_BOOK_TIME:
      return {
        ...state,
        'allow_book_time?': action.bool,
      }
    case types.SET_BUTTON_LABEL:
      return {
        ...state,
        appointment_button_label: action.string,
      }
    case types.SET_TIME_FORMAT:
      return {
        ...state,
        appointment_time_format: +action.number,
      }
    case types.SET_SESSION_DURATION:
      return {
        ...state,
        session_duration: +action.number,
      }
    case types.SET_TIMEZONE:
      return {
        ...state,
        timezone: action.string,
      }
    case types.UPDATE_AVAILABLE_TIME:
      return {
        ...state,
        availabilities: [...action.data],
      }

    case types.CLEAR_DELETED_AVAILABLES:
      return {
        ...state,
        deletedAvailabilities: [],
      }

    default:
      return { ...state }
  }
}
export default appointmentsSettingsReducer
