import * as types from './types'

const initState = {
  serviceTitle: '',
  services: [],
  deletedServices: [],
}

const servicesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.UPDATE_SERVICES_TITLE: {
      return { ...state, serviceTitle: action.string }
    }

    case types.ADD_SERVICES_LIST_ITEM:
      return {
        ...state,
        services: [...state.services, action.data],
      }

    case types.UPDATE_SERVICES_LIST_ITEM:
      return {
        ...state,
        services: (() => {
          const data = action.data
          const newState = state.services.map((item) =>
            item.id === data.id ? data : item
          )
          return newState
        })(),
      }

    case types.UPDATE_SERVICES_LIST:
      return { ...state, services: [...action.data] }

    case types.DELETE_SERVICES_LIST_ITEM:
      return {
        ...state,
        services: (() => {
          const id = action.payload
          const newState = state.services.filter((item) => item.id !== id)
          return newState
        })(),
        deletedServices: (() => {
          if (action.payload >= 0) {
            return [...state.deletedServices, action.payload]
          } else return [...state.deletedServices]
        })(),
      }
    case types.CLEAR_DELETED_SERVICES:
      return {
        ...state,
        deletedServices: [],
      }
    default:
      return { ...state }
  }
}
export default servicesReducer
