import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from '../../components/constructor/Sidebar';
import Template from '../../components/constructor/Template';
import { fetchVcardDataToEditor } from '../../redux/constructor/actions';

const StyledConstructor = styled.div`
  display: flex;
  background: #2d3748;
  height: 100vh;
`;

const Constructor = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer);
  let id = localStorage.getItem('vcard_id');

  useEffect(() => {
    if (!vcardDataToEdit) {
      if (id) {
        dispatch(fetchVcardDataToEditor(id));
        return;
      }
      history.replace('/account');
    }
  }, [dispatch, vcardDataToEdit, history, id]);

  return vcardDataToEdit ? (
    <StyledConstructor>
      <Sidebar />
      <Template />
    </StyledConstructor>
  ) : (
    <div></div>
  );
};
export default Constructor;
