import React from 'react'
import styled from 'styled-components'
import Section from '../../kit/Section'
import Title from '../../kit/Title'

const StyledTitle = styled.p`
  && {
    font-size: 1.25rem;
    word-wrap: break-word;
    font-weight: 500;
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
`
const StyledBackground = styled.div`
  background-color: ${(props) => props.bgcolor}80;
  padding: 1rem;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  margin: 0 -0.75rem;
`
const Col = styled.div`
  flex-grow: 1;
  width: ${(props) => (props.ismobile ? '100%' : '50%')};
  padding: 0 0.75rem;
`

const StyledContent = styled.p`
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 1.125rem;
`

const Services = ({ data, mode }) => {
  const isMobile = mode === 'mobile'
  const { services } = data
  return (
    services.length > 0 && (
      <Section>
        <Title textContent={data?.section_title} />
        <StyledBackground bgcolor={data?.banner_group.background_secondary_color ? data?.banner_group.background_secondary_color : data?.background_secondary_color}>
          <Row>
            {services?.map((item) => (
              <Col ismobile={isMobile} key={item?.title}>
                <StyledTitle>{item?.title}</StyledTitle>
                <StyledContent>
                  <div dangerouslySetInnerHTML={{ __html: item?.body }} />
                </StyledContent>
              </Col>
            ))}
          </Row>
        </StyledBackground>
      </Section>
    )
  )
}

export default Services
