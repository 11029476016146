import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { APIMAIN } from '../../../redux/API'
import { setImgPreview, togglePopup } from '../../../redux/gallery/action'
import Section from '../../kit/Section'
import Title from '../../kit/Title'

const Grid = styled.div`
  /* display: grid; */
  /* grid-template-columns: repeat(${(p) =>
    p.ismobile
      ? p.count <= 2
        ? p.count
        : 2
      : p.count <= 4
      ? p.count
      : 4}, minmax(0, 1fr)); */
  /* grid-gap: 1rem; */

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const Wrapper = styled.div`
  width: ${(p) =>
    p.ismobile
      ? `max(calc(100% / ${p.count}), 50%)`
      : `max(min(calc(100% / ${p.count}), 50%), 25%)`};
  position: relative;
  aspect-ratio: 1;
  justify-self: center;
  padding: 0.5rem;
`
const StyledButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: zoom-in !important;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  position: relative;
  img {
    object-fit: cover;
    width: 100%;
  }

  a {
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);

    width: calc(100% - 1rem);
    height: max(50px, 20%);

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    background-color: #00000080;
    text-transform: capitalize;
    font-size: 1.125rem;

    visibility: hidden;
    opacity: 0;
    cursor: auto;
    transition: opacity 0.6s ease;
    cursor: pointer;
  }

  &:hover {
    a:-webkit-any-link {
      opacity: 1;
      visibility: visible;
    }
  }
`

const Gallery = ({ mode, data }) => {
  const { t } = useTranslation();

  const { gallery_images } = data
  const isMobile = mode === 'mobile'
  const dispatch = useDispatch()
  const setImgPreviewHandler = (item) => {
    const link = APIMAIN + item?.filename?.url
    dispatch(setImgPreview(link))
    dispatch(togglePopup(true))
  }

  const imagesCount = gallery_images?.length
  const checkLink = (value) => {
    let https = value.match(/https:+\/\//) || value.match(/http:+\/\//)
    if (https) {
      return value
    } else {
      return 'https://' + value
    }
  }

  return (
    imagesCount > 0 && (
      <Section>
        <Title color={data?.heading_color} textContent={data?.gallery_title} />
        <Grid ismobile={isMobile} count={imagesCount}>
          {gallery_images?.map((item) => (
            <Wrapper ismobile={isMobile} count={imagesCount} key={item?.id}>
              <StyledButton>
                <img
                  onClick={() => setImgPreviewHandler(item)}
                  src={APIMAIN + item?.filename?.url}
                  alt={item?.id}
                />
                {item?.link && (
                  <a
                    target='_blank'
                    rel='noreferrer noopener'
                    href={checkLink(item?.link ?? '')}
                  >
                    {t('viewOnline')}
                  </a>
                )}
              </StyledButton>
            </Wrapper>
          ))}
        </Grid>
      </Section>
    )
  )
}

export default Gallery
