import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import { MainListItems, SecondaryListItems } from './ListItems';
import MyVcards from './MyVcards';
import AccountInfo from './AccountInfo.jsx';
import Settings from './Settings.jsx';
// import Reseller from "./Reseller";
import ReferRequest from './ReferRequest';
import LogOut from '../../components/account/LogOut.jsx';
import Logo from '../../assets/images/MainLogo';
import { useTranslation } from 'react-i18next';
import CorporateAccount from './CorporateAccount';
// import { useSelector } from 'react-redux';
import { getLoggedInUser } from '../../utils/helpers';
import API, { APIMAIN } from '../../redux/API';
import axios from 'axios';
import CompanyList from './company/Companies';
import ReferUserList from './referUserList';
import CustomerList from './customer';
import Weblink from './Weblink';
import MenuVideo from './MenuVideo';

function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t('copyrightText')}
      <Link color="inherit" href={window.location.href}>
        {t('copywrite')}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '100%',
  },
}));

export default function Dashboard() {
  const { t } = useTranslation();

  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);
  const [page, setPage] = useState('myvcards');
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const open = true;

  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = React.useState({ ...user });

  useEffect(() => {
    if (loggedUser?.user_type === 'content_admin') {
      setPage('companies');
    }
  }, [loggedUser]);

  useEffect(() => {
    if (loggedUser?.user_type === 'super_admin') {
      setPage('customer');
    }
  }, [loggedUser]);

  function getPage(page) {
    switch (page) {
      case 'accountInfo':
        return <AccountInfo />;
      case 'myvcards':
        return <MyVcards />;
      case 'settings':
        return <Settings />;
      // case "reseller":
      //   return <Reseller />;
      case 'corporateAccount':
        return <CorporateAccount />;
      case 'companies':
        return <CompanyList />;
      case 'customer':
        return <CustomerList />;
      case 'link':
        return <Weblink />;
      case 'referUserList':
        return <ReferUserList />;
      case 'MenuVideo':
        return <MenuVideo />;
      case 'referRequest':
        return <ReferRequest />;
      default:
        return <AccountInfo />;
    }
  }

  const logoUrl = API + `/companies/${loggedUser?.company_id}`;
  const [logo, setLogo] = useState(null);

  const getLogo = async () => {
    const res = await axios.get(logoUrl, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });

    return res.data;
  };

  useEffect(() => {
    getLogo().then((res) => {
      setLogo(APIMAIN + res.logo.url);
    });
  }, []);

  return (
    <div className={classes.root}>
      <script id="sbinit" src="https://talkingvcard.support/supportboard/js/main.js"></script>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {t('dashboard')}
          </Typography>
          <IconButton color="inherit">
            {/* <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge> */}
          </IconButton>
          <LogOut />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          {loggedUser?.user_type === ('admin' || 'distributor') && (
          <>          
          <img
            src={logo}
            alt="logo"
            style={{ width: '100%', height: '90px', objectFit: 'contain' }}
          />
          </>
          )}
          {(loggedUser?.user_type === null || loggedUser?.user_type === 'sales') && (
            <>        
            <Logo />
            </>
          )}
        </div>

        <Divider />
        <List>
          <MainListItems setPage={setPage} />
        </List>
        <Divider />
        <List>
          <SecondaryListItems />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>{getPage(page)}</Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
