import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DefaultTextArea from '../kit/DefaultTextArea';
import DefaultTextField from '../kit/DefaultTextField';

export default function AboutCompany({ distributorId }) {
  const { t } = useTranslation();

  const { formData } = useSelector((state) => state.Request);
  const [mainData, setMainData] = useState({});

  function handleContentChange(event, label) {
    let newValue = event.target.value;
    let newStore = { ...mainData };
    newStore[label] = newValue;
    formData.set(label, newValue);
    setMainData(newStore);
  }

  return (
    <Form style={{ width: '100%' }}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        {distributorId.length > 0 ? t('aboutYou') : t('aboutYourCompany')}
      </div>
      <DefaultTextField
        label={distributorId.length > 0 ? t('aboutme') : t('aboutmeCompany')}
        value={mainData?.about_title ?? formData.get('about_title')}
        onChange={(e) => handleContentChange(e, 'about_title')}
      />
      <DefaultTextArea
        label={t('aboutSectionContent')}
        value={mainData?.about_content ?? formData.get('about_content')}
        onChange={(e) => handleContentChange(e, 'about_content')}
      />
    </Form>
  );
}
