import * as types from './constans';

const INIT_STATE = {
  vcardDataToEdit: null,
  formData: new FormData(),
  loading: false,
  error: null,
  previewDisplay: 'mobile' || 'display', // should be 'mobile' or 'display'
  updatePreviewTemplate: 0,
  isEdited: false,
  component: '',
  anotherServicesErrors: [],
  qrCode: false,
  webVideoUrl: '',
  phone_number: '',
  //  <- Ver 2.0
  colorScheme: {
    primary: '#d31212',
    headings: '#aaa',
    body: '#afa',
    buttonText: '#dca',
    backgroundPrimary: '#dfa',
    backgroundSecondary: '#faa',
    backgroundPage: '#faf',
  },

  gallery: {
    title: 'Gallery',
    images: [],
  },
  settings: {
    contactFormTitle: 'Send me message',
    displayContactForm: true,
    domain: '',
    facebookId: '',
    googleId: '',
    metaData: '',
    pageTitle: '',
    receiveEmail: '',
  },
};

const Constructor = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.SET_COMPONENT_EDITOR:
      return { ...state, component: action.payload };
    // cases for fetch
    case types.UPDATE_DATA:
      return { ...state, loading: true };
    case types.UPDATE_DATA_SUCCES:
      return { ...state, loading: false, error: null };
    case types.UPDATE_DATA_FAILED:
      return { ...state, loading: false, error: action.payload };

    // socialLinks->
    case types.SET_PHONE_NUMBER:
      return { ...state, phone_number: action.payload };

    // <- socialLinks

    // cases for services
    case types.SET_ERROR_ANOTHER_SERVICES:
      return { ...state, anotherServicesErrors: action.payload };

    // Record Video
    case types.SET_WEBVIDEOURL:
      return { ...state, webVideoUrl: action.payload };
    // << Record Video
    case types.SET_PREVIEWDISPLAY:
      return { ...state, previewDisplay: action.payload };
    case types.GO_TO_EDIT_VCARD_DATA:
      return { ...state, vcardDataToEdit: action.payload };
    case types.SAVE_CONTENT_TO_SEND:
      return { ...state, contentToSend: action.payload };
    case types.SET_QR_CODE:
      return { ...state, qrCode: action.payload };
    case types.UPDATE_VCARD_DATA:
      return { ...state, vcardDataToEdit: action.payload };
    case types.UPDATE_VCARD_TRANSFER_DATA:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};

export default Constructor;
