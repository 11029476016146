import * as types from './types'

const initState = {
  videoLibrary: [],
  deletedVideos: [],
}

const videoLibraryReducer = (state = initState, action) => {
  switch (action.type) {
    case types.ADD_VIDEO_LINK:
      return { ...state, videoLibrary: [...state.videoLibrary, action.string] }
    case types.UPDATE_VIDEO_LINK:
      return {
        ...state,
        videoLibrary: (() => {
          const data = action.data
          const newState = state.videoLibrary.map((item) =>
            item.id === data.id ? data : item
          )
          return newState
        })(),
      }
    case types.DELETE_VIDEO_LINK:
      return {
        ...state,
        videoLibrary: (() => {
          const id = action.payload
          const newState = state.videoLibrary.filter((item) => item.id !== id)
          return newState
        })(),
        deletedVideos: (() => {
          if (action.payload >= 0) {
            return [...state.deletedVideos, action.payload]
          } else return [...state.deletedVideos]
        })(),
      }
    case types.UPDATES_VIDEOS:
      return { ...state, videoLibrary: action.data }
    case types.CLEAR_DELETED_VIDEOS:
      return { ...state, deletedVideos: [] }
    default:
      return { ...state }
  }
}
export default videoLibraryReducer
