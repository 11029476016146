import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LinkM from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { fetchChangePassword } from '../../../redux/auth/actions';
import { useTranslation } from 'react-i18next';
// import { t } from 'i18next';

function Copyright() {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t('copyrightText')}
      <LinkM color="inherit" href={window.location.href}>
        {t('copywrite')}
      </LinkM>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  succes: {
    margin: theme.spacing(1),
    backgroundColor: 'green',
  },
  div: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassword(props) {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();

  let { loading, login, error } = useSelector((state) => state.Authorization);
  const [userData, setUserData] = useState({
    password: '',
    password_confirmation: '',
    reset_password_token: '',
  });

  function handleChangeUserData(e) {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  }

  function sendForgotPassword() {
    if (!userData) return;
    dispatch(
      fetchChangePassword({
        ...userData,
        reset_password_token: props?.match.params.code,
      })
    );
  }

  const minPassword = 0;

  function checkCurrentPassword(e) {
    let a = userData?.password;
    let b = userData?.password_confirmation;

    return a === b && a.length > minPassword && b.length > minPassword;
  }

  let checkPass = useMemo(checkCurrentPassword, [
    userData?.password_confirmation,
    userData?.password,
  ]);

  useEffect(() => {
    if (error?.status === 200) {
      window.location.href = '/#/sign-in';
    }
  }, [error?.status]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={login ? classes.succes : classes.avatar}>
          {loading ? (
            <CircularProgress />
          ) : login ? (
            <LockOpenIcon />
          ) : (
            <LockOutlinedIcon />
          )}
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('passwordReset')}
        </Typography>
        <div className={classes.div} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            // error={!checkPass}
            // helperText={!checkPass?"Password"}
            onChange={handleChangeUserData}
            name={t('password')}
            label={t('password')}
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            // error={!checkPass}
            // helperText={!checkPass ? "Password mismatch" : ""}
            onChange={handleChangeUserData}
            name={t('confirmPassword')}
            label={t('confirmPassword')}
            type="password"
            id="password_confirmation"
            autoComplete="confirm-password"
          />
          <p className="error-text">
            {!checkPass && userData.password_confirmation.length > minPassword
              ? t('passwordDontMatch')
              : userData.password_confirmation.length > 5
              ? ' '
              : ' '}
          </p>
          {error === 'error' ? (
            <p className="error-text">{t('linkNotValid')}</p>
          ) : (
            ''
          )}

          <Button
            onClick={sendForgotPassword}
            fullWidth
            variant="contained"
            color="primary"
            disabled={!checkPass}
            className={classes.submit}
          >
            {t('requestReset')}
          </Button>
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}