import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.1);
`

const Wrapper = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>
}

export default Wrapper
