import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { togglePopup } from '../../../redux/gallery/action'
import { StyledBookingForm, Wrapper } from './BookingForm'

const StyledGalleryImgPreview = styled(StyledBookingForm)``
const StyledWrapper = styled(Wrapper)`
  padding: 0;
  background-color: black;
  button {
    outline: none;
    border: none;
    user-select: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #006edc;
    border-radius: 50%;
    padding: 0.5rem;
    color: white;
  }
  img {
    width: 100%;
    object-fit: contain;
    vertical-align: middle;
    border-radius: 5px;
  }
`

const GalleryImgPreview = ({ imgPreview }) => {
  const dispatch = useDispatch()
  const togglePopupHandler = () => {
    dispatch(togglePopup(false))
  }
  return (
    <StyledGalleryImgPreview>
      <StyledWrapper>
        <button onClick={togglePopupHandler}>
          <CloseIcon />
        </button>
        <img src={imgPreview} alt='gallery-img' />
      </StyledWrapper>
    </StyledGalleryImgPreview>
  )
}

export default GalleryImgPreview
