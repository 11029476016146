// import DeleteIcon from '@mui/icons-material/Delete'
// import Button from '@mui/material/Button'
// import axios from 'axios'
import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import GoogleLogin from 'react-google-login'
// import { useReactMediaRecorder } from 'react-media-recorder'
import { useDispatch, useSelector } from 'react-redux'
// import styled from 'styled-components'
// import API from '../../../../redux/API'
import { fetchContentJson } from '../../../../redux/request/actions'
// import { getLoggedInUser } from '../../../../utils/helpers'
import DefaultTextField from '../../../kit/DefaultTextField'
import SaveBtn from '../../../kit/SaveBtn'
import Header from '../Header'
// import Alert from '../../../../utils/alert'
// import OAuth2Login from 'react-simple-oauth2-login'
// import VimeoIcon from '../../../../assets/icons/VimeoIcon'

// const StyledRecordVideo = styled.div`
//   h4 {
//     margin-top: 0;
//     font-size: 1.125rem;
//     color: inherit;
//     font-weight: 400;
//   }
//   video {
//     margin-top: 1rem;
//   }
// `

// const StyledControlBtns = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   margin-top: 1rem;
// `
// const StyledVimeoLoginButton = styled.div`
//   display: flex;
//   align-items: center;
//   button {
//     background-color: rgb(255, 255, 255);
//     display: inline-flex;
//     align-items: center;
//     color: rgba(0, 0, 0, 0.54);
//     box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
//     padding: 0px;
//     border-radius: 2px;
//     border: 1px solid transparent;
//     font-size: 14px;
//     font-weight: 500;
//     font-family: 'Roboto', sans-serif;
//     outline: none;
//     &:hover {
//       opacity: 0.9;
//     }
//   }
//   svg {
//     padding: 10px;
//   }
//   span {
//     padding: 10px 10px 10px 0;
//   }
// `
// const VideoPreview = ({ stream }) => {
//   const videoRef = useRef(null)

//   useEffect(() => {
//     if (videoRef.current && stream) {
//       videoRef.current.srcObject = stream
//     }
//   }, [stream])

//   if (!stream) {
//     return null
//   }

//   return <video ref={videoRef} width={'100%'} autoPlay controls />
// }

// const VimeoLoginButton = () => {
//   return (
//     <StyledVimeoLoginButton>
//       <VimeoIcon />
//       <span>Login</span>
//     </StyledVimeoLoginButton>
//   )
// }

const GreetingVideo = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { vcardDataToEdit, formData } = useSelector((state) => state.ConstructorReducer)
  const { id } = vcardDataToEdit
  const videoLinkFromServer = vcardDataToEdit?.video_link

  const [video_link, setVideo_link] = useState(videoLinkFromServer)

  const inputHandler = (e) => {
    const value = e.target.value
    const id = e.target.id
    formData.set(id, value)
    setVideo_link(value)
  }

  const isVideoUrlSame = video_link === videoLinkFromServer

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isVideoUrlSame) {
      dispatch(fetchContentJson(id, { video_link }))
    }
  }

  // let user = getLoggedInUser()

  // const [mediaUrl, setMediaUrl] = useState(null)

  // const [accessToken, setAccessToken] = useState(null)

  // const {
  //   status,
  //   previewStream,
  //   startRecording,
  //   stopRecording,
  //   mediaBlobUrl,
  //   clearBlobUrl,
  //   pauseRecording,
  //   resumeRecording,
  // } = useReactMediaRecorder({ video: true })

  // const responseGoogle = (response) => setAccessToken(response.accessToken)

  // const listStatusFoo = () => {
  //   switch (status) {
  //     case 'idle':
  //       return 'Waiting for record...'
  //     case 'acquiring_media':
  //       return 'Loading...'
  //     case 'recording':
  //       return 'Recording...'
  //     case 'stopped':
  //       return 'Ready to upload'
  //     default:
  //       return ''
  //   }
  // }

  // useEffect(() => {
  //   setMediaUrl(mediaBlobUrl)
  // }, [mediaBlobUrl])

  // const uploadVideoHandler = () => {
  //   fetch(mediaUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       let formData = new FormData()
  //       let file = new File([blob], 'test', { type: 'video/mp4' })
  //       formData.append('title', 'TalkingVcard Greeting Video')
  //       formData.append('file', file)
  //       formData.append('description', 'TalkingVcard Greeting Video')
  //       formData.append('access_token', accessToken)
  //       for (var key of formData.entries()) {
  //         console.log(key)
  //       }
  //       axios({
  //         method: 'post',
  //         url: `${API}/vcards/${id}/upload_youtube_video`,
  //         data: formData,
  //         headers: {
  //           Authorization: `Bearer ${user.auth.token}`,
  //           'content-Type': 'multipart/form-data',
  //         },
  //       })
  //         .then((data) => console.log(data))
  //         .catch((err) => Alert('Error. Try later', 'error'))
  //     })
  //     .catch((err) => console.log(err))
  // }

  // const responseVimeoOnSuccess = (response) => {
  //   setAccessToken(response.access_token)
  //   console.log(response)
  // }
  // const responseVimeoOnFailure = (response) => console.error(response)

  // const listStatus = listStatusFoo()
  return (
    <>
      {/* <StyledRecordVideo>
        <h4>{listStatus}</h4>
        <StyledControlBtns>
          <Button variant='contained' color='success' onClick={startRecording}>
            Start
          </Button>
          <Button variant='contained' color='primary' onClick={pauseRecording}>
            Pause
          </Button>
          <Button variant='contained' color='primary' onClick={resumeRecording}>
            Resume
          </Button>
          <Button variant='contained' color='error' onClick={stopRecording}>
            Stop
          </Button>
        </StyledControlBtns>
        {status !== 'idle' && (
          <>
            {status === 'recording' ? (
              <VideoPreview stream={previewStream} controls />
            ) : (
              <video src={mediaBlobUrl} controls loop width={'100%'} />
            )}
            <Button startIcon={<DeleteIcon />} variant='contained' onClick={clearBlobUrl}>
              delete
            </Button>
          </>
        )}
        <StyledControlBtns>
          <GoogleLogin
            clientId='570934385380-m5lj9t55h0fab0k3cs0t1vltl44ituec.apps.googleusercontent.com'
            buttonText='Login'
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            scope='email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube.readonly'
          />
          <StyledVimeoLoginButton>
            <OAuth2Login
              children={<VimeoLoginButton />}
              authorizationUrl='https://api.vimeo.com/oauth/authorize'
              responseType='token'
              // clientId='0d5031b825c7f82ea0fa941f606f7dc7532f64e5'
              clientId='a68749f2daaa2ba3204e4092bca8450536a528cd'
              redirectUri='http://localhost:3000/constructor'
              onSuccess={responseVimeoOnSuccess}
              onFailure={responseVimeoOnFailure}
              scope='private public upload'
              // extraParams={{ access_token: '6daf0a0b3d076263859797aa870dad13', token_type: 'bearer', scope: 'upload' }}
            />
          </StyledVimeoLoginButton>
          <Button
            type='button'
            disabled={!accessToken}
            variant='contained'
            onClick={uploadVideoHandler}
          >
            upload
          </Button>
        </StyledControlBtns>
      </StyledRecordVideo> */}

      <form onSubmit={saveHandler}>
        <Header disabled={isVideoUrlSame} />
        <DefaultTextField
          value={video_link || ''}
          onChange={inputHandler}
          label={t('video')}
          subtitle={t('videoSubtitle')}
        />
        <SaveBtn type='submit' disabled={isVideoUrlSame} />
      </form>
    </>
  )
}

export default GreetingVideo
