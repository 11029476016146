import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React from 'react';
import styled from 'styled-components';
import { STYLES } from '../../../styles';

export const StyledDeleteBtn = styled.button`
  outline: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  font-size: 0.875rem;
  border-radius: 0.25rem;
  padding: 0.25rem;
  color: ${STYLES.COLORS.warning};
  cursor: pointer;
  &:hover {
    background-color: ${STYLES.COLORS.warning};
    color: #fff;
  }
`;

const DeleteLogoBtn = ({ setState, id, type }) => {
  const deleteHandler = () => {
    setState(null);
    if (type === 'logo') {
      return;
    }
  };
  return (
    <StyledDeleteBtn onClick={deleteHandler}>
      <DeleteForeverIcon />
    </StyledDeleteBtn>
  );
};

export default DeleteLogoBtn;
