import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import FacebookIcon from '../../../assets/icons/FacebookIcon';
import MessengerIcon from '../../../assets/icons/MessengerIcon';
import QrCodeIcon from '../../../assets/icons/QrCodeIcon';
import SmsIcon from '../../../assets/icons/SmsIcon';
import TwitterIcon from '../../../assets/icons/TwitterIcon';
import WhatsappIcon from '../../../assets/icons/WhatsappIcon';
import Section from '../../kit/Section';
import Title from '../../kit/Title';

const StyledLinksGroup = styled.ul`
  display: flex;
  justify-content: center;
  padding: 1rem 0rem 0rem 0rem;
  margin: 0rem;
  column-gap: 0.5rem;
  li {
    display: flex;
    width: 40px;
    height: 40px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  button {
    border: none;
    outline: none;
    background-color: #444;
    border-radius: 50%;
    padding: 5px;
    width: 100%;
    height: 100%;
    svg {
      width: 25px;
    }
  }
`;

const Link = ({ href, children }) => {
  return (
    <li>
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    </li>
  );
};

const ShareMyCard = ({ setQrCode, data }) => {
  console.log('qrdata:', data);
  const domain = `${data?.domain_prefix}.talkingvcard.net`;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const setQrCodeHandler = () => dispatch(setQrCode(true));
  return (
    <Section>
      <Title textContent={t('shareMyCard')} />
      <StyledLinksGroup>
        <Link
          href={`whatsapp://send?text=${data?.title}%20https%3A%2F%2F${domain}`}
          children={<WhatsappIcon />}
        />
        <Link
          href={`sms:?&body=${data?.title}%20https%3A%2F%2F${domain}`}
          children={<SmsIcon />}
        />
        <Link
          href={`fb-messenger://share/?link=https://${domain}`}
          children={<MessengerIcon />}
        />
        <Link
          href={`https://www.facebook.com/sharer/sharer.php?u=https://${domain}`}
          children={<FacebookIcon />}
        />
        <Link
          href={`https://twitter.com/intent/tweet?text=${data?.title}%20https%3A%2F%2F${domain}`}
          children={<TwitterIcon />}
        />
        <li>
          <button onClick={setQrCodeHandler}>
            <QrCodeIcon />
          </button>
        </li>
      </StyledLinksGroup>
    </Section>
  );
};

export default ShareMyCard;
