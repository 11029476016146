import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import API from '../../../redux/API'
import DefaultButton from '../../kit/DefaultButton'
import DefaultTextArea from '../../kit/DefaultTextArea'
import DefaultTextField from '../../kit/DefaultTextField'
import Title from '../../kit/Title'

const Wrapper = styled.section`
  background-color: ${(props) => props.bgcolor};
  padding: 2rem;
  margin-top: 0rem;
`

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 500px;
  p {
    font-weight: 500;
    text-align: center;
    margin-bottom: 1rem;
  }
  button {
    margin-top: 2rem;
  }
  label {
    color:${(data) => data.color};
  }
`
const StyledSuccessMessage = styled.div`
  color: #276749;
  font-size: 1rem;
  text-align: center;
  margin-top: 0rem;
  height: 36px;
  font-weight: 500;
`
const ContactForm = ({ data }) => {
  const { t } = useTranslation();

  const initState = {
    name: '',
    subject: '',
    email: '',
    message: '',
  }
  const [postData, setPostData] = useState(initState)
  const [sentStatus, setSentStatus] = useState(false)

  function handleChangeUserData(e) {
    let value = e.target.value
    let id = e.target.id
    setPostData({ ...postData, [id]: value })
  }

  const submitFormHandler = (e) => {
    e.preventDefault()
    axios
      .post(API + `/vcards/${data.id}/contact_form`, postData, {
        headers: {
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        setSentStatus(data)
        setPostData(initState)
      })
      .catch((err) => console.warn('submitFormHandler', err))
  }
  return (
    data?.['display_contact_form?'] && (
      <Wrapper bgcolor={data?.banner_group.background_secondary_color ? data?.banner_group.background_secondary_color : data?.background_secondary_color}>
        <InnerWrapper color={data?.banner_group.body_color ? data?.banner_group.body_color : data?.body_color}>
          <Title textContent={data?.contact_form_title} />
          <p>{t('feelFreeContactMe')}</p>
          <form onSubmit={submitFormHandler}>
            <DefaultTextField
              id='name'
              label={t('name')}
              onChange={handleChangeUserData}
              required
              value={postData?.name}
            />
            <DefaultTextField
              id='subject'
              label={t('subject')}
              onChange={handleChangeUserData}
              required
              value={postData?.subject}
            />
            <DefaultTextField
              id='email'
              label={t('email')}
              type='email'
              onChange={handleChangeUserData}
              required
              value={postData?.email}
            />
            <DefaultTextArea
              id='message'
              label={t('yourMessage')}
              onChange={handleChangeUserData}
              required
              value={postData?.message}
            />
            {sentStatus?.success ? (
              <StyledSuccessMessage>{sentStatus?.message}</StyledSuccessMessage>
            ) : (
              <DefaultButton
                type='submit'
                data={data}
                textContent={t('sendMsg')}
              />
            )}
          </form>
        </InnerWrapper>
      </Wrapper>
    )
  )
}

export default ContactForm
