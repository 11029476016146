import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Section from '../../kit/Section';
import Title from '../../kit/Title';
import { getLoggedInUser } from '../../../utils/helpers';

const StyledAboutContent = styled.div`
  color: ${(props) => props.color};
  display: flex;
  flex-direction: column;

  #html_editor {
    width: 100%;
    display: flex;
    flex-direction: column;

    margin: 10px 10px !important;
  }
`;

const About = ({ data }) => {
  const vcardDataToEdit = data;
  const title = data?.about_title?.length > 0;
  const content = vcardDataToEdit?.html_editor?.length > 0;

  // wrap all iframes in vcardDataToEdit?.about_content with a div
  useEffect(() => {
    const p = document.querySelector('#html_editor p');
    if (p) {
      Object.assign(p.style, { width: '100%' });

      const iframes = document.querySelectorAll('#html_editor iframe');
      if (iframes) {
        iframes.forEach((iframe) => {
          Object.assign(iframe, {
            width: '100%',
            height: '100%',
          });
          const wrapper = document.createElement('div');
          wrapper.classList.add('iframe-wrapper');
          Object.assign(wrapper.style, {
            overflow: 'hidden',
            width: '100%',
            marginTop: '15px',
            marginBottom: '15px',
          });
          iframe.parentNode.insertBefore(wrapper, iframe);
          wrapper.appendChild(iframe);
        });
      }
    }
  }, [vcardDataToEdit]);

  return (
    (content) && (
      <Section>
        {vcardDataToEdit?.body_color && (
          <StyledAboutContent color={vcardDataToEdit?.body_color}>
            <div
              id="html_editor"
              dangerouslySetInnerHTML={{
                __html: vcardDataToEdit?.html_editor,
              }}
            ></div>
          </StyledAboutContent>
        )}
      </Section>
    )
  );
};

export default About;
