import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DefaultTextField from '../../components/kit/DefaultTextField'
import { updatePassword, updatePasswordData } from '../../redux/request/actions'
import Title from './Title'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}))

export default function Settings() {
  const { t } = useTranslation()

  const { passwordChange } = useSelector((state) => {
    return state.Request
  })
  const classes = useStyles()
  const defaultData = {
    currentPassword: '',
    password: '',
    password_confirmation: '',
  }
  const [settingsData, setSettingsData] = useState(defaultData)
  const dispatch = useDispatch()

  function handleChangeUserData(e) {
    setSettingsData({ ...settingsData, [e.target.id]: e.target.value })
  }

  function checkCurrentPassword(e) {
    let a = settingsData?.password.trim()
    let b = settingsData?.password_confirmation.trim()

    return (
      a === b &&
      a.length > 0 &&
      b.length > 0 &&
      settingsData.currentPassword.length > 0
    )
  }

  let checkPass = useMemo(checkCurrentPassword, [
    settingsData?.password_confirmation,
    settingsData.currentPassword.length,
    settingsData?.password,
  ])

  useEffect(() => {
    dispatch(updatePassword(''))
  }, [dispatch])

  const onSumbit = (e) => {
    e.preventDefault()
    dispatch(updatePasswordData(settingsData))
  }

  return (
    <div style={{ maxWidth: 370 }}>
      <Title>{t('accountSettings')}</Title>
      <form className={classes.div} noValidate onSubmit={onSumbit}>
        <DefaultTextField
          label={t('currentPassword')}
          id='currentPassword'
          onChange={handleChangeUserData}
        />
        <DefaultTextField
          label={t('newPassword')}
          id='password'
          onChange={handleChangeUserData}
        />
        <DefaultTextField
          label={t('confirmPassword')}
          id='password_confirmation'
          onChange={handleChangeUserData}
        />
        <p className='error-text'>
          {!checkPass && settingsData.password_confirmation.length > 0
            ? t('passwordDontMatch')
            : settingsData.password_confirmation.length > 5
            ? ' '
            : ' '}
        </p>
        {passwordChange && (
          <p style={{ color: passwordChange.success ? 'green' : 'red' }}>
            {passwordChange.message || passwordChange.error}
          </p>
        )}
        <Grid item xs style={{ margin: '5px 0' }}>
          <Link to='/forgot-password' variant='body2'>
            {t('forgotPassword')}
          </Link>
        </Grid>
      </form>

      <Button
        fullWidth
        // type="submit"
        onClick={onSumbit}
        disabled={!checkPass}
        variant='contained'
        color='primary'
        className={classes.submit}
      >
        {t('saveAccountSettings')}
      </Button>
    </div>
  )
}
