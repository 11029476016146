import * as types from './types'

export const setPreloader = (bool) => ({
  type: types.SET_PRELOADER,
  bool,
})
export const setSuccessMessage = (string) => ({
  type: types.SET_SUCCESS_MESSAGE,
  string,
})
export const resetSuccessMessage = () => ({
  type: types.RESET_MESSAGE,
})
