import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getLoggedInUser } from '../../../utils/helpers';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Legacy from './Legacy';
import { useDispatch, useSelector } from 'react-redux';
import API, { APIMAIN } from '../../../redux/API';
import { Link } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MenuBar() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const menu = ['Legacy'];

  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = React.useState({ ...user });
  const [allUsers, setAllUsers] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${API}/menu_videos`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        setAllUsers(res.data);
      });
  }, []);
  console.log(allUsers);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {menu.map((item, index) => (
            <Tab
              style={{ marginRight: 10 }}
              label={item}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Legacy allUsers={allUsers} />
      </TabPanel>
      {/*<TabPanel value={value} index={1}>
        <SaaS allUsers={allUsers} />
      </TabPanel>*/}
    </Box>
  );
}
