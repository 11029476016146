import axios from 'axios'
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styled from 'styled-components'

const StyledPhoneMask = styled.div`
  && input {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 3.25rem;
    border-radius: 4px;
    font-size: 1.125rem;
    background-color: white;
    border: none;
    outline: none;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%),
      0 0 0 0.025rem #bdbdbd;
    height: auto;
    line-height: 1.5;
    font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif !important;
  }
  && .flag-dropdown {
    background-color: #fff;
    border: none;
    border-right: 1px solid #cacaca;
    & .selected-flag {
      padding-left: 13px;
      width: 45px;
    }
  }
`

export default function PhoneNumber({
  onChange,
  value,
  phoneNumberFromServer,
}) {
  const [countryCode, setCountryCode] = useState('')
  useEffect(() => {
    if (!phoneNumberFromServer) {
      axios({
        method: 'get',
        url: 'https://ipapi.co/json/',
      })
        .then(({ data }) => setCountryCode(data.country))
        .catch((err) => console.warn(err))
    }
  }, [phoneNumberFromServer])

  return (
    <StyledPhoneMask>
      <PhoneInput
        country={countryCode.toLowerCase()}
        value={value}
        onChange={(e) => onChange(e)}
      />
    </StyledPhoneMask>
  )
}
