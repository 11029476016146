import { faSms } from '@fortawesome/free-solid-svg-icons';
import { faPeopleArrows, faTty } from '@fortawesome/free-solid-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import checkLink from '../../../utils/validSocialLinks'

const StyledSocialLink = styled.section`
  padding: 0.5rem 2rem;
  margin-top: 0rem;
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    padding: 0;
    margin-bottom: 0;
  }
  li {
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5px 8px;
  }
  a {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.325rem;
    text-decoration: none;
    color: ${(p) => p.icon};
    &:hover {
      color: ${(p) => p.icon};
      box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
    }
  }
  p {
    margin: 0;
  }
`
const CustomLink = ({ href, children, target = '_blank', text, data }) => {
  return (
    <li>
      <a 
        target={target} 
        rel='noreferrer noopener' 
        href={href} 
        style={{'font-size': '20px','font-weight': '800',background:(data?.user_type === null) || (data?.user_type === 'sales') ? data?.primary_color : data?.banner_group.primary_color}}>
        {children}
      </a>
      <p>{text}</p>
    </li>
  )
}

export default function PhoneSocialLinks({ data }) {
  const { t } = useTranslation();

  let phone_number = data?.phone_number

  return (
    <StyledSocialLink
      background={(data?.user_type === null) || (data?.user_type === 'sales') ? data?.primary_color : data?.banner_group.primary_color}
      icon={(data?.user_type === null) || (data?.user_type === 'sales') ? data?.button_text_color : data?.banner_group?.button_text_color}
    >
      <ul>
        {data?.['display_call_me?'] && (
          <CustomLink
            target='_self'
            href={`tel:+${phone_number?.toString()}`}
            text={t('callMe')}
            children={<i className='fa fa-phone' />}
            data={data}
          />
        )}
        {data?.phone_office && (
          <CustomLink
            target='_self'
            href={`tel:+${data?.phone_office?.toString()}`}
            text={t('Office')}
            children={<FontAwesomeIcon icon={faTty} />}
            data={data}
          />
        )}
        {data?.['display_text_me?'] && (
          <CustomLink
            target='_self'
            href={`sms:+${phone_number}`}
            text={t('textMe')}
            children={<FontAwesomeIcon icon={faSms} />}
            data={data}
          />
        )}
        {data?.['display_email?'] && (
          <CustomLink
            href={`mailto:${data.user_email}`}
            text={t('email')}
            children={<i className='fa fa-envelope' />}
            data={data}
          />
        )}
        {data?.['display_whatsapp?'] && (
          <CustomLink
            href={`https://wa.me/+${phone_number?.toString()}`}
            text={t('whatsApp')}
            children={
              <i style={{ fontSize: '30px' }} className='fa fa-whatsapp' />
            }
            data={data}
          />
        )}
        {data?.facebook_link && (
          <CustomLink
            href={checkLink('facebook_link', data?.facebook_link)}
            title={t('followMeOnFacebook')}
            children={<i className='fa fa-facebook' />}
            text={t('facebook')}
            data={data}
          />
        )}

        {data?.facebook_business_page && (
          <CustomLink
            href={checkLink('facebook_business_page', data?.facebook_business_page)}
            title={t('followMeOnFacebook')}
            children={<i className='fa fa-facebook' />}
            text={t('page')}
            data={data}
          />
        )}

        {data?.facebook_business_group && (
          <CustomLink
            href={checkLink('facebook_business_group', data?.facebook_business_group)}
            title={t('followMeOnFacebook')}
            children={<i className='fa fa-facebook' />}
            text={t('group')}
            data={data}
          />
        )}

        {data?.instagram_link && (
          <CustomLink
            href={checkLink('instagram_link', data?.instagram_link)}
            title={t('followMeOnInstagram')}
            children={<i className='fa fa-instagram' />}
            text={t('instagram')}
            data={data}
          />
        )}

        {data?.linkedin_link && (
          <CustomLink
            href={checkLink('linkedin_link', data?.linkedin_link)}
            title={t('followMeOnLinkedIn')}
            children={<i className='fa fa-linkedin' />}
            text={t('linkedIn')}
            data={data}
          />
        )}

        {data?.linkedin_company && (
          <CustomLink
            href={checkLink('linkedIn_company', data?.linkedin_company)}
            title={t('linkedin ompany')}
            children="Lin"
            text={t('linkedIn')}
            data={data}
          />
        )}

        {data?.social_link_alignable && (
          <CustomLink
            href={checkLink('social_link_alignable', data?.social_link_alignable)}
            title={t('linkedin ompany')}
            children="A"
            text={t('alignable')}
            data={data}
          />
        )}

        {data?.connect && (
          <CustomLink
            href={checkLink('connect_link', data?.connect)}
            title={t('Connect')}
            children={<FontAwesomeIcon icon={faPeopleArrows} />}
            text={t('Connect')}
            data={data}
          />
        )}
        {data?.tiktok_link && (
          <CustomLink
            href={checkLink('tiktok_link', data?.tiktok_link)}
            children={<FontAwesomeIcon icon={faTiktok} />}
            text={t('tiktok')}
            data={data}
          />
        )}
        {data?.twitter_link && (
          <CustomLink
            href={checkLink('twitter_link', data?.twitter_link)}
            title={t('followMeOnTwiter')}
            children={<i className='fa fa-twitter' />}
            text={t('twitter')}
            data={data}
          />
        )}
        {data?.web_link && (
          <CustomLink
            href={checkLink('web_link', data?.web_link)}
            children={<i className='fa fa-globe' />}
            text={t('webSite')}
            data={data}
          />
        )}
        {data?.cashapp_link && (
          <CustomLink
            href={checkLink('cashapp_link', data?.cashapp_link)}
            title={t('followMeOnCashApp')}
            children={<i className='fas fa-cashapp'>$</i>}
            text={t('cashApp')}
            data={data}
          />
        )}
        {data?.paypal_link && (
          <CustomLink
            href={checkLink('paypal_link', data?.paypal_link)}
            title={t('followMeOnPaypal')}
            children={<i className='fa fa-paypal' />}
            text={t('paypal')}
            data={data}
          />
        )}

        {data?.social_networks?.map((item) => (
          <CustomLink
            key={item.title + 'socialLinksTemplate'}
            href={checkLink('web_link', item.title)}
            children={<i className='fa fa-link' />}
            text={item.icon}
            data={data}
          />
        ))}
      </ul>
    </StyledSocialLink>
  )
}
