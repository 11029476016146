import axios from 'axios'
import { getLoggedInUser } from '../../utils/helpers'
import { sendContentSuccess } from '../request/actions'
import * as types from './constants';
import API from './../API'
import { fetchAccountVcardsAll } from '../account/actions'


export const updateData = (payload) => {
  return {
    type: types.UPDATE_DATA,
    payload,
  }
}
export const updateDataSuccess = (payload) => {
  return {
    type: types.UPDATE_DATA_SUCCES,
    payload,
  }
}
export const updateDataFailed = (payload) => {
  return {
    type: types.UPDATE_DATA_FAILED,
    payload,
  }
}

export const setMainContent = (payload) => {
  return {
    type: types.SET_MAINCONTENT,
    payload,
  }
}

export const setPreviewDisplay = (payload) => {
  return {
    type: types.SET_PREVIEWDISPLAY,
    payload,
  }
}

export const setColorScheme = (id, data) => {
  let user = getLoggedInUser()
  return (dispatch) => {
    axios
      .patch(API + `/vcards/${id}`, data, {
        headers: {
          Authorization: 'Bearer ' + user.auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        // if (data.message.match(/domain is not/)) {
        //     dispatch(errorByDomain(true))
        // } else dispatch(errorByDomain(false))
        dispatch(sendContentSuccess())
        dispatch(fetchVcardDataToEditor(id))
      })
      .catch((err) => { })
  }
}

export const createVcard = (data) => {
  return (dispatch) => { }
}

export const setPhoneNumber = (data) => {
  return {
    type: types.SET_PHONE_NUMBER,
    payload: data,
  }
} // <- Another Links

export const updateGalleryTitle = (data) => {
  return {
    type: types.UPDATE_GALLERY_TITLE,
    payload: data,
  }
}

export const updateStore = (data) => {
  return {
    type: types.UPDATE_STORE,
    payload: data,
  }
}

export const updateSettingsData = (data) => {
  return {
    type: types.UPDATE_SETTINGS,
    payload: data,
  }
}

export const updatePreviewDisplay = (id) => {
  return (dispatch) => {
    dispatch(fetchVcardDataToEditor(id))
  }
}

export const fetchVcardDataToEditor = (id) => {
  let { auth } = getLoggedInUser()
  return (dispatch) => {
    axios
      .get(API + `/companies/${id}`, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(goVcardDataToEditor(data))
      })
      .catch((err) => {
        // dispatch(sendContentFailed())
      })
  }
}

export const fetctCompanyDataToEditor = (id) => {
  let { auth } = getLoggedInUser()
  return (dispatch) => {
    axios
      .get(API + `/companies/${id}`, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(goVcardDataToEditor(data))
      })
      .catch((err) => {
        // dispatch(sendContentFailed())
      })
  }
}

export const goVcardDataToEditor = (data, history, bool = false) => {
  if (bool) history.replace('/constructor')
  localStorage.setItem('vcard_id', data?.id)
  return {
    type: types.GO_TO_EDIT_VCARD_DATA,
    payload: data,
  }
}

export const updateVcardData = (data) => {
  return {
    type: types.UPDATE_VCARD_DATA,
    payload: data,
  }
}

export const changeQrCode = (data) => {
  return {
    type: types.SET_QR_CODE,
    payload: data,
  }
}
export const saveMainContentToSend = (data) => {
  return {
    type: types.SAVE_MAIN_CONTENT_TO_SEND,
    payload: data,
  }
}
export const transferFaildVcard = (data) => {
  return {
    type: types.UPDATE_VCARD_TRANSFER_DATA,
    payload: data,
  }
  
}

export const fetchCloneVCardAPI = (id) => {
  let { auth } = getLoggedInUser()
  //http://localhost:3000/api/v1/vcards/596/clone
  return (dispatch) => {
    axios
      .get(API + `/vcards/${id}/clone`, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchAccountVcardsAll())
      })
      .catch((err) => {
        // dispatch(sendContentFailed())
      })
  }
}
export const deleteCloneVCardAPI = (id) => {
  let { auth } = getLoggedInUser()
  //http://localhost:3000/api/v1/vcards/596/clone
  //http://localhost:3000/api/v1/vcards/649/destroy

  return (dispatch) => {
    axios
      .get(API + `/vcards/${id}/destroy`, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchAccountVcardsAll())
      })
      .catch((err) => {
        // dispatch(sendContentFailed())
      })
  }
}

export const transferCloneVCardAPI = (id, emailId) => {
  let { auth } = getLoggedInUser()
  //http://localhost:3000/api/v1/vcards/596/clone
  //http://localhost:3000/api/v1/vcards/649/destroy
  //http://localhost:3000/api/v1/vcards/620/assign_vcard?email=dwivedisankalp02@gmail.com
  return (dispatch) => {
    axios
      .get(API + `/vcards/${id}/assign_vcard?email=${emailId}`, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchAccountVcardsAll())
      })
      .catch((err) => { 
        dispatch(transferFaildVcard("user email Id not found"))
        
      })
  }
}
export const goVcardDataToClone = (data, history, bool = false) => {
  // if (bool) history.replace('/constructor')
  // localStorage.setItem('vcard_id', data?.id)
  return {
    type: types.GO_TO_EDIT_VCARD_DATA,
    payload: data,
  }
}

export const UpdateVideoLink = (id, title, url) => {
  let user = getLoggedInUser();
  const formDate = new FormData();
  formDate.append("title", title);
  formDate.append("url", url);
  return (dispatch) => {
    axios
      .patch(API + `/menu_videos/${id}`, formDate, {
        headers: {
          Authorization: 'Bearer ' + user.auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(getVideoLinkList())
      })
      .catch((err) => { })
  }
}

export const getVideoLink = (id) => {
  let user = getLoggedInUser();
  return (dispatch) => {
    axios
      .get(API+`/menu_videos/${id}`, {
        headers: {
          Authorization: 'Bearer ' + user.auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(GetVideoSuccess(data))
      })
      .catch((err) => { })
  }
}

export const getVideoLinkList = () => {
  let user = getLoggedInUser();
  return (dispatch) => {
    axios
      .get(API+`/menu_videos`, {
        headers: {
          Authorization: 'Bearer ' + user.auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(GetVideoSuccess(data))
      })
      .catch((err) => { })
  }
}

export const GetVideoSuccess = (payload) => {
  return {
    type: types.GET_VIDEO_SUCCESS,
    payload,
  }
}


export const getVideoLinkByComppany = () => {
  let user = getLoggedInUser();
  return (dispatch) => {
    axios
      .get(API+`/menu_videos?company_id=${localStorage.getItem('c_id')}`, {
        headers: {
          Authorization: 'Bearer ' + user.auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(GetVideoSuccess(data))
      })
      .catch((err) => { })
  }
}


export const UpdateVideoLinkByComppany = (id,url) => {
  let user = getLoggedInUser();
  const formDate = new FormData();
  formDate.append("url", url);
  return (dispatch) => {
    axios
      .patch(API + `/menu_videos/${id}`, formDate, {
        headers: {
          Authorization: 'Bearer ' + user.auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(getVideoLinkByComppany())
      })
      .catch((err) => { })
  }
}

export const CreateLinkByComppany = (title) => {
  let user = getLoggedInUser();
  const formDate = new FormData();
  formDate.append("title", title);
  formDate.append("url", "");
  formDate.append("company_id", localStorage.getItem('c_id'));
  return (dispatch) => {
    axios
      .post(API + `/menu_videos`, formDate, {
        headers: {
          Authorization: 'Bearer ' + user.auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(getVideoLinkByComppany())
      })
      .catch((err) => { })
  }
}

