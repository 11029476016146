import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAccountVcardsAll } from '../../redux/account/actions'
import { getLoggedInUser } from '../../utils/helpers'
import { EnterDomainPrefixForNewVcard } from '../../utils/ModalWindows.jsx'
import Title from './Title'
import Vcard from './Vcard'

// const drawerWidth = 240

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   toolbar: {
//     paddingRight: 24, // keep right padding when drawer closed
//   },
//   toolbarIcon: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     padding: '0 8px',
//     ...theme.mixins.toolbar,
//   },
//   appBar: {
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },
//   appBarShift: {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   menuButton: {
//     marginRight: 36,
//   },
//   menuButtonHidden: {
//     display: 'none',
//   },
//   title: {
//     flexGrow: 1,
//   },
//   drawerPaper: {
//     position: 'relative',
//     whiteSpace: 'nowrap',
//     width: drawerWidth,
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   drawerPaperClose: {
//     overflowX: 'hidden',
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     width: theme.spacing(7),
//     [theme.breakpoints.up('sm')]: {
//       width: theme.spacing(9),
//     },
//   },
//   appBarSpacer: theme.mixins.toolbar,
//   content: {
//     flexGrow: 1,
//     height: '100vh',
//     overflow: 'auto',
//   },
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//   },
//   fixedHeight: {
//     height: '100%',
//   },
// }))

export default function MyVcards() {
  const dispatch = useDispatch()
  const { t } = useTranslation()


  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer)
  const { vCards, loading } = useSelector((state) => state.Account)
  const [modalShow, setModalShow] = useState(false)
  const { user } = getLoggedInUser()
  console.log(user?.id);
  if(localStorage.getItem('payment_status')==="1"){
    console.log("payment_status",localStorage.getItem('payment_status'));
  }else if (user && user?.id === 952) {
    console.log(user?.id);
  }else if (user && user?.activity_info?.is_active === false) {
    window.location.href = '/#/card'
  }
  useEffect(() => {
    dispatch(fetchAccountVcardsAll())
  }, [dispatch])

  useEffect(() => {}, [vcardDataToEdit])

  return (
    <React.Fragment>
      <Title>{t('myVcards')}</Title>
      <Grid container spacing={3}>
        {loading ? (
          <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {vCards.map((item, index) => {
              return (
                <Grid key={index + 'vcard'} item xs={6} md={4} lg={3}>
                  <Vcard data={item} />
                </Grid>
              )
            })}
            {user.number_of_vcards > vCards.length ? (
              <Grid
                key={111 + 'vcard'}
                item
                xs={12}
                md={4}
                lg={4}
                style={{
                  marginLeft: 'auto',
                  marginTop: 'auto',
                  textAlign: 'right',
                }}
              >
                <Button color='primary' onClick={() => setModalShow(true)}>
                  {t('createVCard')}
                </Button>
              </Grid>
            ) : (
              false
            )}
          </>
        )}
        <EnterDomainPrefixForNewVcard
          show={modalShow}
          setModalShow={setModalShow}
          onHide={() => setModalShow(false)}
        />
      </Grid>
    </React.Fragment>
  )
}
