import * as types from './types'

const initState = {
  popup: false,
  imgPreview: '',
}

const galleryReducer = (state = initState, action) => {
  switch (action.type) {
    case types.TOGGLE_POPUP:
      return { ...state, popup: action.bool }
    case types.SET_IMG_PREVIEW:
      return { ...state, imgPreview: action.str }

    default:
      return { ...state }
  }
}
export default galleryReducer
