import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addTestimonialsListItem,
  deleteTestimonialsFromServer,
  putTestimonialsToServer,
  updateTestimonialsList
} from '../../../../redux/testimonials/action'
import AddBtn from '../../../kit/AddBtn'
import SaveBtn from '../../../kit/SaveBtn'
import Wrapper from '../../../kit/Wrapper'
import Header from '../Header'
import TestimonialItem from './TestimonialItem'

const Testimonials = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { testimonials, deletedTestimonials } = useSelector(
    (s) => s.testimonialsReducer
  )

  const { vcardDataToEdit } = useSelector((s) => s.ConstructorReducer)

  const vcardId = vcardDataToEdit?.id
  const testimonialsFromServer = vcardDataToEdit?.testimonials

  const addNewTestimonialHandler = () => {
    const id = Math.round(Math.random() * -1000)
    const testimonial = {
      id,
      message: '',
      given_by: '',
      title_of_the_person: '',
    }
    dispatch(addTestimonialsListItem(testimonial))
  }

  let isMessage = testimonials?.reduce((acc, rec) => {
    const message = rec.message !== ''
    return acc && message
  }, true)

  const isTestimonialsSame = useMemo(() => {
    return (
      JSON.stringify(testimonialsFromServer) === JSON.stringify(testimonials)
    )
  }, [testimonialsFromServer, testimonials])

  const saveHandler = (e) => {
    e.preventDefault()
    if (testimonials.length)
      dispatch(putTestimonialsToServer(vcardId, testimonials))
    if (deletedTestimonials.length)
      dispatch(deleteTestimonialsFromServer(vcardId, deletedTestimonials))
  }

  useEffect(() => {
    dispatch(updateTestimonialsList(testimonialsFromServer))
  }, [dispatch, testimonialsFromServer])

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isTestimonialsSame || !isMessage} />
      <Wrapper>
        {testimonials?.map((item, idx) => (
          <TestimonialItem item={item} key={idx} />
        ))}
        <AddBtn onClick={addNewTestimonialHandler} textContent={t('testimonial')} />
      </Wrapper>
      <SaveBtn
        array={testimonials}
        disabled={isTestimonialsSame || !isMessage}
      />
    </form>
  )
}
export default Testimonials
