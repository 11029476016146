import React from 'react'
import styled from 'styled-components'
import Section from '../../kit/Section'

const StyledVideoFrame = styled.div`
  margin: 0 auto;
  max-width: 700px;
  aspect-ratio: 16/9;
  background-color: ${(props) => props.color};

  & iframe {
    width: 100%;
    height: 350px;
  }
`

export default function VmailBLAZE({ data }) {
    const link = data?.vmail_blaze_code
    let titleIframe = ''
    const src = getLink(link)
    return (
        data?.vmail_blaze_code && (
            <Section>
                <StyledVideoFrame color={data?.background_primary_color}>
                    <iframe 
                    src={src}
                    allowFullScreen
                    scrolling="no" 
                    allow="camera; microphone"></iframe>

                </StyledVideoFrame>
            </Section>
        )
    )
}
export const getLink = (link) => {
    let vmailLink = ''
    if (link) {
        vmailLink = "https://members.vmailblaze.com/widget/inline/" + link
    }

    return vmailLink
}
