import React from 'react'
import styled from 'styled-components'
import { STYLES } from '../../../styles'
import { useDispatch } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { setComponentEditor } from '../../../redux/constructor/actions'
import SaveBtn from '../../kit/SaveBtn'
import { useTranslation } from 'react-i18next'

const StyledHeader = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  max-width: 35rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  height: 56px;
  border-bottom: 1px solid #bbb;
  color: ${STYLES.COLORS.lightBlue};
  cursor: pointer;
  align-items: center;
  background-color: #d7e9ed;
  & > div {
    display: flex;
    align-items: center;
  }
`

const Header = ({ disabled }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <div onClick={() => dispatch(setComponentEditor(''))}>
        <ArrowBackIcon /> {t('backToMainMenu')}
      </div>
      <SaveBtn type='submit' disabled={disabled} />
    </StyledHeader>
  )
}

export default Header
