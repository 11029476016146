import React from 'react';
import styled from 'styled-components';
import TemplateHeader from './TemplateHeader';
import TemplatePreview from './TemplatePreview';

const StyledTemplate = styled.main`
  position: relative;
  flex-grow: 1;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Template = () => {
  return (
    <StyledTemplate>
      <TemplateHeader />
      <TemplatePreview />
    </StyledTemplate>
  );
};

export default Template;
