import React from 'react';
import styled from 'styled-components';
import Section from '../../kit/Section';

const StyledVideoFrame = styled.div`
  overflow: hidden;
  margin: 0 auto;
  max-width: 700px;
  aspect-ratio: 16/9;
  background-color: ${(props) => props.color};

  & iframe {
    width: 100%;
    height: 100%;
  }
`;

export default function VideoFrame({ data }) {
  const link = data?.video_link;
  
  let titleIframe = '';
  const src = getLink(link, titleIframe);
  return (
    data?.video_link && (
      <Section>
        <StyledVideoFrame color={data?.background_primary_color} style={{'margin-top': '20px'}}>
          <iframe
            src={src}
            title={titleIframe}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </StyledVideoFrame>
      </Section>
    )
  );
}

export const getLink = (link, titleIframe) => {
  let videoID = '';
  let webLinkRgx = new RegExp(/.youtube.+\/?v=(.+)/);
  let copyLinkRgx = new RegExp(/.youtu.be\/(.+)/);
  let vimeoLinkRgx = new RegExp(/vimeo.+\/(.+)/);
  if (link) {
    if (link.match(/youtu/)) {
      videoID = link.match(webLinkRgx) || link.match(copyLinkRgx);
      videoID = 'https://www.youtube.com/embed/' + videoID?.[1];
      titleIframe = 'YouTube Video Player';
    } else if (link.match(/vimeo/)) {
      videoID = link.match(vimeoLinkRgx);
      videoID = 'https://player.vimeo.com/video/' + videoID?.[1];
      titleIframe = 'Vimeo Video Player';
    } else if (link.match(/player/)) {
      videoID = link.match(vimeoLinkRgx);
      videoID = 'https://player.vimeo.com/video/' + videoID?.[1]+"?loop=1";
      titleIframe = 'Vimeo Video Player';
    }
  }

  return videoID;
};
