import * as types from './types'

const initState = {
  phone_number: '',
  'display_call_me?': false,
  'display_text_me?': false,
  'display_email?': false,
  'display_whatsapp?': false,
  'display_add_to_contacts?': false,
  facebook_link: '',
  instagram_link: '',
  linkedin_link: '',
  twitter_link: '',
  cashapp_link: '',
  paypal_link: '',
  web_link: '',
  social_networks: [],
  deleted_links: [],
}

const socialLinksReducer = (state = initState, action) => {
  switch (action.type) {
    case types.UPDATE_SOCIAL_LINKS_FROM_SERVER:
      return {
        ...action.data,
      }

    case types.UPDATE_SOCIAL_LINKS:
      return { ...state, [action.label]: action.value }

    case types.CREATE_CUSTOM_LINK:
      return {
        ...state,
        social_networks: [
          ...state.social_networks,
          {
            id: action.id,
            icon: '',
            title: '',
          },
        ],
      }
    case types.UPDATE_CUSTOM_LINK:
      return {
        ...state,
        social_networks: (() => {
          const data = action.data
          const newState = state.social_networks.map((item) =>
            item.id === data.id ? data : item
          )
          return newState
        })(),
      }
    case types.DELETE_CUSTOM_LINK:
      return {
        ...state,
        social_networks: (() => {
          const id = action.id
          const newState = state.social_networks.filter(
            (item) => item.id !== id
          )
          return newState
        })(),
        deleted_links: (() => {
          const id = action.id
          if (id >= 0) {
            return [...state.deleted_links, id]
          } else return [...state.deleted_links]
        })(),
      }

    case types.CLEAR_DELETED_CUSTOM_LINKS:
      return { ...state, deleted_links: [] }

    default:
      return { ...state }
  }
}
export default socialLinksReducer
