import React from 'react'
import styled from 'styled-components'

export const StyledTitle = styled.h3`
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.75rem;
  font-weight: 600;
`

const Title = ({ textContent }) => {
  return textContent && <StyledTitle>{textContent}</StyledTitle>
}

export default Title
