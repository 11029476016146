import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getLoggedInUser } from '../../utils/helpers';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Title from './Title';
import UserTable from './UserTable';
import { useDispatch, useSelector } from 'react-redux';
import API, { APIMAIN } from '../../redux/API';
import UploadButton from '../../components/constructor/Editors/mainContent/UploadButton';
import UploadedImage from '../../components/constructor/Editors/mainContent/UploadedImage';
import SaveBtn from '../../components/kit/SaveBtn';
import { fetchContent } from '../../redux/request/actions';
import { Link } from 'react-router-dom';
import LogoUploadBtn from './logoUpload/LogoUploadBtn';
import ImageLogoUploaded from './logoUpload/ImageLogoUploaded';
import {
  setWebLink,
} from '../../redux/account/actions';

export default function MenuBar() {


  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = React.useState({ ...user });
  const [allUsers, setAllUsers] = React.useState([]);
  const [agentID, setAgentID] = useState(loggedUser?.agent_link);
  const [errorcode, setErrorCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  console.log(loggedUser);
  const saveHandler = async () => {
    dispatch(setWebLink(agentID,loggedUser?.id,loggedUser));
  };

  return (
    <Box sx={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '12px',
          }}
        >
        <p>https://talkingvcard.com/</p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <input
                value={agentID}
                onChange={(e) => setAgentID(e.target.value)}
                type="text"
                style={{ width: '300px', padding: 6, marginLeft: 20, marginRight: 20 }}
              />
            </div>
              <Button
                onClick={saveHandler}
              >
                Save
              </Button>
        </div>
    </Box>
  );
}
