import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import { setColorScheme } from '../../../redux/constructor/actions'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export default function ColorPicker({ title, label }) {
  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer)
  let vcardId = vcardDataToEdit.id
  const dispatch = useDispatch()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [colorPicker, setColorPicker] = useState(vcardDataToEdit[label])

  useEffect(() => {
    setColorPicker(vcardDataToEdit[label])
  }, [vcardDataToEdit, label])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleColorChange = (e) => {
    setColorPicker(e.hex)
    dispatch(setColorScheme(vcardId, { [label]: e.hex }))
  }

  return (
    <div className='color_picker_item'>
      <Button
        aria-describedby={id}
        variant='contained'
        color='default'
        onClick={handleClick}
      >
        {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>
          <SketchPicker color={colorPicker} onChange={handleColorChange} />
          <button
            style={{ background: 0, border: 0, right: 0 }}
            onClick={() => setAnchorEl(null)}
          >
            <HighlightOffIcon />
          </button>
        </Typography>
      </Popover>
      <div
        className='preview_color'
        onClick={handleClick}
        style={{ background: colorPicker }}
      ></div>
    </div>
  )
}
