import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  checkDomainOnValidMainData,
  fetchContentJson,
} from '../../../../redux/request/actions'
import { STYLES } from '../../../../styles'
import { languageMap } from '../../../../utils/helpers'
import DefaultSelectNormal from '../../../kit/DefaultSelectNormal'
import DefaultTextArea from '../../../kit/DefaultTextArea'
import DefaultTextField, {
  StyledInputLabel,
  StyledTextField,
} from '../../../kit/DefaultTextField'
import SaveBtn from '../../../kit/SaveBtn'
import Header from '../Header';
import { getLoggedInUser } from '../../../../utils/helpers';

const StyledDomainTextField = styled.div`
  margin-bottom: 4px;

  label {
    margin: 0;
    width: 100%;
    & > div {
      display: flex;
      column-gap: 4px;
      align-items: center;
    }
  }
  h5 {
    font-size: 0.875rem;
    color: ${STYLES.COLORS.warning};
  }
  input {
    box-shadow: 0 0 0 0.125rem
      ${(p) => (p.isValid ? 'transparent' : STYLES.COLORS.warning)};

    &:focus {
      box-shadow: 0 0 0 0.125rem
        ${(p) => (p.isValid ? STYLES.COLORS.lightBlue : STYLES.COLORS.warning)};
    }
  }
  h6 {
    margin-top: 4px;
    font-weight: 400;
    color: ${(p) => (p.isValid ? 'green' : STYLES.COLORS.warning)};
  }
`
export default function Settings() {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { domainError, domainIsValid } = useSelector((state) => state.Request)
  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer)
  const domainChanged = vcardDataToEdit?.['domain_changed?']

  const initState = {
    google_tracking: vcardDataToEdit?.google_tracking,
    facebook_pixel: vcardDataToEdit?.facebook_pixel,
    tvc_tracking_code: vcardDataToEdit?.tvc_tracking_code,
    page_title: vcardDataToEdit?.page_title,
    metadata_description: vcardDataToEdit?.metadata_description,
    'display_contact_form?': vcardDataToEdit?.['display_contact_form?'],
    contact_form_title: vcardDataToEdit?.contact_form_title,
    email: vcardDataToEdit?.email,
    kwiki_chat: vcardDataToEdit?.kwiki_chat,
  }

  const [settings, setSettings] = useState(initState)

  const [domain_prefix, setDomain] = useState(vcardDataToEdit?.domain_prefix)

  const domainHandler = (e) => {
    const value = e.target.value
    setDomain(value)
    dispatch(checkDomainOnValidMainData(value))
  }

  const contentChangeHandler = (e) => {
    const value = e.target.value
    const id = e.target.id
    const newStore = { ...settings }
    newStore[id] = value
    setSettings(newStore)
  }

  const checkboxHandler = (e) => {
    const value = e.target.checked
    const id = e.target.id
    const newStore = { ...settings }
    newStore[id] = value
    setSettings(newStore)
  }

  const chechDomainHandler = () => {
    dispatch(checkDomainOnValidMainData(settings?.domain_prefix))
  }

  const isSameData = JSON.stringify(settings) === JSON.stringify(initState)
  const isDomainSame = vcardDataToEdit?.domain_prefix === domain_prefix

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isDomainSame) {
      dispatch(fetchContentJson(vcardDataToEdit?.id, { domain_prefix }))
    }
    dispatch(fetchContentJson(vcardDataToEdit?.id, settings))
  }
  const { user } = getLoggedInUser();
  const [userData, setUserData] = useState({ ...user });

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={isSameData && isDomainSame} />
      <StyledDomainTextField isValid={domainIsValid}>
        <StyledInputLabel onBlur={chechDomainHandler}>
          <h4>
            {t('domain')}<span>*</span>
          </h4>
          <p>
            {t('domainNote')}
          </p>
          {/* <h5>You can change your domain only once.</h5> */}
          <div>
            <StyledTextField
              disabled={domainChanged}
              id='domain_prefix'
              value={domain_prefix}
              onChange={domainHandler}
              placeholder={t('enterDomainName')}
              required
            />
            <div>{t('domainSample')}</div>
          </div>
        </StyledInputLabel>
        <h6>{domainError}</h6>
      </StyledDomainTextField>

      <DefaultTextField
        label={t('googleTID')}
        subtitle={t('googleTIDDesc')}
        placeholder={t('enterGoogleTID')}
        id='google_tracking'
        value={settings?.google_tracking ?? ''}
        onChange={contentChangeHandler}
      />
      <DefaultTextField
        label={t('facebookPixcelID')}
        subtitle={t('facebookPSubTitle')}
        placeholder={t('enterFacebookID')}
        id='facebook_pixel'
        value={settings?.facebook_pixel ?? ''}
        onChange={contentChangeHandler}
      />
       
      <DefaultTextField
        label={t('pageTitle')}
        subtitle={t('pageTitleDesc')}
        placeholder={t('enterPageTitle')}
        id='page_title'
        value={settings?.page_title ?? ''}
        onChange={contentChangeHandler}
      />
      <DefaultTextArea
        label={t('metaDesc')}
        subtitle={t('metaSubtitle')}
        placeholder={t('enterMetaDesc')}
        id='metadata_description'
        value={settings?.metadata_description ?? ''}
        onChange={contentChangeHandler}
      />
      {(userData?.user_type === null || userData?.user_type === 'sales') && (
        <>
        <DefaultTextField
          label={t('tvcTrackingCode')}
          subtitle={t('tvcDesc')}
          placeholder={t('enterTvcCode')}
          id='tvc_tracking_code'
          value={settings?.tvc_tracking_code ?? ''}
          onChange={contentChangeHandler}
        />
        </>
      )}
      <DefaultTextField
          label={t('kwikiChat')}
          subtitle={t('kwikiLive')}
          placeholder={t('kwikiChatCode')}
          id='kwiki_chat'
          value={settings?.kwiki_chat ?? ''}
          onChange={contentChangeHandler}
        />
      <FormControlLabel
        className='form_checkbox'
        label={t('displayCorrectForm')}
        control={
          <Checkbox
            checked={settings?.['display_contact_form?'] ?? false}
            onChange={checkboxHandler}
            id='display_contact_form?'
            name='card_form'
          />
        }
      />
      <DefaultTextField
        label={t('contactFormTitle')}
        subtitle={t('contactFormDesc')}
        placeholder={t('sendMessage')}
        id='contact_form_title'
        value={settings?.contact_form_title ?? ''}
        onChange={contentChangeHandler}
      />
      <DefaultTextField
        label={t('email')}
        subtitle={t('emailDesc')}
        placeholder={t('enterEmail')}
        id='email'
        type='email'
        value={settings?.email || ''}
        onChange={contentChangeHandler}
      />


       
      <SaveBtn disabled={isSameData && isDomainSame} />
    </form>
  )
}
