import * as types from './types'

const initState = {
  reserver_email: '',
  reserver_name: '',
  reserver_phone: '',
  notes: '',
  start_date: '',
  start_time: '',
  timezone: null,
  bookingForm: false,
}

const appointmentsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_START_DATE:
      return { ...state, start_date: action.date }
    case types.SET_START_TIME:
      return { ...state, start_time: action.time }
    case types.OPEN_BOOKING_FORM:
      return { ...state, bookingForm: action.bool }
    case types.UPDATE_STATE:
      return { ...state, ...action }
    case types.RESET_STATE:
      return (state = {
        ...state,
        reserver_email: '',
        reserver_name: '',
        reserver_phone: '',
        notes: '',
      })
    case types.UPDATE_BOOKING_TIMEZONE:
      return { ...state, timezone: action.data.toString() }
    default:
      return { ...state }
  }
}
export default appointmentsReducer
