import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompantAll } from '../../../redux/account/actions';
import { getLoggedInUser } from '../../../utils/helpers';
import { EnterDomainPrefixForNewVcard } from '../../../utils/ModalWindows.jsx';
import Title from '../Title';
import CompanyCard from './CompanyCard';

export default function CompanyList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer);
  const { vCards, loading } = useSelector((state) => state.Account);
  const [modalShow, setModalShow] = useState(false);
  const { user } = getLoggedInUser();

  useEffect(() => {
    dispatch(fetchCompantAll());
  }, [dispatch]);

  useEffect(() => {}, [vcardDataToEdit]);  

  return (
    <React.Fragment>
      <Title>{t('Company List')}</Title>
      <Grid container spacing={3}>
        {loading ? (
          <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : (
          <> 
            <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
              <CompanyCard data={vCards} /> 
            </div>                         
          </>
        )}   
      </Grid>
    </React.Fragment>
  );
}
