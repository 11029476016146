import axios from 'axios'
import { getLoggedInUser } from '../../utils/helpers'
import API from '../API'
import { fetchVcardDataToEditor } from '../constructor/actions'
import * as types from './types'

export const addVideoLink = (string) => ({
  type: types.ADD_VIDEO_LINK,
  string,
})

export const deleteVideoLink = (payload) => ({
  type: types.DELETE_VIDEO_LINK,
  payload,
})

export const updateVideoLink = (data) => ({
  type: types.UPDATE_VIDEO_LINK,
  data,
})

export const updateVideos = (data) => ({
  type: types.UPDATES_VIDEOS,
  data,
})

export const putVideosToServer = (vcardId, data) => {
  let user = getLoggedInUser()
  data = data.map((item) => {
    if (item.id < 0) {
      delete item.id
      return item
    } else return item
  })
  return (dispatch) => {
    axios
      .put(`${API}/vcards/${vcardId}/videos`, data, {
        headers: {
          Authorization: `Bearer ${user.auth.token}`,
          'content-Type': 'application/json',
        },
      })
      .then((data) => {
        dispatch(fetchVcardDataToEditor(vcardId))
      })
      .catch((err) => console.warn('putTestimonialsToServer:', err))
  }
}

export const deleteVideosFromServer = (vcardId, deletedVideos) => {
  let user = getLoggedInUser()
  let promises = deletedVideos.map((item) => {
    return axios.delete(`${API}/vcards/${vcardId}/videos/${item}`, {
      headers: {
        Authorization: 'Bearer ' + user.auth.token,
        'content-Type': 'application/json',
      },
    })
  })
  return (dispatch) => {
    Promise.all(promises)
      .then((data) => {
        dispatch(clearDeletedVideos())
        dispatch(fetchVcardDataToEditor(vcardId))
      })
      .catch((err) => console.warn('deleteTestimonialsFromServer', err))
  }
}

export const clearDeletedVideos = () => ({
  type: types.CLEAR_DELETED_VIDEOS,
})
