import React from 'react'
import Section from '../../kit/Section'


const CalendlyAppointments = ({ data, mode }) => {

  const vcardDataToEdit = data
  const content = vcardDataToEdit?.vcard_calendly_id?.length > 0

  return (
    (content) && (
      <Section>
        {content && (
          <div>
            <div id="schedule_form">
              <div
                className="calendly-inline-widget"
                data-url={"https://calendly.com/" + vcardDataToEdit?.vcard_calendly_id}
                style={{ minWidth: '320px', height: '450px' }} />
            </div>
          </div>
        )}
      </Section>
    )
  )
}

export default CalendlyAppointments
