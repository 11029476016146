import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  deleteServicesListItem,
  updateServicesListItem,
} from '../../../../redux/services/action';
import DefaultCKEditor from '../../../kit/DefaultCKEditor';
import DefaultTextArea from '../../../kit/DefaultTextArea';
import DefaultTextField from '../../../kit/DefaultTextField';
import ItemWrapper from '../../../kit/ItemWrapper';
import RemoveBtn from '../../../kit/RemoveBtn';
import { FroalaEditor } from '../mainContent/FroalaEditor';

const ServiceItem = ({ item }) => {
  const { title, body } = item;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateServicesHandler = (e) => {
    const val = e.target.value;
    const id = e.target.id;
    dispatch(updateServicesListItem({ ...item, [id]: val }));
  };

  const [bodyValue, setBodyValue] = useState('');
  const ckEditorChangeHandler = (value, name) => {
    if (name === 'body') {
      setBodyValue(value);
    }
  };
  useEffect(() => {
    dispatch(updateServicesListItem({ ...item, ['body']: bodyValue }));
  }, [bodyValue]);

  const deleteServicesHandler = () => dispatch(deleteServicesListItem(item.id));

  return (
    <ItemWrapper>
      <DefaultTextField
        label={t('titleService')}
        id="title"
        value={title}
        onChange={updateServicesHandler}
      />
      {/* <DefaultTextArea
        label={t('description')}
        id='body'
        value={body}
        onChange={updateServicesHandler}
        placeholder={t('descriptionService')}
      /> */}
      {/* <DefaultCKEditor
        value={body || ''}
        onChange={ckEditorChangeHandler}
        label={t('description')}
        id='body'
      /> */}
      <FroalaEditor
        value={body || ''}
        onChange={(value) => setBodyValue(value)}
        id="message"
        label={t('description')}
      />
      <RemoveBtn onClick={deleteServicesHandler} textContent={t('service')} />
    </ItemWrapper>
  );
};

export default ServiceItem;
