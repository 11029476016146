import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import { setColorScheme } from '../../../../redux/constructor/actions'
import { setBannerColor, setBannerSecondaryColor } from '../../../../redux/banner/actions';
import { getLoggedInUser } from '../../../../utils/helpers';
import API from '../../../../redux/API';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export default function ColorPicker({ title, label }) {
  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer)
  let vcardId = vcardDataToEdit.id
  const dispatch = useDispatch()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [colorPicker, setColorPicker] = useState(null)
  const [getId, setId] = useState("")
  const [getColorId, setColorID] = useState("")
  useEffect(() => {
    setColorPicker(vcardDataToEdit[label])
  }, [vcardDataToEdit, label])

  const handleClick = (event) => {
    setColorID(event.currentTarget.getAttribute('data-id'));
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleColorChange = (e) => {
    setColorPicker(e.hex)
    console.log("color id",getColorId);
    if(title==="Group A Secondary color" || title==="Group B Secondary color" || title==="Group C Secondary color" || title==="Group D Secondary color"){
      dispatch(setBannerSecondaryColor(getColorId,localStorage.getItem('vcard_id'),localStorage.getItem('c_id'), { 'background_secondary_color':  e.hex  }))
    }else{
      dispatch(setBannerColor(localStorage.getItem('vcard_id'),localStorage.getItem('c_id'), { 'group_type': label,'color_code': e.hex }))
    }    
  }

  let { auth, user } = getLoggedInUser();
  useEffect(() => {
    axios
      .get(API + `/companies/${localStorage.getItem('c_id')}/company_group_colors`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        data.map((item, index) => {
          // setColorPicker(item.color_code)
          if(item.group_type==1){
            if(title==="Group A Secondary color"){
              setColorPicker(item.background_secondary_color);  
              setId(item.id);  
            }
            if(title==="Group A"){
              setColorPicker(item.color_code);  
              setId(item.id);  
            }
          }
          if(item.group_type==2){
            if(title==="Group B Secondary color"){
              setColorPicker(item.background_secondary_color);  
              setId(item.id);  
            }
            if(title==="Group B"){
              setColorPicker(item.color_code);  
              setId(item.id);  
            }
          }
          if(item.group_type==3){
            if(title==="Group C Secondary color"){
              setColorPicker(item.background_secondary_color);  
              setId(item.id);  
            }
            if(title==="Group C"){
              setColorPicker(item.color_code);  
              setId(item.id);  
            }
          }
          if(item.group_type==4){
            if(title==="Group D Secondary color"){
              setColorPicker(item.background_secondary_color);  
              setId(item.id);  
            }
            if(title==="Group D"){
              setColorPicker(item.color_code);  
              setId(item.id);  
            }
          }
        })
        
        // if(data[0].group_type==label){
        //   setColorPicker(data[0].color_code);  
        //   setId(data[0].id);  
        // }
        // if(data[1].group_type==label){
        //   setColorPicker(data[1].color_code);  
        //   setId(data[1].id);  
        // }
        // if(data[2].group_type==label){
        //   setColorPicker(data[2].color_code);  
        //   setId(data[2].id);  
        // }
        // if(data[3].group_type==label){
        //   setColorPicker(data[3].color_code);  
        //   setId(data[3].id);  
        // }
        
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [auth.token]);

  return (
    <div className='color_picker_item'>
      <Button
        aria-describedby={id}
        variant='contained'
        color='default'
        onClick={handleClick}
      >
        {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>
          <SketchPicker color={colorPicker} onChange={handleColorChange} />
          <button
            style={{ background: 0, border: 0, right: 0 }}
            onClick={() => setAnchorEl(null)}
          >
            <HighlightOffIcon />
          </button>
        </Typography>
      </Popover>
      <div
        className='preview_color'
        onClick={handleClick}
        style={{ background: colorPicker!==null ? colorPicker : "#3d1ce0"  }}
        data-id={getId}
      ></div>
    </div>
  )
}
