import React, { useEffect } from 'react';
import styled from 'styled-components';
import Section from '../../kit/Section';
import Title from '../../kit/Title';

const StyledAboutContent = styled.div`
  color: ${(props) => props.color};
  display: flex;
  flex-direction: column;

  #companyInfo {
    width: 100%;
    display: flex;
    flex-direction: column;

    margin: 10px 10px !important;
  }
`;

const CompanyInfo = ({ data }) => {
  const vcardDataToEdit = data;

  const content = vcardDataToEdit?.company_info?.length > 0;

  // wrap all iframes in vcardDataToEdit?.about_content with a div
  useEffect(() => {
    const p = document.querySelector('#companyInfo p');
    if (p) {
      Object.assign(p.style, { width: '100%' });

      const iframes = document.querySelectorAll('#companyInfo iframe');
      if (iframes) {
        iframes.forEach((iframe) => {
          Object.assign(iframe, {
            width: '100%',
            height: '100%',
          });
          const wrapper = document.createElement('div');
          wrapper.classList.add('iframe-wrapper');
          Object.assign(wrapper.style, {
            overflow: 'hidden',
            aspectRatio: '16/9',
            width: '100%',
            marginTop: '15px',
            marginBottom: '15px',
          });
          iframe.parentNode.insertBefore(wrapper, iframe);
          wrapper.appendChild(iframe);
        });
      }
    }
  }, [vcardDataToEdit]);

  return (
    content && (
      <Section>
        <Title textContent="Company Info" />
        {content && (
          <StyledAboutContent color={vcardDataToEdit?.body_color}>
            <div
              id="companyInfo"
              dangerouslySetInnerHTML={{
                __html: vcardDataToEdit?.company_info,
              }}
            ></div>
          </StyledAboutContent>
        )}
      </Section>
    )
  );
};

export default CompanyInfo;
