import { Radio } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { group1, group2 } from './BannerGifs';
import { selectBanner, getAllBanners,setBannerUser } from '../../../../redux/banner/actions';
import { getLoggedInUser } from '../../../../utils/helpers';

const Banner = ({ selectedBanner }) => {
  const GridDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    background-color: #fff;
  `;
  let { user } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });

  useEffect(() => {
    dispatch(getAllBanners(loggedUser?.company_id));
  }, []);
  useEffect(() => {
    dispatch(getAllBanners(localStorage.getItem('vcard_id')));
  }, []);
  const defaultBanner = selectedBanner;
  const [banner, setBanner] = useState(defaultBanner);

  useEffect(() => {
    console.log("ddhd",banner);
    dispatch(selectBanner(banner));
  }, []);

  const handleBannerChange = (bannerGif) => {
    setBanner(bannerGif.filename.url);

    dispatch(setBannerUser(localStorage.getItem('vcard_id'), bannerGif.id));
    dispatch(selectBanner(bannerGif.filename.url));
  };

  const dispatch = useDispatch();

  const { banners } = useSelector((state) => state.Banner);

  return (
    <div>
          <p style={{ padding: 10, marginBottom: '-10px' ,width: '100%' , background:"white", fontWeight : "700"}}>Group 1</p>
          <GridDiv> 
            {banners.map((el) => (
            <>
              {
                el.group_type === 1?
                <div style={{ display: 'flex', flexDirection: 'column' }}>   
                  <SingleBanner
                    bannerGif={el}
                    banner={banner}
                    handleBannerChange={handleBannerChange}
                  />   
                </div>
                :''
              }
            </>
            ))}   
          </GridDiv>
          <p style={{ padding: 10, marginBottom: '-10px' ,width: '100%' , background:"white", fontWeight : "700"}}>Group 2</p>
          <GridDiv> 
            {banners.map((el) => (
            <>
              {
                el.group_type === 2?
                <div style={{ display: 'flex', flexDirection: 'column' }}>   
                  <SingleBanner
                    bannerGif={el}
                    banner={banner}
                    handleBannerChange={handleBannerChange}
                  />   
                </div>
                :''
              }
            </>
            ))}   
          </GridDiv>
          <p style={{ padding: 10, marginBottom: '-10px' ,width: '100%' , background:"white", fontWeight : "700"}}>Group 3</p>
          <GridDiv> 
            {banners.map((el) => (
            <>
              {
                el.group_type === 3?
                <div style={{ display: 'flex', flexDirection: 'column' }}>   
                  <SingleBanner
                    bannerGif={el}
                    banner={banner}
                    handleBannerChange={handleBannerChange}
                  />   
                </div>
                :''
              }
            </>
            ))}   
          </GridDiv>
          <p style={{ padding: 10, marginBottom: '-10px' ,width: '100%' , background:"white", fontWeight : "700"}}>Group 4</p>
          <GridDiv> 
            {banners.map((el) => (
            <>
              {
                el.group_type === 4?
                <div style={{ display: 'flex', flexDirection: 'column' }}>   
                  <SingleBanner
                    bannerGif={el}
                    banner={banner}
                    handleBannerChange={handleBannerChange}
                  />   
                </div>
                :''
              }
            </>
            ))}   
          </GridDiv>
    </div>
  );
};

const SingleBanner = ({ bannerGif, banner, handleBannerChange }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Radio
        checked={bannerGif.filename.url === banner}
        onChange={() => handleBannerChange(bannerGif)}
        value="a"
        name="radio-buttons"
        size="small"
      />
      <img
        style={{ width: '80%', height: '70%', objectFit: 'cover' }}
        src={bannerGif.filename.url}
        alt="TMGreen1"
      />
    </div>
  );
};
export default Banner;
