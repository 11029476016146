import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  openBookingForm,
  postAppointmentToServer,
  resetState,
  updateBookingTimezone,
  updateState,
} from '../../../redux/appointments/action'
import {
  resetSuccessMessage,
  setPreloader,
} from '../../../redux/preloader/action'
import DefaultButton from '../../kit/DefaultButton'
import DefaultTextArea from '../../kit/DefaultTextArea'
import DefaultTextField from '../../kit/DefaultTextField'
import LoadingComponent from '../../kit/loadingComponent'
import SaveBtn from '../../kit/SaveBtn'

export const StyledBookingForm = styled.div`
  position: absolute;
  height: calc(100% - 2rem);
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2d3748;
`
export const Wrapper = styled.div`
  border: solid grey;
  background-color: #e9effa;
  height: 100%;
  padding: 1rem;
  border-radius: 5px;
  scroll-behavior: smooth;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`
const InnerWrapper = styled.div`
  padding: 4rem 1rem 2rem;
  max-width: 500px;
  width: 100%;
  position: relative;
  margin: auto;
`
const StyledCloseBtn = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
`
const SelectedDate = styled.div`
  text-align: center;
  font-size: 1.5rem;
`
const ChooseAnotherDate = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  button {
    color: #0070f3;
    background-color: transparent;
  }
`
const EnterContacts = styled.div`
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 1rem;
`
const StyledSaveBtn = styled.div`
  text-align: center;
`
const StyledItem = styled.div`
  padding-bottom: 1rem;
`
const StyledSuccessMessage = styled.div`
  max-width: 500px;
  position: relative;
  margin: auto;
  p {
    font-size: 1.125rem;
    text-align: center;
  }
`

const BookingForm = ({ vcardDataToEdit }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch()
  const vcardId = vcardDataToEdit?.id
  const { appointment_time_format } = useSelector(
    (s) => s.appointmentsSettingsReducer
  )

  const appointments = useSelector((s) => s.appointmentsReducer)

  const closeFormHandler = () => {
    dispatch(openBookingForm(false))
    dispatch(resetState())
  }

  const updateFormHandler = (e) => {
    const val = e.target.value
    const key = e.target.id
    dispatch(updateState(key, val))
  }

  const submitFormHandler = (e) => {
    e.preventDefault()
    dispatch(postAppointmentToServer(vcardId, appointments))
    // dispatch(openBookingForm(false))
    dispatch(setPreloader(true))
  }

  const displayDate = () => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }

    const day = appointments.start_date.slice(0, 2)
    const month = appointments.start_date.slice(3, 5)
    const year = appointments.start_date.slice(6, 10)
    const formattedDate = `${year}-${month}-${day}`
    const newDate = new Date(formattedDate).toLocaleString('en', options)
    const startTime = appointments.start_time

    const hh = startTime.slice(0, 2)
    const mm = startTime.slice(3, 5)

    const formatMinutes = () => {
      if (appointment_time_format === 12) {
        switch (hh) {
          case '00':
            return [`${'12'}:${mm}am`]
          case '12':
            return [`${'12'}:${mm}pm`]
          case '13':
            return [`${'01'}:${mm}pm`]
          case '14':
            return [`${'02'}:${mm}pm`]
          case '15':
            return [`${'03'}:${mm}pm`]
          case '16':
            return [`${'04'}:${mm}pm`]
          case '17':
            return [`${'05'}:${mm}pm`]
          case '18':
            return [`${'06'}:${mm}pm`]
          case '19':
            return [`${'07'}:${mm}pm`]
          case '20':
            return [`${'08'}:${mm}pm`]
          case '21':
            return [`${'09'}:${mm}pm`]
          case '22':
            return [`${'10'}:${mm}pm`]
          case '23':
            return [`${'11'}:${mm}pm`]
          default:
            break
        }
        return [`${hh}:${mm}am`]
      } else {
        return [`${hh}:${mm}`]
      }
    }
    return `${newDate} at ${formatMinutes()}`
  }

  useEffect(() => {
    const localTimezone = (new Date().getTimezoneOffset() / 60) * -1
    dispatch(updateBookingTimezone(localTimezone))
  }, [dispatch])

  const { preloader, successMessage } = useSelector((s) => s.preloaderReducer)
  const resetMessageHandler = () => {
    dispatch(resetSuccessMessage())
    dispatch(openBookingForm(false))
  }

  return (
    <StyledBookingForm>
      <Wrapper>
        {successMessage ? (
          <StyledSuccessMessage>
            <p>{successMessage}</p>
            <DefaultButton
              onClick={resetMessageHandler}
              data={vcardDataToEdit}
              textContent='ok'
            />
          </StyledSuccessMessage>
        ) : preloader ? (
          <LoadingComponent />
        ) : (
          <InnerWrapper>
            <StyledCloseBtn onClick={closeFormHandler}>
              <CloseIcon />
            </StyledCloseBtn>
            <SelectedDate>{displayDate()}</SelectedDate>
            <ChooseAnotherDate>
              <button onClick={closeFormHandler}>
                {t('chooseADifferentDateTime')}
              </button>
            </ChooseAnotherDate>
            <EnterContacts>{t('pleaseEnterContactDetails')}</EnterContacts>
            <form onSubmit={submitFormHandler}>
              <StyledItem>
                <DefaultTextField
                  placeholder={t('enterYourName')}
                  label={t('name')}
                  required
                  id='reserver_name'
                  onChange={updateFormHandler}
                />
              </StyledItem>
              <StyledItem>
                <DefaultTextField
                  placeholder={t('enterYourNumber')}
                  label={t('number')}
                  minLength='6'
                  required
                  id='reserver_phone'
                  onChange={updateFormHandler}
                />
              </StyledItem>
              <StyledItem>
                <DefaultTextField
                  placeholder={t('enterYourEmail')}
                  label={t('email')}
                  type='email'
                  required
                  id='reserver_email'
                  onChange={updateFormHandler}
                />
              </StyledItem>
              <StyledItem>
                <DefaultTextArea
                  placeholder={t('enterAdditionalInfo')}
                  label={t('meetingNotes')}
                  id='notes'
                  onChange={updateFormHandler}
                />
              </StyledItem>
              <StyledSaveBtn>
                <SaveBtn disabled={false} type='submit' />
              </StyledSaveBtn>
            </form>
          </InnerWrapper>
        )}
      </Wrapper>
    </StyledBookingForm>
  )
}

export default BookingForm
