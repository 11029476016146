import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef } from "react";
import context from "react-bootstrap/esm/AccordionContext";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteCloneVCardAPI, fetchCloneVCardAPI, transferCloneVCardAPI, updateVcardData } from "../../redux/constructor/actions";
import { getLoggedInUser } from "../../utils/helpers";
import { APIMAIN } from "./../../redux/API";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
  },
  buttonBox: {
    display: 'contents '
  }
})
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function Vcard({ data }) {
  const { t } = useTranslation();
  const { error } = useSelector(
    (state) => state.ConstructorReducer
  )

  const dispatch = useDispatch();
  const classes = useStyles();

  const history = useHistory();
  const refs = useRef();
  const { user } = getLoggedInUser();
  if(localStorage.getItem('payment_status')==="1"){
    console.log("payment_status",localStorage.getItem('payment_status'));
  }else if (user && user?.id === 952) {
    console.log(user?.id);
  }else if (user && user?.activity_info?.is_active === false) {
    window.location.href = '/#/card'
  }
  console.log(user.activity_info?.is_active);
  const [open, setOpen] = React.useState(false);
  const [transferEmail, setTransferEmail] = React.useState("");
  const [transferMessage, setTransferMessage] = React.useState("");
  const handleOpen = (id) => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    setTransferEmail("");
    setTransferMessage("");
  }

  function handleClick(e) {
    localStorage.setItem('vcard_id', data.id);
    Promise.all([dispatch(updateVcardData(data))]).then((res) => {
      history.replace('/constructor');
    })
  }
  const cloneHandler = () => {
    dispatch(fetchCloneVCardAPI(data.id));
  }
  const handleDeleteClick = () => {
    const confirmBox = window.confirm(
      "Do you really want to delete this Vcard?"
    );
    if (confirmBox === true) {
      dispatch(deleteCloneVCardAPI(data.id));
    }
  }
  const handleTransferClick = () => {
    //requiredField
    if (transferEmail.length <= 1) {
      setTransferMessage(t("requiredField"));
      return;
    }
    dispatch(transferCloneVCardAPI(data.id, transferEmail));

  }

  useEffect(() => {
    if (error != undefined && error != "") {
      console.log("vcardDataToEdit", error);
      setTransferMessage(t("notfoundMessage"));
    }
  }, [error]);
  return (
    <>
      <Card className={classes.root} >
        <CardActionArea onClick={handleClick}>
        {data.background && (
          <>
          <CardMedia
            className={classes.media}
            image={
              APIMAIN + data.background.url ??
              'https://www.ranik.org/wp-content/uploads/2020/06/sites.jpg'
            }
            title={t('vCard')}
          />
          </>
          )}
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              {data.title ?? t('myVcard')}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {data.company_name ?? t('noText')}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.buttonBox}>
          <Button ref={refs} size='small' color='primary' onClick={handleClick}>
            {t('edit')}
          </Button>
          {
            user.can_clone_vcard === true && (
              <>

                <Button size="small" color="primary" onClick={cloneHandler}>
                  {t('clone')}
                </Button>
                <Button size="small" color="primary" onClick={handleOpen}>
                  {t('transfer')}
                </Button>
              </>
            )
          }
          <Button ref={refs} size='small' color='primary' onClick={handleDeleteClick}>
            {t('delete')}
          </Button>
          {/* <Button size="small" color="primary">
                    Learn More
                </Button> */}
        </CardActions>
      </Card>
      <Dialog open={open}>
        <DialogTitle style={{ paddingBottom: 0 }}>{t("transferHeading")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("transferMessage")}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            type="email"
            fullWidth
            id='email'
            label={t('emailAddress')}
            name={t('email')}
            autoComplete='email'
            variant="standard"
            value={transferEmail}
            onChange={(e) => setTransferEmail(e.target.value)}
          />
          <p style={{ color: 'red' }}>
            {transferMessage != "" && transferMessage}

          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button onClick={handleTransferClick}>{t("transfer")}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
