import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { APIMAIN } from '../../../../redux/API';
import axios from 'axios';
import { getLoggedInUser } from '../../../../utils/helpers';
import {
  fetchContent,
  fetchContentJson,
} from '../../../../redux/request/actions';
import { STYLES } from '../../../../styles/index';
import DefaultCKEditor from '../../../kit/DefaultCKEditor';
import DefaultTextArea from '../../../kit/DefaultTextArea';
import DefaultTextField from '../../../kit/DefaultTextField';
import SaveBtn from '../../../kit/SaveBtn';
import Header from '../Header';
import UploadButton from './UploadButton';
import UploadedImage from './UploadedImage';

import ReactDOM from 'react-dom';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { FroalaEditor } from './FroalaEditor';
const StyledAboutContent = styled.div`
  color: ${(props) => props.color};
  display: flex;
  flex-direction: column;

  #about {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color:white;
    margin: 10px 10px !important;
  }
`;
const StyledTitle = styled.h3`
  margin: 1rem 0;
  color: ${STYLES.COLORS.body};
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
`;

const MainContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector(
    (state) => state?.ConstructorReducer
  );
  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });
  const logoUrl = APIMAIN + `/api/v1/companies/${loggedUser?.company_id}`;
  const [logo, setLogo] = useState('');

  const getLogo = async () => {
    const res = await axios.get(logoUrl, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
    return res.data;
  };

  useEffect(() => {
    getLogo().then((res) => {
      setLogo(res.content_info);
    });
  }, []);
  const initState = {
    title: vcardDataToEdit?.title,
    subtitle: vcardDataToEdit?.subtitle,
    company_name: vcardDataToEdit?.company_name,
    about_title: vcardDataToEdit?.about_title,
    about_content: logo,
  };

  const [mainContent, setMainContent] = useState(initState);
  const [avatar, setAvatar] = useState(null);
  const [background, setBackground] = useState(null);

  const contentChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.id;
    const newStore = { ...mainContent };
    newStore[name] = value;
    setMainContent(newStore);
  };
  const [about_content, setAbout_content] = useState('');
  // const ckEditorChangeHandler = (value, name) => {
  //   console.log(value)
  // if (name === "about_content") {
  // setAbout_content(value)
  // }

  /*    const value = e.target.value
       const name = e.target.id */
  // const newStore = { ...mainContent }
  // newStore[name] = value
  // setMainContent(newStore)
  // }

  const froalaEditor = (value, name) => {
    // alert(value)
    // if (name === "about_content") {
    setAbout_content(value);
    // }

    /*    const value = e.target.value
       const name = e.target.id */
    // const newStore = { ...mainContent }
    // newStore[name] = value
    // setMainContent(newStore)
  };
  useEffect(() => {
    const newStore = { ...mainContent };
    newStore['about_content'] = about_content;
    setMainContent(newStore);
  }, [about_content]);

  useEffect(() => {
    if (vcardDataToEdit?.avatar?.url)
      setAvatar(APIMAIN + vcardDataToEdit?.avatar?.url);
    if (vcardDataToEdit?.background?.url)
      setBackground(APIMAIN + vcardDataToEdit?.background?.url);
  }, [vcardDataToEdit?.avatar?.url, vcardDataToEdit?.background?.url]);

  const isMessage = mainContent?.title !== '';

  const isAvatarSame = vcardDataToEdit?.avatar?.url
    ? avatar === APIMAIN + vcardDataToEdit?.avatar?.url
    : avatar === vcardDataToEdit?.avatar?.url;

  const isBackgroundSame = vcardDataToEdit?.background?.url
    ? background === APIMAIN + vcardDataToEdit?.background?.url
    : background === vcardDataToEdit?.background?.url;

  const isSameContent =
    JSON.stringify(mainContent) === JSON.stringify(initState);

  return (
    <form>
      <div
        id="about"
        dangerouslySetInnerHTML={{
          __html: logo,
        }}
      ></div>
    </form>
  );
};

export default MainContent;
