import React from 'react'
import styled from 'styled-components'
import { STYLES } from '../../styles'
import { useTranslation } from 'react-i18next'


const StyledRemoveBtn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  color: ${STYLES.COLORS.warning};
  cursor: pointer;
  text-transform: capitalize;
`

const RemoveBtn = ({ onClick, textContent }) => {
  const { t } = useTranslation();

  return (
    <StyledRemoveBtn onClick={onClick} type='button'>
      - {t('removeThis')} {textContent}
    </StyledRemoveBtn>
  )
}

export default RemoveBtn
