import React from 'react'
import styled from 'styled-components'
import { STYLES } from '../../styles/index'

export const StyledInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  color: ${STYLES.COLORS.body};
  margin-bottom: 1rem;
  h4 {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 1.125rem;
    color: inherit;
    font-weight: 400;
  }
  p {
    font-size: 0.875rem;
    font-weight: 200;
    color: ${STYLES.COLORS.body};
    margin-bottom: 4px;
  }
  span {
    color: ${STYLES.COLORS.warning};
  }
`

export const StyledTextField = styled.input`
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1.125rem;
  background-color: white;
  border: none;
  outline: none;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%),
    0 0 0 0.025rem #bdbdbd;
  &:focus {
    box-shadow: 0 0 0 0.125rem ${STYLES.COLORS.lightBlue};
  }
`

const DefaultTextField = ({
  label,
  subtitle,
  id,
  value,
  onChange,
  placeholder,
  required,
  type,
  minLength,
  onBlur,
  name
}) => {
  return (
    <StyledInputLabel onBlur={onBlur}>
      {label && (
        <h4>
          {label}
          <span>{required ? '*' : ''}</span>
        </h4>
      )}
      {subtitle && <p>{subtitle}</p>}
      <StyledTextField
        id={id}
        type={type}
        minLength={minLength}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        name={name}
      />
    </StyledInputLabel>
  )
}

export default DefaultTextField
