import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import { setColorScheme } from '../../../../redux/constructor/actions'
import { setBannerColor, setBannerSecondaryColor } from '../../../../redux/banner/actions';
import { getLoggedInUser } from '../../../../utils/helpers';
import API from '../../../../redux/API';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export default function ColorPicker({ title, label }) {
  const { vcardDataToEdit } = useSelector((state) => state.ConstructorReducer)
  let vcardId = vcardDataToEdit.id
  const dispatch = useDispatch()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [colorPicker, setColorPicker] = useState(vcardDataToEdit[label]);
  const [getId, setId] = useState("");
  const [getColorId, setColorID] = useState("");
  const [primary_color, setPrimary_color] = useState("");
  const [heading_color, setHeading_color] = useState("");
  const [body_color, setbody_color] = useState("");
  const [button_text_color, setbutton_text_color] = useState("");
  const [background_primary_color, setbackground_primary_color] = useState("");
  const [background_secondary_color, setbackground_secondary_color] = useState("");
  const [background_page_color, setbackground_page_color] = useState("");

  useEffect(() => {
    setColorPicker(vcardDataToEdit[label])
  }, [vcardDataToEdit, label])

  const handleClick = (event) => {
    setColorID(event.currentTarget.getAttribute('data-id'));
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleColorChange = (e) => {
    setColorPicker(e.hex)
    console.log(getColorId);
    // dispatch(setColorScheme(vcardId, { [label]: e.hex }))
    if(label === "primary_color"){
      setPrimary_color(e.hex); 
    }
    if(label === "heading_color"){
      setHeading_color(e.hex); 
    }
    if(label === "body_color"){
      setbody_color(e.hex); 
    }
    if(label === "button_text_color"){
      setbutton_text_color(e.hex); 
    }
    if(label === "background_primary_color"){
      setbackground_primary_color(e.hex); 
    }
    if(label === "background_secondary_color"){
      setbackground_secondary_color(e.hex); 
    }
    if(label === "background_page_color"){
      setbackground_page_color(e.hex); 
    }
    if(getColorId){
      dispatch(setBannerSecondaryColor(getColorId,localStorage.getItem('vcard_id'),localStorage.getItem('c_id'), { 'group_type': 3,[label]:  e.hex  }))
    }else{
      dispatch(setBannerColor(localStorage.getItem('vcard_id'),localStorage.getItem('c_id'), { 'group_type': 3, [label]: e.hex, 'color_code' : e.hex }))  
    }
    
  }


  let { auth, user } = getLoggedInUser();
  useEffect(() => {
    axios
      .get(API + `/companies/${localStorage.getItem('c_id')}/company_group_colors`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        data.map((item, index) => {
          if(item.group_type==3){
              setPrimary_color(item.primary_color); 
              setHeading_color(item.heading_color); 
              setbody_color(item.body_color); 
              setbutton_text_color(item.button_text_color); 
              setbackground_primary_color(item.background_primary_color); 
              setbackground_secondary_color(item.background_secondary_color); 
              setbackground_page_color(item.background_page_color); 
              setId(item.id);  
          }
        })
        
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [auth.token]);

  return (
    <div className='color_picker_item'>
      <Button
        aria-describedby={id}
        variant='contained'
        color='default'
        onClick={handleClick}
      >
        {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>
          <SketchPicker color={colorPicker} onChange={handleColorChange} />
          <button
            style={{ background: 0, border: 0, right: 0 }}
            onClick={() => setAnchorEl(null)}
          >
            <HighlightOffIcon />
          </button>
        </Typography>
      </Popover>
      {getId ?  
      <>
        {(label === "primary_color") ?                
        <div
          className='preview_color'
          onClick={handleClick}
          style={{ background: primary_color !==null ? primary_color : "#3d1ce0" }}
          data-id={getId}
        ></div>
        : ''
        }
        {(label === "heading_color") ?                
        <div
          className='preview_color'
          onClick={handleClick}
          style={{ background: heading_color !==null ? heading_color : "#3d1ce0" }}
          data-id={getId}
        ></div>
        : ''
        }
        {(label === "body_color") ?                
        <div
          className='preview_color'
          onClick={handleClick}
          style={{ background: body_color !==null ? body_color : "#3d1ce0" }}
          data-id={getId}
        ></div>
        : ''
        }
        {(label === "button_text_color") ?                
        <div
          className='preview_color'
          onClick={handleClick}
          style={{ background: button_text_color !==null ? button_text_color : "#3d1ce0" }}
          data-id={getId}
        ></div>
        : ''
        }
        {(label === "background_primary_color") ?                
        <div
          className='preview_color'
          onClick={handleClick}
          style={{ background: background_primary_color !==null ? background_primary_color : "#3d1ce0" }}
          data-id={getId}
        ></div>
        : ''
        }
        {(label === "background_secondary_color") ?                
        <div
          className='preview_color'
          onClick={handleClick}
          style={{ background: background_secondary_color !==null ? background_secondary_color : "#3d1ce0" }}
          data-id={getId}
        ></div>
        : ''
        }
        {(label === "background_page_color") ?                
        <div
          className='preview_color'
          onClick={handleClick}
          style={{ background: background_page_color !==null ? background_page_color : "#3d1ce0" }}
          data-id={getId}
        ></div>
        : ''
        }
      </>
        :
        <div
          className='preview_color'
          onClick={handleClick}
          style={{ background: "#3d1ce0" }}
          data-id={getId}
        ></div>
      }
    </div>
  )
}
