import CloseIcon from '@mui/icons-material/Close'
import React, { useCallback, useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  openBookingForm,
  setStartDate,
  setStartTime
} from '../../../redux/appointments/action'
import { getAvailableTime } from '../../../redux/availabilities/action'
import { StyledDefaultButton } from '../../kit/DefaultButton'
import Section from '../../kit/Section'

const Wrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  background-color: #fff;
`
const StyledCalendar = styled.div`
  display: flex;
  position: relative;

  & .react-calendar {
    border: none;
    width: 100%;
    line-height: 1;
    margin: 0 auto;
    &__navigation {
      justify-content: center;
      & .react-calendar__navigation__label {
        flex-grow: 0 !important;
      }
    }
  }
  & .react-calendar__tile {
    padding: 0.25rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    abbr {
      padding: 6px;
      border-radius: 50%;
    }
  }

  & .available-tile {
    abbr {
      color: #0070f3;
      border: 1px dashed #0070f3;
    }
  }

  & .react-calendar__tile--active {
    abbr {
      color: #fff;
      border: none;
    }
  }

  & .react-calendar__tile--now {
    background-color: #1087ff;
    color: #fff;
    &:hover {
      background-color: #1087ff;
      color: #fff;
    }
  }
`
const StyledCloseBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  width: 44px;
  height: 44px;
  background-color: #f0f0f0;
  &:hover {
    background-color: #e6e6e6;
  }
  & svg {
    font-size: 0.75rem;
  }
`
const StyledSelectedDate = styled.div`
  text-align: center;
  font-size: 1.125rem;
  padding: 0.75rem 0;
  font-weight: 500;
  border-top: 1px solid #e2e8f0;
  margin-top: 0.75rem;
`
const TimeButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const TimeButton = styled.button`
  border: none;
  outline: none;
  flex-basis: ${(p) => (p.ismobile ? 'calc(100% / 4)' : 'calc(100% / 7)')};
  max-width: ${(p) => (p.ismobile ? 'calc(100% / 4)' : 'calc(100% / 7)')};
  padding: 0.75em 0;
  line-height: 1;
  color: ${(p) => (p.active ? '#fff' : '#000')};
  background-color: ${(p) => (p.active ? '#006edc' : '#fff')};
  &:hover {
    background-color: ${(p) => (p.active ? '#1087ff' : '#e6e6e6')};
    color: ${(p) => (p.active ? '#fff' : '#000')};
  }
`
const StyledNoTime = styled.div`
  padding: 12px 0;
  text-align: center;
  line-height: 1;
`

function setToday() {
  const date = new Date()
  const day = date.getDate()
  const month = ('0' + (date.getMonth()+1)).slice(-2)
  const year = date.getFullYear()
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

function formatDate(el) {
  const date = new Date(el.getAttribute('aria-label'))
  const day = ('0' + date.getDate()).slice(-2)
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

const Appointments = ({ data, mode }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch()
  const [date, setDate] = useState(setToday())
  const [calendar, setCalendar] = useState(false)
  const [displayDate, setDisplayDate] = useState('')
  const [localTimezone, setLocalTimezone] = useState('')

  const allowBookTime = data?.['allow_book_time?']
  const { appointment_time_format } = data

  const { start_date, start_time } = useSelector((s) => s.appointmentsReducer)
  const { availableTime, availabilities } = useSelector(
    (s) => s.availabilitiesReducer
  )

  const availableTimeArr = availableTime?.[date]

  const formatAvailableTime = Array.from(
    new Set(
      availableTimeArr?.reduce((acc, rec) => {
        const hh = ('0' + rec[0]).slice(-2)
        const mm = ('0' + rec[1]).slice(-2)
        if (appointment_time_format === 12) {
          switch (hh) {
            case '00':
              return [...acc, `${'12'}:${mm}am`]
            case '12':
              return [...acc, `${'12'}:${mm}pm`]
            case '13':
              return [...acc, `${'01'}:${mm}pm`]
            case '14':
              return [...acc, `${'02'}:${mm}pm`]
            case '15':
              return [...acc, `${'03'}:${mm}pm`]
            case '16':
              return [...acc, `${'04'}:${mm}pm`]
            case '17':
              return [...acc, `${'05'}:${mm}pm`]
            case '18':
              return [...acc, `${'06'}:${mm}pm`]
            case '19':
              return [...acc, `${'07'}:${mm}pm`]
            case '20':
              return [...acc, `${'08'}:${mm}pm`]
            case '21':
              return [...acc, `${'09'}:${mm}pm`]
            case '22':
              return [...acc, `${'10'}:${mm}pm`]
            case '23':
              return [...acc, `${'11'}:${mm}pm`]
            default:
              break
          }
          return [...acc, `${hh}:${mm}am`]
        } else {
          return [...acc, `${hh}:${mm}`]
        }
      }, [])
    )
  )

  const calendarHandler = () => {
    setCalendar(!calendar)
    setDate(setToday())
  }

  const startDateHandler = (e) => {
    const date = new Date(e)

    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2) 
    const year = date.getFullYear()

    const availableDate = `${year}-${month}-${day}`
    const startDate = `${day}.${month}.${year}`

    setDate(availableDate)

    if (start_date !== startDate) {
      dispatch(setStartDate(startDate))
    }

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }

    setDisplayDate(date.toLocaleString('us-us', options))
  }

  const startTimeHandler = (e) => {
    const value = e.target.value
    const hh = value.slice(0, 2)
    const mm = value.slice(3, 5)
    const formattedhh = () => {
      if (value.includes('pm')) {
        switch (hh) {
          case '12':
            return '12'
          case '01':
            return '13'
          case '02':
            return '14'
          case '03':
            return '15'
          case '04':
            return '16'
          case '05':
            return '17'
          case '06':
            return '18'
          case '07':
            return '19'
          case '08':
            return '20'
          case '09':
            return '21'
          case '10':
            return '22'
          case '11':
            return '23'
          default:
            break
        }
      } else if (value.includes('am')) {
        if (hh === '12') {
          return '00'
        } else {
          return hh
        }
      } else {
        return hh
      }
    }

    if (value !== start_time) {
      dispatch(setStartTime(`${formattedhh()}:${mm}`))
    }
    dispatch(openBookingForm(true))
  }

  const getTimeAvailableHandler = useCallback(() => {
    dispatch(getAvailableTime(data?.id, localTimezone))
  }, [dispatch, data?.id, localTimezone])

  const colorizeTiles = useCallback(() => {
    const tile = document.querySelectorAll('.react-calendar__tile')
    const tilesArray = Array.from(tile)

    const availableTimeArray = Object.entries(availableTime)
      ?.filter((el) => el[1].length > 0)
      ?.map((el) => el[0])

    const calendarTimeArray = tilesArray.map((tile) => {
      const abbr = tile.querySelector('abbr')
      return formatDate(abbr)
    })

    const uniqTimeArr = calendarTimeArray.filter(
      (item) => availableTimeArray.indexOf(item) > -1
    )

    tilesArray.forEach((tile) => {
      const abbr = tile.querySelector('abbr')
      if (uniqTimeArr.filter((item) => item === formatDate(abbr)).length > 0) {
        tile.classList.add('available-tile')
      }
    })
  }, [availableTime, date])

  const isMobile = mode === 'mobile'

  useEffect(() => {
    setLocalTimezone((new Date().getTimezoneOffset() / 60) * -1)
    if (calendar) {
      getTimeAvailableHandler()
    }
  }, [
    calendar,
    getTimeAvailableHandler,
    dispatch,
    localTimezone,
    availabilities,
  ])

  useEffect(() => {
    colorizeTiles()
  }, [colorizeTiles])
  
  return (
    allowBookTime && (
      <Section>
        {!calendar && (
          <div className='justify-content-center d-flex'>
            <StyledDefaultButton
              bgcolor={(data?.user_type === null) || (data?.user_type === 'sales') ? data?.primary_color : data?.banner_group.primary_color}
              color={(data?.user_type === null) || (data?.user_type === 'sales') ? data?.button_text_color : data?.banner_group?.button_text_color}
              onClick={calendarHandler}
              variant='contained'
            >
              {data?.appointment_button_label}
            </StyledDefaultButton>
          </div>
        )}
        {calendar && (
          <Wrapper>
            <StyledCalendar>
              <Calendar
                locale='en-US'
                next2Label={null}
                prev2Label={null}
                showNeighboringMonth={false}
                defaultView='month'
                minDetail='year'
                minDate={new Date()}
                onChange={startDateHandler}
                onActiveStartDateChange={getTimeAvailableHandler}
              />
              <StyledCloseBtn onClick={calendarHandler}>
                <CloseIcon />
              </StyledCloseBtn>
            </StyledCalendar>
            <StyledSelectedDate>{displayDate}</StyledSelectedDate>
            {!formatAvailableTime?.length && (
              <StyledNoTime>{t('noSlotsavailable')}</StyledNoTime>
            )}
            <TimeButtons>
              {formatAvailableTime?.map((el) => {
                const active = el === start_time
                return (
                  <TimeButton
                    onClick={startTimeHandler}
                    active={active}
                    value={el}
                    key={el}
                    ismobile={isMobile}
                  >
                    {el}
                  </TimeButton>
                )
              })}
            </TimeButtons>
          </Wrapper>
        )}
      </Section>
    )
  )
}

export default Appointments
