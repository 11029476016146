import { combineReducers } from 'redux';
import Account from './account/reducer';
import appointmentsReducer from './appointments/reducer';
import appointmentsSettingsReducer from './appointmentsSettings/reducer';
import Authorization from './auth/reducer';
import availabilitiesReducer from './availabilities/reducer';
import ConstructorReducer from './constructor/reducer';
import galleryReducer from './gallery/reducer';
import preloaderReducer from './preloader/reducer';
import Request from './request/reducer';
import servicesReducer from './services/reducer';
import testimonialsReducer from './testimonials/reducer';
import videoLibraryReducer from './videoLibrary/reducer';
import socialLinksReducer from './socialLinks/reducer';
import Agent from './agent/reducer';
import ContentAdminReducer from './contentAdmin/reducer';


// banner reducer
import Banner from './banner/reducer';
import RecordVideo from './recordvideo/reducer';

const rootReducer = () =>
  combineReducers({
    ConstructorReducer,
    Authorization,
    Account,
    Request,
    preloaderReducer,
    socialLinksReducer,
    servicesReducer,
    appointmentsSettingsReducer,
    appointmentsReducer,
    availabilitiesReducer,
    galleryReducer,
    testimonialsReducer,
    videoLibraryReducer,
    Banner,
    RecordVideo,
    Agent,
    ContentAdminReducer
  });

export default rootReducer;
