import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useRef, useState } from 'react';
import context from 'react-bootstrap/esm/AccordionContext';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchCloneVCardAPI,
  transferCloneVCardAPI,
  fetchVcardDataToEditor,
  updateVcardData,
} from '../../../redux/constructor/actions';
import { getLoggedInUser } from '../../../utils/helpers';
import { APIMAIN } from '../../../redux/API';
// datatabel
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { 
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton, 
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
  },
  buttonBox: {
    display: 'contents ',
  },
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function CompanyCard({ data }) {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.ConstructorReducer);

  const dispatch = useDispatch();
  const classes = useStyles();

  const history = useHistory();
  const refs = useRef();
  const { user } = getLoggedInUser();
  const [userData, setUserData] = useState({ ...user });
  const [open, setOpen] = React.useState(false);
  const [transferEmail, setTransferEmail] = React.useState('');
  const [transferMessage, setTransferMessage] = React.useState('');
  const handleOpen = (id) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTransferEmail('');
    setTransferMessage('');
  };
  localStorage.removeItem('c_id');
  localStorage.removeItem('c_info');
  localStorage.removeItem('html_editer');
  function handleClick(data) {
    // alert(data.vcard_id[0]);
    localStorage.setItem('vcard_id', data.vcard_id[0]);
    dispatch(fetchVcardDataToEditor(data.vcard_id[0]));
    localStorage.setItem('c_id', data.id);
    if(userData?.user_type === 'super_admin'){
      history.replace('/distributor');
    }else{
      history.replace('/ContentAdmin');
    }
  }
  const cloneHandler = () => {
    dispatch(fetchCloneVCardAPI(data.id));
  };
  // const handleDeleteClick = () => {
  //   const confirmBox = window.confirm(
  //     "Do you really want to delete this Vcard?"
  //   )
  //   if (confirmBox === true) {
  //     dispatch(deleteCloneVCardAPI(data.id))
  //   }
  // }
  const handleTransferClick = () => {
    //requiredField
    if (transferEmail.length <= 1) {
      setTransferMessage(t('requiredField'));
      return;
    }
    dispatch(transferCloneVCardAPI(data.id, transferEmail));
  };

  useEffect(() => {
    if (error != undefined && error != '') {
      console.log('vcardDataToEdit', error);
      setTransferMessage(t('notfoundMessage'));
    }
  }, [error]);

  //datatable
  const defaultTheme = createTheme();
  const columns = [
    { field: 'name', headerName: 'Name' , width: 900},
    { field: 'actions', headerName: 'Actions', renderCell: (params) => {
      return (
        <Button
          onClick={(e) => handleClick(params.row)}
          variant="contained"
        >
          View
        </Button>
      );
    } }
  ]
  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState(data.rows);
  function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  const requestSearch = (searchedVal: string) => {
    const filteredRows = data.filter((o: any) => {
        return Object.keys(o).some((k: any) => {
            return  o[k].toString().toLowerCase().indexOf(searchedVal.toLowerCase()) != -1;
        })
    });
    console.log(filteredRows);
    // setTableData(filteredRows);
};
  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  const classes = useStyles();

  return (
     <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarQuickFilter
  quickFilterParser={(searchInput) =>
    searchInput.split(',').map((value) => value.trim())
  }
  quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
  debounceMs={200} // time before applying the new quick filter value
/>
      </div>
    </div>
  );
}

  interface QuickSearchToolbarProps {
      clearSearch: () => void;
      onChange: () => void;
      value: string;
    }

  return (
    <>
      <Card className={classes.root}>
        <div style={{ height: 900, width: '100%' }}>
          <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            rows={data}
            columns={columns}
            componentsProps={{
             toolbar: {
                 showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
              },
           }}
          />
        </div>
      </Card>
      <Dialog open={open}>
        <DialogTitle style={{ paddingBottom: 0 }}>
          {t('transferHeading')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('transferMessage')}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            type="email"
            fullWidth
            id="email"
            label={t('emailAddress')}
            name={t('email')}
            autoComplete="email"
            variant="standard"
            value={transferEmail}
            onChange={(e) => setTransferEmail(e.target.value)}
          />
          <p style={{ color: 'red' }}>
            {transferMessage != '' && transferMessage}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button onClick={handleTransferClick}>{t('transfer')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
