import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  deleteAvailabilities,
  updateAvailabilities
} from '../../../../redux/availabilities/action'
import { STYLES } from '../../../../styles'
import DefaultSelect from '../../../kit/DefaultSelect'

const StyledListItem = styled.li`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: ${STYLES.COLORS.body};
`
const StyledTime = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 5px;
`
const StyledLine = styled.div`
  height: 2px;
  width: 10px;
  margin-bottom: 1.125rem;
  background-color: ${STYLES.COLORS.body};
`
const StyledBtns = styled.div`
  display: flex;
  column-gap: 10px;
`
const StyledAddBtn = styled.button`
  color: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: ${STYLES.COLORS.lightBlue};
  outline: none;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  line-height: 29px;
  margin-bottom: 3px;
  font-size: 1.5rem;
`
const StyledDeleteBtn = styled(StyledAddBtn)`
  background-color: ${STYLES.COLORS.warning};
`
const StyledTimeLabel = styled.h5`
  text-transform: capitalize;
  padding-left: 1rem;
  font-size: 1rem;
  margin-top: 0;
  font-size: 1.125rem;
  color: ${STYLES.COLORS.body};
  font-weight: 400;
`
const CreateTimeLine = (props) => {
  const { exactWeekday, availabilities, createNewTimeLine, id, index, item } =
    props
  const dispatch = useDispatch()
  const lastItem = exactWeekday.length - 1

  const hours = [
    {
      12: '12am',
      24: '00',
    },
    {
      12: '01am',
      24: '01',
    },
    {
      12: '02am',
      24: '02',
    },
    {
      12: '03am',
      24: '03',
    },
    {
      12: '04am',
      24: '04',
    },
    {
      12: '05am',
      24: '05',
    },
    {
      12: '06am',
      24: '06',
    },
    {
      12: '07am',
      24: '07',
    },
    {
      12: '08am',
      24: '08',
    },
    {
      12: '09am',
      24: '09',
    },
    {
      12: '10am',
      24: '10',
    },
    {
      12: '11am',
      24: '11',
    },
    {
      12: '12pm',
      24: '12',
    },
    {
      12: '01pm',
      24: '13',
    },
    {
      12: '02pm',
      24: '14',
    },
    {
      12: '03pm',
      24: '15',
    },
    {
      12: '04pm',
      24: '16',
    },
    {
      12: '05pm',
      24: '17',
    },
    {
      12: '06pm',
      24: '18',
    },
    {
      12: '07pm',
      24: '19',
    },
    {
      12: '08pm',
      24: '20',
    },
    {
      12: '09pm',
      24: '21',
    },
    {
      12: '10pm',
      24: '22',
    },
    {
      12: '11pm',
      24: '23',
    },
  ]

  const mm = ['00', '15', '30', '45']

  const { start_time_hh, start_time_mm, end_time_hh, end_time_mm } = item

  const deleteHandler = () => dispatch(deleteAvailabilities(id))

  const setTime = (e) => {
    const value = e.target.value
    const key = e.target.name
    dispatch(
      updateAvailabilities(
        availabilities.map((item) =>
          item.id !== id
            ? item
            : {
                ...item,
                [key]: value,
              }
        )
      )
    )
  }

  return (
    <StyledListItem>
      <StyledTime>
        <div>
          {!index && <StyledTimeLabel>from</StyledTimeLabel>}
          <DefaultSelect
            onChange={setTime}
            array={hours}
            name='start_time_hh'
            value={start_time_hh}
          />{' '}
          <DefaultSelect
            onChange={setTime}
            array={mm}
            name='start_time_mm'
            value={start_time_mm}
          />
        </div>
        <StyledLine></StyledLine>
        <div>
          {!index && <StyledTimeLabel>to</StyledTimeLabel>}
          <DefaultSelect
            onChange={setTime}
            array={hours}
            name='end_time_hh'
            value={end_time_hh}
          />{' '}
          <DefaultSelect
            onChange={setTime}
            array={mm}
            name='end_time_mm'
            value={end_time_mm}
          />
        </div>
      </StyledTime>
      <StyledBtns>
        {lastItem === index && (
          <StyledAddBtn type='button' onClick={createNewTimeLine}>
            +
          </StyledAddBtn>
        )}
        <StyledDeleteBtn type='button' onClick={deleteHandler}>
          -
        </StyledDeleteBtn>
      </StyledBtns>
    </StyledListItem>
  )
}

export default CreateTimeLine
