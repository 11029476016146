const linkMatches = {
  facebook_link: function (value) {
    let check = value.match(/.facebook./)
    if (check !== null) return value
    else return 'https://www.facebook.com/' + value
  },
  facebook_business_page: function (value) {
    let check = value.match(/.facebook./)
    if (check !== null) return value
    else return 'https://www.facebook.com/' + value
  },
  facebook_business_group: function (value) {
    let check = value.match(/.facebook./)
    if (check !== null) return value
    else return 'https://facebook.com/groups/' + value
  },
  cashapp_link: function (value) {
    let check = value.match(/.cash./)
    if (check !== null) return value
    else return 'https://cash.app/' + value
  },
  paypal_link: function (value) {
    let check = value.match(/.paypal./)
    if (check !== null) return value
    else return 'https://www.paypal.com/paypalme/' + value
  },
  instagram_link: function (value) {
    let check = value.match(/.instagram./)
    if (check !== null) return value
    else return 'https://www.instagram.com/' + value + '/'
  },
  linkedin_link: function (value) {
    let check = value.match(/.linkedin./)
    if (check !== null) return value
    else return 'https://www.linkedin.com/in/' + value + '/'
  },
  linkedIn_company: function (value) {
    let check = value.match(/.linkedin./)
    if (check !== null) return value
    else return 'https://www.linkedin.com/company/' + value + '/'
  },
  twitter_link: function (value) {
    let check = value.match(/.twitter./)
    if (check !== null) return value
    else return 'https://twitter.com/' + value
  },
  web_link: function (value = '') {
    let check = value ? value.match(/https.+\/\//) : ''
    if (check !== null) return value
    else return 'https://' + value
  },
  connect_link: function (value = '') {
    let check = value ? value.match(/https.+\/\//) : ''
    if (check !== null) return value
    else return 'https://' + value
  },
  tiktok_link: function (value = '') {
    let check = value ? value.match(/https.+\/\//) : ''
    if (check !== null) return value
    else return 'https://' + value
  },
  social_link_alignable: function (value) {
    let check = value.match(/.alignable./)
    if (check !== null) return value
    else return 'http://alignable.com/' + value + '/'
  },
}

export default function validSocialLinks(label, value) {
  if (value === undefined) value = ''
  return linkMatches[label](value)
}
