import * as types from './types'

const initState = {
  testimonials: [],
  deletedTestimonials: [],
}

const testimonialsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.ADD_TESTIMONIALS_LIST_ITEM:
      return {
        ...state,
        testimonials: [...state.testimonials, action.testimonial],
      }

    case types.UPDATE_TESTIMONIALS_LIST_ITEM:
      return {
        ...state,
        testimonials: (() => {
          const data = action.data
          const newState = state.testimonials.map((item) =>
            item.id === data.id ? data : item
          )
          return newState
        })(),
      }

    case types.UPDATE_TESTIMONIALS_LIST:
      return { ...state, testimonials: [...action.data] }

    case types.DELETE_TESTIMONIALS_LIST_ITEM:
      return {
        ...state,
        testimonials: (() => {
          const id = action.id
          const newState = state.testimonials.filter((item) => item.id !== id)
          return newState
        })(),
        deletedTestimonials: (() => {
          const id = action.id
          if (id >= 0) {
            return [...state.deletedTestimonials, id]
          } else return [...state.deletedTestimonials]
        })(),
      }

    case types.CLEAR_DELETED_TESTIMONIALS:
      return { ...state, deletedTestimonials: [] }

    default:
      return { ...state }
  }
}
export default testimonialsReducer
