import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CompanyInfo from './Editors/companyInfo/CompanyInfo';
import './index.scss';
import SidebarMainMenu from './mainMenu/SidebarMainMenu';
import SidebarComponent from './SidebarComponent';
import Banner from './Editors/Banner';
import HTMLEditor from './Editors/HTMLeditor/HTMLEditor';
import ColoreScheme from './Editors/ColoreEditor';
import GroupA from './Editors/colorGroupA/ColoreEditor';
import GroupB from './Editors/colorGroupB/ColoreEditor';
import GroupC from './Editors/colorGroupC/ColoreEditor';
import GroupD from './Editors/colorGroupD/ColoreEditor';
import Bannertwo from './Editors/Bannertwo';
import BannerGroup from './Editors/BannerGroup';
import Calendar from './Editors/calendar/Calendar';
import DistributorID from './Editors/distributorID/DistributorID';
import MenuVideo from './Editors/MenuVideo';
import {
  getVideoLinkByComppany,
  CreateLinkByComppany
} from '../../redux/contentAdmin/actions';

const StyledSidebar = styled.aside`
  position: relative;
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  min-width: 35rem;
  max-width: 35rem;
`;

export default function Sidebar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { videoLink, loading } = useSelector((state) => state.ContentAdminReducer);
  useEffect(() => {
    dispatch(getVideoLinkByComppany())
  }, [dispatch]);
  useEffect(() => {
    if(videoLink.length > 12){
      console.log("visit",videoLink.length)
    }else{      
      dispatch(CreateLinkByComppany('Banner'));
      dispatch(CreateLinkByComppany('Main Content'));
      dispatch(CreateLinkByComppany('Set Vcard Default Language'));
      dispatch(CreateLinkByComppany('Record a Greeting video'));
      dispatch(CreateLinkByComppany('Greeting Video'));
      dispatch(CreateLinkByComppany('Phone/Social Links'));
      dispatch(CreateLinkByComppany('Calendar'));
      dispatch(CreateLinkByComppany('DistributorID'));
      dispatch(CreateLinkByComppany('Gallery'));
      dispatch(CreateLinkByComppany('Our Stories'));
      dispatch(CreateLinkByComppany('Testimonials Video Library'));
      dispatch(CreateLinkByComppany('Video Library'));
      dispatch(CreateLinkByComppany('Settings'));
    }
  }, [dispatch]);

  const { component } = useSelector((s) => s.ConstructorReducer);

  const list = {
    Banner,
    Calendar,
    'Company Info': CompanyInfo,
    'HTML Editor': HTMLEditor,
    // 'Color Scheme': ColoreScheme,
    'Group A': GroupA,
    'Group B': GroupB,
    'Group C': GroupC,
    'Group D': GroupD,
    'Image Upload': Bannertwo,
    'Menu Video': MenuVideo,
    'Distributor ID': DistributorID,
  };

  const components = [
    {
      title: 'Banner',
      description: '',
      component: Banner,
    },
    {
      title: 'Company Info',
      description: '',
      component: CompanyInfo,
    },
    {
      title: 'HTML Editor',
      description: '',
      component: HTMLEditor,
    },
    // {
    //   title: 'Color Scheme',
    //   description: '',
    //   component: ColoreScheme,
    // },
    {
      title: 'Image Upload',
      description: '',
      component: Bannertwo,
    },
    // {
    //   title: 'Calendar',
    //   description: '',
    //   component: Calendar,
    // },
    {
      title: 'Distributor ID',
      description: '',
      component: DistributorID,
    },
    {
      title: 'Group A',
      description: '',
      component: GroupA,
    },
    {
      title: 'Group B',
      description: '',
      component: GroupB,
    },
    {
      title: 'Group C',
      description: '',
      component: GroupC,
    },
    {
      title: 'Group D',
      description: '',
      component: GroupD,
    },    
    {
      title: 'Menu Video',
      description: '',
      component: MenuVideo,
    },
  ];
  
  return (
    <StyledSidebar>
      {!component ? (
        <SidebarMainMenu components={components} />
      ) : (
        <SidebarComponent Component={list[component]} />
      )}
    </StyledSidebar>
  );
}
