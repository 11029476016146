import React, { useState } from 'react';
import { isCompositeComponentWithType } from 'react-dom/test-utils';
import styled from 'styled-components';
import { STYLES } from '../../../styles';
import { getLoggedInUser } from '../../../utils/helpers';
import ListItem from './SidebarListItem';

const StyledSidebarListGroup = styled.ul`
  padding: 0;
  border-top: 1px solid ${STYLES.COLORS.sidebarBorder};
  margin: 0;
`;

const SidebarListGroup = ({ components }) => {
  let { user } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });

  return (
    <StyledSidebarListGroup>
      {components.map((item) => {
        if (
          item.title === 'Greeting Video' &&
          (loggedUser?.user_type === 'distributor' ||
            (loggedUser?.user_type === null || loggedUser?.user_type ==='sales'))
        ) {
          return null;
        } else if (
          item.title === 'Image Uploade' &&
          (loggedUser?.user_type === 'admin' || loggedUser?.user_type === 'distributor')
        ) {
          return null;
        }else {
          return <ListItem key={item.id} item={item} />;
        }
      })}
    </StyledSidebarListGroup>
  );
};

export default SidebarListGroup;
