import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useRef, useState } from 'react';
import context from 'react-bootstrap/esm/AccordionContext';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import {
  fetchCloneVCardAPI,
  transferCloneVCardAPI,
  fetchVcardDataToEditor,
  updateVcardData,
} from '../../../redux/constructor/actions';
import {
  fetchChangePassword,
} from '../../../redux/account/actions';
import { getLoggedInUser } from '../../../utils/helpers';
import { APIMAIN } from '../../../redux/API';
// datatabel
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { 
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton, 
  GridToolbarQuickFilter
} from '@mui/x-data-grid'
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 1040,
    backgroundSize: 'contain',
  },
  buttonBox: {
    display: 'contents ',
  },
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function CompanyCard({ data }) {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.ConstructorReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const refs = useRef();
  const { user } = getLoggedInUser();
  const [userData, setUserData] = useState({ ...user });
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [cPassword, setCPassword] = React.useState('');
  const [id, setID] = React.useState('');
  const [transferMessage, setTransferMessage] = React.useState('');
  const handleOpen = (id) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setPassword('');
    setCPassword('');
    setTransferMessage('');
  };
  localStorage.removeItem('c_id');
  localStorage.removeItem('c_info');
  localStorage.removeItem('html_editer');
  function handleClick(data) {
    localStorage.setItem('user_id', data.id);
    // dispatch(fetchVcardDataToEditor(data.v_card_ids[0]));
      history.replace('/CardList');
  }
  const cloneHandler = () => {
    dispatch(fetchCloneVCardAPI(data.id));
  };

  const handlePasswordClick = () => {
    if(password === cPassword){      
      dispatch(fetchChangePassword(id.id, password,cPassword));
      setOpen(false);
      setPassword('');
      setCPassword('');
      setTransferMessage('');
    }else{
      alert("Your password and confirmation password must match");
    }
  };

  useEffect(() => {
    if (error != undefined && error != '') {
      console.log('vcardDataToEdit', error);
      setTransferMessage(t('notfoundMessage'));
    }
  }, [error]);

  //datatable
  const defaultTheme = createTheme();
  const columns = [
    { field: 'username', headerName: 'Name' , width: 200},
    { field: 'email', headerName: 'Email' , width: 300},
    { field: 'created_at', headerName: 'Join Date', renderCell: (params) => 
      {
        return (
          <p>
            {Moment(params.row.created_at).local().format("DD-MM-YYYY")}
          </p>
        );
      }, width:120 
    },
    { field: 'activity_info', headerName: 'Is Paid', width: 150, renderCell: (params) => 
      {
        return (
           <>
            <div className="form-check" style={{ marginRight: '10px' }}>
                <input 
                    checked={ params.row.activity_info.payment_made==true ? 'checked':''}
                    type="checkbox"
                    className="form-check-input"
                    style={{ height: '20px', width: '20px',marginRight: '10px' }}
                    value={params.row.activity_info.is_active}
                />
                <label className="form-check-label" id="booty-check">Yes</label>
            </div>
            <div className="form-check">
                <input 
                  checked={ params.row.activity_info.payment_made==false ? 'checked':''}
                    type="checkbox"
                    className="form-check-input"
                    style={{ height: '20px', width: '20px' }}
                />
                <label className="form-check-label" id="booty-check" >No</label>
            </div>
        </>
        );
      } 
    },
    { field: 'vcard_url', headerName: 'Card URL' ,  width: 300, renderCell: (params) => 
      {
        return (
           <>
            <div className="form-check">
              <a href={params.row.vcard_url}  target="_blank">{params.row.vcard_url}</a>
            </div>
        </>
        );
      } 
    },
    { field: 'number_of_vcards', headerName: 'Number of Vcards' , width: 100},
    { field: 'actions', headerName: 'Actions', renderCell: (params) => {
      return (
        <Button
          onClick={(e) => handleClick(params.row)}
          variant="contained"
        >
          View
        </Button>
      );
    } },
    { field: 'ResetPassword', headerName: 'Reset Password', width: 260, renderCell: (params) => 
      {
        return (
          <Button
            onClick={(e) => {
              setOpen(true);
              setID(params.row);
            }}
            variant="contained"
          >
            Reset Password
          </Button>
        );
      } 
    }
  ]
  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState(data.rows);
  function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  const requestSearch = (searchedVal: string) => {
    const filteredRows = data.filter((o: any) => {
        return Object.keys(o).some((k: any) => {
            return  o[k].toString().toLowerCase().indexOf(searchedVal.toLowerCase()) != -1;
        })
    });
    console.log(filteredRows);
    // setTableData(filteredRows);
};
  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
 

  return (
     <div>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput.split(',').map((value) => value.trim())
          }
          quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
          debounceMs={200} // time before applying the new quick filter value
        />
      </div>
    </div>
  );
}

  interface QuickSearchToolbarProps {
      clearSearch: () => void;
      onChange: () => void;
      value: string;
    }

  return (
    <>
      <Card>
        <div style={{ height: 1000, width: '100%' }}>
          <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            rows={data}
            columns={columns}
            componentsProps={{
             toolbar: {
                 showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
              },
           }}
          />
        </div>
      </Card>
      
      <Dialog open={open}>
        <DialogTitle style={{ paddingBottom: 0 }}>
          {t('Change password')}
        </DialogTitle>
        <DialogContent>
          {/*<DialogContentText>{t('transferMessage')}</DialogContentText>*/}
          <TextField
            autoFocus
            margin="dense"
            type="password"
            fullWidth
            id="password"
            label={t('password')}
            name={t('password')}
            autoComplete="password"
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            type="password"
            fullWidth
            id="email"
            label={t('confirmPassword')}
            name={t('confirmPassword')}
            autoComplete="email"
            variant="standard"
            onChange={(e) => setCPassword(e.target.value)}
          />
          <p style={{ color: 'red' }}>
            {transferMessage != '' && transferMessage}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button onClick={handlePasswordClick}>{t('requestReset')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
