export const SAVE_CONTENT_TO_SEND = 'SAVE_CONTENT_TO_SEND'
export const SAVE_GALLERY_TO_SEND = 'SAVE_GALLERY_TO_SEND'
export const DELETE_GALLERY_ITEM_FROM_SEND = 'DELETE_GALLERY_ITEM_FROM_SEND'
export const SUCCESS_GALLERY_ITEM_FROM_SEND = 'SUCCESS_GALLERY_ITEM_FROM_SEND'

export const SEND_CONTENT = 'SEND_CONTENT'
export const SEND_CONTENT_SUCCESS = 'SEND_CONTENT_SUCCESS'
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const SEND_CONTENT_FAILED = 'SEND_CONTENT_FAILED'

export const ERROR_BY_DOMAIN = 'ERROR_BY_DOMAIN'
export const STEP_DOMAIN_IS_VALID = 'STEP_DOMAIN_IS_VALID'
export const FETCH_DOMAIN_IS_VALID = 'FETCH_DOMAIN_IS_VALID'

export const CREATE_FIRST_VCARD_SUCCESS = 'CREATE_FIRST_VCARD_SUCCESS'
export const CREATE_FIRST_VCARD_FAILED = 'CREATE_FIRST_VCARD_FAILED'

export const FALSE_FIRST_VCARD_FAILED = 'FALSE_FIRST_VCARD_FAILED'
export const SUCCESS_BY_DOMAIN = 'SUCCESS_BY_DOMAIN'
