import MenuBar from './List';
import React, { useState } from 'react';
import Title from '../Title';

const CorporateAccount = () => {
  return (
    <div>
      <Title>Menu Video</Title>
      <MenuBar />
    </div>
  );
};

export default CorporateAccount;
