import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';

export default function UserTable({ allUsers }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell align="right">User Type</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Vcards</TableCell>
            <TableCell align="right">Is Active</TableCell>
            <TableCell align="left">Payment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allUsers?.length > 0 ? (
            allUsers.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.username}
                </TableCell>
                <TableCell align="right">
                  {row.user_type !== null ? row.user_type : 'Normal User'}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.number_of_vcards}</TableCell>
                <TableCell align="right">
                  {row.activity_info?.is_active ? (
                    <DoneIcon style={{ color: 'red' }} />
                  ) : (
                    <CancelIcon style={{ color: 'red' }} />
                  )}
                </TableCell>
                <TableCell align="left">
                  <b>Amount</b> : {row.activity_info.amount}<br/>
                  <b>Subscription Plan</b> : {row.activity_info.subscription_plan}<br/>
                  <b>Next Payment Date</b> : {row.activity_info.next_payment_date}<br/></TableCell>
              </TableRow>
            ))
          ) : (
            <Typography varaint="h5">No Users Found</Typography>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
