import { t } from 'i18next';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkDomainOnValid,
  fetchDomainInSteppik,
} from '../../redux/request/actions';

export default function Domain({ distributorId }) {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.Request);

  const [domain, setDomain] = useState('');

  function handleContentChange(e) {
    let newValue = e.target.value
      ?.toLowerCase()
      ?.replace(/\s+/g, '-')
      ?.replace(/^[-]$/gi, '')
      ?.replace(/[^a-z0-9-]/g, '');
    let trimmedValue = newValue.replace(/^[^a-z\d]*|[^a-z\d]*$/gi, '');
    const label = 'domain_prefix';
    formData.set(label, trimmedValue);
    setDomain(newValue);
    dispatch(checkDomainOnValid(newValue.trim()));
  }

  return (
    <Form style={{ width: '100%' }}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        {t('addyourCustomSubdomain')} {t('note')}: <br></br>
        <span style={{ color: 'rgba(240,10,10,.8)' }}>
          {!distributorId ? t('editDomainDesc') : ''}
          <br />
          {t('editDomainDesc2')}
        </span>{' '}
        <br></br>
        {t('yourCardPublishURL')} <br></br>
        {t('ex')}:{t('yournameDomain')}
      </div>
      <Form.Group className="mb-3">
        <Form.Label className="form_title">
          {t('domain')}
          <span style={{ color: 'red' }}>*</span>
        </Form.Label>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Form.Control
            style={{
              width: '100%',
              padding: '0.375rem 0.75rem',
              border: '1px solid #ced4da',
              borderRadius: '0.25rem',
            }}
            onFocus={() => {
              dispatch(fetchDomainInSteppik(false));
            }}
            required
            placeholder={t('myvcard')}
            value={domain}
            onChange={handleContentChange}
          />
          <span style={{ fontSize: '18px' }}>.talkingvcard.net</span>
        </div>
      </Form.Group>
    </Form>
  );
}
