import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Section from '../../kit/Section';
import join_team from '../../../assets/images/joinourteam.png';
import new_join_team from '../../../assets/images/join2.png';
import { APIMAIN } from '../../../redux/API';

const JoinTeam = ({ data }) => {

  return (
      <Section>
        {data?.user_type === 'admin' && data?.distributor_url && (
          <>
            <div className="">
              <a href={data?.distributor_url + data?.distributor_id}  style={{width:"100%"}} target="_blank">
                <img src={data?.join_team_img ? APIMAIN + data?.join_team_img?.url : join_team} alt="" className="fr-fic fr-dib" style={{width:"100%"}}/>
              </a>
            </div>
          </>
        )}
        {data?.user_type === 'distributor' && data?.distributor_url && (
          <>
            <div className="">
              <a href={data?.distributor_url + data?.distributor_id}  style={{width:"100%"}} target="_blank">
                <img src={data?.join_team_img ? APIMAIN + data?.join_team_img?.url : join_team} alt="" className="fr-fic fr-dib" style={{width:"100%"}}/>
              </a>
            </div>
          </>
        )}
        {data?.id === 865 && (
          <>
            <div className="">
              <a href="https://mbnconnect.com/" style={{width:"100%"}} target="_blank">
                <img src={new_join_team ? new_join_team : join_team} alt="" className="fr-fic fr-dib" style={{width:"100%"}}/>
              </a>
            </div>
          </>
        )} 
        {data?.id === 861 && (
          <>
            <div className="">
              <a href="https://mbnconnect.com/" style={{width:"100%"}} target="_blank">
                <img src={new_join_team ? new_join_team : join_team} alt="" className="fr-fic fr-dib" style={{width:"100%"}}/>
              </a>
            </div>
          </>
        )}
      </Section>
     
    )
}

export default JoinTeam;
