import React from 'react'

const MessengerIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      fill='none'
      viewBox='0 0 48 48'
    >
      <rect width='48' height='48' fill='#007FFF' rx='24' />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M25.4906 28.3286L21.6708 24.2547L14.2174 28.3286L22.4161 19.6248L26.3292 23.699L33.6895 19.6248L25.4906 28.3286ZM24 9.625C15.7158 9.625 9 15.8432 9 23.5138C9 27.8847 11.1813 31.7833 14.5901 34.3293V39.625L19.6973 36.8219C21.0605 37.199 22.5045 37.4027 24 37.4027C32.2842 37.4027 39 31.1845 39 23.5138C39 15.8432 32.2842 9.625 24 9.625Z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default MessengerIcon
