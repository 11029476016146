import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { logOutUser } from '../../redux/auth/actions'
import LanguageSelect from '../multiLanguage/LanguageSelect';
import { getLoggedInUser } from '../../utils/helpers';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

export default function LogOut() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const { user } = getLoggedInUser();
  const [userData, setUserData] = useState({ ...user });

  return (
    <>
      <div style={{ "display": "flex", "align-items": "center", "justify-content": "flex-end" }}>
        <div style={{ marginRight: '10px' }}>
          <LanguageSelect />
        </div>
        {(userData?.user_type === null || userData?.user_type === 'sales') && (
          <>
        <Button
          aria-controls='customized-menu'
          aria-haspopup='true'
          variant='contained'
          color='primary'
          style={{ marginRight: '10px' }}
          onClick={() => window.open("https://talkingvcard.com/tutorials")}

        >
          {t('tutorials')}
        </Button>
        <Button
          aria-controls='customized-menu'
          aria-haspopup='true'
          variant='contained'
          color='primary'
          style={{ marginRight: '10px' }}
          onClick={() => window.open("https://talkingvcard.support")}

        >

          {t('support')}

        </Button>
        </>
        )}
        <Button
          aria-controls='customized-menu'
          aria-haspopup='true'
          variant='contained'
          color='primary'
          onClick={handleClick}
        >
          <AccountCircleIcon style={{ color: 'white' }} />
        </Button>
      </div>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to='/constructor'>
          <StyledMenuItem>
            <ListItemIcon>
              <DashboardIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={t("editor")} />
          </StyledMenuItem>
        </Link>
        <Link to='/account'>
          <StyledMenuItem>
            <ListItemIcon>
              <DashboardIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={t("myAccount")} />
          </StyledMenuItem>
        </Link>
        <StyledMenuItem onClick={() => dispatch(logOutUser())}>
          <ListItemIcon>
            <ExitToAppIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary={t("logout")} />
        </StyledMenuItem>
      </StyledMenu>
    </>
  )
}
