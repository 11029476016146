import LinkM from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Copyright() {
  const { t } = useTranslation();

  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {t('copyrightText')}
      <LinkM color='inherit' href={window.location.origin}>
      {t('TalkingVcard')}
      </LinkM>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
