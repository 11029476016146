import * as types from './types'

export const allowBookTime = (bool) => ({
  type: types.ALLOW_BOOK_TIME,
  bool,
})

export const setButtonLabel = (string) => ({
  type: types.SET_BUTTON_LABEL,
  string,
})

export const setTimeFormat = (number) => ({
  type: types.SET_TIME_FORMAT,
  number,
})

export const setSessionDuration = (number) => ({
  type: types.SET_SESSION_DURATION,
  number,
})

export const setTimezone = (string) => ({
  type: types.SET_TIMEZONE,
  string,
})
