import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import React, { useEffect, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContentJson } from '../../../../redux/request/actions'
import {
  deleteCustomLinksFromServer,
  putCustomLinksToServer,
  updateSocialLinks,
  updateSocialLinksFromServer,
} from '../../../../redux/socialLinks/action'
import Phonenumber from '../../../../utils/PhoneNumber'
import DefaultTextField from '../../../kit/DefaultTextField'
import SaveBtn from '../../../kit/SaveBtn'
import Header from '../Header'
import CustomLinks from './CustomLinks'

export default function SocialLinks() {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { vcardDataToEdit, formData } = useSelector((state) => {
    return state.ConstructorReducer
  })

  const stateReducer = useSelector((s) => s.socialLinksReducer)
  const stateFromServer = useMemo(() => {
    return {
      phone_number: vcardDataToEdit?.phone_number,
      'display_call_me?': vcardDataToEdit?.['display_call_me?'],
      'display_text_me?': vcardDataToEdit?.['display_text_me?'],
      'display_email?': vcardDataToEdit?.['display_email?'],
      'display_whatsapp?': vcardDataToEdit?.['display_whatsapp?'],
      'display_add_to_contacts?': vcardDataToEdit?.['display_add_to_contacts?'],
      facebook_link: vcardDataToEdit?.facebook_link,
      facebook_business_page: vcardDataToEdit?.facebook_business_page,
      facebook_business_group: vcardDataToEdit?.facebook_business_group,
      instagram_link: vcardDataToEdit?.instagram_link,
      linkedin_link: vcardDataToEdit?.linkedin_link,
      twitter_link: vcardDataToEdit?.twitter_link,
      connect: vcardDataToEdit?.connect,
      cashapp_link: vcardDataToEdit?.cashapp_link,
      paypal_link: vcardDataToEdit?.paypal_link,
      web_link: vcardDataToEdit?.web_link,
      tiktok_link: vcardDataToEdit?.tiktok_link,
      social_networks: vcardDataToEdit?.social_networks,
      deleted_links: [],
      phone_office: vcardDataToEdit?.phone_office,
      linkedin_company: vcardDataToEdit?.linkedin_company,
      social_link_alignable: vcardDataToEdit?.social_link_alignable,
    }
  }, [vcardDataToEdit])

  const handleChangeData = (e) => {
    let value = e.target.value
    let id = e.target.id
    formData.set(id, value)
    dispatch(updateSocialLinks(id, value))
  }

  const checkboxHandler = (e) => {
    const value = e.target.checked
    const id = e.target.id
    dispatch(updateSocialLinks(id, value))
  }

  function handleChangePhone(number) {
    dispatch(updateSocialLinks('phone_number', number))
  }

  function handleChangeOfficePhone(number) {
    dispatch(updateSocialLinks("phone_office", number))
  }

  const isStatesSame =
    JSON.stringify(stateFromServer) === JSON.stringify(stateReducer)

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isStatesSame) {
      dispatch(fetchContentJson(vcardDataToEdit?.id, stateReducer))
    }
    if (stateReducer?.social_networks.length) {
      dispatch(
        putCustomLinksToServer(
          vcardDataToEdit?.id,
          stateReducer?.social_networks
        )
      )
    }
    if (stateReducer?.deleted_links.length) {
      dispatch(
        deleteCustomLinksFromServer(
          vcardDataToEdit?.id,
          stateReducer?.deleted_links
        )
      )
    }
  }

  useEffect(() => {
    dispatch(updateSocialLinksFromServer(stateFromServer))
  }, [stateFromServer, dispatch])

  return (
    <form onSubmit={saveHandler} noValidate autoComplete='off'>
      <Header disabled={isStatesSame} />

      <Form.Label className='form_title'>{t('phone')}</Form.Label>
      <br />
      <Form.Label className='form_description'>
        {t('enterPhone')}
      </Form.Label>
      <Phonenumber
        key={'socialLinksNumber'}
        onChange={handleChangePhone}
        value={stateReducer?.phone_number}
        phoneNumberFromServer={vcardDataToEdit?.phone_number}
      />
      <FormGroup row>
        <FormControlLabel
          className='form_checkbox'
          control={
            <Checkbox
              checked={!!stateReducer['display_call_me?'] ?? false}
              onChange={checkboxHandler}
              id='display_call_me?'
              name='display_call_me'
            />
          }
          label={t('displayCallMe')}
        />
        <FormControlLabel
          className='form_checkbox'
          control={
            <Checkbox
              checked={!!stateReducer['display_text_me?'] ?? false}
              onChange={checkboxHandler}
              id='display_text_me?'
              name='display_text_me'
            />
          }
          label={t('displaTextMe')}
        />
        <FormControlLabel
          className='form_checkbox'
          control={
            <Checkbox
              checked={!!stateReducer['display_email?']}
              onChange={checkboxHandler}
              id='display_email?'
              name={t('email')}
            />
          }
          label={t('displayEmailButton')}
        />
        <FormControlLabel
          className='form_checkbox'
          control={
            <Checkbox
              checked={!!stateReducer['display_whatsapp?']}
              onChange={checkboxHandler}
              id='display_whatsapp?'
              name='display_whatsapp?'
            />
          }
          label={t('displayWhatsappButton')}
        />
        <FormControlLabel
          className='form_checkbox'
          control={
            <Checkbox
              checked={!!stateReducer['display_add_to_contacts?']}
              onChange={checkboxHandler}
              id='display_add_to_contacts?'
              name='display_add_to_contacts?'
            />
          }
          label={t('displayAddContactButton')}
        />
      </FormGroup>
      <Form.Label className='form_title'>{t('Office')}</Form.Label>
      <br />
      <Phonenumber
        key={'phone_office'}
        onChange={handleChangeOfficePhone}
        value={stateReducer?.phone_office}
        phoneNumberFromServer={vcardDataToEdit?.phone_office}
      />
      <br />
      <DefaultTextField
        onChange={handleChangeData}
        value={stateReducer?.facebook_link || ''}
        label={t('facebook')}
        subtitle={t('facebookProfilelink')}
        placeholder={t('facebookSamplelink')}
        id='facebook_link'
      />
      <DefaultTextField
        onChange={handleChangeData}
        value={stateReducer?.facebook_business_page || ''}
        label={t('facebookPage')}
        subtitle={t('facebookPagelink')}
        placeholder={t('facebookPageSamplelink')}
        id='facebook_business_page'
      />
      <DefaultTextField
        onChange={handleChangeData}
        value={stateReducer?.facebook_business_group || ''}
        label={t('facebookGroup')}
        subtitle={t('facebookGrouplink')}
        placeholder={t('facebookGroupSamplelink')}
        id='facebook_business_group'
      />
      <DefaultTextField
        onChange={handleChangeData}
        value={stateReducer?.instagram_link || ''}
        label={t('instagram')}
        subtitle={t('instagramProfileLink')}
        placeholder={t('instagramSamplelink')}
        id='instagram_link'
      />
      <DefaultTextField
        onChange={handleChangeData}
        value={stateReducer?.linkedin_link || ''}
        label={t('linkedin')}
        subtitle={t('linkedinProfileLink')}
        placeholder={t('linkedinSamplelink')}
        id='linkedin_link'
      />
      <DefaultTextField
        onChange={handleChangeData}
        value={stateReducer?.linkedin_company || ''}
        label={t('linkedinC')}
        subtitle={t('linkedinProfileLinkC')}
        placeholder={t('linkedinSamplelinkC')}
        id='linkedin_company'
      />
      <DefaultTextField
        onChange={handleChangeData}
        value={stateReducer?.connect || ''}
        label={t('Connect')}
        placeholder={t('webSamplelink')}
        id='connect'
      />
      <DefaultTextField
        onChange={handleChangeData}
        value={stateReducer?.twitter_link || ''}
        label={t('twitter')}
        subtitle={t('twitterProfileLink')}
        placeholder={t('twitterSamplelink')}
        id='twitter_link'
      />
      <DefaultTextField
        onChange={handleChangeData}
        value={stateReducer?.paypal_link || ''}
        label={t('paypal')}
        subtitle={t('paypalProfilelink')}
        placeholder={t('paypalSamplelink')}
        id='paypal_link'
      />
      <DefaultTextField
        label={t('cashAPP')}
        subtitle={t('cashAPPProfilelink')}
        placeholder={t('cashAPPSamplelink')}
        value={stateReducer?.cashapp_link || ''}
        id='cashapp_link'
        onChange={handleChangeData}
      />
      <DefaultTextField
        label={t('web')}
        subtitle={t('webProfilelink')}
        placeholder={t('webSamplelink')}
        value={stateReducer?.web_link || ''}
        id='web_link'
        onChange={handleChangeData}
      />
      <DefaultTextField
        label={t('tiktok')}
        subtitle={t('tiktoklink')}
        placeholder={t('webSamplelink')}
        value={stateReducer?.tiktok_link || ''}
        id='tiktok_link'
        onChange={handleChangeData}
      />
      <DefaultTextField
        label={t('alignable')}
        subtitle={t('alignableSubtitle')}
        placeholder={t('alignablelink')}
        value={stateReducer?.social_link_alignable || ''}
        id='social_link_alignable'
        onChange={handleChangeData}
      />
      <CustomLinks socialNetworks={stateReducer?.social_networks} />
      <SaveBtn disabled={isStatesSame} />
    </form>
  )
}
