import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import mainLogo from '../../assets/images/main-logo.png';
import DefaultButton from '../../components/kit/DefaultButton';
import DefaultTextArea from '../../components/kit/DefaultTextArea';
import API from '../../redux/API';

const StyledCancelAppointment = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  padding-top: 100px;
  & form {
    max-width: 500px;
    width: 100%;
  }
  & img {
    max-width: 206px;
    height: auto;
  }
  & button {
    background-color: rgb(0, 102, 214);
    color: #fff;
    margin-top: 30px;
  }
`;
const StyledMessage = styled.p`
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  font-size: 1.125rem;
  color: #4a5568;
`;

const CancelAppointment = () => {
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const vcardId = urlParams.get('vcard_id');
  const [message, setMessage] = useState('');
  const [reason, setReason] = useState({});

  const inputHandler = (e) => {
    setReason({ reason: e.target.value });
  };

  const cancelAppointmentHandler = (e) => {
    e.preventDefault();
    axios({
      method: 'delete',
      url: `${API}/vcards/${vcardId}/appointments/cancel_appointment/${token}`,
      data: reason,
    })
      .then(({ data }) => setMessage(Object.values(data).toString()))
      .catch((err) => console.warn(err));
  };

  return (
    <StyledCancelAppointment>
      <img src={mainLogo} alt="" />
      {message.length > 0 ? (
        <StyledMessage>{message}</StyledMessage>
      ) : (
        <form onSubmit={cancelAppointmentHandler}>
          <DefaultTextArea
            label={t('cancelReason')}
            onChange={inputHandler}
            required
          />
          <DefaultButton textContent={t('cancelAppointment')} type="submit" />
        </form>
      )}
    </StyledCancelAppointment>
  );
};

export default CancelAppointment;
