import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  deleteCustomLink,
  updateCustomLink
} from '../../../../redux/socialLinks/action'
import DefaultTextField from '../../../kit/DefaultTextField'
import ItemWrapper from '../../../kit/ItemWrapper'
import RemoveBtn from '../../../kit/RemoveBtn'

const CustomLinkItem = ({ item }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { icon, title } = item
  const deleteItemHandler = () => dispatch(deleteCustomLink(item.id))
  const updateValueHandler = (e) => {
    const val = e.target.value
    const id = e.target.id
    dispatch(updateCustomLink({ ...item, [id]: val }))
  }

  return (
    <ItemWrapper>
      <DefaultTextField
        label={t('nameIcon')}
        required
        id='icon'
        value={icon}
        onChange={updateValueHandler}
      />
      <DefaultTextField
        label={t('urlIcon')}
        required
        placeholder={t('https')}
        id='title'
        onChange={updateValueHandler}
        value={title}
      />
      <RemoveBtn textContent={t('link')} onClick={deleteItemHandler} />
    </ItemWrapper>
  )
}

export default CustomLinkItem
