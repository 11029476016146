import { Button } from '@material-ui/core';
import MenuBar from './MenuBar';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from '../../utils/helpers';
import Title from './Title';

const CorporateAccount = () => {
  let { user, auth } = getLoggedInUser();
  const [loggedUser, setLoggedUser] = useState({ ...user });

  // 24-10 changes in get all users of that company

  return (
    <div>
      <Title>Account Information</Title>
      <p>Company vCards: {loggedUser?.number_of_vcards}</p>
      <MenuBar />
    </div>
  );
};

export default CorporateAccount;
