import axios from 'axios'
import { getLoggedInUser } from '../../utils/helpers'
import API from '../API'
import { fetchVcardDataToEditor } from '../constructor/actions'
import { sendContent, sendContentSuccess } from '../request/actions'
import * as types from './types'

export const updateSocialLinksFromServer = (data) => ({
  type: types.UPDATE_SOCIAL_LINKS_FROM_SERVER,
  data,
})

export const updateSocialLinks = (label, value) => ({
  type: types.UPDATE_SOCIAL_LINKS,
  label,
  value,
})

export const createCustomLink = (id) => ({
  type: types.CREATE_CUSTOM_LINK,
  id,
})

export const updateCustomLink = (data) => ({
  type: types.UPDATE_CUSTOM_LINK,
  data,
})

export const deleteCustomLink = (id) => ({
  type: types.DELETE_CUSTOM_LINK,
  id,
})

export const deleteCustomLinksFromServer = (vcardId, deleted_links) => {
  let user = getLoggedInUser()
  let promises = deleted_links.map((item) => {
    return axios.delete(`${API}/vcards/${vcardId}/social_networks/${item}`, {
      headers: {
        Authorization: 'Bearer ' + user.auth.token,
        'content-Type': 'application/json',
      },
    })
  })

  return (dispatch) => {
    Promise.all(promises)
      .then((values) => {
        dispatch(clearDeletedCustomLinks())
        dispatch(fetchVcardDataToEditor(vcardId))
      })
      .catch((err) => console.warn('deleteCustomLinksFromServer', err))
  }
}

export const clearDeletedCustomLinks = () => ({
  type: types.CLEAR_DELETED_CUSTOM_LINKS,
})

export const putCustomLinksToServer = (id, data) => {
  let { auth } = getLoggedInUser()
  data = data.map((item) => {
    if (item.id < 0) delete item.id
    return item
  })
  return (dispatch) => {
    dispatch(sendContent())
    axios
      .put(API + `/vcards/${id}/social_networks`, data, {
        headers: {
          Authorization: 'Bearer ' + auth.token,
          'content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        dispatch(sendContentSuccess())
        dispatch(fetchVcardDataToEditor(id))
      })
      .catch((err) => {})
  }
}
