import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContentJson } from '../../../../redux/request/actions'
import {
  addServicesListItem,
  deleteServicesFromServer,
  putServicesToServer,
  updateServicesList,
  updateServicesTitle
} from '../../../../redux/services/action'
import AddBtn from '../../../kit/AddBtn'
import DefaultTextField from '../../../kit/DefaultTextField'
import SaveBtn from '../../../kit/SaveBtn'
import Wrapper from '../../../kit/Wrapper'
import Header from '../Header'
import ServiceItem from './ServiceItem'

const Services = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const { services, deletedServices, serviceTitle } = useSelector(
    (s) => s.servicesReducer
  )

  const { vcardDataToEdit } = useSelector((s) => s.ConstructorReducer)
  const vcardId = vcardDataToEdit?.id

  const servicesFromServer = vcardDataToEdit?.services
  const servicesTitleFromServer = vcardDataToEdit?.section_title

  const updateTitleHandler = (e) => {
    dispatch(updateServicesTitle(e.target.value))
  }

  const addNewServiceHandler = () => {
    const id = Math.round(Math.random() * -1000)
    const service = {
      id,
      title: '',
      body: '',
    }
    dispatch(addServicesListItem(service))
  }

  const isTitlesSame = useMemo(() => {
    return servicesTitleFromServer === serviceTitle
  }, [servicesTitleFromServer, serviceTitle])

  const isServicesSame = useMemo(() => {
    return JSON.stringify(servicesFromServer) === JSON.stringify(services)
  }, [servicesFromServer, services])

  let isMessage = services?.reduce((acc, rec) => {
    const message = rec.title !== '0'
    return acc && message
  }, true)

  const saveHandler = (e) => {
    e.preventDefault()
    if (!isTitlesSame)
      dispatch(fetchContentJson(vcardId, { section_title: serviceTitle }))
    if (!serviceTitle)
      dispatch(fetchContentJson(vcardId, { section_title: t('services') }))
    if (!isServicesSame) {
      if (services.length) dispatch(putServicesToServer(vcardId, services))
      if (deletedServices.length)
        dispatch(deleteServicesFromServer(vcardId, deletedServices))
    }
  }

  useEffect(() => {
    dispatch(updateServicesTitle(servicesTitleFromServer))
    dispatch(updateServicesList(servicesFromServer))
  }, [servicesFromServer, servicesTitleFromServer, dispatch])

  return (
    <form onSubmit={saveHandler}>
      <Header disabled={(isServicesSame && isTitlesSame) || !isMessage} />
      <DefaultTextField
        onChange={updateTitleHandler}
        value={serviceTitle || ''}
        placeholder={t('services')}
        label={t('sectionTitle')}
        subtitle={t('sectionTitleDesc')}
      />
      <Wrapper>
        {services.map((item, idx) => (
          <ServiceItem item={item} key={idx} />
        ))}
        <AddBtn textContent={t('service')} onClick={addNewServiceHandler} />
      </Wrapper>
      <SaveBtn
        array={services}
        disabled={(isServicesSame && isTitlesSame) || !isMessage}
      />
    </form>
  )
}

export default Services
